import { MetricField } from '@/components/metrics/types/MetricField';
import { FormattingParams } from '@/hooks/useFormatting';
import { DEFAULT_AVAILABLE_METRICS } from '@/types/context-shared';
import { FunctionComponent } from 'react';
import Metric from '../../../../components/metrics/Metric';
import {
  canSelectMoreMetrics,
  getColorByIndex,
  getSelectedMetricsCount,
  MAX_METRICS_CAN_SELECT,
  MetricSelectionColorIndex,
} from '../../../../components/metrics/MetricsConfig';
import { MetricModel } from '../../../../components/metrics/models/MetricModel';

interface MetricsListProps {
  visibleMetrics: MetricSelectionColorIndex[];
  metricValues: MetricModel[] | undefined;
  onSingleSelectionChanged: (changedMetric: MetricField) => void;
  availableMetrics?: MetricField[];
  formattingParams?: FormattingParams;
  showComparison?: boolean;
}

const MetricsList: FunctionComponent<MetricsListProps> = ({
  visibleMetrics,
  metricValues,
  onSingleSelectionChanged,
  availableMetrics = DEFAULT_AVAILABLE_METRICS,
  formattingParams,
  showComparison,
}) => {
  const visibleMetricsFiltered = visibleMetrics.filter((metric) => availableMetrics.includes(metric.key));

  const generateTooltipText = (isSelected: boolean, selectedMetricsCount: number, maxMetrics: number): string => {
    const actionText = isSelected ? 'Remove from timeline' : canSelectMoreMetrics(visibleMetrics) ? 'Add to timeline' : 'Timeline full';

    return `${actionText} (${selectedMetricsCount}/${maxMetrics} selected)`;
  };

  return visibleMetricsFiltered.map((visibleMetric) => {
    const isSelected = visibleMetric.isSelected;
    const isDisabled = !isSelected && !canSelectMoreMetrics(visibleMetrics);
    const selectedMetricsCount = getSelectedMetricsCount(visibleMetrics);
    const tooltipText = generateTooltipText(isSelected, selectedMetricsCount, MAX_METRICS_CAN_SELECT);
    const metricValue = metricValues ? metricValues.find((mv) => mv.key === visibleMetric.key) : undefined;

    return (
      <Metric
        key={visibleMetric.key}
        metricField={visibleMetric.key}
        currentValue={metricValue ? metricValue.current : 0}
        previousValue={metricValue ? metricValue.previous : 0}
        isSelected={isSelected}
        selectionChanged={onSingleSelectionChanged}
        isDisabled={isDisabled}
        tooltipText={tooltipText}
        color={getColorByIndex(visibleMetric.colorIndex)}
        formattingParams={formattingParams}
        showComparison={showComparison}
      />
    );
  });
};

export default MetricsList;
