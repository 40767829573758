import { useCallback } from 'react';

/**
 * Hook to handle downloading a CSV string or Base64 image as a file.
 * @returns Functions to trigger CSV and Base64 image downloads.
 */
export const useFileDownload = () => {
  /**
   * Triggers the download of a CSV string as a file.
   * @param csvString The CSV content as a string.
   * @param filename The name of the file (default: 'data.csv').
   */
  const downloadCsvString = useCallback((csvString: string, filename = 'data.csv') => {
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, []);

  /**
   * Triggers the download of a Base64 image as a file.
   * @param base64Src The Base64 image source (data:image/... format).
   * @param filename The name of the file (default: 'image.png').
   */
  const downloadBase64ImageSrc = useCallback((base64Src: string, filename = 'image.png') => {
    const a = document.createElement('a');
    a.href = base64Src;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, []);

  return {
    downloadCsvString,
    downloadBase64ImageSrc,
  };
};
