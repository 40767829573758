import { useState, useEffect } from 'react';

// This is a simple hook that checks if the document is in fullscreen mode and updates state accordingly
export const useFullscreenToggle = (): [boolean, () => void] => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const handleFullscreenChange = (): void => {
    setIsFullscreen(Boolean(document.fullscreenElement));
  };

  const toggleFullscreen = (): void => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  // Add event listeners to detect when the fullscreen status changes
  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return [isFullscreen, toggleFullscreen];
};
