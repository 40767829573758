import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { PlanCapabilities } from '@/modules/plans/types/SubscriptionPlan';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Routes } from '@/router/router-paths';
import { Link } from '@mui/material';
import { isNil } from 'lodash-es';
import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router';
import { toastService } from '@/services/toast.service';
import { MainMenuButton } from './MainMenuButton';
import { MainMenuTextOnlyButton } from './MainMenuTextOnlyButton';

interface MainMenuItemProps {
  pathname: string;
  label: string;
  icon?: ReactElement;
  activePathnames: string[];
  isExpanded: boolean;
  isBeta?: boolean;
  paywallMessage?: string;
  shouldDisplayPaywall?: (planCapabilities: PlanCapabilities) => boolean;
}

export const MainMenuItem: FunctionComponent<MainMenuItemProps> = ({
  activePathnames,
  icon,
  label,
  pathname,
  isExpanded,
  isBeta,
  paywallMessage,
  shouldDisplayPaywall = () => false,
}) => {
  const [isRouteActive, setIsRouteActive] = useState(false);
  const currentLocation = useLocation();

  const { activeTeam } = useActiveTeamContext();

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  useEffect(() => {
    if (currentLocation.pathname) {
      setIsRouteActive(
        activePathnames.some((r) => {
          const regex = new RegExp(`^${r.replace('*', '.*')}$`);
          return regex.test(currentLocation.pathname);
        }),
      );
    }
  }, [activePathnames, currentLocation.pathname]);

  const shouldShowPaywall = !isNil(activeTeam) && shouldDisplayPaywall(activeTeam?.subscriptionPlan);

  function onButtonClicked() {
    if (isNil(activeTeam)) {
      toastService.error('Error active team is not set');
      return;
    }

    if (shouldShowPaywall) {
      setIsPaywallModalOpen(true);
      return;
    }
  }

  const mainMenuButton = icon ? (
    <MainMenuButton isActive={isRouteActive} icon={icon} label={label} isExpanded={isExpanded} isBeta={isBeta} onClick={onButtonClicked} />
  ) : (
    <MainMenuTextOnlyButton isActive={isRouteActive} label={label} isExpanded={isExpanded} isBeta={isBeta} onClick={onButtonClicked} />
  );

  return (
    <>
      {!shouldShowPaywall ? (
        <Link to={pathname} component={RouterLink} underline="none" className="active:text-inherit w-full">
          {mainMenuButton}
        </Link>
      ) : (
        mainMenuButton
      )}
      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.PROFILES}
        headerText={paywallMessage}
      ></PaywallModal>
    </>
  );
};
