import { useEffect, useMemo, useState } from 'react';
import { DARK_THEME } from './dark-theme';
import { BASE_THEME } from './light-theme';

export type ColorMode = 'dark' | 'light';

export function useTheming() {
  const [mode, setMode] = useState<ColorMode>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        return;
      },
    }),
    [],
  );

  useEffect(() => {
    const rootElement = document.body as HTMLElement;
    if (!rootElement) {
      return;
    }

    if (mode === 'dark') {
      rootElement.classList.add('dark');
      rootElement.classList.remove('light');
    } else {
      rootElement.classList.add('light');
      rootElement.classList.remove('dark');
    }
  }, [mode]);

  const theme = useMemo(() => {
    return mode === 'dark' ? DARK_THEME : BASE_THEME;
  }, [mode]);

  return {
    theme,
    mode,
    colorMode,
    setMode,
  };
}
