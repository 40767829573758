import { useState } from 'react';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';

export const useDeleteConfirmation = ({
  questionText,
  headerText,
  onDelete,
  confirmButtonText = 'Delete',
}: {
  questionText: string;
  headerText: string;
  onDelete: () => void;
  confirmButtonText?: string;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDelete = () => {
    onDelete();
    setIsModalOpen(false);
  };

  const ModalComponent = (
    <DeleteConfirmationModal
      confirmButtonText={confirmButtonText}
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      onConfirmDelete={handleConfirmDelete}
      questionText={questionText}
      headerText={headerText}
    />
  );

  return { ModalComponent, handleOpenModal };
};
