import { useEffect, useRef, useState } from 'react';

export type HandleSize = 'small' | 'medium' | 'large';

export const useDynamicHandleSize = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [handleSize, setHandleSize] = useState<HandleSize>('medium');

  useEffect(() => {
    if (!containerRef.current) return;

    const observer = new ResizeObserver(([entry]) => {
      const width = entry.contentRect.width;

      // Logic to determine drag handle size based on parent width
      if (width < 300) {
        setHandleSize('small');
      } else if (width < 600) {
        setHandleSize('medium');
      } else {
        setHandleSize('large');
      }
    });

    observer.observe(containerRef.current);

    // Cleanup observer
    return () => {
      observer.disconnect();
    };
  }, []);

  return { containerRef, handleSize };
};
