import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import ShowXTimesAlert from '@/components/feedback/ShowXTimesAlert';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultAdvertisedProductsFilters } from '@/components/filter-builder/models/AlFilterModel';
import useEscapableToggle from '@/hooks/useEscapableToggle';
import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { ShowPaywallParams, usePaywall } from '@/modules/plans/hooks/usePaywall';
import { createAdvertisedProductsWithTimelineQueryKey, productsService } from '@/modules/products/api/products-service';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { toastService } from '@/services/toast.service';
import { ADVERTISED_PRODUCT_CONTEXT_KEY } from '@/types/context-shared';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import AdvertisedProductsFilterBar from '../components/AdvertisedProductsFilterBar';
import AdvertisedProductsMetrics from '../components/AdvertisedProductsMetrics';
import AdvertisedProductsTimelineChart from '../components/AdvertisedProductsTimelineChart';
import AdvertisedProductsTable from '../components/advertised-products-table/AdvertisedProductsTable';
import { AdvertisedProductsProvider } from '../contexts/AdvertisedProductsContext';
import { SelectedAdvertisedProduct } from '../models/AdvertisedProductModel';

let isPaywallDisplayedBefore = false;

const AdvertisedProductsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam, activeProfile, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { refetchUser } = useUserContext();
  const { showPaywallWithDelay } = usePaywall();

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render
    return filtersService.loadProfileFilters(ADVERTISED_PRODUCT_CONTEXT_KEY, activeProfile?.id ?? '', getDefaultAdvertisedProductsFilters());
  });

  const [selectedAdvertisedProducts, setSelectedAdvertisedProducts] = useState<SelectedAdvertisedProduct[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const advertisedProductsTableGridApiRef = useRef<GridApi | null>(null);
  // function clearSelections() {
  //   setSelectedAdvertisedProducts([]);
  //   if (advertisedProductsTableGridApiRef.current && !advertisedProductsTableGridApiRef.current.isDestroyed()) {
  //     advertisedProductsTableGridApiRef.current.deselectAll();
  //   }
  // }

  const advertisedProductsWithTimelineQueryKey = createAdvertisedProductsWithTimelineQueryKey(activeProfile?.id, filters);
  const {
    data: advertisedProductsWithTimeline,
    isLoading: isAdvertisedProductsRowDataLoading,
    isError: isAdvertisedProductsLoadingError,
    error: advertisedProductsLoadingError,
    isFetching,
  } = useQuery({
    queryKey: advertisedProductsWithTimelineQueryKey,

    queryFn: async () => {
      const result = await productsService.getAdvertisedProductsWithTimeline(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading advertisedProducts ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(filters) && !isEmpty(activeProfile?.id),
  });

  useGlobalLoadingStateObserver('isFetching advertised products', isFetching);

  function onAdvertisedProductsTableGridReady(params: GridReadyEvent) {
    advertisedProductsTableGridApiRef.current = params.api;
  }

  useEffect(() => {
    const paywallDelay = isPaywallDisplayedBefore ? 0 : 10;
    const paywallParms: ShowPaywallParams = {
      message: 'Upgrade to Pro to Access the AdvertisedProducts Tool Suite',
      delayInSeconds: paywallDelay,
    };

    if (hasUserOverMaxAllowedFreeProfiles()) {
      showPaywallWithDelay(paywallParms);
      isPaywallDisplayedBefore = true;
    }

    if (isNil(activeTeam)) {
      toastService.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canUseAdvertisedProductsPage) {
      showPaywallWithDelay(paywallParms);
      isPaywallDisplayedBefore = true;
      return;
    }
  }, []);

  // // TUTORIAL MODAL
  // const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  // const onTutorialModalClose = () => {
  //   setIsTutorialModalOpen(false);
  // };

  const [isTableExpanded, toggleTableExpanded] = useEscapableToggle(false);

  return (
    <>
      <PageLayout showFullscreen={isTableExpanded}>
        {!isTableExpanded && (
          <PageLayoutTopBar
            header={
              <div className="flex items-center gap-x-2">
                <div>{t('advertised_products')}</div>
                <div>
                  <Tooltip
                    title={
                      'Product advertising data is only for Sponsored Products & Display (Amazon does not provide product-level data for Sponsored Brands)'
                    }
                  >
                    <span className="flex items-center font-medium">
                      <InfoIcon fontSize="small" style={{ marginRight: '6px' }} />
                    </span>
                  </Tooltip>
                </div>
              </div>
            }
            actions={
              <div className="flex flex-row items-center gap-2">
                {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
                <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
                <TeamSelect />
                <ProfileSyncSelectButton setFilters={setFilters} />
              </div>
            }
          />
        )}
        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding={isTableExpanded} suppressHorizontalPadding={isTableExpanded}>
            <AdvertisedProductsProvider filters={filters} setFilters={setFilters}>
              {!isTableExpanded && (
                <ShowXTimesAlert
                  timesToDisplay={3}
                  title="Advertised Product Data"
                  content="Advertised Product data is only for Sponsored Products & Display (Amazon does not provide product-level data for Sponsored Brands)"
                  userSettingKey={UserSettingKey.ADVERTISED_PRODUCTS_INFO_MESSAGE_DISPLAY_COUNT}
                />
              )}

              {!isTableExpanded && (
                <div className="mt-2">
                  <AlErrorBoundary>
                    <AdvertisedProductsMetrics />
                  </AlErrorBoundary>
                </div>
              )}
              {!isTableExpanded && (
                <div className="my-2">
                  <AlErrorBoundary>
                    <AdvertisedProductsTimelineChart />
                  </AlErrorBoundary>
                </div>
              )}

              <AlErrorBoundary>
                <AdvertisedProductsFilterBar
                  withTimeline={advertisedProductsWithTimeline}
                  gridApiRef={advertisedProductsTableGridApiRef}
                  isExpanded={isTableExpanded}
                  onExpandTable={toggleTableExpanded}
                />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <AdvertisedProductsTable
                  withTimeline={advertisedProductsWithTimeline}
                  isLoading={isAdvertisedProductsRowDataLoading}
                  selectedAdvertisedProducts={selectedAdvertisedProducts}
                  setSelectedAdvertisedProducts={setSelectedAdvertisedProducts}
                  advertisedProductsLoadingErrorMessage={
                    advertisedProductsLoadingError instanceof Error ? advertisedProductsLoadingError.message : ''
                  }
                  isAdvertisedProductsLoadingError={isAdvertisedProductsLoadingError}
                  onGridReadyCallback={onAdvertisedProductsTableGridReady}
                  noTopBorderRadius={true}
                  isExpanded={isTableExpanded}
                />
              </AlErrorBoundary>
            </AdvertisedProductsProvider>
          </PageLayoutBody>
        )}
      </PageLayout>
      {/* <AlErrorBoundary>
        <AdvertisedProductsSelectionActionsBar
          selectedAdvertisedProducts={selectedAdvertisedProducts}
          setSelectedAdvertisedProducts={setSelectedAdvertisedProducts}
          totalAdvertisedProductsCount={advertisedProductsWithTimeline?.advertisedProducts ? advertisedProductsWithTimeline.advertisedProducts.length : 0}
          onDiscardClicked={clearSelections}
          advertisedProductsTableGridApiRef={advertisedProductsTableGridApiRef}
        />
      </AlErrorBoundary> */}

      {/* <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={''}
        title={'How to Use the Search Terms Tab'}
      /> */}
    </>
  );
};

export default AdvertisedProductsPage;
