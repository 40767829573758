import VideoModal from '@/components/modals/video-modal/VideoModal';
import { VideoUrl } from '@/config/urls';
import { InfoOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Button, Popover } from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';
import { DataGroupModel } from '../models/DataGroup';
import { CreateDataGroup } from './CreateDataGroup';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import { UserSettingKey } from '@/modules/users';

interface DataGroupsActionsProps {
  rowData: DataGroupModel[] | undefined;
  refetch: () => void;
}

const DataGroupsActions: FunctionComponent<DataGroupsActionsProps> = ({ rowData, refetch }) => {
  // CREATE GROUP
  const createButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isCreatePopoverOpen, setIsCreatePopoverOpen] = useState(false); // state to control the popover
  const onCreateClicked = () => {
    setIsCreatePopoverOpen((previousValue) => !previousValue);
  };

  const handleCreatePopoverClose = () => {
    setIsCreatePopoverOpen((previousValue) => !previousValue);
  };

  const onCreateConfirmed = () => {
    refetch();
    setIsCreatePopoverOpen((previousValue) => !previousValue);
  };

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(
    UserSettingKey.DATA_GROUPS_TUTORIAL_DISPLAY_COUNT,
  );

  return (
    <>
      <div className="flex flex-row my-4 gap-x-2">
        <Button ref={createButtonRef} onClick={onCreateClicked} variant="contained" startIcon={<AddIcon />}>
          Create New Tag
        </Button>

        <Button onClick={() => openTutorialModal()} variant="text" startIcon={<OndemandVideoIcon />}>
          How to Use Tags
        </Button>

        <div className="flex-grow"></div>

        <div className="flex items-center gap-2 h-9">
          <InfoOutlined /> Tags are shared across the entire team
        </div>
      </div>

      <Popover
        id={'create-group-popover'}
        open={isCreatePopoverOpen}
        anchorEl={createButtonRef.current}
        onClose={handleCreatePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{ paper: { style: { maxWidth: 500 } } }}
      >
        <CreateDataGroup existingGroups={rowData ?? []} onClose={handleCreatePopoverClose} onCreateConfirmed={onCreateConfirmed} />
      </Popover>

      <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={VideoUrl.DATA_GROUPS}
        title={'How to Use Tags in AdLabs'}
      />
    </>
  );
};

export default DataGroupsActions;
