import { CampaignAdType, EnabledPausedArchivedState, TargetingType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { AdGroupModel } from '@/modules/optimizer/api/campaign/models/AdGroupModel';
import { CampaignToAdGroupsDTO } from '@/modules/optimizer/api/campaign/models/CampaignModel';

export class CampaignToAdGroups {
  id: string;
  name: string;
  campaignAdType: CampaignAdType;
  state: EnabledPausedArchivedState;
  adGroupIdToAdGroup: Record<string, AdGroupModel>;
  targetingType: TargetingType;
  isVideo: boolean;
  constructor(
    id: string,
    name: string,
    campaignAdType: CampaignAdType,
    adGroupIdToAdGroup: Record<string, AdGroupModel>,
    targetingType: TargetingType,
    isVideo: boolean,
  ) {
    this.id = id;
    this.name = name;
    this.campaignAdType = campaignAdType;
    this.state = EnabledPausedArchivedState.ENABLED;
    this.adGroupIdToAdGroup = adGroupIdToAdGroup;
    this.targetingType = targetingType;
    this.isVideo = isVideo;
  }

  static fromDTO(dto: CampaignToAdGroupsDTO): CampaignToAdGroups {
    const adGroupIdToAdGroup: Record<string, AdGroupModel> = {};
    dto.ags.forEach((adGroupDTO) => {
      const adGroup = AdGroupModel.fromResponseArray([adGroupDTO])[0];
      adGroupIdToAdGroup[adGroup.id] = adGroup;
    });

    return new CampaignToAdGroups(dto.i, dto.n, dto.at, adGroupIdToAdGroup, dto.tt, dto.iv);
  }

  public canAddTargets(): boolean {
    return (
      this.state === EnabledPausedArchivedState.ENABLED &&
      this.campaignAdType !== CampaignAdType.DISPLAY &&
      this.campaignAdType !== CampaignAdType.TV &&
      this.targetingType !== TargetingType.AUTO
    );
  }

  static fromDTOArray(dtos: CampaignToAdGroupsDTO[] | null): CampaignToAdGroups[] {
    if (!dtos) {
      return [];
    }
    return dtos.map((dto) => CampaignToAdGroups.fromDTO(dto));
  }
}
