import useFormatting, { FormattingParams } from '@/hooks/useFormatting';
import { getColorClassBySentimentCurrentPrevious } from '@/types/colors.enum';
import { Card, CardActionArea, CardContent, Tooltip, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import './Metrics.css';
import { METRICS } from './MetricsConfig';
import { MetricField } from './types/MetricField';

interface MetricProps {
  metricField: MetricField;
  isSelected: boolean;
  selectionChanged: (changedMetric: MetricField) => void;
  isDisabled: boolean;
  tooltipText: string;
  currentValue: number;
  previousValue: number;
  color: string | undefined;
  formattingParams?: FormattingParams;
  showComparison?: boolean;
}

const Metric: FunctionComponent<MetricProps> = ({
  metricField,
  isSelected,
  selectionChanged,
  isDisabled,
  tooltipText,
  currentValue,
  previousValue,
  color,
  formattingParams,
  showComparison = true,
}) => {
  const { formatChangeInUnsignedPercentWithArrow, getShortFormatterForMetricField } = useFormatting();

  const handleCardClick = () => {
    if (isDisabled) return;
    selectionChanged(metricField);
  };

  const config = METRICS[metricField];

  const colorClass = config ? getColorClassBySentimentCurrentPrevious(config?.sentimentDirection, currentValue, previousValue) : '';
  const formattedChange = formatChangeInUnsignedPercentWithArrow(currentValue, previousValue);

  const valueFormatter = (value: number | undefined): string => {
    if (!config?.key) return '';
    return getShortFormatterForMetricField(config?.key)(value, formattingParams);
  };

  return (
    <Card className="min-w-36 rounded-lg" onClick={handleCardClick}>
      <Tooltip title={tooltipText}>
        <CardActionArea disableRipple={isDisabled} disableTouchRipple={isDisabled}>
          <CardContent className={`${isDisabled ? 'disabled-opacity-reduce' : ''} py-2`}>
            <Typography className="text-sm font-medium opacity-90 text-primary-950">{config?.title}</Typography>
            <Typography className="mt-1 text-xl font-semibold">{valueFormatter(currentValue)}</Typography>
            {showComparison && (
              <Typography className="whitespace-no-wrap flex justify-between text-xs font-medium">
                <span className="self-start opacity-90">{valueFormatter(previousValue)}</span>
                <span className={`self-end ${colorClass}`}>{formattedChange}</span>
              </Typography>
            )}
          </CardContent>
          <div style={{ backgroundColor: isSelected && color ? color : '', height: 5 }} />
        </CardActionArea>
      </Tooltip>
    </Card>
  );
};

export default Metric;
