import { Edit } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent } from 'react';

export interface ITargetEstimateCellRendererParams {
  target: number | null;
  estimate: number | null;
  isEditable: boolean;
  formatter: (value: number | null) => string;
  tooltip?: React.ReactNode;
}

interface ITargetEstimateInternalRendererParams extends ITargetEstimateCellRendererParams, ICellRendererParams {}
// TODO: remove this custom renderer once design settles and is too similar to text cell renderer
const TargetEstimateCellRenderer: FunctionComponent<ITargetEstimateInternalRendererParams> = ({
  target,
  isEditable = true,
  api,
  node,
  column,
  tooltip,
  formatter,
}) => {
  const handleEditIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent the grid from processing the click
    const colKey = column?.getId();

    if (isEditable && api && !isNil(node.rowIndex) && colKey) {
      api.startEditingCell({
        rowIndex: node.rowIndex,
        colKey,
      });
    }
  };

  if (!target) {
    return (
      <>
        <div className="flex flex-1 justify-end cursor-text">
          <Tooltip title="Set monthly Target to see this month's estimate">
            <span className="opacity-30 hover:opacity-100 cursor-pointer">
              <Edit fontSize="inherit" />
            </span>
          </Tooltip>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="flex flex-1 justify-end">
        <Tooltip title={tooltip}>
          <div className="text-right transition-opacity ">
            <div className="cursor-text" onClick={handleEditIconClick}>
              <Typography variant="body2" className="font-normal">
                {formatter(target) ?? '-'}
              </Typography>
            </div>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default TargetEstimateCellRenderer;
