import { PropsWithChildren, FunctionComponent, ReactNode } from 'react';

interface SecondaryMenuLayoutProps extends PropsWithChildren {
  secondaryMenu: ReactNode;
}

export const SecondaryMenuLayout: FunctionComponent<SecondaryMenuLayoutProps> = ({ children, secondaryMenu }) => {
  return (
    <div className="flex h-full flex-grow">
      {secondaryMenu}

      <div className="w-full">{children}</div>
    </div>
  );
};
