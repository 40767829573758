import { DEFAULT_VISIBLE_METRICS, METRIC_COLORS, MetricColor, MetricSelectionColorIndex } from '@/components/metrics/MetricsConfig';
import { MetricField } from '@/components/metrics/types/MetricField';
import { UserSettingKey } from '@/modules/users';
import { DEFAULT_AVAILABLE_METRICS } from '@/types/context-shared';
import { useEffect, useState } from 'react';
import { useUserSetting } from './useUserSetting';

const useMetricsInContext = (settingKey: UserSettingKey, availableMetrics: MetricField[] = DEFAULT_AVAILABLE_METRICS) => {
  const { handleSettingStateChange: updateVisibleMetrics, settingState: visibleMetricsSetting } =
    useUserSetting<MetricSelectionColorIndex[]>(settingKey);

  // TODO: remove legacy handling after 1. aug 2025 (legacy conversion added nov 2024). By then all of the users should have moved on to new structure. Also remove old keys from db
  // Legacy handling
  const { settingState: legacySelectedMetricsSetting } = useUserSetting<MetricField[]>(UserSettingKey.SELECTED_METRICS);
  const { settingState: legacySelectedMetricColorsSetting } = useUserSetting<MetricColor[]>(UserSettingKey.SELECTED_METRIC_COLORS);
  const { settingState: legacyVisibleMetricsSetting } = useUserSetting<MetricField[]>(UserSettingKey.VISIBLE_METRICS);

  function getSavedVisibleMetrics(): MetricSelectionColorIndex[] {
    if (visibleMetricsSetting) return visibleMetricsSetting;

    const convertedSettingFromLegacy = getLegacyMetricSetting();
    if (convertedSettingFromLegacy) return convertedSettingFromLegacy;

    return DEFAULT_VISIBLE_METRICS;
  }

  function getLegacyMetricSetting(): MetricSelectionColorIndex[] | null {
    if (!legacySelectedMetricsSetting || !legacySelectedMetricColorsSetting || !legacyVisibleMetricsSetting) {
      return null;
    }

    return legacyVisibleMetricsSetting.map((metric) => {
      function legacyColorToIndex(color: string): number {
        return METRIC_COLORS.indexOf(color);
      }

      const legacyColor = legacySelectedMetricColorsSetting.find((color) => color.key === metric)?.color;
      const colorIndex = legacyColor ? legacyColorToIndex(legacyColor) : -1;

      return {
        key: metric,
        isSelected: legacySelectedMetricsSetting.includes(metric),
        colorIndex: colorIndex,
      };
    });
  }

  const [visibleMetrics, setVisibleMetrics] = useState<MetricSelectionColorIndex[]>(() =>
    getSavedVisibleMetrics().filter((metric) => availableMetrics.includes(metric.key)),
  );

  useEffect(() => {
    updateVisibleMetrics(visibleMetrics);
  }, [visibleMetrics]);
  return {
    visibleMetrics,
    setVisibleMetrics,
  };
};

export default useMetricsInContext;
