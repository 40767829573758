import { Navigate, RouteObject } from 'react-router';
import DashboardsLayout from '../layouts/DashboardsLayout';
import { DashboardPage } from '../pages/DashboardPage';
import DashboardsOverviewPage from '../pages/DashboardsOverviewPage';

export const DASHBOARDS_ROUTES: RouteObject[] = [
  {
    path: 'dashboards',
    element: <DashboardsLayout />,

    children: [
      {
        index: true,
        element: <Navigate to="overview" replace />,
      },
      {
        path: 'overview',
        element: <DashboardsOverviewPage />,
      },
      {
        path: ':dashboardId?',
        element: <DashboardPage />,
      },
    ],
  },
];
