import useFormatting from '@/hooks/useFormatting';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Button, Checkbox, Divider, Tooltip, useTheme } from '@mui/material';
import { Fragment, FunctionComponent, useRef, useState } from 'react';
import OppositeModeOverlayBar from '../../../components/OppositeModeOverlayBar';
import { SelectedPlacement } from '../models/PlacementsModel';
import PlacementsBulkEditPopover from './PlacementsBulkEditPopover';
import usePlacementWarnings from '../hooks/usePlacementWarnings';
import InfoIcon from '@mui/icons-material/InfoOutlined';

interface PlacementsSelectionActionsBarProps {
  selectedPlacements: SelectedPlacement[];
  totalPlacementsCount: number;
  onDiscardClicked: () => void;
}

const PlacementsSelectionActionsBar: FunctionComponent<PlacementsSelectionActionsBarProps> = ({
  selectedPlacements,
  totalPlacementsCount,
  onDiscardClicked,
}) => {
  const { formatWithThousandsSeparator } = useFormatting();
  const theme = useTheme();

  // Bulk Edit
  const bulkEditButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isBulkEditPopoverOpen, setIsBulkEditPopoverOpen] = useState(false);
  const handleBulkEditPopoverClose = () => setIsBulkEditPopoverOpen(false);

  const onBulkEditClicked = () => {
    setIsBulkEditPopoverOpen(true);
  };

  const { warnings } = usePlacementWarnings({ selectedPlacements });

  const warningTooltipContent = warnings ? (
    <>
      {warnings.map((warning, index) => (
        <Fragment key={index}>
          {'\u24D8'} {warning}
          <br />
        </Fragment>
      ))}
    </>
  ) : null;

  return (
    <>
      <OppositeModeOverlayBar visible={selectedPlacements.length > 0}>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <div onClick={onDiscardClicked} className="flex flex-row text-sm font-bold items-center hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selectedPlacements.length)} of {formatWithThousandsSeparator(totalPlacementsCount)}
            </div>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row gap-4 whitespace-nowrap items-center">
            {warnings.length > 0 && (
              <Tooltip title={warningTooltipContent}>
                <span className="flex items-center font-medium">
                  <InfoIcon fontSize="small" style={{ marginRight: '6px', color: theme.palette.info.light }} />
                  {formatWithThousandsSeparator(warnings.length)} {warnings.length == 1 ? 'Notice' : 'Notices'}
                </span>
              </Tooltip>
            )}

            <Button
              ref={bulkEditButtonRef}
              size="small"
              className="whitespace-nowrap"
              onClick={onBulkEditClicked}
              variant={isBulkEditPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AssignmentOutlinedIcon />}
            >
              Bulk Actions
            </Button>
          </div>
        </div>
      </OppositeModeOverlayBar>

      <PlacementsBulkEditPopover
        buttonRef={bulkEditButtonRef}
        isOpen={isBulkEditPopoverOpen}
        onClose={handleBulkEditPopoverClose}
        selectedPlacements={selectedPlacements}
      />
    </>
  );
};

export default PlacementsSelectionActionsBar;
