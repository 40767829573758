import { getASINFromExpression, isExpression, isValidASIN, removeInvalidKeywords, standardizeASIN } from '@/modules/application/amazon-utils';
import { CampaignAdType, EnabledPausedArchivedState, TargetingType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { isEmpty, isNil } from 'lodash-es';
import {
  createNegativesParamKeyToNegativeMatchType,
  CreateNegativesParams,
  SelectedSearchTerm,
} from '../../search-terms/models/SearchTermModel';
import { CAMPAIGN_NEGATIVES, CreateNegativeDTO, NegativeMatchType, PRODUCT_TARGET_NEGATIVES } from '../api/negative-targets-contracts';
import { CampaignToAdGroups } from './CampaignToAdGroupModel';

const MOCK_VALID_KEYWORDS_ASINS = ['search-term', 'B07PGL2N7J'];

export class CreateNegativeModel {
  private dto: CreateNegativeGeneratorArguments;
  public negativeMatchType: NegativeMatchType;
  public entityType: TargetEntityType;

  constructor(dto: CreateNegativeGeneratorArguments, negativeMatchType: NegativeMatchType, entityType: TargetEntityType) {
    this.dto = dto;
    this.negativeMatchType = negativeMatchType;
    this.entityType = entityType;
  }

  public get keywordOrAsin(): string {
    return this.dto.keywordOrAsin;
  }

  public get campaignAdType(): CampaignAdType {
    return this.dto.campaignAdType;
  }

  public get campaignId(): string {
    return this.dto.campaignId;
  }

  public get adGroupId(): string {
    return this.isCampaign ? '' : (this.dto.adGroupId ?? '');
  }

  public get isCampaign(): boolean {
    return CAMPAIGN_NEGATIVES.includes(this.negativeMatchType);
  }

  public get isProductTarget(): boolean {
    return PRODUCT_TARGET_NEGATIVES.includes(this.negativeMatchType);
  }

  public get rowSelectWarning(): string | null {
    if (this.entityType == TargetEntityType.KEYWORD) {
      const { warnings } = removeInvalidKeywords([this.keywordOrAsin], [this.negativeMatchType]);
      if (warnings.size > 0) {
        return warnings.values().next().value;
      }
    }
    return null;
  }

  public static fromSelectedSearchTermArrayAndParams(
    selectedSearchTerms: SelectedSearchTerm[],
    params: CreateNegativesParams,
  ): CreateNegativeModel[] {
    const models: CreateNegativeModel[] = [];

    for (const searchTerm of selectedSearchTerms) {
      const negArgs: CreateNegativeGeneratorArguments = {
        campaignId: searchTerm.campaignId,
        campaignAdType: searchTerm.campaignAdType,
        keywordOrAsin: searchTerm.searchTerm,
        adGroupId: searchTerm.adGroupId,
        entityType: searchTerm.entityType,
        targetingType: searchTerm.targetingType,
      };

      models.push(...CreateNegativeModel.generateCreateNegativeModels(negArgs, params));
    }

    return models;
  }

  public static generateCreateNegativeModels(
    negArgs: CreateNegativeGeneratorArguments,
    params: CreateNegativesParams,
  ): CreateNegativeModel[] {
    const models: CreateNegativeModel[] = [];

    // TEMPORARY
    if (negArgs.campaignAdType == CampaignAdType.DISPLAY) {
      return [];
    }

    if (negArgs.campaignAdType == CampaignAdType.TV) {
      return [];
    }

    // If a target type is KW then search term is always keyword
    // However, if target type is PT (e.g., substitutes) then search term can be both keyword or asin
    const targetEntityType = negArgs.entityType; // search term does not have type, only target has

    const isValidAdGroupId = !isEmpty(negArgs.adGroupId) && !isNil(negArgs.adGroupId);
    const isTargetKW = targetEntityType == TargetEntityType.KEYWORD;
    const isTargetPT = targetEntityType == TargetEntityType.PRODUCT_TARGET;
    const isAutoTarget = negArgs.targetingType == TargetingType.AUTO;

    let isSearchTermValidAsin = isValidASIN(negArgs.keywordOrAsin);
    if (!isSearchTermValidAsin && isExpression(negArgs.keywordOrAsin)) {
      isSearchTermValidAsin = isValidASIN(getASINFromExpression(negArgs.keywordOrAsin));
    }

    const isSearchTermKW = !isSearchTermValidAsin;

    // RULES
    // Campaign Level keywords and product targets can be created only to sponsored product campaigns
    if (negArgs.campaignAdType == CampaignAdType.PRODUCTS) {
      if (isSearchTermKW) {
        models.push(new CreateNegativeModel(negArgs, NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT, targetEntityType));
        models.push(new CreateNegativeModel(negArgs, NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE, targetEntityType));
      }

      if (isAutoTarget && isSearchTermValidAsin) {
        models.push(
          new CreateNegativeModel(
            { ...negArgs, keywordOrAsin: standardizeASIN(negArgs.keywordOrAsin) },
            NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET,
            targetEntityType,
          ),
        );
      }
    }

    if (isValidAdGroupId) {
      if ((isTargetKW && isSearchTermKW) || (isTargetPT && isAutoTarget && isSearchTermKW)) {
        models.push(new CreateNegativeModel(negArgs, NegativeMatchType.AD_GROUP_NEGATIVE_EXACT, targetEntityType));
        models.push(new CreateNegativeModel(negArgs, NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE, targetEntityType));
      }

      if (isTargetPT && isSearchTermValidAsin) {
        models.push(
          new CreateNegativeModel(
            { ...negArgs, keywordOrAsin: standardizeASIN(negArgs.keywordOrAsin) },
            NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET,
            targetEntityType,
          ),
        );
      }
    }

    // Filter models based on params
    const enabledPrams: NegativeMatchType[] = [];
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        enabledPrams.push(createNegativesParamKeyToNegativeMatchType[key as keyof CreateNegativesParams]);
      }
    });
    return models.filter((m) => enabledPrams.includes(m.negativeMatchType));
  }

  public static canCreateSomeNegativesForCampaign(campaign: CampaignToAdGroups, params: CreateNegativesParams): boolean {
    if (campaign.state === EnabledPausedArchivedState.ARCHIVED) {
      return false;
    }

    for (const adGroup of Object.values(campaign.adGroupIdToAdGroup)) {
      for (const mockKeywordOrAsin of MOCK_VALID_KEYWORDS_ASINS) {
        const negArgs: CreateNegativeGeneratorArguments = {
          entityType: adGroup.entityType,
          targetingType: campaign.targetingType,
          adGroupId: adGroup.id,
          campaignId: campaign.id,
          keywordOrAsin: mockKeywordOrAsin,
          campaignAdType: campaign.campaignAdType,
        };

        const models = CreateNegativeModel.generateCreateNegativeModels(negArgs, params);

        if (models.length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  public static canCreateAnyNegativesForAdGroup(args: AdGroupNegativeCheckArguments, selectedParams: CreateNegativesParams): boolean {
    // Do not check for campaigns. Further limiting by selected ones from selectedParams
    const params: CreateNegativesParams = {
      ...selectedParams,
      campaignNegativeExact: false,
      campaignNegativePhrase: false,
      campaignNegativeProductTarget: false,
    };

    for (const mockKeywordOrAsin of MOCK_VALID_KEYWORDS_ASINS) {
      const negArgs: CreateNegativeGeneratorArguments = {
        entityType: args.entityType,
        targetingType: args.targetingType,
        adGroupId: args.adGroupId,
        campaignId: args.campaignId,
        keywordOrAsin: mockKeywordOrAsin,
        campaignAdType: args.campaignAdType,
      };

      const models = CreateNegativeModel.generateCreateNegativeModels(negArgs, params);
      if (models.length > 0) {
        return true;
      }
    }

    return false;
  }

  // Slower than canCreateAnyNegativesForAdGroup because needs to go through more
  public getTypesOfNegativesThatCanCreateForAdGroup(
    args: AdGroupNegativeCheckArguments,
    selectedParams: CreateNegativesParams,
  ): NegativeMatchType[] {
    const params: CreateNegativesParams = {
      ...selectedParams,
      campaignNegativeExact: false,
      campaignNegativePhrase: false,
      campaignNegativeProductTarget: false,
    };

    // Array of all the types of negatives that want to be created by params
    const negativesOfInterest = Object.entries(selectedParams)
      .filter(([, value]) => value === true)
      .map(([key]) => createNegativesParamKeyToNegativeMatchType[key as keyof CreateNegativesParams]);

    // Find all the negatives that can be created by params AND can be actually created by rules
    const creatableNegatives = new Set<NegativeMatchType>();
    for (const mockKeywordOrAsin of MOCK_VALID_KEYWORDS_ASINS) {
      const negArgs: CreateNegativeGeneratorArguments = {
        entityType: args.entityType,
        targetingType: args.targetingType,
        adGroupId: args.adGroupId,
        campaignId: args.campaignId,
        keywordOrAsin: mockKeywordOrAsin,
        campaignAdType: args.campaignAdType,
      };

      const models = CreateNegativeModel.generateCreateNegativeModels(negArgs, params);

      models.forEach((model) => {
        if (negativesOfInterest.includes(model.negativeMatchType)) {
          creatableNegatives.add(model.negativeMatchType);
        }
      });

      // Check if all that want to be created are creatable and if there's a need to check any further
      if (creatableNegatives.size === negativesOfInterest.length) {
        break;
      }
    }

    return Array.from(creatableNegatives);
  }

  public toDTO(): CreateNegativeDTO {
    return {
      campaign_id: this.campaignId,
      ad_group_id: this.adGroupId,
      expression: this.isProductTarget ? this.keywordOrAsin : undefined,
      keyword: this.isProductTarget ? undefined : this.keywordOrAsin,
      negative_match: this.negativeMatchType,
      ad_type: this.campaignAdType,
    };
  }
}

export interface CreateNegativeGeneratorArguments {
  entityType: TargetEntityType;
  targetingType: TargetingType;
  adGroupId: string | null; // null when only want to create campaign level negatives
  campaignId: string;
  keywordOrAsin: string;
  campaignAdType: CampaignAdType;
}

export interface AdGroupNegativeCheckArguments {
  campaignId: string;
  adGroupId: string;
  campaignAdType: CampaignAdType;
  targetingType: TargetingType;
  entityType: TargetEntityType;
}
