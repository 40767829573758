import { useTranslation } from '@/lib';
import { FunctionComponent } from 'react';
import DropZone from '@/components/form/Dropzone';

interface FileUploaderProps {
  file: File | null;
  onFileChanged: (file: File | null) => void;
}

const FileUploader: FunctionComponent<FileUploaderProps> = ({ file, onFileChanged }) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col items-center">
      <div className="text-lg font-semibold">{t('upload_a_file')}</div>
      <div className="mb-4">{t('select_a_xlsx_file_to_be_imported')}</div>
      {!file && (
        <DropZone
          file={file}
          onFileDragged={onFileChanged}
          acceptedFileTypes={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }}
        />
      )}
    </div>
  );
};

export { FileUploader };
