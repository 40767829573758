import BigTooltip from '@/components/feedback/BigTooltip';
import { AdLabsColorVariant } from '@/config/theme/color.type';
import { GridColors } from '@/types/colors.enum';
import { Tooltip, Typography } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';
import { FunctionComponent } from 'react';
import { NONE_LABEL, StringToCount, isStringToCount } from '../helpers';

export interface ITextCellRendererParams {
  textLabel?: string; // Use only when table is not aggregating rows
  textColor?: AdLabsColorVariant | undefined; // Use only when table is not aggregating rows
  tooltip?: React.ReactNode | null; // Use only when table is not aggregating rows
  valueToString?: (enumKey: string) => string;
  noneLabel?: string;
  valueToColor?: (enumKey: string) => AdLabsColorVariant | undefined;
  valueToTooltip?: (enumKey: string) => React.ReactNode | null;
  useBigtooltip?: boolean;
}
interface ITextCellInternalRendererParams extends ITextCellRendererParams, ICellRendererParams {}

export const TextCellRenderer: FunctionComponent<ITextCellInternalRendererParams> = ({
  textLabel,
  textColor,
  tooltip,
  node,
  colDef,
  value,
  valueToString,
  noneLabel = NONE_LABEL,
  valueToColor,
  valueToTooltip,
  useBigtooltip = false,
}) => {
  // Can't always use textLabel because when aggregate cell the aggFunc might not return a string
  if (node.group) {
    const isAutoGroupColumn = colDef?.colId?.includes('ag-Grid-AutoColumn') ?? false;
    if (isAutoGroupColumn) {
      // Auto group cell - grouped by this column
      return createAutoColumnComponent({ value, noneLabel, valueToString, node, valueToColor, valueToTooltip, useBigtooltip });
    } else {
      // Aggregate cell - on group row, but not grouped by
      return createAggregateCellComponent({
        value,
        noneLabel,
        valueToString,
        valueToColor,
        valueToTooltip,
        useBigtooltip,
      });
    }
  }

  // Regular cell
  const text = valueToString ? valueToString(value) : value;
  return (
    <RenderingComponent
      text={textLabel ?? text}
      color={valueToColor ? valueToColor(value) : textColor}
      tooltip={valueToTooltip ? valueToTooltip(value) : tooltip}
      useBigtooltip={useBigtooltip}
    />
  );
};

interface IAggregateCellComponentProps {
  value: unknown;
  noneLabel: string;
  useBigtooltip: boolean;
  valueToString?: (value: string) => string;
  valueToColor?: (value: string) => AdLabsColorVariant | undefined;
  valueToTooltip?: (value: string) => React.ReactNode;
}

function createAggregateCellComponent({
  value,
  noneLabel,
  valueToString,
  valueToColor,
  valueToTooltip,
  useBigtooltip,
}: IAggregateCellComponentProps): React.ReactNode {
  // value == node.aggData[colDef.colId]
  if (value !== null && value !== undefined) {
    // value is returned by aggFunc

    if (typeof value === 'string') {
      return <span className={GridColors.GRAY}>{value}</span>;
    }

    if (isStringToCount(value)) {
      return createComponentFromStringToCount(value, noneLabel, useBigtooltip, valueToString, valueToColor, valueToTooltip);
    }

    // Unknown aggFunc return type
    return null; // to avoid potential runtime type errors
  } else {
    // Aggregate cell, but aggFunc didn't return anything or aggFunc not assigned
    return <span className={GridColors.GRAY}>{noneLabel}</span>;
  }
}

interface IAutoColumnComponentProps {
  value: string;
  noneLabel: string;
  useBigtooltip: boolean;
  valueToString?: (value: string) => string;
  node: ICellRendererParams['node'];
  valueToColor?: (enumKey: string) => AdLabsColorVariant | undefined;
  valueToTooltip?: (enumKey: string) => React.ReactNode | null;
}

function createAutoColumnComponent({
  value,
  noneLabel,
  valueToString,
  node,
  valueToColor,
  valueToTooltip,
  useBigtooltip,
}: IAutoColumnComponentProps): React.ReactNode {
  const text = valueToString && value ? valueToString(value) : value || noneLabel;

  return (
    <div className="flex flex-row items-center">
      <RenderingComponent
        text={text}
        groupChildrenCount={node.allChildrenCount ?? 0}
        color={valueToColor ? valueToColor(value) : undefined}
        tooltip={valueToTooltip ? valueToTooltip(value) : undefined}
        useBigtooltip={useBigtooltip}
      />
    </div>
  );
}

function createComponentFromStringToCount(
  stringToCount: StringToCount,
  noneLabel: string,
  useBigtooltip: boolean,
  valueToString?: (value: string) => string,
  valueToColor?: (enumKey: string) => AdLabsColorVariant | undefined,
  valueToTooltip?: (enumKey: string) => React.ReactNode | null,
): React.JSX.Element {
  const elements = Object.entries(stringToCount)
    .filter(([, valueCount]) => valueCount > 0)
    .map(([value, valueCount]) => {
      let text = '';
      if (isEmpty(value) || value == 'null') {
        text = noneLabel;
      } else {
        text = valueToString ? valueToString(value) : value;
      }
      return (
        <div className="flex flex-row items-center mr-2" key={value}>
          <RenderingComponent
            text={text}
            groupChildrenCount={valueCount}
            color={valueToColor ? valueToColor(value) : undefined}
            tooltip={valueToTooltip ? valueToTooltip(value) : undefined}
            useBigtooltip={useBigtooltip}
          />
        </div>
      );
    });

  return <div className="flex flex-row gap-2 h-full items-center">{elements}</div>;
}

interface RenderingComponentProps {
  text: string;
  color?: AdLabsColorVariant | undefined;
  tooltip?: React.ReactNode | null;
  groupChildrenCount?: number;
  valueToString?: (enumKey: string) => string;
  useBigtooltip: boolean;
}

const RenderingComponent: FunctionComponent<RenderingComponentProps> = ({
  text,
  color,
  tooltip,
  groupChildrenCount,
  valueToString,
  useBigtooltip,
}) => {
  const contents = (
    <Typography color={color} variant="body2">
      {valueToString ? valueToString(text) : text}
      {groupChildrenCount ? <span className={GridColors.GRAY}> {groupChildrenCount}</span> : null}
    </Typography>
  );

  if (useBigtooltip) {
    return <BigTooltip title={tooltip}>{contents}</BigTooltip>;
  }

  return <Tooltip title={tooltip}>{contents}</Tooltip>;
};
