import { FunctionComponent } from 'react';
import { IconButton, List, ListItem, ListItemText } from '@mui/material';
import { DashboardCollaboratorDTO } from '../../api/dashboard/dashboard.contracts';
import DeleteIcon from '@mui/icons-material/Delete';

interface DashboardCollaboratorListProps {
  collaborators: DashboardCollaboratorDTO[];
  onCollaboratorRemoved: (collaboratorId: number) => void;
}

const DashboardCollaboratorList: FunctionComponent<DashboardCollaboratorListProps> = ({ collaborators, onCollaboratorRemoved }) => {
  if (!collaborators) {
    return null;
  }

  function onRemoveCollaboratorClicked(collaboratorId: number) {
    onCollaboratorRemoved(collaboratorId);
  }

  return (
    <List dense>
      {collaborators.map((collaborator) => (
        <ListItem
          key={collaborator.id}
          secondaryAction={
            <IconButton edge="end" aria-label="delete" onClick={() => onRemoveCollaboratorClicked(collaborator.user_id)}>
              <DeleteIcon />
            </IconButton>
          }
        >
          <ListItemText primary={collaborator.name} />
        </ListItem>
      ))}
    </List>
  );
};

export default DashboardCollaboratorList;
