import { MetricData, MetricDataWithPreviousDays } from '@/components/metrics/types/MetricData';
import { AlDate } from '@/lib/date/AlDate';
import {
  AmazonBrandsBidOptimization,
  BidStrategyType,
  CampaignAdType,
  CostType,
  CreativeType,
  EnabledPausedArchivedState,
  MultiAdGroupsEnabledType,
  PlacementType,
} from '@/modules/optimizer/api/campaign/campaign-contracts';
import { DTO } from '@/types/dto-wrapper';
import { isEmpty } from 'lodash-es';
import { BidAdjustmentWithMetricsWithPreviousDTO } from '../api/placements-contracts';

export class PlacementModel extends DTO<BidAdjustmentWithMetricsWithPreviousDTO> {
  constructor(data: BidAdjustmentWithMetricsWithPreviousDTO) {
    super(data);
  }

  public get id(): string {
    return this.dto.id;
  }

  public get placementType(): PlacementType {
    if (this.dto.pt == PlacementType.OTHER) {
      return PlacementType.PLACEMENT_REST_OF_SEARCH;
    }

    if (this.dto.pt == PlacementType.DETAIL_PAGE) {
      return PlacementType.PLACEMENT_PRODUCT_PAGE;
    }

    return this.dto.pt;
  }

  // THIS IS AN INT, NOT FRACTION
  public get percentage(): number {
    return this.dto.per;
  }

  public set percentage(p: number) {
    this.dto.per = p;
  }

  // From CampaignModel:
  public get campaignId(): string {
    // var name changed from id to avoid collision with placement id
    return this.dto.i;
  }

  public get name(): string {
    return this.dto.n;
  }

  public get adType(): CampaignAdType {
    return this.dto.ad;
  }

  public get portfolioName(): string {
    return this.dto.p;
  }

  public get creativeType(): CreativeType {
    return isEmpty(this.dto.cr) ? CreativeType.NONE : this.dto.cr;
  }

  public get costType(): CostType {
    return isEmpty(this.dto.co) ? CostType.NONE : this.dto.co;
  }

  public get multiAdGroupsEnabled(): MultiAdGroupsEnabledType {
    return this.dto.mag == true
      ? MultiAdGroupsEnabledType.TRUE
      : this.dto.mag == false
        ? MultiAdGroupsEnabledType.FALSE
        : MultiAdGroupsEnabledType.NONE;
  }

  public get groupId(): number {
    return this.dto.gi;
  }

  public get groupName(): string {
    return this.dto.gn;
  }

  public get state(): EnabledPausedArchivedState {
    return this.dto.s;
  }

  public get startDate(): string | null {
    return this.dto.sd;
  }

  public get endDate(): string | null {
    return this.dto.ed;
  }

  public get targetingType(): string {
    return this.dto.tt;
  }

  public get budgetAmount(): number {
    return this.dto.ba;
  }

  public get bidStrategy(): BidStrategyType {
    return this.dto.bs;
  }

  public get bidOptimization(): AmazonBrandsBidOptimization {
    return isEmpty(this.dto.bo) ? AmazonBrandsBidOptimization.NONE : this.dto.bo;
  }

  public get lastOptimizedAt(): string | null {
    return this.dto.lo;
  }

  // Metric getters
  public get impressions(): MetricData {
    return this.dto.impressions;
  }

  public get clicks(): MetricData {
    return this.dto.clicks;
  }

  public get orders(): MetricData {
    return this.dto.orders;
  }

  public get units(): MetricData {
    return this.dto.units;
  }

  public get ctr(): MetricData {
    return this.dto.ctr;
  }

  public get cvr(): MetricData {
    return this.dto.cvr;
  }

  public get cpc(): MetricData {
    return this.dto.cpc;
  }

  public get spend(): MetricDataWithPreviousDays {
    return this.dto.spend;
  }

  public get sales(): MetricDataWithPreviousDays {
    return this.dto.sales;
  }

  public get acos(): MetricData {
    return this.dto.acos;
  }

  public get roas(): MetricData {
    return this.dto.roas;
  }

  public get rpc(): MetricData {
    return this.dto.rpc;
  }

  public get cpa(): MetricData {
    return this.dto.cpa;
  }

  public get aov(): MetricData {
    return this.dto.aov;
  }

  public get cpm(): MetricData {
    return this.dto.cpm;
  }

  public get dataItemIds(): number[] {
    return this.dto.cd ?? [];
  }

  public get isEditingEnabled(): boolean {
    // SB TOS and SB video shouldn't have checkboxes in the table
    if (this.adType == CampaignAdType.BRANDS) {
      if (
        this.placementType == PlacementType.PLACEMENT_TOP ||
        this.creativeType == CreativeType.VIDEO ||
        this.creativeType == CreativeType.BRAND_VIDEO ||
        this.bidOptimization == AmazonBrandsBidOptimization.AUTO
      ) {
        return false;
      }
    } else if (this.endDate && AlDate.parse(this.endDate).isBefore(AlDate.now())) {
      return false;
    }

    if (this.state == EnabledPausedArchivedState.ARCHIVED) {
      return false;
    }

    return true;
  }

  public static fromResponse(dto: BidAdjustmentWithMetricsWithPreviousDTO): PlacementModel {
    return new PlacementModel(dto);
  }

  public static fromResponseArray(dtos: BidAdjustmentWithMetricsWithPreviousDTO[] | null): PlacementModel[] {
    return dtos ? dtos.map(PlacementModel.fromResponse) : [];
  }

  public static convertToBackendPlacementType(at: CampaignAdType, placementType: PlacementType): PlacementType {
    if (at == CampaignAdType.BRANDS) {
      switch (placementType) {
        case PlacementType.PLACEMENT_TOP:
          return PlacementType.PLACEMENT_TOP;
        case PlacementType.PLACEMENT_REST_OF_SEARCH:
          return PlacementType.OTHER;
        case PlacementType.PLACEMENT_PRODUCT_PAGE:
          return PlacementType.DETAIL_PAGE;
      }

      // HOME stays as is
    }
    return placementType;
  }
}

export interface SelectedPlacement {
  campaignId: string;
  state: EnabledPausedArchivedState;
  adType: CampaignAdType;
  placementType: PlacementType;
  percentage: number;
  bidOptimization: AmazonBrandsBidOptimization;
}
