import { CircularProgress } from '@mui/material';
import { FunctionComponent } from 'react';

interface LoadingBlockProps {
  isLoading?: boolean;
}

const LoadingBlock: FunctionComponent<LoadingBlockProps> = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <CircularProgress />
    </div>
  );
};

export default LoadingBlock;
