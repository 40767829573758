import { useState, useCallback } from 'react';

/**
 * A custom hook that provides a boolean state with toggle and set functionalities.
 *
 * @param {boolean} [initialValue=false] - The initial value of the toggle state.
 * @returns {[boolean, () => void, (value: boolean) => void]} **An array containing:**
 *   1. The current state (boolean).
 *   1. A function to toggle the state.
 *   1. A function to explicitly set the state to a given value.
 */
function useToggle(initialValue: boolean = false): [boolean, () => void, (value: boolean) => void] {
  const [state, setState] = useState(initialValue);

  // Toggles the current state
  const toggle = useCallback(() => {
    setState((prev) => !prev);
  }, []);

  // Explicitly set the state to a given value
  const setToggle = useCallback((value: boolean) => {
    setState(value);
  }, []);

  return [state, toggle, setToggle];
}

export default useToggle;
