import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { TextLabel } from '@/components/typography/TextLabel';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSearchTermsContext } from '../../../contexts/SearchTermsContext';
import { SelectedSearchTerm } from '../../../models/SearchTermModel';
import { HarvestingParams } from '../models/KeywordHarvestingModel';
import UnmappedCampaignMappingsTable from './UnmappedCampaignMappingsTable';

interface KeywordHarvestingParamsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedSearchTerms: SelectedSearchTerm[];
  onPreviewClicked: (harvestingParams: HarvestingParams, filters: AlFilterModel[]) => void;
}

export const KeywordHarvestingParamsModal: FunctionComponent<KeywordHarvestingParamsModalProps> = ({
  isOpen,
  onClose,
  selectedSearchTerms,
  onPreviewClicked,
}) => {
  const { filters: contextFilters, setFilterValues } = useSearchTermsContext();
  const [filtersUsed, setFilters] = useState(contextFilters);
  const theme = useTheme();

  const [harvestingParams, setHarvestingParams] = React.useState<HarvestingParams>({
    tacos: 30,
    useGroupSettings: true,
  });

  useEffect(() => {
    setFilters(contextFilters);
  }, [contextFilters]);

  const handleHarvestingParamsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    if (type === 'checkbox') {
      setHarvestingParams({
        ...harvestingParams,
        [name]: checked,
      });
    } else {
      setHarvestingParams({
        ...harvestingParams,
        [name]: value,
      });
    }
  };

  const onPreviewActionClicked = () => {
    onClose();
    onPreviewClicked(harvestingParams, filtersUsed);
  };

  let optimizationApplicabilityText;
  if (harvestingParams.useGroupSettings) {
    optimizationApplicabilityText = 'Applies to ungrouped destination campaigns and "Not Set" Opt Groups';
  } else {
    optimizationApplicabilityText = 'Applies to all selected campaigns';
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle style={{ paddingBottom: '0px' }}>Harvest Targets from Source Settings</DialogTitle>
      <DialogContent>
        <div className="flex items-center my-2">
          <TextLabel>Date Range used for optimizing</TextLabel>
          <Tooltip title="Optimize current bids based on data from this date range">
            <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
          </Tooltip>
        </div>
        <DateRangeButton
          title="Optimization Range"
          filters={contextFilters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to calculate bid optimizations"
          hideComparison
        />

        <div className="grid grid-cols-2 my-8">
          <div className="flex flex-col">
            <div className="flex items-center">
              <TextLabel>Opt Group Settings</TextLabel>
              <Tooltip title="Disable to ignore Optimization Group settings">
                <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
              </Tooltip>
            </div>

            <FormGroup row>
              <div className="flex items-center">
                <Switch checked={harvestingParams.useGroupSettings} onChange={handleHarvestingParamsChange} name="useGroupSettings" />
                <span className={`ml-2 text-sm font-bold ${harvestingParams.useGroupSettings ? 'text-blue-600' : 'text-orange-500'}`}>
                  {harvestingParams.useGroupSettings ? 'Use Opt Group Settings' : 'Ignore Opt Group Settings'}
                </span>
              </div>
            </FormGroup>
          </div>

          {/* TACOS, PRIORITIZATION and bottom text */}
          <div className={`flex flex-col`}>
            <div className="flex flex-row gap-x-2">
              <div>
                <TextLabel>{'Target ACOS for new targets*'}</TextLabel>
                <TextField
                  className="w-24"
                  type="number"
                  onChange={handleHarvestingParamsChange}
                  name="tacos"
                  defaultValue={harvestingParams.tacos}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    },
                  }}
                />
              </div>
            </div>

            <Typography className={'ml-2 text-xs '} style={{ color: theme.palette.text.disabled }}>
              <span className="text-red-500">*</span>
              {optimizationApplicabilityText}
            </Typography>
          </div>
        </div>
        <AlErrorBoundary>
          <TextLabel>{'Campaign Mapping'}</TextLabel>
          <UnmappedCampaignMappingsTable selectedSearchTerms={selectedSearchTerms} />
        </AlErrorBoundary>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button onClick={onPreviewActionClicked} endIcon={<EastRoundedIcon />}>
          Preview Harvested Targets
        </Button>
      </DialogActions>
    </Dialog>
  );
};
