import { RouteObject } from 'react-router';
import AdvertisedProductsPage from '../pages/AdvertisedProductsPage';

export const ADVERTISED_PRODUCT_ROUTES: RouteObject[] = [
  {
    path: 'advertised-products',
    children: [
      {
        index: true,
        element: <AdvertisedProductsPage />,
      },
    ],
  },
];
