import {
  isArray,
  isBoolean,
  isDate,
  isEmpty,
  isFinite,
  isNil,
  isString,
} from "lodash-es";

export function sleep(ms = 500): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// eslint-disable-next-line
export const encodeQueryString = (data: any): string => {
  const queryParameters = [];
  for (const d in data) {
    if (!isNil(data[d])) {
      if (isArray(data[d])) {
        if (!isEmpty(data[d])) {
          const param = encodeURIComponent(d) + "=";
          queryParameters.push(param + data[d].join(`&${param}`));
        }
      } else if (isDate(data[d])) {
        queryParameters.push(
          encodeURIComponent(d) + "=" + encodeURIComponent(d)
        );
      } else if (
        isFinite(data[d]) ||
        (isString(data[d]) && !isEmpty(data[d])) ||
        isBoolean(data[d])
      ) {
        queryParameters.push(
          encodeURIComponent(d) + "=" + encodeURIComponent(data[d])
        );
      }
    }
  }
  return queryParameters.join("&");
};
