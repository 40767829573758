import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import useFiltersInGridContext from '@/components/filter-builder/hooks/useFiltersInGridContext';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ChipArrayCellRenderer, { ChipArrayChip, IChipArrayCellRendererParams } from '@/components/grid/cells/ChipArrayCellRenderer';
import ImageWithTitleCellRenderer, { IImageWithTitleCellRendererParams } from '@/components/grid/cells/ImageWithTitleCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import { StringToCount } from '@/components/grid/helpers';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import useDynamicHeight, { EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN } from '@/components/grid/hooks/useDynamicHeight';
import useProfileData from '@/components/grid/hooks/useProfileData';
import useToggles from '@/components/grid/hooks/useToggles';
import { AlColDef, ExpandedGridContext, WithActiveProfileGridContext, WithFiltersGridContext } from '@/components/grid/types';
import { useLayoutContext } from '@/contexts/LayoutContext';
import { useDataGroups } from '@/hooks/useDataGroups';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useTranslation } from '@/lib';
import { getProductLink } from '@/modules/application/amazon-utils';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { AvailabilityColors } from '@/types/colors.enum';
import { Card } from '@mui/material';
import {
  BodyScrollEvent,
  CellClickedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { isNil } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { ProductAvailability, ProductsWithTimeline } from '../../api/products-contracts';
import { useProductsContext } from '../../contexts/ProductsContext';
import useProductsMetricColumnAggregates from '../../hooks/useProductsMetricColumnAggregates';
import { ProductModel, SelectedProduct } from '../../models/ProductModel';
import { generateProductsTableColumnState } from './products-table.default-column-state';

interface ProductsTableProps {
  withTimeline: ProductsWithTimeline | undefined;
  isLoading: boolean;
  selectedProducts: SelectedProduct[];
  setSelectedProducts: (selectedProducts: SelectedProduct[]) => void;
  productsLoadingErrorMessage: string;
  isProductsLoadingError: boolean;
  filters: AlFilterModel[];
  noTopBorderRadius?: boolean;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
  isExpanded: boolean;
}

export interface ProductsGridContext extends ExpandedGridContext, WithFiltersGridContext, WithActiveProfileGridContext {}

const ProductsTable: FunctionComponent<ProductsTableProps> = ({
  withTimeline,
  isLoading,
  selectedProducts,
  setSelectedProducts,
  productsLoadingErrorMessage,
  isProductsLoadingError,
  noTopBorderRadius = false,
  onGridReadyCallback,
  filters,
  isExpanded,
}) => {
  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.PRODUCTS_TABLE_COLUMN_STATE,
    generateProductsTableColumnState(),
  );

  const rowData = withTimeline?.products ?? [];
  const isComparisonDataMissing = withTimeline?.isComparisonDataMissing ?? false;

  const gridApiRef = useRef<GridApi<ProductModel>>();
  const gridContextRef = useRef<ProductsGridContext>();
  const { t } = useTranslation();
  const { onSourceScroll } = useLayoutContext();
  const { heightCssValue, onGridReadyCallback: onGridReadyForDynamicHeight } = useDynamicHeight({});

  const {
    metricFieldWithChangePercentage,
    dataGroupColumnType,
    checkboxColumnType,
    salesPreviousDaysType,
    spendPreviousDaysType,
    rankChangeType,
    asinType,
  } = useColumnTypes();
  const { formatCurrency } = useFormatting();
  const { hasSellerProfiles } = useUserContext();
  const { activeProfile } = useActiveTeamContext();

  // Additional data sources
  const { metricColumnAggregates, isMetricsLoading, onGridReadyForMetricColumnAggregates } = useProductsMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
  });

  const { onGridReadyForComparisonMissing } = useComparisonMissing({ gridApiRef, gridContextRef, isComparisonDataMissing });

  const { gridToggles } = useProductsContext();
  const { gridToggleParams, activeMetricComperator } = useToggles<ProductModel>({
    gridApiRef,
    gridContextRef,
    gridToggles,
  });

  const { onGridReadyForFiltersInGridContext, filterCurrentStartEndDate } = useFiltersInGridContext({
    filters,
    filterDependentColumns: [ColumnId.RANK_CHANGE],
    gridApiRef,
    gridContextRef,
  });

  const { onGridReadyForProfileData } = useProfileData({ gridApiRef, gridContextRef, profile: activeProfile });

  const {
    enabledPausedArchivedStateAggFunc,
    metricDataWithPreviousDaysAggFunc,
    stringToCountAggFunc,
    targetsAggFunc,
    campaignNameAggFunc,
    adGroupsAggFunc,
    groupNameAggFunc,
    booleanAggFunc,
    metricsDataAggFunc,
    dataGroupAggFunc,
  } = useAggregators();

  const { dataGroups, dataItemToInfoMap, groupIdToItemSetMap, isDataGroupsLoading } = useDataGroups([DataGroupType.PRODUCT]);

  const columnDefs: ColDef<ProductModel>[] = useMemo(() => {
    if (isDataGroupsLoading) {
      return [];
    }

    const colDefs: AlColDef<ProductModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        pinned: 'left',
        lockPosition: 'left',
        type: 'checkboxColumnType',
      },
      {
        colId: ColumnId.ID,
        headerName: 'ID',
        field: 'id',
        hide: true,
      },

      {
        colId: ColumnId.TITLE,
        headerName: 'Title',
        field: 'title',
        cellRenderer: ImageWithTitleCellRenderer,
        cellRendererParams: (
          params: ICellRendererParams<ProductModel, unknown, WithActiveProfileGridContext>,
        ): IImageWithTitleCellRendererParams<ProductModel> => {
          const countryCode = params.context?.countryCode;
          return {
            extractImageUrl: (data) => data.imageUrl,
            extractTitle: (data) => data.title,
            extractUrl: (data) => (data?.asin && countryCode ? getProductLink(data.asin, countryCode) : undefined),
          };
        },
      },
      {
        colId: ColumnId.AVAILABILITY,
        headerName: 'Availability',
        field: 'availability',
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: ChipArrayCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProductModel>): IChipArrayCellRendererParams => {
          const isAutoGroupColumn = params.colDef?.colId?.includes('ag-Grid-AutoColumn') ?? false;

          if (params.node.group && !isAutoGroupColumn) {
            // Expecting stringToCountAggFunc output
            const availabilityToCountRecord = params.value as StringToCount;

            const chipArrayChips: ChipArrayChip[] = [];
            for (const [availability, count] of Object.entries(availabilityToCountRecord).sort(([keyA], [keyB]) =>
              keyA.localeCompare(keyB),
            )) {
              chipArrayChips.push({
                value: `${t(`enums.product_availability.${availability}`)}: ${count}`,
                color: availability in AvailabilityColors ? AvailabilityColors[availability as ProductAvailability] : 'amber',
                tooltip: t(`enums.product_availability_description.${availability}`),
              });
            }

            return { chipArrayChips };
          }

          const value = params.value as ProductAvailability;
          return {
            chipArrayChips: [
              {
                value: t(`enums.product_availability.${value}`),
                color: AvailabilityColors[value],
                tooltip: t(`enums.product_availability_description.${value}`),
              },
            ],
          };
        },
      },
      {
        colId: ColumnId.PRICE_TO_PAY,
        headerName: 'Price',
        field: 'priceToPay',
        valueFormatter: (params: ValueFormatterParams) =>
          formatCurrency(params.value, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
      },
      {
        colId: ColumnId.BEST_SELLER_RANK,
        headerName: 'Best Seller Rank',
        field: 'bestSellerRank',
        width: 95,
        cellRenderer: ChipArrayCellRenderer,
        cellRendererParams: (params: ICellRendererParams<ProductModel>): IChipArrayCellRendererParams => {
          // If agg row
          if (!params.value) {
            return {
              chipArrayChips: [],
            };
          }
          return {
            chipArrayChips: [
              {
                value: `#${params.value}`,
                color: 'sky',
                tooltip: params.data ? `#${params.value} in category "${params.data?.category}"` : undefined,
              },
            ],
          };
        },
      },
      {
        colId: ColumnId.RANK_CHANGE,
        headerName: 'BSR Trend',
        field: 'rankChange',
        type: 'rankChangeType',
      },
      {
        colId: ColumnId.ASIN,
        field: 'asin',
        type: 'asinType',
      },
      {
        colId: ColumnId.SKU,
        headerName: 'SKU',
        field: 'sku',
        enableRowGroup: true,
      },
      {
        colId: ColumnId.CATEGORY,
        headerName: 'Category',
        field: 'category',
        enableRowGroup: true,
      },
      {
        colId: ColumnId.BRAND,
        headerName: 'Brand',
        field: 'brand',
        enableRowGroup: true,
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'orders',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.UNITS,
        headerName: 'Units',
        field: 'units',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.SAME_SKU_ORDERS,
        headerName: 'Same SKU Orders',
        field: 'sso',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.SAME_SKU_SALES,
        headerName: 'Same SKU Sales',
        field: 'sss',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },

      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.SALES_PREVIOUS_DAYS,
        field: 'sales',
        type: 'salesPreviousDaysType',
      },
      {
        colId: ColumnId.SPEND_PREVIOUS_DAYS,
        field: 'spend',
        type: 'spendPreviousDaysType',
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComperator,
      },
    ];

    // TODO: create colDefs transformer that conditionally adds metrics based on availableMetrics from context instead of hasSellerProfiles, add similar to filters?
    if (hasSellerProfiles) {
      colDefs.push(
        {
          colId: ColumnId.ACOTS,
          headerName: 'TACOS', // Total Ad Cost of Sales was Ad Cost of Total Sales (ACOTS)
          field: 'acots',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.ASP,
          headerName: 'ASP',
          field: 'asp',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.ORGANIC_SALES,
          headerName: 'Organic Sales',
          field: 'organicSales',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.ORGANIC_TRAFFIC,
          headerName: 'Organic Traffic',
          field: 'organicTraffic',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.TOTAL_VIEWS,
          headerName: 'Total Page Views',
          field: 'totalViews',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.TOTAL_UNITS,
          headerName: 'Total Units',
          field: 'totalUnits',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.TOTAL_SALES,
          headerName: 'Total Sales',
          field: 'totalSales',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.AD_SALES_OF_TOTAL,
          headerName: 'Ad Sales % of Total',
          field: 'adSalesOfTotal',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.TOTAL_CPA,
          headerName: 'Total CPA',
          field: 'totalCpa',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.TOTAL_ROAS,
          headerName: 'Total ROAS',
          field: 'totalRoas',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.UNIT_VIEW,
          headerName: 'Unit Pageview %',
          field: 'unitView',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
          width: 111,
        },
        {
          colId: ColumnId.UPS,
          headerName: 'Units Per Session',
          field: 'ups',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.TOTAL_CVR,
          headerName: 'Total CVR',
          field: 'totalCvr',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.TOTAL_CLICKS,
          headerName: 'Total Sessions',
          field: 'totalClicks',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.TOTAL_ORDERS,
          headerName: 'Total Orders',
          field: 'totalOrders',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.TOTAL_AOV,
          headerName: 'Total AOV',
          field: 'totalAov',
          type: 'metricFieldWithChangePercentage',
          comparator: activeMetricComperator,
        },
        {
          colId: ColumnId.PARENT_ASIN,
          headerName: 'Parent ASIN',
          field: 'parentAsin',
          enableRowGroup: true,
        },
      );
    }

    dataGroups
      .filter((group) => group.type === DataGroupType.PRODUCT)
      .forEach((group) => {
        colDefs.push({
          colId: `data_group_${group.id}`,
          headerName: group.name,
          headerClass: 'accented-header data-group-product data-group-base',
          toolPanelClass: 'data-group-base data-group-product',
          headerTooltip: t('product_data_group'),
          valueGetter: (params: ValueGetterParams<ProductModel>) => {
            const itemId = params.data?.productDataItemIds.find((itemId) => groupIdToItemSetMap.get(group.id)?.has(itemId));
            return itemId ? dataItemToInfoMap.get(itemId)?.name : undefined;
          },
          type: 'dataGroupColumnType',
          enableRowGroup: true,
        });
      });

    applyStateToDefinitions(colDefs);
    return colDefs;
  }, [groupIdToItemSetMap, isDataGroupsLoading]);

  // Needed to keep the selection up to date when Tags change on the product and data is refetched
  useEffect(() => {
    if (!gridApiRef.current || !rowData || rowData.length == 0 || selectedProducts.length == 0) return;
    updateSelectedProducts(gridApiRef.current);
  }, [rowData]);

  const updateSelectedProducts = (api: GridApi<ProductModel>) => {
    if (!api || api.isDestroyed()) {
      console.warn('[ProductsTable.tsx] Grid API is not available or has been destroyed.');
      return;
    }

    try {
      const selectedRows = api.getSelectedRows();
      const selectedProductsDTO = selectedRows.map(
        (row): SelectedProduct => ({
          id: row.id,
          dataGroupType: DataGroupType.PRODUCT,
          dataItemIds: row.productDataItemIds,
        }),
      );
      setSelectedProducts(selectedProductsDTO);
    } catch (error) {
      console.error('Error updating selected products:', error);
    }
  };

  const handleRowSelection = (event: SelectionChangedEvent<ProductModel>) => {
    updateSelectedProducts(event.api);
  };

  const onCellClicked = useCallback((params: CellClickedEvent<ProductModel>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<ProductModel> = useMemo(() => {
    // While values might be undefined, default values are needed so grid knows which fields to expect
    const defaultProductsGridContext: ProductsGridContext = {
      metricColumnAggregates,
      isComparisonDataMissing,
      filterCurrentStartEndDate,
      countryCode: activeProfile?.countryCode,
      ...gridToggleParams,
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      getRowId: (params) => params.data.id.toString(),
      onSelectionChanged: handleRowSelection,
      onCellClicked: onCellClicked,
      context: defaultProductsGridContext,
      maintainColumnOrder: true,
      onBodyScroll: (event: BodyScrollEvent) => onSourceScroll(event.top),
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      rowGroupPanelShow: 'always', // The place you can drag headers for row grouping
      groupDefaultExpanded: 0,
      autoGroupColumnDef: {
        pinned: 'left',
        cellRendererParams: {
          suppressCount: true, // Suppress the default (count)
        },
      },
      groupSelectsChildren: true,
      suppressAggFuncInHeader: true, // without this aggregated Impressions header would be func(Impressions)
      columnTypes: {
        metricFieldWithChangePercentage,
        dataGroupColumnType,
        checkboxColumnType,
        salesPreviousDaysType,
        spendPreviousDaysType,
        rankChangeType,
        asinType,
      },
      rowHeight: 68,
      getRowStyle: (params) => {
        // Add custom style to group rows
        if (params.node.group) {
          return {
            background: 'linear-gradient(to bottom, rgba(96,165,250, 0.2), rgba(96,165,250, 0.05))',
          };
        }
      },
      aggFuncs: {
        metricsDataAggFunc,
        enabledPausedArchivedStateAggFunc,
        stringToCountAggFunc,
        metricDataWithPreviousDaysAggFunc,
        targetsAggFunc,
        campaignNameAggFunc,
        adGroupsAggFunc,
        groupNameAggFunc,
        booleanAggFunc,
        dataGroupAggFunc,
      },
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    onGridReadyCallback?.(params);
    onGridReadyForDynamicHeight(params);

    // Context
    gridContextRef.current = params.context;

    // Need this because for initial context set the external data sources might have not been set yet
    if (gridContextRef.current) {
      onGridReadyForMetricColumnAggregates();
      onGridReadyForComparisonMissing();
      onGridReadyForFiltersInGridContext();
      onGridReadyForProfileData();
      // Not needed for comparison unit as it isn't fetched and always exists

      gridApiRef.current.refreshHeader();
      gridApiRef.current.refreshCells({ force: true });
    }
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      {isProductsLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={productsLoadingErrorMessage} />
        </Card>
      ) : (
        <div style={{ height: isExpanded ? `calc(100vh - ${EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` : heightCssValue }}>
          <AlGrid
            colDefs={!isNil(dataGroups) ? columnDefs : []}
            rowData={rowData}
            gridOptions={customGridOptions}
            isLoading={isLoading || isMetricsLoading}
            onGridReadyCallback={onGridReady}
            noTopBorderRadius={noTopBorderRadius}
            fitToResizeEnabled={false}
            addExtraBottomPadding={true}
          />
        </div>
      )}
    </>
  );
};

export default ProductsTable;
