import { FunctionComponent } from 'react';

const BetaLabel: FunctionComponent = () => {
  return (
    <div className=" rounded-full border border-orange-400 bg-opacity-100 tracking-wide leading-4 px-2 text-[10px] font-semibold text-orange-400 uppercase">
      beta
    </div>
  );
};

export default BetaLabel;
