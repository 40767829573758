import { FunctionComponent, useState } from 'react';
import { IDashboardWidgetCatalogItem } from '../../types/IDashboardWidgetCatalogItem';
import DashboardWidgetCatalogItemImage from './DashboardWidgetCatalogItemImage';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { cloneDeep } from 'lodash-es';

interface DashboardWidgetCatalogItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  widget: IDashboardWidgetCatalogItem<any>;
  onWidgetAdded: () => void;
}

const DashboardWidgetCatalogItem: FunctionComponent<DashboardWidgetCatalogItemProps> = ({ widget, onWidgetAdded }) => {
  const { addWidget } = useDashboardContextValue((context) => ({
    addWidget: context.addWidget,
  }));

  const [isAdded, setIsAdded] = useState(false);

  const handleAddWidget = () => {
    addWidget(cloneDeep(widget));
    setIsAdded(true);
    onWidgetAdded();
    setTimeout(() => {
      setIsAdded(false);
    }, 1250);
  };

  return (
    <div
      className={`relative flex border border-transparent  p-1 rounded-md ${!isAdded && `hover:bg-primary-500  hover:border-primary-500 hover:bg-opacity-25`} hover:cursor-pointer group`}
      onClick={handleAddWidget}
    >
      {isAdded && (
        <div className="absolute inset-0 bg-white">
          <div className=" bg-green-600 bg-opacity-25  text-green-950 flex items-center  flex-1 justify-center h-full  text-xs font-semibold px-1 rounded-md">
            Added!
          </div>
        </div>
      )}

      {/* Widget Icon */}
      <div className="flex h-10 w-10 shrink-0 items-center justify-center bg-gray-100 dark:bg-gray-700 rounded-md group-hover:bg-primary-600 group-hover:bg-opacity-25">
        <DashboardWidgetCatalogItemImage icon={widget.icon} />
      </div>

      {/* Widget Details */}
      <div className="ml-2 flex flex-col flex-1">
        <div className="font-bold text-sm">{widget.title}</div>
        <div className="text-xs text-gray-700 dark:text-gray-400">{widget.description}</div>
      </div>
    </div>
  );
};

export default DashboardWidgetCatalogItem;
