import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import NumbersRounded from '@mui/icons-material/NumbersRounded';
import { MetricWidgetConfiguration } from './MetricWidgetConfiguration';
import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { v4 } from 'uuid';

export const METRIC_WIDGET_CATALOG_ITEM: IDashboardWidgetCatalogItem<MetricWidgetConfiguration> = {
  title: 'Metric',
  description: "Shows a Metric's statistics.",
  icon: <NumbersRounded />,
  id: v4(),
  type: DashboardWidgetType.MetricWidget,
  configuration: new MetricWidgetConfiguration(),
  layout: {
    h: 2,
    w: 2,
    i: v4(),
    x: 0,
    y: 0,
    maxH: 4,
    maxW: 4,
    minH: 2,
    minW: 2,
  },
};
