import { ButtonBase } from '@mui/material';
import { FunctionComponent } from 'react';
import BetaLabel from '@/components/BetaLabel';

interface MainMenuTextOnlyButtonProps {
  isActive: boolean;
  label: string;
  isExpanded: boolean;
  isBeta?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const MainMenuTextOnlyButton: FunctionComponent<MainMenuTextOnlyButtonProps> = ({ onClick, isBeta, label, isExpanded, isActive }) => {
  return (
    <ButtonBase
      onClick={onClick}
      component="div"
      className={`flex  w-full  justify-start overflow-hidden   rounded-lg    px-2 py-1.5 text-gray-100 bg-opacity-70
      focus:text-gray-100 hover:text-gray-200   ${isActive ? ' bg-gray-600  dark:bg-gray-500 ' : '  hover:bg-gray-700 dark:hover:bg-gray-600'}`}
    >
      <span className={`truncate pl-2.5  font-medium transition-opacity ${isExpanded ? 'opacity-100' : 'opacity-0'}`}>{label}</span>
      {isBeta && isExpanded && (
        <div className="ml-auto mr-2.5">
          <BetaLabel />
        </div>
      )}
    </ButtonBase>
  );
};
