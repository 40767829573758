import { HelpOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import Gleap from 'gleap';
import { FunctionComponent } from 'react';

const HelpButton: FunctionComponent = () => {
  const startGleapFeedbackFlow = () => {
    Gleap.open();
  };

  return (
    <Button onClick={startGleapFeedbackFlow} variant="outlined" className="whitespace-nowrap px-4" startIcon={<HelpOutline />}>
      I need help
    </Button>
  );
};

export default HelpButton;
