import { isEqual } from 'lodash-es';
import { IDashboardWidget } from '../types/IDashboardWidget';
import { IDashboardWidgetBaseConfiguration } from '../types/IDashboardWidgetBaseConfiguration';
import { WidgetDataDTO } from '../api/dashboard/data/dashboard-data.contracts';

interface BaseWidgetProps extends IDashboardWidget<IDashboardWidgetBaseConfiguration> {
  data?: WidgetDataDTO;
  isFetchingDataForWidget?: boolean;
  configurationId?: string;
}

export function isBaseWidgetConfigurationEqual(prevProps: Readonly<BaseWidgetProps>, nextProps: Readonly<BaseWidgetProps>): boolean {
  // Logic to determine if the component should update
  // Return false to prevent re-render, true to allow re-render
  const areEqual = isEqual(prevProps.configuration, nextProps.configuration);
  const isDataEqual = isEqual(prevProps.data, nextProps.data);
  return (
    areEqual &&
    isDataEqual &&
    prevProps.isFetchingDataForWidget === nextProps.isFetchingDataForWidget &&
    prevProps.configurationId === nextProps.configurationId
  );
}
