import { TextField, Typography } from '@mui/material';
import { FunctionComponent, useState, useEffect, useContext } from 'react';
import EntityTypeSelect, { EntityType } from '../../forms/EntityTypeSelect';
import GroupBySelect, { TableWidgetGroupByOptionSelectOption } from '../../forms/GroupBySelect';
import { BarGraphWidgetConfiguration } from './BarGraphWidgetConfiguration';
import { CommonMetricField, CommonWithSellerMetricField, MetricField, SellerMetricField } from '@/components/metrics/types/MetricField';

import { cloneDeep } from 'lodash-es';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import BarGraphWidgetFilters from './BarGraphWidgetFilters';
import ProfileSelect from '../../forms/ProfileSelect';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import MultipleMetricSelect from '@/components/form/MultipleMetricSelect';
import { useTranslation } from '@/lib';

interface BarGraphWidgetConfigurationFormProps {
  configuration: BarGraphWidgetConfiguration;
  id: string;
}

const BarGraphWidgetConfigurationForm: FunctionComponent<BarGraphWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);
  const { t } = useTranslation();
  const [localConfiguration, setLocalConfiguration] = useState<BarGraphWidgetConfiguration>(cloneDeep(configuration));

  useEffect(() => {
    localConfiguration.isConfigured = true;
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration, setModifiedConfiguration]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalConfiguration((prev) => {
      if (!e.currentTarget) return prev;
      const newConfig = cloneDeep(prev);
      newConfig.title = e.currentTarget?.value || '';
      return newConfig;
    });
  };

  const handleEntityTypeChange = (entityType: EntityType | '') => {
    if (entityType === '') return;
    if (entityType === localConfiguration.entityType) return;
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.entityType = entityType;

      if (entityType === EntityType.CAMPAIGN) {
        newConfig.campaignGroupByValue = prev.campaignGroupByValue;
        newConfig.campaignGroupByCategory = prev.campaignGroupByCategory;
      } else if (entityType === EntityType.TARGET) {
        newConfig.targetsGroupByValue = prev.targetsGroupByValue;
        newConfig.targetsGroupByCategory = prev.targetsGroupByCategory;
      } else if (entityType === EntityType.PLACEMENT) {
        newConfig.placementGroupByValue = prev.placementGroupByValue;
        newConfig.placementGroupByCategory = prev.placementGroupByCategory;
      } else if (entityType === EntityType.PROFILE) {
        // No additional properties to set
      }

      return newConfig;
    });
  };

  const handleCampaignGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.campaignGroupByValue = groupByOption.value;
      newConfig.campaignGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handlePlacementGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.placementGroupByValue = groupByOption.value;
      newConfig.placementGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleTargetsGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.targetsGroupByValue = groupByOption.value;
      newConfig.targetsGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleProductGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.productGroupByValue = groupByOption.value;
      newConfig.productGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleMetricsChange = (selectedMetrics: MetricField[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.selectedMetrics = selectedMetrics as CommonWithSellerMetricField[];
      return newConfig;
    });
  };

  const availableMetrics = new Map<string, MetricField[]>([
    [t('ad_metrics'), Object.values(CommonMetricField)],
    [t('total_metrics'), Object.values(SellerMetricField)],
  ]);

  const onSelectedProfilesChanged = (selectedProfileIds: string[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.profilesIds = selectedProfileIds;
      return newConfig;
    });
  };

  return (
    <>
      <TextField label="Name" value={localConfiguration.title} onChange={handleTitleChange} />

      {dashboardProfiles && (
        <ProfileSelect
          profiles={dashboardProfiles}
          selectedProfileIds={localConfiguration.profilesIds}
          setSelectedProfileIds={onSelectedProfilesChanged}
        />
      )}

      <EntityTypeSelect
        value={localConfiguration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
        disabledEntityTypes={[EntityType.SEARCH_TERM]}
      />

      {localConfiguration.entityType === EntityType.CAMPAIGN && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.campaignGroupByValue}
          dataGroupType={DataGroupType.CAMPAIGN}
          onChange={handleCampaignGroupByChange}
        />
      )}

      {localConfiguration.entityType === EntityType.TARGET && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.targetsGroupByValue}
          dataGroupType={DataGroupType.TARGET}
          onChange={handleTargetsGroupByChange}
        />
      )}

      {localConfiguration.entityType === EntityType.PLACEMENT && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.placementGroupByValue}
          dataGroupType={DataGroupType.CAMPAIGN}
          onChange={handlePlacementGroupByChange}
        />
      )}

      {localConfiguration.entityType === EntityType.PRODUCT && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.productGroupByValue}
          dataGroupType={DataGroupType.PRODUCT}
          onChange={handleProductGroupByChange}
        />
      )}

      <div>
        <div className="text-sm">Metrics</div>
        <Typography variant="caption"> Select up to 3 metrics to display</Typography>
        <MultipleMetricSelect
          availableMetrics={availableMetrics}
          selectedMetrics={localConfiguration.selectedMetrics}
          onChange={handleMetricsChange}
          maxSelectedMetrics={3}
        />
      </div>

      <BarGraphWidgetFilters configuration={localConfiguration} setConfiguration={setLocalConfiguration} />
    </>
  );
};

export default BarGraphWidgetConfigurationForm;
