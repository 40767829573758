import { DTO } from '@/types/dto-wrapper';
import { DashboardCalculatedDataDTO, WidgetDataDTO } from '../api/dashboard/data/dashboard-data.contracts';

export class DashboardCalculatedDataModel extends DTO<DashboardCalculatedDataDTO> {
  constructor(data: DashboardCalculatedDataDTO) {
    super(data);
  }

  public get widgetData(): { [key: string]: WidgetDataDTO } {
    return this.dto.widgetData;
  }

  // Getter method to retrieve widget data by ID, with generic return type
  public getWidgetData<T extends WidgetDataDTO>(key: string): T {
    const widgetData = this.dto.widgetData[key];

    return widgetData as T;
  }

  public static fromResponse(dto: DashboardCalculatedDataDTO): DashboardCalculatedDataModel {
    return new DashboardCalculatedDataModel(dto);
  }
}
