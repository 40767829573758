import { UserSettingKey, useUserSettingsContext } from '@/modules/users';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import { FunctionComponent, ReactNode, useState } from 'react';

interface ShowXTimesAlertProps {
  timesToDisplay: number;
  title: ReactNode;
  content: ReactNode;
  userSettingKey: UserSettingKey;
}

const ShowXTimesAlert: FunctionComponent<ShowXTimesAlertProps> = ({ timesToDisplay, title, content, userSettingKey }) => {
  const { getUserSettingValueByKey, upsertUserSetting } = useUserSettingsContext();
  const [currentCount, setCurrentCount] = useState(fetchCurrentCount());
  const [hasClosedThisSession, setHasClosedThisSession] = useState(false);

  function fetchCurrentCount() {
    const currentCount = getUserSettingValueByKey<number>(userSettingKey);
    return currentCount || 0;
  }

  function onClose() {
    // Increment the count and update the setting
    const newCount = (currentCount || 0) + 1;
    setCurrentCount(newCount);
    upsertUserSetting(userSettingKey, newCount);

    setHasClosedThisSession(true);
  }

  const isShowingAlert = currentCount < timesToDisplay && !hasClosedThisSession;

  return isShowingAlert ? (
    <Alert
      className="py-4 mt-2"
      severity="info"
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      <AlertTitle>{title}</AlertTitle>
      {content}
    </Alert>
  ) : null;
};

export default ShowXTimesAlert;
