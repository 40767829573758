import { useLayoutContext } from '@/contexts/LayoutContext';
import { sleep } from '@/lib/api/api-utils';
import { UpdateProfilePerformanceTargetsDTO } from '@/modules/profiles/api/profile.contracts';
import { profileService } from '@/modules/profiles/api/profile.service';
import { toastService } from '@/services/toast.service';

const useProfileSettingsUpdate = () => {
  const { startGlobalLoading, stopGlobalLoading } = useLayoutContext();

  async function updateProfileSettings({
    teamId,
    profileId,
    performanceAcos,
    performanceAcots,
    performanceAdSpend,
    performanceAdSales,
    performanceTotalSales,
    createSTVReports,
    refetch,
  }: {
    teamId: number;
    profileId: string;
    performanceAcos?: number;
    performanceAcots?: number;
    performanceAdSpend?: number;
    performanceAdSales?: number;
    performanceTotalSales?: number;
    createSTVReports?: boolean;
    refetch?: () => void;
  }) {
    // Add all and remove undefined
    const payload: UpdateProfilePerformanceTargetsDTO = Object.fromEntries(
      Object.entries({
        acos: performanceAcos,
        acots: performanceAcots,
        ad_spend: performanceAdSpend,
        ad_sales: performanceAdSales,
        total_sales: performanceTotalSales,
        create_stv_reports: createSTVReports,
      }).filter(([_, value]) => value !== undefined),
    );

    const globalLoadingKey = `updateProfilePerformanceTargets-${teamId}-${profileId}-${JSON.stringify(payload)}`;

    startGlobalLoading(globalLoadingKey);
    const response = await profileService.updateProfilePerformanceTargets(teamId, profileId, payload);
    stopGlobalLoading(globalLoadingKey);

    if (response.isSuccess === false) {
      toastService.error(response.message);
      return;
    }

    await sleep(200);

    if (refetch) refetch();
  }
  return {
    updateProfileSettings,
  };
};

export default useProfileSettingsUpdate;
