import SpokeIcon from '@mui/icons-material/Spoke';
import { ButtonBase } from '@mui/material';
import { FunctionComponent, cloneElement } from 'react';

interface AffiliateButtonProps {
  isExpanded: boolean;
}

const AFFILIATE_URL = 'https://affiliates.adlabs.app/';

export const AffiliateButton: FunctionComponent<AffiliateButtonProps> = ({ isExpanded }) => {
  function onAffiliateButtonClicked() {
    window.open(AFFILIATE_URL, '_blank');
  }

  return (
    <ButtonBase
      onClick={onAffiliateButtonClicked}
      component="div"
      className="relative flex w-full justify-start overflow-hidden rounded-lg px-2 py-1.5 hover:text-[#f56ea3]"
    >
      {cloneElement(<SpokeIcon />, {
        fontSize: 'inherit',
        className: 'text-[#EB548F] transition-colors hover:text-[#EB548F]',
      })}
      <span
        className={`relative truncate pl-2.5 font-semibold transition-colors ${
          isExpanded ? 'opacity-100' : 'opacity-0'
        } bg-gradient-to-l from-[#EB548F] to-[#F57335] bg-clip-text text-transparent hover:text-[#f56ea3]`}
      >
        Become an Affiliate
      </span>
    </ButtonBase>
  );
};
