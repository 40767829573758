import { FilterDTO } from '@/components/filter-builder/api/filters-contracts';
import { CommonMetricField, CommonWithSellerMetricField } from '@/components/metrics/types/MetricField';
import { v4 } from 'uuid';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';
import { EntityType } from '../../forms/EntityTypeSelect';

export class MetricWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  id: string;
  title = 'ACOS';
  isConfigured: boolean = false;

  metricField = CommonMetricField.ACOS as CommonWithSellerMetricField;
  entityType = EntityType.PROFILE;
  profilesIds: string[] = [];

  campaignFilters: FilterDTO[] = [];
  placementFilters: FilterDTO[] = [];
  targetFilters: FilterDTO[] = [];
  productFilters: FilterDTO[] = [];

  constructor() {
    this.id = v4();
  }
}
