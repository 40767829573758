import { useHelperComponents } from '@/hooks/useHelperComponents';
import { FunctionComponent } from 'react';

export interface IExternalLinkCellRendererParams {
  url?: string;
  title?: string;
}

const ExternalLinkCellRenderer: FunctionComponent<IExternalLinkCellRendererParams> = ({ url, title }) => {
  const { getExternalLinkComponent } = useHelperComponents();

  if (!url) {
    return title ?? null;
  }

  return getExternalLinkComponent(url, title, true);
};

export default ExternalLinkCellRenderer;
