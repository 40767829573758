import InfoMessage from '@/components/feedback/InfoMessage';
import useFilters from '@/components/filter-builder/hooks/useFilters';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { TextLabel } from '@/components/typography/TextLabel';
import useFormatting from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash-es';
import { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import { useOptimizerContext } from '../contexts/OptimizerContext';
import { PreOptimizationInfo } from '../hooks/useOptimizationWarnings';
import { BidCeilingType } from '../types/BidCeilingType';
import { BidFloorType } from '../types/BidFloorType';
import { BidLimitType, PlacementBidLimitType } from '../types/BidLimitType';
import { SmartBidCeilingType } from '../types/SmartBidCeilingType';
import { OptimizationParams, OptimizationPreset, availableOptimizationPresets } from './optimization/OptimizerConfig';

interface OptimizationParamsModalProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  optimizationParams: OptimizationParams;
  setOptimizationParams: (optimizationParams: OptimizationParams) => void;
  selectedCampaigns: SelectedCampaignDTO[];
  onPreviewOptimizationsClicked: (filtersModified: AlFilterModel[]) => void;
  preOptimizationInfo: PreOptimizationInfo;
}

export const OptimizationParamsModal: FunctionComponent<OptimizationParamsModalProps> = ({
  isOpen,
  onClose,
  optimizationParams,
  setOptimizationParams,
  selectedCampaigns,
  onPreviewOptimizationsClicked,
  preOptimizationInfo,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { filters: contextFilters } = useOptimizerContext();
  const [filtersUsedForOptimizing, setFiltersUsedForOptimizing] = useState(contextFilters); // Needs to stay separate from page filters

  const { setFilterValues } = useFilters({ filters: filtersUsedForOptimizing, setFilters: setFiltersUsedForOptimizing });

  const { getCurrencySymbol } = useFormatting();
  const currencySymbol = getCurrencySymbol();

  const [bidCeilingType, setBidCeilingType] = useState<BidCeilingType>(optimizationParams.bidCeilingType);
  const [smartBidCeilingType, setSmartBidCeilingType] = useState<SmartBidCeilingType>(optimizationParams.smartBidCeilingType);
  const [bidFloorType, setBidFloorType] = useState<BidFloorType>(isNil(optimizationParams.bidFloor) ? BidFloorType.OFF : BidFloorType.MANUAL);

  const [targetBidLimitIncreaseType, setTargetBidLimitIncreaseType] = useState<BidLimitType>(optimizationParams.bidMaxIncreaseType);
  const [targetBidLimitDecreaseType, setTargetBidLimitDecreaseType] = useState<BidLimitType>(optimizationParams.bidMaxDecreaseType);

  const [placementBidLimitIncreaseType, setPlacementBidLimitIncreaseType] = useState<PlacementBidLimitType>(
    optimizationParams.placementMaxIncreaseType,
  );
  const [placementBidLimitDecreaseType, setPlacementBidLimitDecreaseType] = useState<PlacementBidLimitType>(
    optimizationParams.placementMaxDecreaseType,
  );

  useEffect(() => {
    setFiltersUsedForOptimizing(contextFilters);
  }, [contextFilters]);

  const handleOptimizationParamsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    if (type === 'checkbox') {
      setOptimizationParams({
        ...optimizationParams,
        [name]: checked,
      });
    } else {
      setOptimizationParams({
        ...optimizationParams,
        [name]: value,
      });
    }
  };

  const handlePresetChange = (event: SelectChangeEvent<OptimizationPreset>) => {
    const selectedPreset = event.target.value as OptimizationPreset;
    setOptimizationParams({
      ...optimizationParams,
      selectedPreset: selectedPreset,
    });
  };

  const handleBidCeilingChange = (event: SelectChangeEvent<BidCeilingType>) => {
    const selectedOption = event.target.value as BidCeilingType;

    setOptimizationParams({
      ...optimizationParams,
      bidCeiling: 0,
      bidCeilingType: selectedOption,
    });

    setBidCeilingType(selectedOption);
  };

  const handleSmartBidCeilingChange = (event: SelectChangeEvent<SmartBidCeilingType>) => {
    const selectedOption = event.target.value as SmartBidCeilingType;

    setOptimizationParams({
      ...optimizationParams,
      bidCeiling: 0,
      smartBidCeilingType: selectedOption,
    });

    setSmartBidCeilingType(selectedOption);
  };

  const handleBidFloorChange = (event: SelectChangeEvent<BidFloorType>) => {
    const selectedOption = event.target.value as BidFloorType;

    if (selectedOption === BidFloorType.OFF) {
      setOptimizationParams({
        ...optimizationParams,
        bidFloor: undefined,
      });
    }

    if (selectedOption === BidFloorType.MANUAL) {
      setOptimizationParams({
        ...optimizationParams,
        bidFloor: 0, // expected to change
      });
    }

    setBidFloorType(selectedOption);
  };

  const handleTargetBidLimitDecreaseChange = (event: SelectChangeEvent<BidLimitType>) => {
    const selectedOption = event.target.value as BidLimitType;

    setOptimizationParams({
      ...optimizationParams,
      bidMaxDecreaseType: selectedOption,
      bidMaxDecrease: selectedOption == BidLimitType.OFF ? 0 : optimizationParams.bidMaxDecrease, // Set to 0 if OFF
    });

    setTargetBidLimitDecreaseType(selectedOption);
  };

  const handleTargetBidLimitIncreaseChange = (event: SelectChangeEvent<BidLimitType>) => {
    const selectedOption = event.target.value as BidLimitType;

    setOptimizationParams({
      ...optimizationParams,
      bidMaxIncreaseType: selectedOption,
      bidMaxIncrease: selectedOption == BidLimitType.OFF ? 0 : optimizationParams.bidMaxIncrease, // Set to 0 if OFF
    });

    setTargetBidLimitIncreaseType(selectedOption);
  };

  const handlePlacementBidLimitDecreaseChange = (event: SelectChangeEvent<PlacementBidLimitType>) => {
    const selectedOption = event.target.value as PlacementBidLimitType;

    setOptimizationParams({
      ...optimizationParams,
      placementMaxDecreaseType: selectedOption,
      placementMaxDecrease: selectedOption == PlacementBidLimitType.OFF ? 0 : optimizationParams.placementMaxDecrease, // Set to 0 if OFF
    });

    setPlacementBidLimitDecreaseType(selectedOption);
  };

  const handlePlacementBidLimitIncreaseChange = (event: SelectChangeEvent<PlacementBidLimitType>) => {
    const selectedOption = event.target.value as PlacementBidLimitType;

    setOptimizationParams({
      ...optimizationParams,
      placementMaxIncreaseType: selectedOption,
      placementMaxIncrease: selectedOption == PlacementBidLimitType.OFF ? 0 : optimizationParams.placementMaxIncrease, // Set to 0 if OFF
    });

    setPlacementBidLimitIncreaseType(selectedOption);
  };

  // TODO: use CollapsableSection
  const [showAdvanced, setShowAdvanced] = useState(false);

  const toggleAdvanced = () => {
    setShowAdvanced((prev) => {
      return !prev;
    });
  };

  const onPreviewActionClicked = () => {
    onClose();
    onPreviewOptimizationsClicked(filtersUsedForOptimizing);
  };

  let optimizationApplicabilityText;
  if (optimizationParams.useGroupSettings) {
    if (preOptimizationInfo.stats.selectedCampaignsGroupedCount === selectedCampaigns.length) {
      optimizationApplicabilityText = 'Applies only to "Not Set" Opt Groups';
    } else if (preOptimizationInfo.stats.selectedCampaignsUnGroupedCount === selectedCampaigns.length) {
      optimizationApplicabilityText = 'Applies to all selected campaigns';
    } else {
      optimizationApplicabilityText = 'Applies to ungrouped campaigns and "Not Set" Opt Groups';
    }
  } else {
    optimizationApplicabilityText = 'Applies to all selected campaigns';
  }

  const selectedCampaignsContainOptGroups = preOptimizationInfo.stats.selectedCampaignsGroupedCount > 0;

  const bidCeilingValueMissing =
    bidCeilingType == BidCeilingType.MANUAL && (isNil(optimizationParams.bidCeiling) || optimizationParams.bidCeiling <= 0);

  const bidFloorValueMissing =
    bidFloorType == BidFloorType.MANUAL && (isNil(optimizationParams.bidFloor) || optimizationParams.bidFloor <= 0);

  const targetMaxIncreaseMissing =
    optimizationParams.bidMaxIncreaseType != BidLimitType.OFF &&
    (isNil(optimizationParams.bidMaxIncrease) || optimizationParams.bidMaxIncrease <= 0);

  const targetMaxDecreaseMissing =
    optimizationParams.bidMaxDecreaseType != BidLimitType.OFF &&
    (isNil(optimizationParams.bidMaxDecrease) || optimizationParams.bidMaxDecrease <= 0);

  const placementMaxIncreaseMissing =
    optimizationParams.placementMaxIncreaseType != PlacementBidLimitType.OFF &&
    (isNil(optimizationParams.placementMaxIncrease) || optimizationParams.placementMaxIncrease <= 0);

  const placementMaxDecreaseMissing =
    optimizationParams.placementMaxDecreaseType != PlacementBidLimitType.OFF &&
    (isNil(optimizationParams.placementMaxDecrease) || optimizationParams.placementMaxDecrease <= 0);

  const isPreviewButtonDisabled =
    bidCeilingValueMissing ||
    bidFloorValueMissing ||
    targetMaxIncreaseMissing ||
    targetMaxDecreaseMissing ||
    placementMaxIncreaseMissing ||
    placementMaxDecreaseMissing;

  let previewButtonTooltip = '';
  if (bidCeilingValueMissing) {
    previewButtonTooltip = 'Manual bid ceiling not set. Must be greater than 0';
  } else if (bidFloorValueMissing) {
    previewButtonTooltip = 'Manual bid floor not set. Must be greater than 0';
  } else if (targetMaxIncreaseMissing) {
    previewButtonTooltip = 'Target bid increase not set. Must be greater than 0';
  } else if (targetMaxDecreaseMissing) {
    previewButtonTooltip = 'Target bid decrease not set. Must be greater than 0';
  } else if (placementMaxIncreaseMissing) {
    previewButtonTooltip = 'Placement bid increase not set. Must be greater than 0';
  } else if (placementMaxDecreaseMissing) {
    previewButtonTooltip = 'Placement bid decrease not set. Must be greater than 0';
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle style={{ paddingBottom: '0px' }}>Optimization Settings</DialogTitle>
      <DialogContent>
        <div className="flex items-center my-2">
          <TextLabel>Date Range used for optimizing</TextLabel>
          <Tooltip title="Optimize current bids based on data from this date range">
            <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
          </Tooltip>
        </div>

        <DateRangeButton
          title="Optimization Range"
          filters={filtersUsedForOptimizing}
          setFilterValues={setFilterValues}
          tooltip="Date range used to calculate bid optimizations"
          hideComparison
        />
        {/* Group toggle with header */}
        <div className="grid grid-cols-2 mt-4">
          {selectedCampaignsContainOptGroups && (
            <div className="flex flex-col">
              <div className="flex items-center">
                <TextLabel>Opt Group Settings</TextLabel>
                <Tooltip title="Disable to ignore Optimization Group settings">
                  <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
                </Tooltip>
              </div>

              <div className="flex flex-row mx-2 mt-4">
                <FormGroup row>
                  <div className="flex items-center">
                    <Switch checked={optimizationParams.useGroupSettings} onChange={handleOptimizationParamsChange} name="useGroupSettings" />
                    <span className={`ml-2 text-sm font-bold ${optimizationParams.useGroupSettings ? 'text-blue-600' : 'text-orange-500'}`}>
                      {optimizationParams.useGroupSettings ? 'Use Opt Group Settings' : 'Ignore Opt Group Settings'}
                    </span>
                  </div>
                </FormGroup>
              </div>
            </div>
          )}

          {/* TACOS, PRIORITIZATION and bottom text */}
          <div className={`flex flex-col ${selectedCampaignsContainOptGroups ? '' : 'col-span-2'}`}>
            <div className="flex flex-row gap-x-2">
              <div className="w-24 shrink-0">
                <TextLabel>{'Target ACOS*'}</TextLabel>
                <TextField
                  type="number"
                  onChange={handleOptimizationParamsChange}
                  name="tacos"
                  defaultValue={optimizationParams.tacos}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    },
                  }}
                />
              </div>

              <div>
                <TextLabel>{'Prioritization*'}</TextLabel>
                <Select value={optimizationParams.selectedPreset} onChange={handlePresetChange} className="w-40 mt-2">
                  {availableOptimizationPresets
                    .filter((preset) => preset !== OptimizationPreset.NOT_SET)
                    .map((preset) => (
                      <MenuItem key={preset} value={preset}>
                        {t(`enums.optimization_presets.${preset}`)}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>

            <Typography className={'ml-2 text-xs '} style={{ color: theme.palette.text.disabled }}>
              <span className="text-red-500">*</span>

              {optimizationApplicabilityText}
            </Typography>
          </div>
        </div>
        <Divider className="mt-6" />
        <Typography variant="h6" gutterBottom className="mt-4">
          Targets to Optimize
        </Typography>
        <div className="grid grid-cols-2">
          <div className="flex flex-col">
            <TextLabel>Improve Efficiency</TextLabel>
            <FormGroup row>
              <Checkbox checked={optimizationParams.highAcos} onChange={handleOptimizationParamsChange} name="highAcos" />
              <Typography variant="body1" className="flex items-center gap-1">
                High ACOS
                <Tooltip title="The ACOS is above the user's Target ACOS">
                  <HelpOutlineIcon className="cursor-pointer text-sm" />
                </Tooltip>
              </Typography>
            </FormGroup>
            <FormGroup row>
              <Checkbox checked={optimizationParams.highSpend} onChange={handleOptimizationParamsChange} name="highSpend" />
              <Typography variant="body1" className="flex items-center gap-1">
                High Spend, No Sales
                <Tooltip title="Spend has exceeded the Target Cost-Per-Acquisition without any sales">
                  <HelpOutlineIcon className="cursor-pointer text-sm" />
                </Tooltip>
              </Typography>
            </FormGroup>
          </div>
          <div className="flex flex-col">
            <TextLabel>Improve Sales</TextLabel>
            <FormGroup row>
              <Checkbox checked={optimizationParams.lowAcos} onChange={handleOptimizationParamsChange} name="lowAcos" />
              <Typography variant="body1" className="flex items-center gap-1">
                Low ACOS
                <Tooltip title="The ACOS is below the user's Target ACOS">
                  <HelpOutlineIcon className="cursor-pointer text-sm" />
                </Tooltip>
              </Typography>
            </FormGroup>

            <FormGroup row>
              <Checkbox checked={optimizationParams.lowVisibility} onChange={handleOptimizationParamsChange} name="lowVisibility" />
              <Typography variant="body1" className="flex items-center gap-1">
                Low Visibility
                <Tooltip title="Fewer clicks than the average Clicks-to-Conversion">
                  <HelpOutlineIcon className="cursor-pointer text-sm" />
                </Tooltip>
              </Typography>
            </FormGroup>
            <div className="flex flex-row items-center">
              <span className="ml-[10px]">{'\u2514'}</span>
              <FormGroup row>
                <Checkbox
                  checked={optimizationParams.showZeroImpressions}
                  onChange={handleOptimizationParamsChange}
                  name="showZeroImpressions"
                />
                <Typography variant="body1" className="flex items-center gap-1">
                  Include 0 Impressions
                  <Tooltip title="Vastly increases the number of Low Visibility bid adjustments">
                    <HelpOutlineIcon className="cursor-pointer text-sm" />
                  </Tooltip>
                </Typography>
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center items-center mt-4">
          <Button className="self-end " variant="text" onClick={toggleAdvanced}>
            {showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
            {showAdvanced ? <ArrowDropUpRoundedIcon fontSize="small" /> : <ArrowDropDownRoundedIcon fontSize="small" />}
          </Button>
        </div>
        <Collapse in={showAdvanced}>
          <Divider className="my-2" />
          <Typography className="mt-4" variant="h6" gutterBottom>
            Bid Change Limits
          </Typography>
          <div className="flex flex-row gap-6">
            <div className="flex flex-col">
              <div className="flex items-center">
                <TextLabel>Bid Floor</TextLabel>
                <Tooltip title="Bid Floor">
                  <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
                </Tooltip>
              </div>
              <div className="flex flex-row items-start">
                <Select value={bidFloorType} onChange={handleBidFloorChange} className="w-34 mt-2 mr-2">
                  {Object.values(BidFloorType)
                    .filter((o) => o != BidFloorType.NOT_SET)
                    .map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`enums.bid_floor_type.${option}`)}
                      </MenuItem>
                    ))}
                </Select>
                {bidFloorType == BidFloorType.MANUAL && (
                  <TextField
                    defaultValue={optimizationParams.bidFloor}
                    type="number"
                    onChange={handleOptimizationParamsChange}
                    name="bidFloor"
                    style={{ width: '75px' }}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                      },
                    }}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                <TextLabel>Bid Ceiling</TextLabel>
                <Tooltip title="Limit bid increases to remain within Target ACOS">
                  <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
                </Tooltip>
              </div>
              <div className="flex flex-row items-start">
                <Select value={bidCeilingType} onChange={handleBidCeilingChange} className="w-34 mt-2 mr-2">
                  {Object.values(BidCeilingType)
                    .filter((o) => o != BidCeilingType.NOT_SET)
                    .map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`enums.bid_ceiling_type.${option}`)}
                      </MenuItem>
                    ))}
                </Select>
                {bidCeilingType == BidCeilingType.MANUAL && (
                  <TextField
                    defaultValue={optimizationParams.bidCeiling}
                    type="number"
                    onChange={handleOptimizationParamsChange}
                    name="bidCeiling"
                    style={{ width: '75px' }}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                      },
                    }}
                  />
                )}

                {bidCeilingType == BidCeilingType.SMART && (
                  <Select value={smartBidCeilingType} onChange={handleSmartBidCeilingChange} className="w-34 mt-2 mr-2">
                    {Object.values(SmartBidCeilingType).map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(`enums.smart_bid_ceiling_type.${option}`)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            </div>
          </div>
          <div className="mt-2 flex flex-row gap-6">
            <div className="flex flex-col">
              <div className="flex items-center">
                <TextLabel>Max Increase</TextLabel>
                <Tooltip title="Targets Max Increase Limit">
                  <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
                </Tooltip>
              </div>
              <div className="flex flex-row items-start">
                <Select value={targetBidLimitIncreaseType} onChange={handleTargetBidLimitIncreaseChange} className="w-34 mt-2 mr-2">
                  {Object.values(BidLimitType).map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(`enums.bid_limit_type.${option}`)}
                    </MenuItem>
                  ))}
                </Select>
                {(targetBidLimitIncreaseType == BidLimitType.PERCENT || targetBidLimitIncreaseType == BidLimitType.CURRENCY) && (
                  <TextField
                    defaultValue={optimizationParams.bidMaxIncrease}
                    type="number"
                    onChange={handleOptimizationParamsChange}
                    name="bidMaxIncrease"
                    style={{ width: '75px' }}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            {targetBidLimitIncreaseType == BidLimitType.PERCENT ? '%' : currencySymbol}
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                <TextLabel>Max Decrease</TextLabel>
                <Tooltip title="Targets Max Decrease Limit">
                  <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
                </Tooltip>
              </div>
              <div className="flex flex-row items-start">
                <Select value={targetBidLimitDecreaseType} onChange={handleTargetBidLimitDecreaseChange} className="w-34 mt-2 mr-2">
                  {Object.values(BidLimitType).map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(`enums.bid_limit_type.${option}`)}
                    </MenuItem>
                  ))}
                </Select>
                {(targetBidLimitDecreaseType == BidLimitType.PERCENT || targetBidLimitDecreaseType == BidLimitType.CURRENCY) && (
                  <TextField
                    defaultValue={optimizationParams.bidMaxDecrease}
                    type="number"
                    onChange={handleOptimizationParamsChange}
                    name="bidMaxDecrease"
                    style={{ width: '75px' }}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            {targetBidLimitDecreaseType == BidLimitType.PERCENT ? '%' : currencySymbol}
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <Divider className="mt-6" />
          <Typography variant="h6" gutterBottom className="mt-4">
            Placement Settings
          </Typography>
          <div className="flex flex-row gap-6">
            <FormGroup row>
              <Checkbox
                checked={optimizationParams.usePlacementOptimization}
                onChange={handleOptimizationParamsChange}
                name="usePlacementOptimization"
              />
              <Typography variant="body1" className="flex items-center gap-1">
                Optimize Placements
                <Tooltip title="Campaign placements are calculated from performance and factored into keyword/product target bids">
                  <HelpOutlineIcon className="cursor-pointer text-sm" />
                </Tooltip>
              </Typography>
            </FormGroup>
          </div>
          {optimizationParams.usePlacementOptimization && (
          <div className="mt-2 flex flex-row gap-6">
            <div className="flex flex-col">
              <div className="flex items-center">
                <TextLabel>Max Increase</TextLabel>
                <Tooltip title="Max Placement Increase Limit">
                  <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
                </Tooltip>
              </div>
              <div className="flex flex-row items-start">
                <Select value={placementBidLimitIncreaseType} onChange={handlePlacementBidLimitIncreaseChange} className="w-34 mt-2 mr-2">
                  {Object.values(PlacementBidLimitType).map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(`enums.bid_limit_type.${option}`)}
                    </MenuItem>
                  ))}
                </Select>
                {placementBidLimitIncreaseType == PlacementBidLimitType.PERCENT && (
                  <TextField
                    defaultValue={optimizationParams.placementMaxIncrease}
                    type="number"
                    onChange={handleOptimizationParamsChange}
                    name="placementMaxIncrease"
                    style={{ width: '75px' }}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      },
                    }}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex items-center">
                <TextLabel>Max Decrease</TextLabel>
                <Tooltip title="Max Placements Decrease Limit">
                  <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
                </Tooltip>
              </div>
              <div className="flex flex-row items-start">
                <Select value={placementBidLimitDecreaseType} onChange={handlePlacementBidLimitDecreaseChange} className="w-34 mt-2 mr-2">
                  {Object.values(PlacementBidLimitType).map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(`enums.bid_limit_type.${option}`)}
                    </MenuItem>
                  ))}
                </Select>
                {placementBidLimitDecreaseType == PlacementBidLimitType.PERCENT && (
                  <TextField
                    defaultValue={optimizationParams.placementMaxDecrease}
                    type="number"
                    onChange={handleOptimizationParamsChange}
                    name="placementMaxDecrease"
                    style={{ width: '75px' }}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          )}
        </Collapse>
      </DialogContent>
      <Divider />
      {preOptimizationInfo.warnings.length > 0 && (
        <div className="flex flex-col mx-5">
          {preOptimizationInfo.warnings.map((warning, index) => (
            <InfoMessage key={index} text={warning} />
          ))}
        </div>
      )}
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Tooltip title={previewButtonTooltip}>
          <span>
            <Button onClick={onPreviewActionClicked} disabled={isPreviewButtonDisabled} endIcon={<EastRoundedIcon />}>
              Preview Optimizations
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
