import { Box, Paper, Slide } from '@mui/material';
import { FunctionComponent, PropsWithChildren, useRef } from 'react';
import OppositeModeProvider from './theme/OppositeModeProvider';

interface OppositeModeOverlayBarProps extends PropsWithChildren {
  visible?: boolean;
  marginBottom?: number | string;
}

const OppositeModeOverlayBar: FunctionComponent<OppositeModeOverlayBarProps> = ({ children, visible = true, marginBottom = '28px' }) => {
  const containerDivRef = useRef<HTMLDivElement>(null);

  return (
    <OppositeModeProvider>
      <Slide direction="up" in={visible} mountOnEnter unmountOnExit>
        <Box className={`fixed flex justify-center w-full z-3 pointer-events-none pr-[64px] z-[50]`} sx={{ bottom: marginBottom }}>
          <Paper
            className="rounded-xl mx-auto p-2 flex justify-between items-center z-3 pointer-events-auto"
            ref={containerDivRef}
            elevation={3}
          >
            {children}
          </Paper>
        </Box>
      </Slide>
    </OppositeModeProvider>
  );
};

export default OppositeModeOverlayBar;
