import { RouteObject } from 'react-router';
import SearchTermsPage from '../pages/SearchTermsPage';

export const SEARCH_TERMS_ROUTES: RouteObject[] = [
  {
    path: 'search-terms',
    children: [
      {
        index: true,
        element: <SearchTermsPage />,
      },
    ],
  },
];
