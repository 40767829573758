import { DARK_THEME } from '@/config/theme/dark-theme';
import { BASE_THEME } from '@/config/theme/light-theme';
import { useTheming } from '@/config/theme/useTheming';
import { ColorModeContext } from '@/modules/application';
import { ThemeProvider } from '@mui/material';
import { FunctionComponent, PropsWithChildren, useContext } from 'react';

const OppositeModeProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { mode } = useContext(ColorModeContext);

  const { colorMode } = useTheming();

  return (
    <ColorModeContext.Provider
      value={{ toggleColorMode: colorMode.toggleColorMode, mode: mode === 'light' ? 'dark' : 'light', isDarkMode: mode === 'light' }}
    >
      <ThemeProvider theme={mode === 'light' ? DARK_THEME : BASE_THEME}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default OppositeModeProvider;
