import { MetricData } from '@/components/metrics/types/MetricData';
import { DTO } from '@/types/dto-wrapper';
import { ResponseAdGroupDebugRow } from '../api/optimization-contracts';

export class AdGroupDebugRow extends DTO<ResponseAdGroupDebugRow> {
  constructor(data: ResponseAdGroupDebugRow) {
    super(data);
  }

  public get adGroup(): string {
    return this.dto.ad_group;
  }

  public get tcpa(): MetricData {
    return this.dto.tcpa;
  }

  public get tcpc(): MetricData {
    return this.dto.tcpc;
  }

  public get rpcInherited(): string {
    return this.dto.rpc_inherited;
  }

  public get aovInherited(): string {
    return this.dto.aov_inherited;
  }

  public get actcInherited(): string {
    return this.dto.actc_inherited;
  }

  public get impressions(): MetricData {
    return this.dto.impressions;
  }

  public get clicks(): MetricData {
    return this.dto.clicks;
  }

  public get orders(): MetricData {
    return this.dto.orders;
  }

  public get units(): MetricData {
    return this.dto.units;
  }

  public get ctr(): MetricData {
    return this.dto.ctr;
  }

  public get cvr(): MetricData {
    return this.dto.cvr;
  }

  public get cpc(): MetricData {
    return this.dto.cpc;
  }

  public get spend(): MetricData {
    return this.dto.spend;
  }

  public get sales(): MetricData {
    return this.dto.sales;
  }

  public get acos(): MetricData {
    return this.dto.acos;
  }

  public get roas(): MetricData {
    return this.dto.roas;
  }

  public get rpc(): MetricData {
    return this.dto.rpc;
  }

  public get cpa(): MetricData {
    return this.dto.cpa;
  }

  public get aov(): MetricData {
    return this.dto.aov;
  }

  public get cpm(): MetricData {
    return this.dto.cpm;
  }

  public static fromResponse(dto: ResponseAdGroupDebugRow): AdGroupDebugRow {
    return new AdGroupDebugRow(dto);
  }

  public static fromResponseArray(responseArray: ResponseAdGroupDebugRow[]): AdGroupDebugRow[] {
    return responseArray.map(AdGroupDebugRow.fromResponse);
  }
}
