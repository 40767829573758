import { useEffect } from 'react';
import useToggle from './useToggle'; // Adjust the import path as necessary

/**
 * A custom hook that provides toggle functionality and sets the toggle state to false when the Escape key is pressed.
 *
 * @param {boolean} [initialValue=false] - The initial value of the toggle state.
 * @returns {[boolean, () => void, (value: boolean) => void]} **An array containing:**
 *   1. The current state (boolean).
 *   2. A function to toggle the state.
 *   3. A function to explicitly set the state to a given value.
 */
function useEscapableToggle(initialValue: boolean = false): [boolean, () => void, (value: boolean) => void] {
  const [state, toggle, setToggle] = useToggle(initialValue);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setToggle(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setToggle]);

  return [state, toggle, setToggle];
}

export default useEscapableToggle;
