import { ChevronRightOutlined } from '@mui/icons-material';
import ChangeHistory from '@mui/icons-material/ChangeHistory';
import NumbersIcon from '@mui/icons-material/Numbers';
import PercentIcon from '@mui/icons-material/Percent';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { ComparisonUnit, SortByUnit } from '../grid/types';

interface SortByDropdownProps {
  sortBy: SortByUnit; // Current sort by unit
  handleSortChange: (newSortBy: SortByUnit) => void; // Callback to handle sort change
  comparisonUnit: ComparisonUnit;
}

const SortByDropdown: FunctionComponent<SortByDropdownProps> = ({ sortBy, handleSortChange, comparisonUnit }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleSortByChange = (event: SelectChangeEvent<SortByUnit>) => {
    handleSortChange(event.target.value as SortByUnit);
  };

  return (
    <div className="flex items-center">
      <div className="text-xs pr-1">Sort by</div>
      <Select
        className="cursor-pointer"
        onClick={() => setIsSelectOpen(!isSelectOpen)}
        open={isSelectOpen}
        value={sortBy}
        onChange={handleSortByChange}
        size="small"
        displayEmpty
        inputProps={{ className: 'pl-2  pr-1' }}
        IconComponent={(props) => (
          <ChevronRightOutlined
            {...props}
            style={{ marginRight: 3, paddingRight: 0, display: 'flex' }}
            className="rotate-90 text-sm pr-1 text-slate-400"
          />
        )}
        renderValue={(selected) => (
          <div className="text-xs leading-none flex items-center h-full pt-1">
            {selected === SortByUnit.Count ? (
              <NumbersIcon sx={{ fontSize: 'inherit', paddingTop: '0px', lineHeight: '10px' }} />
            ) : (
              <>
                <ChangeHistory sx={{ fontSize: 'inherit', marginRight: 0.5 }} />
                {comparisonUnit === 'percent' ? <PercentIcon sx={{ fontSize: 'inherit' }} /> : <NumbersIcon sx={{ fontSize: 'inherit' }} />}
              </>
            )}
          </div>
        )}
        aria-label="sort by"
      >
        <MenuItem value={SortByUnit.Count}>
          <div className="flex items-center">
            <div>Count</div>
            <div className="flex items-start ml-1 h-full">
              <NumbersIcon fontSize="inherit" />
            </div>
          </div>
        </MenuItem>
        <MenuItem value={SortByUnit.Delta}>
          <div className="flex items-center">
            <div>{comparisonUnit === 'percent' ? 'Delta (percentage)' : 'Delta (absolute)'}</div>
            <div className="flex items-start ml-1 h-full">
              <ChangeHistory sx={{ fontSize: 'inherit' }} />
              {comparisonUnit === 'percent' ? (
                <PercentIcon sx={{ fontSize: 10, marginLeft: 0.5 }} />
              ) : (
                <NumbersIcon sx={{ fontSize: 'inherit', marginLeft: 0.5 }} />
              )}
            </div>
          </div>
        </MenuItem>
      </Select>
    </div>
  );
};

export default SortByDropdown;
