import { createProductFilters } from '@/components/filter-builder/models/AlFilterModel';
import { useDataGroups } from '@/hooks/useDataGroups';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';

const useAvailableProductFilters = () => {
  const { activeProfile } = useActiveTeamContext();
  const { dataGroupTypeToDataGroupMap } = useDataGroups([DataGroupType.PRODUCT]);

  const availableProductFilters = createProductFilters(
    dataGroupTypeToDataGroupMap[DataGroupType.PRODUCT] ?? [],
    activeProfile?.isSeller ?? false,
  );

  const availableProductFilterBuilderFilters = availableProductFilters.filter((filter) => filter.isFilterBuilderFilter);

  return {
    availableProductFilters,
    availableProductFilterBuilderFilters,
    isLoading: false, // TODO
    error: false, // TODO
  };
};

export default useAvailableProductFilters;
