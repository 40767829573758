export enum DashboardWidgetType {
  CountWidget = 'CountWidget',
  ComboGraphWidget = 'ComboGraphWidget',
  MetricWidget = 'MetricWidget',
  TableWidget = 'TableWidget',
  BarGraphWidget = 'BarGraphWidget',
  PieGraphWidget = 'PieGraphWidget',
  HorizontalDividerWidget = 'HorizontalDividerWidget',
  TextWidget = 'TextWidget',
  LineGraphWidget = 'LineGraphWidget',
}
