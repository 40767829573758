import axios from 'axios';
import { registerErrorInterceptor } from './error.interceptor';
import { registerFirebaseInterceptor } from './firebase.interceptor';
import { apiClient, apiProfileClient, apiTeamClient } from './base-client';
import { Environment } from '@/config/Environment';

export function initApiClient() {
  registerErrorInterceptor(axios);
  registerFirebaseInterceptor(axios);

  apiClient.setBaseUrl(Environment.API_URL);
  apiProfileClient.setBaseUrl(Environment.API_URL);
  apiTeamClient.setBaseUrl(Environment.API_URL);
}
