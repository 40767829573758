import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { APP_DEFAULT_PATH } from './router';
import { Routes } from './router-paths';
import { useUserContext } from '@/modules/users';

const CatchAllRoute: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUserContext();

  const navigateToAppropriateLocation = () => {
    if (!user) {
      navigate(Routes.LOGIN, { replace: true });
    } else {
      navigate(APP_DEFAULT_PATH, { replace: true });
    }
  };

  useEffect(() => {
    navigateToAppropriateLocation();
  }, [location]);

  return null;
};

export default CatchAllRoute;
