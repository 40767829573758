import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { Routes } from '@/router/router-paths';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Paper, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SellerCentralAuthorizationDialog } from './SellerCentralAuthorizationDialog';
import { VendorCentralAuthorizationDialog } from './VendorCentralAuthorizationDialog';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Environment } from '@/config/Environment';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';

export interface SellerOrVendorCentralSelectionDialogProps {
  open: boolean;
  onClose: () => void;
}

export function SellerOrVendorCentralSelectionDialog({ onClose, open }: SellerOrVendorCentralSelectionDialogProps) {
  const { activeTeam } = useActiveTeamContext();
  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  const [isSellerCentralDialogOpen, setIsSellerCentralDialogOpen] = useState(false);

  const onAuthSellerCentralClicked = () => {
    // check if license is pro
    if (!activeTeam?.subscriptionPlan.canAuthorizeSellerCentral) {
      setIsPaywallModalOpen(true);
      return;
    }
    onClose();
    setIsSellerCentralDialogOpen(true);
  };

  const [isVendorCentralDialogOpen, setIsVendorCentralDialogOpen] = useState(false);

  const onAuthVendorCentralClicked = () => {
    // check if license is pro
    if (!activeTeam?.subscriptionPlan.canAuthorizeVendorCentral) {
      setIsPaywallModalOpen(true);
      return;
    }
    onClose();
    setIsVendorCentralDialogOpen(true);
  };

  const handleClose = () => {
    onClose();
    setIsSellerCentralDialogOpen(false);
    setIsVendorCentralDialogOpen(false);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
        <DialogTitle>
          <div className="flex flex-row">
            Authorize Seller Central
            <div className="flex flex-grow"></div>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="max-w-2xl">
            By linking the Selling Partner account to your advertising profile, you gain access to valuable metrics like{' '}
            <strong>Total Sales</strong>, <strong>Organic Sales</strong>, <strong>TACOS</strong>, among many others.
            <div className="flex items-center mt-4 mb-2">
              <InfoIcon fontSize="small" className="mr-1.5 text-blue-600" />
              Make sure to have the correct profile active in Seller/Vendor Central before authorizing.
            </div>
            <div className="flex flex-row justify-between p-4 gap-4 min-h-56">
              <Paper
                elevation={3}
                className="flex-1 flex flex-col items-center justify-center p-4 rounded-lg hover:bg-gray-100 transition-colors gap-4"
              >
                <Typography variant="h5">Seller Central</Typography>

                <Button variant="contained" onClick={onAuthSellerCentralClicked}>
                  Authorize Seller
                </Button>
              </Paper>
              <Paper
                elevation={3}
                className="flex-1 flex flex-col items-center justify-center p-4 rounded-lg hover:bg-gray-100 transition-colors gap-4"
              >
                <Typography variant="h5">Vendor Central</Typography>
                {!Environment.isDev() && (
                  <Typography className="p-1" variant="subtitle1">
                    Coming Soon!
                  </Typography>
                )}
                <Button variant="contained" disabled={!Environment.isDev()} onClick={onAuthVendorCentralClicked}>
                  Authorize Vendor
                </Button>
              </Paper>
            </div>
            <Button
              variant="text"
              href="https://help.adlabs.app/en/articles/38-seller-central-user-permissions-needed-to-use-adlabs"
              target="_blank"
              rel="noopener noreferrer"
              size="large"
              endIcon={<OpenInNewIcon />}
            >
              Learn More about Seller Central Permissions needed to use AdLabs
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <SellerCentralAuthorizationDialog open={isSellerCentralDialogOpen} onClose={() => setIsSellerCentralDialogOpen(false)} />

      <VendorCentralAuthorizationDialog open={isVendorCentralDialogOpen} onClose={() => setIsVendorCentralDialogOpen(false)} />

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.PROFILES}
        headerText="Upgrade to Pro to unlock metrics like Total Sales, Organic Sales and more!"
      ></PaywallModal>
    </>
  );
}
