import { initAgGrid } from './ag-grid';
import { initApiClient } from './api';
import { initGleap } from './gleap';
import { initReactMUI } from './react-mui';
import { initSentry } from './sentry';

export { useTranslation } from './i18n/useTranslate';

export function initLibs() {
  initApiClient();
  initAgGrid();
  initGleap();
  initSentry();
  initReactMUI();
}
