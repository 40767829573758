import { useTranslation } from '@/lib';
import { EntityType } from '../components/forms/EntityTypeSelect';
import { GroupByField } from '../types/GroupByField';

export interface DashboardTranslationProps {
  entityType?: EntityType;
  campaignGroupByValue?: number | GroupByField;
  targetsGroupByValue?: number | GroupByField;
  placementGroupByValue?: number | GroupByField;
  productGroupByValue?: number | GroupByField;
  value: string;
}

export const useDashboardTranslations = () => {
  const { t } = useTranslation();

  const getTranslatedLabel = ({
    entityType,
    campaignGroupByValue,
    targetsGroupByValue,
    placementGroupByValue,
    productGroupByValue,
    value,
  }: DashboardTranslationProps): string => {
    if (entityType === EntityType.PLACEMENT) {
      if (placementGroupByValue === GroupByField.PLACEMENT_TYPE) {
        return t(`enums.placement_type.${value}`);
      } else if (placementGroupByValue === GroupByField.AD_TYPE) {
        return t(`enums.campaign_ad_type.${value}`);
      }
    }

    if (entityType === EntityType.CAMPAIGN) {
      if (campaignGroupByValue === GroupByField.AD_TYPE) {
        return t(`enums.campaign_ad_type.${value}`);
      }
    }

    if (entityType === EntityType.TARGET) {
      if (targetsGroupByValue === GroupByField.AD_TYPE) {
        return t(`enums.campaign_ad_type.${value}`);
      }
    }

    if (entityType === EntityType.PRODUCT) {
      if (productGroupByValue === GroupByField.AVAILABILITY) {
        return t(`enums.product_availability.${value}`);
      }
    }

    return value; // Return the value directly if no specific translation applies
  };

  return { getTranslatedLabel };
};
