import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isNil, throttle } from 'lodash-es';
import { useDashboardContextValue } from '../contexts/DashboardContextProvider';
import { dashboardDataService } from '../api/dashboard/data/dashboard-data.service';
import { DashboardModel } from '../types/DashboardModel';

export const generateDashboardQueryKey = (dashboard: DashboardModel | undefined) => {
  return [dashboard ? dashboard.dto.id : 'unknown', 'DASHBOARD_DATA'];
};

// TODO: Fix never use warnings
export function useDashboardQueries() {
  const { dashboard, setWidgetIdsRequestingData, widgetIdsRequestingData } = useDashboardContextValue((context) => ({
    dates: context.dates,
    comparisonDates: context.comparisonDates,
    dashboard: context.dashboard,
    dashboardDateFilters: context.dashboardDateFilters,
    setWidgetIdsRequestingData: context.setWidgetIdsRequestingData,
    widgetIdsRequestingData: context.widgetIdsRequestingData,
    saveDashboard: context.saveDashboard,
    dashboardVersion: context.dashboardVersion,
  }));
  const [isLoading, setIsLoading] = useState(true);
  // const previousVersion = useRef(dashboardVersion);

  const { data: dashboardData, refetch } = useQuery({
    queryKey: generateDashboardQueryKey(dashboard),
    queryFn: async () => {
      if (!dashboard) {
        throw new Error('Dashboard not found');
      }

      const result = await dashboardDataService.getById(dashboard.dto.id);

      setIsLoading(false);
      setWidgetIdsRequestingData(new Set<string>());

      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading dashboard data');
      }
    },
    enabled: !isNil(dashboard),
  });

  // Throttle the refetch function
  const throttledRefetchDashboardData = throttle(refetch, 2500, {
    leading: true,
    trailing: false,
  });

  const refetchForWidgetWithId = (widgetId: string) => {
    setWidgetIdsRequestingData((prev) => {
      if (prev.has(widgetId)) {
        return prev;
      } else {
        prev.add(widgetId);
        return new Set(prev);
      }
    });
    throttledRefetchDashboardData();
  };

  function getDataForWidgetWithId(widgetId: string) {
    return dashboardData?.getWidgetData(widgetId);
  }

  function refetchDashboardData() {
    // set all widgets loading
    setWidgetIdsRequestingData(new Set(dashboard?.dto.widgets.map((w) => w.id) || []));
    throttledRefetchDashboardData();
  }

  return {
    isLoading,
    dashboardData,
    refetchForWidgetWithId,
    throttledRefetchDashboardData,
    widgetIdsRequestingData,
    getDataForWidgetWithId,
    refetchDashboardData,
  };
}
