import { ColumnId } from '@/components/grid/columns/columns.enum';
import { SentimentDirection } from '@/types/SentimentDirection';
import { FilterKey } from '../filter-builder/types/FilterKey';
import {
  CommonMetricField,
  CommonWithSellerMetricField,
  MetricField,
  ProfileStatsSellerMetricField,
  SellerMetricField,
} from './types/MetricField';

export const METRIC_COLORS: string[] = ['#399fcc', '#2EC598', '#586182', '#E97B20'];
export const MAX_METRICS_CAN_SELECT = METRIC_COLORS.length;

export interface MetricColor {
  key: MetricField;
  color: string;
}

export enum UnitType {
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
  NONE = 'NONE',
}

interface MetricConfiguration {
  key: MetricField;
  title: string;
  sentimentDirection: SentimentDirection;
  chartSeriesType: 'bar' | 'line';
  colId: ColumnId;
  isCalculatedMetric: boolean;
  unitType: UnitType;
  filterKey: FilterKey;
}

export function getConfigForMetric(metricKey: MetricField) {
  return METRICS[metricKey];
}

export function getSentimentByMetric(metricKey: MetricField) {
  return METRICS[metricKey].sentimentDirection;
}

export function getMetricConfigByColId(colId: ColumnId) {
  return Object.values(METRICS).find((metric) => metric.colId === colId);
}

export function getMetricColumns() {
  return Object.values(METRICS).map((metric) => metric.colId);
}

export function getMetricConfigByFilterKey(filterKey: FilterKey) {
  return Object.values(METRICS).find((metric) => metric.filterKey === filterKey);
}

export function getSellerMetricColIds() {
  return Object.values(SELLER_METRICS).map((metric) => metric.colId);
}

export function isMetricColumn(colId: ColumnId) {
  return Object.values(METRICS).some((metric) => metric.colId === colId);
}

export const COMMON_METRICS: { [K in CommonMetricField]: MetricConfiguration } = {
  [CommonMetricField.IMPRESSIONS]: {
    key: CommonMetricField.IMPRESSIONS,
    filterKey: FilterKey.IMPRESSIONS,
    title: 'Impressions',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.IMPRESSIONS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.CLICKS]: {
    key: CommonMetricField.CLICKS,
    filterKey: FilterKey.CLICKS,
    title: 'Clicks',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.CLICKS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.ORDERS]: {
    key: CommonMetricField.ORDERS,
    filterKey: FilterKey.ORDERS,
    title: 'Orders',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.ORDERS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.UNITS]: {
    key: CommonMetricField.UNITS,
    filterKey: FilterKey.UNITS,
    title: 'Units',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.UNITS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.CTR]: {
    key: CommonMetricField.CTR,
    filterKey: FilterKey.CTR,
    title: 'CTR',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.CTR,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [CommonMetricField.CVR]: {
    key: CommonMetricField.CVR,
    filterKey: FilterKey.CVR,
    title: 'CVR',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.CVR,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [CommonMetricField.CPC]: {
    key: CommonMetricField.CPC,
    filterKey: FilterKey.CPC,
    title: 'CPC',
    sentimentDirection: SentimentDirection.NEUTRAL,
    chartSeriesType: 'line',
    colId: ColumnId.CPC,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.SPEND]: {
    key: CommonMetricField.SPEND,
    filterKey: FilterKey.SPEND,
    title: 'Spend',
    sentimentDirection: SentimentDirection.NEUTRAL,
    chartSeriesType: 'bar',
    colId: ColumnId.SPEND,
    isCalculatedMetric: false,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.SALES]: {
    key: CommonMetricField.SALES,
    filterKey: FilterKey.SALES,
    title: 'Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.SALES,
    isCalculatedMetric: false,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.SAME_SKU_ORDERS]: {
    key: CommonMetricField.SAME_SKU_ORDERS,
    filterKey: FilterKey.SAME_SKU_ORDERS,
    title: 'sSKU Orders',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.SAME_SKU_ORDERS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.SAME_SKU_SALES]: {
    key: CommonMetricField.SAME_SKU_SALES,
    filterKey: FilterKey.SAME_SKU_SALES,
    title: 'sSKU Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.SAME_SKU_SALES,
    isCalculatedMetric: false,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.ACOS]: {
    key: CommonMetricField.ACOS,
    filterKey: FilterKey.ACOS,
    title: 'ACOS',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.ACOS,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [CommonMetricField.ROAS]: {
    key: CommonMetricField.ROAS,
    filterKey: FilterKey.ROAS,
    title: 'ROAS',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.ROAS,
    isCalculatedMetric: true,
    unitType: UnitType.NONE,
  },
  [CommonMetricField.RPC]: {
    key: CommonMetricField.RPC,
    filterKey: FilterKey.RPC,
    title: 'RPC',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.RPC,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.CPA]: {
    key: CommonMetricField.CPA,
    filterKey: FilterKey.CPA,
    title: 'CPA',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.CPA,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.AOV]: {
    key: CommonMetricField.AOV,
    filterKey: FilterKey.AOV,
    title: 'AOV',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.AOV,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [CommonMetricField.CPM]: {
    key: CommonMetricField.CPM,
    filterKey: FilterKey.CPM,
    title: 'CPM',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.CPM,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
};

const SELLER_METRICS: { [K in SellerMetricField]: MetricConfiguration } = {
  [SellerMetricField.SELLER_ACOS]: {
    key: SellerMetricField.SELLER_ACOS,
    filterKey: FilterKey.ACOTS,
    title: 'TACOS', // Total Ad Cost of Sales was Ad Cost of Total Sales (ACOTS)
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.ACOTS,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [SellerMetricField.SELLER_AD_SALES_OF_TOTAL]: {
    key: SellerMetricField.SELLER_AD_SALES_OF_TOTAL,
    filterKey: FilterKey.AD_SALES_OF_TOTAL,
    title: 'Ad Sales % of Total',
    sentimentDirection: SentimentDirection.NEUTRAL,
    chartSeriesType: 'line',
    colId: ColumnId.AD_SALES_OF_TOTAL,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [SellerMetricField.SELLER_AOV]: {
    key: SellerMetricField.SELLER_AOV,
    filterKey: FilterKey.TAOV,
    title: 'Total AOV',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.TOTAL_AOV,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [SellerMetricField.SELLER_ASP]: {
    key: SellerMetricField.SELLER_ASP,
    filterKey: FilterKey.ASP,
    title: 'ASP',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.ASP,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [SellerMetricField.SELLER_CLICKS]: {
    key: SellerMetricField.SELLER_CLICKS,
    filterKey: FilterKey.TOTAL_CLICKS,
    title: 'Total Sessions',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_CLICKS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [SellerMetricField.SELLER_CPA]: {
    key: SellerMetricField.SELLER_CPA,
    filterKey: FilterKey.TCPA,
    title: 'Total CPA',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.TOTAL_CPA,
    isCalculatedMetric: true,
    unitType: UnitType.CURRENCY,
  },
  [SellerMetricField.SELLER_CVR]: {
    key: SellerMetricField.SELLER_CVR,
    filterKey: FilterKey.TCVR,
    title: 'Total CVR',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.TOTAL_CVR,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [SellerMetricField.SELLER_ORDERS]: {
    key: SellerMetricField.SELLER_ORDERS,
    filterKey: FilterKey.TOTAL_ORDERS,
    title: 'Total Orders',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_ORDERS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [SellerMetricField.SELLER_ORG_SALES]: {
    key: SellerMetricField.SELLER_ORG_SALES,
    filterKey: FilterKey.ORGANIC_SALES,
    title: 'Organic Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.ORGANIC_SALES,
    isCalculatedMetric: false,
    unitType: UnitType.CURRENCY,
  },
  [SellerMetricField.SELLER_ORG_TRAFFIC]: {
    key: SellerMetricField.SELLER_ORG_TRAFFIC,
    filterKey: FilterKey.ORGANIC_TRAFFIC,
    title: 'Organic Traffic',
    sentimentDirection: SentimentDirection.NEUTRAL,
    chartSeriesType: 'bar',
    colId: ColumnId.ORGANIC_TRAFFIC,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [SellerMetricField.SELLER_ROAS]: {
    key: SellerMetricField.SELLER_ROAS,
    filterKey: FilterKey.TROAS,
    title: 'Total ROAS',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.TOTAL_ROAS,
    isCalculatedMetric: true,
    unitType: UnitType.NONE,
  },
  [SellerMetricField.SELLER_SALES]: {
    key: SellerMetricField.SELLER_SALES,
    filterKey: FilterKey.TOTAL_SALES,
    title: 'Total Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_SALES,
    isCalculatedMetric: false,
    unitType: UnitType.CURRENCY,
  },
  [SellerMetricField.SELLER_UNIT_SESS]: {
    key: SellerMetricField.SELLER_UNIT_SESS,
    filterKey: FilterKey.UPS,
    title: 'Units Per Session',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.UPS,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [SellerMetricField.SELLER_UNIT_VIEW]: {
    key: SellerMetricField.SELLER_UNIT_VIEW,
    filterKey: FilterKey.UPPW,
    title: 'Unit Pageview %',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.UNIT_VIEW,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
  [SellerMetricField.SELLER_UNITS]: {
    key: SellerMetricField.SELLER_UNITS,
    filterKey: FilterKey.TOTAL_UNITS,
    title: 'Total Units',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_UNITS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [SellerMetricField.SELLER_VIEWS]: {
    key: SellerMetricField.SELLER_VIEWS,
    filterKey: FilterKey.TOTAL_VIEWS,
    title: 'Total Views',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_VIEWS,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
};

export const PROFILE_STATS_SELLER_METRICS: { [K in ProfileStatsSellerMetricField]: MetricConfiguration } = {
  [ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED]: {
    key: ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED,
    filterKey: FilterKey.TOTAL_UNITS_REFUNDED,
    title: 'Units Refunded',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'bar',
    colId: ColumnId.UNITS_REFUNDED,
    isCalculatedMetric: false,
    unitType: UnitType.NONE,
  },
  [ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE]: {
    key: ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE,
    filterKey: FilterKey.TOTAL_UNITS_REFUNDED_RATE,
    title: 'Units Refund Rate',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.UNITS_REFUND_RATE,
    isCalculatedMetric: true,
    unitType: UnitType.PERCENTAGE,
  },
};

export const COMMON_WITH_SELLER_METRICS: { [K in CommonWithSellerMetricField]: MetricConfiguration } = {
  ...COMMON_METRICS,
  ...SELLER_METRICS,
};

export const METRICS: { [K in MetricField]: MetricConfiguration } = {
  ...COMMON_METRICS,
  ...SELLER_METRICS,
  ...PROFILE_STATS_SELLER_METRICS,
};

export const DEFAULT_VISIBLE_METRICS: MetricSelectionColorIndex[] = [
  {
    key: CommonMetricField.IMPRESSIONS,
    isSelected: false,
    colorIndex: -1,
  },
  {
    key: CommonMetricField.CLICKS,
    isSelected: false,
    colorIndex: -1,
  },
  {
    key: CommonMetricField.ORDERS,
    isSelected: false,
    colorIndex: -1,
  },
  {
    key: CommonMetricField.SPEND,
    isSelected: false,
    colorIndex: -1,
  },
  {
    key: CommonMetricField.SALES,
    isSelected: true,
    colorIndex: 1,
  },
  {
    key: CommonMetricField.ACOS,
    isSelected: true,
    colorIndex: 0,
  },
  {
    key: CommonMetricField.CTR,
    isSelected: false,
    colorIndex: -1,
  },
  {
    key: CommonMetricField.CVR,
    isSelected: false,
    colorIndex: -1,
  },
];

export type MetricSelectionColorIndex = {
  key: MetricField;
  isSelected: boolean;
  colorIndex: number;
};

export function getSelectedMetricsCount(metrics: MetricSelectionColorIndex[]): number {
  return metrics.filter((metric) => metric.isSelected).length;
}

export function canSelectMoreMetrics(metrics: MetricSelectionColorIndex[]): boolean {
  return getSelectedMetricsCount(metrics) < MAX_METRICS_CAN_SELECT;
}

function updateMetricSelectionState(metrics: MetricSelectionColorIndex[], metricKey: MetricField, isSelected: boolean) {
  if (!canSelectMoreMetrics(metrics) && isSelected) {
    console.error('Tried to select more metrics than allowed');
    return metrics;
  }

  const newColorIndex = isSelected ? getNewColorIndex(metrics) : -1;
  return metrics.map((metric) => {
    if (metric.key === metricKey) {
      return {
        ...metric,
        isSelected,
        colorIndex: newColorIndex,
      };
    }
    return metric;
  });
}

function getNewColorIndex(metrics: MetricSelectionColorIndex[]): number {
  const usedIndexes = metrics.filter((metric) => metric.isSelected).map((metric) => metric.colorIndex);

  // Get next unused index in the span of MAX_METRICS_CAN_SELECT
  for (let i = 0; i < MAX_METRICS_CAN_SELECT; i++) {
    if (!usedIndexes.includes(i)) {
      return i;
    }
  }

  console.error('Could not find new color index. Returning -1');
  return -1;
}

export function toggleMetricSelectionStatus(metrics: MetricSelectionColorIndex[], metricKeyToToggleSelect: MetricField) {
  return updateMetricSelectionState(
    metrics,
    metricKeyToToggleSelect,
    !metrics.find((metric) => metric.key === metricKeyToToggleSelect)?.isSelected,
  );
}

export function updateMetricVisibilityStatus(metrics: MetricSelectionColorIndex[], newVisibleMetrics: MetricField[]) {
  return newVisibleMetrics.map((metric) => {
    const newMetric = metrics.find((m) => m.key === metric);
    return newMetric ? newMetric : { key: metric, isSelected: false, colorIndex: -1 };
  });
}

export function getColorByIndex(index: number): string {
  return METRIC_COLORS[index % METRIC_COLORS.length];
}
