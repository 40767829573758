import { CommonMetrics } from '@/components/metrics/models/CommonMetricsModel';
import { MetricData, MetricDataWithPreviousDays } from '@/components/metrics/types/MetricData';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { CampaignGroupMetricsDTO } from '../campaign-contracts';
import { CampaignGroupArguments, CampaignGroupModel } from './CampaignGroupModel';

export class CampaignGroupMetricsModel extends CampaignGroupModel {
  public impressions: MetricData;
  public clicks: MetricData;
  public orders: MetricData;
  public units: MetricData;
  public ctr: MetricData;
  public cvr: MetricData;
  public cpc: MetricData;
  public spend: MetricDataWithPreviousDays;
  public sales: MetricDataWithPreviousDays;
  public acos: MetricData;
  public roas: MetricData;
  public rpc: MetricData;
  public cpa: MetricData;
  public aov: MetricData;
  public cpm: MetricData;

  constructor(args: CampaignGroupMetricsArguments) {
    super(args);

    this.impressions = args.metrics.impressions;
    this.clicks = args.metrics.clicks;
    this.orders = args.metrics.orders;
    this.units = args.metrics.units;
    this.ctr = args.metrics.ctr;
    this.cvr = args.metrics.cvr;
    this.cpc = args.metrics.cpc;
    this.spend = args.metrics.spend;
    this.sales = args.metrics.sales;
    this.acos = args.metrics.acos;
    this.roas = args.metrics.roas;
    this.rpc = args.metrics.rpc;
    this.cpa = args.metrics.cpa;
    this.aov = args.metrics.aov;
    this.cpm = args.metrics.cpm;
  }

  public static fromResponseArray(dtos: CampaignGroupMetricsDTO[]): CampaignGroupMetricsModel[] {
    return dtos.map(CampaignGroupMetricsModel.fromCampaignGroupResponse);
  }

  public static fromCampaignGroupResponse(response: CampaignGroupMetricsDTO): CampaignGroupMetricsModel {
    // Assuming CommonMetrics.fromResponse correctly constructs a CommonMetrics instance

    return new CampaignGroupMetricsModel({
      id: response.id,
      name: response.name,
      tacos: response.tacos,
      preset: response.preset ?? OptimizationPreset.NOT_SET,
      bidCeiling: response.bid_ceiling,
      bidFloor: response.bid_floor,
      totalCampaigns: response.total_campaigns,
      maxBidIncrease: response.bid_max_increase,
      maxBidDecrease: response.bid_max_decrease,
      placementMaxIncrease: response.placement_max_increase,
      placementMaxDecrease: response.placement_max_decrease,
      bidCeilingOff: response.bid_ceiling_off,
      bidFloorOff: response.bid_floor_off,
      bidMaxIncreaseOff: response.bid_max_increase_off,
      bidMaxDecreaseOff: response.bid_max_decrease_off,
      placementMaxIncreaseOff: response.placement_max_increase_off,
      placementMaxDecreaseOff: response.placement_max_decrease_off,
      optimizationEnabled: !response.no_optimize,
      metrics: CommonMetrics.fromResponse(response.metrics),
    });
  }

  public static fromCampaignGroupModel(model: CampaignGroupModel): CampaignGroupMetricsModel {
    return new CampaignGroupMetricsModel({
      id: model.id,
      name: model.name,
      tacos: model.tacos,
      preset: model.preset,
      bidCeiling: model.bidCeiling,
      bidFloor: model.bidFloor,
      totalCampaigns: model.totalCampaigns,
      maxBidIncrease: model.maxBidIncrease,
      maxBidDecrease: model.maxBidDecrease,
      placementMaxIncrease: model.placementMaxIncrease,
      placementMaxDecrease: model.placementMaxDecrease,
      bidCeilingOff: model.bidCeilingOff,
      bidFloorOff: model.bidFloorOff,
      bidMaxIncreaseOff: model.bidMaxIncreaseOff,
      bidMaxDecreaseOff: model.bidMaxDecreaseOff,
      placementMaxIncreaseOff: model.placementMaxIncreaseOff,
      placementMaxDecreaseOff: model.placementMaxDecreaseOff,
      optimizationEnabled: model.optimizationEnabled,
      metrics: CommonMetrics.withZeroMetrics(),
    });
  }
}

interface CampaignGroupMetricsArguments extends CampaignGroupArguments {
  metrics: CommonMetrics;
}
