import { Chip } from '@mui/material';
import { AdLabsPlanModel } from '../types/AdLabPlanModel';
import { PlanType } from '../types/SubscriptionPlan';

export const FREE_PLAN = new AdLabsPlanModel({
  planType: PlanType.FREE_PLAN,
  name: 'Free',
  price: 0,
  priceLabel: (
    <div className="flex justify-center items-baseline mt-2">
      <span className=" text-lg font-extrabold">$0</span>
      <span className="text-gray-500 dark:text-gray-400">/month</span>
    </div>
  ),
  priceDescription: '',
  features: ['Sponsored Products support', '30 days data retention', 'Scheduled data updates'],
});

export const LEGACY_PRO_PLAN = new AdLabsPlanModel({
  planType: PlanType.PRO_PLAN,
  name: 'PRO (Legacy)',
  price: 79,
  priceLabel: (
    <div className="flex justify-center items-baseline mt-2">
      <span className=" text-lg font-extrabold">$79</span>
      <span className="text-gray-500 dark:text-gray-400">/month</span>
    </div>
  ),
  priceDescription: '',
  features: ['Sponsored Brands & Display', 'Targeting & Placements Analyzer', 'Tags', 'Unlimited Data Retention'],
});

export const PRO_PLAN = new AdLabsPlanModel({
  planType: PlanType.PRO_PLAN,
  name: (
    <div className="flex items-center">
      PRO
      <Chip
        className="bg-amber-500 text-amber-950 text-sm ml-2"
        label={
          <>
            <span className="font-semibold">50% OFF</span> <span className="font-normal">(For Life!)</span>
          </>
        }
      />
    </div>
  ),
  price: 40,
  priceLabel: (
    <div className="flex flex-col justify-center mt-2">
      <div></div>
      <span>
        <span className="text-3xl font-semibold">1%</span>
        <span className="text-2xl font-semibold"> Ad Spend</span>
        <span className=""> + $40/mo per team</span>
      </span>
    </div>
  ),
  priceDescription: (
    <p>
      <span>2024 subscribers enjoy 1% of Ad Spend: </span>
      <span className="font-semibold text-amber-600">50% OFF FOREVER</span>
    </p>
  ),
  features: [
    'Search Term Analysis',
    'Negative Keyword Tools',
    'Sponsored Brands & Display',
    'Targeting & Placements Analyzer',
    'Tags',
    'Unlimited Data Retention',
  ],
});

export function createProPlan({
  adSpendPercentage,
  pricePerTeam,
  discountRate,
}: {
  adSpendPercentage: number;
  pricePerTeam: number;
  discountRate: number;
}): AdLabsPlanModel {
  return new AdLabsPlanModel({
    planType: PlanType.PRO_PLAN,
    name: (
      <div className="flex items-center">
        PRO
        {discountRate > 0 && (
          <Chip
            className="bg-amber-500 text-amber-950 text-sm ml-2"
            label={
              <>
                <span className="font-semibold">{discountRate}% OFF</span>
              </>
            }
          />
        )}
      </div>
    ),
    price: pricePerTeam,
    priceLabel: (
      <div className="flex flex-col justify-center mt-2">
        <span>
          <span className="text-3xl font-semibold">{adSpendPercentage}%</span>
          <span className="text-2xl font-semibold"> Ad Spend</span>
          <span> + ${pricePerTeam}/mo per team</span>
        </span>
      </div>
    ),
    priceDescription: (
      <p>
        <span>2024 subscribers enjoy 1% of Ad Spend: </span>
        <span className="font-semibold text-amber-600">50% OFF FOREVER</span>
      </p>
    ),
    features: [
      'Search Term Analysis',
      'Negative Keyword Tools',
      'Sponsored Brands & Display',
      'Targeting & Placements Analyzer',
      'Tags',
      'Unlimited Data Retention',
    ],
  });
}

export const PRO_PROFILES_80_PLAN = new AdLabsPlanModel({
  planType: PlanType.PRO_PLAN,
  name: 'PRO',
  price: 80,
  priceLabel: (
    <div className="flex flex-col items-center justify-center mt-2">
      <span className=" text-lg font-extrabold">$80 per profile</span>
    </div>
  ),
  priceDescription: '',
  features: [
    'Search Term Analysis',
    'Negative Keyword Tools',
    'Sponsored Brands & Display',
    'Targeting & Placements Analyzer',
    'Tags',
    'Unlimited Data Retention',
  ],
});

export const PRO_PROFILES_120_PLAN = new AdLabsPlanModel({
  planType: PlanType.PRO_PLAN,
  name: 'PRO',
  price: 120,
  priceLabel: (
    <div className="flex flex-col items-center justify-center mt-2">
      <span className=" text-lg font-extrabold">$120 per profile</span>
    </div>
  ),
  priceDescription: '',
  features: [
    'Search Term Analysis',
    'Negative Keyword Tools',
    'Sponsored Brands & Display',
    'Targeting & Placements Analyzer',
    'Tags',
    'Unlimited Data Retention',
  ],
});

export const PRO_PROFILES_250_PLAN = new AdLabsPlanModel({
  planType: PlanType.PRO_PLAN,
  name: 'PRO',
  price: 250,
  priceLabel: (
    <div className="flex flex-col items-center justify-center mt-2">
      <span className=" text-lg font-extrabold">$250 per profile</span>
    </div>
  ),
  priceDescription: '',
  features: [
    'Search Term Analysis',
    'Negative Keyword Tools',
    'Sponsored Brands & Display',
    'Targeting & Placements Analyzer',
    'Tags',
    'Unlimited Data Retention',
  ],
});

export const PRO_BRANDS_250_PLAN = new AdLabsPlanModel({
  planType: PlanType.PRO_PLAN,
  name: 'PRO',
  price: 250,
  priceLabel: (
    <div className="flex flex-col items-center justify-center mt-2">
      <span className=" text-lg font-extrabold">$250 per brand</span>
    </div>
  ),
  priceDescription: '',
  features: [
    'Search Term Analysis',
    'Negative Keyword Tools',
    'Sponsored Brands & Display',
    'Targeting & Placements Analyzer',
    'Tags',
    'Unlimited Data Retention',
  ],
});
