import CollapsibleCopyableDetails from '@/components/feedback/CollapsibleCopyableDetails';
import AddMetricButton from '@/components/metrics/AddMetricButton';
import { MetricSelectionColorIndex, toggleMetricSelectionStatus, updateMetricVisibilityStatus } from '@/components/metrics/MetricsConfig';
import { MetricsCustomizationModal } from '@/components/metrics/MetricsCustomizationModal';
import { MetricField } from '@/components/metrics/types/MetricField';
import { FormattingParams } from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import MetricsList from '@/modules/dashboards/components/forms/MetricsList';
import { Alert, AlertTitle, Card, Skeleton, Typography } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { MetricModel } from './models/MetricModel';

interface MetricsContainerProps {
  metricValues: MetricModel[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  visibleMetrics: MetricSelectionColorIndex[];
  setVisibleMetrics: React.Dispatch<React.SetStateAction<MetricSelectionColorIndex[]>>;
  showComparison?: boolean;
  availableMetrics?: MetricField[];
  formattingParams?: FormattingParams;
}

const MetricsContainer: FunctionComponent<MetricsContainerProps> = ({
  metricValues,
  isLoading,
  isError,
  error,
  visibleMetrics,
  setVisibleMetrics,
  availableMetrics,
  formattingParams,
  showComparison,
}) => {
  const { t } = useTranslation();
  const [isCustomizationModalOpen, setIsCustomizationModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsCustomizationModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCustomizationModalOpen(false);
  };

  function onSingleSelectionChanged(changedMetric: MetricField) {
    setVisibleMetrics((prevVisibleMetrics) => toggleMetricSelectionStatus(prevVisibleMetrics, changedMetric));
  }

  const onApplyVisibilityChanges = (newVisibleMetrics: MetricField[]) => {
    setVisibleMetrics((prevVisibleMetrics) => updateMetricVisibilityStatus(prevVisibleMetrics, newVisibleMetrics));
  };

  return (
    <div className="flex flex-wrap gap-2">
      {isError && (
        <Card className="flex-grow rounded-xl py-0">
          <Alert severity="error">
            <AlertTitle>Oops!</AlertTitle>
            <div className="flex w-full flex-col">
              <Typography variant="body1">{t('something_went_wrong')}</Typography>
              <CollapsibleCopyableDetails headerText={'Details'} message={error instanceof Error ? error.message : ''} />
            </div>
          </Alert>
        </Card>
      )}
      {isLoading && (
        <div className="flex animate-fadeInBg gap-2">
          {Array(5)
            .fill(1)
            .map((_, i) => (
              <Skeleton key={i} variant="rectangular" width={138} height={90} style={{ borderRadius: '12px' }} />
            ))}
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <MetricsList
            metricValues={metricValues}
            visibleMetrics={visibleMetrics}
            onSingleSelectionChanged={onSingleSelectionChanged}
            availableMetrics={availableMetrics}
            formattingParams={formattingParams}
            showComparison={showComparison}
          />
          <AddMetricButton onClick={handleOpenModal} />
        </>
      )}
      <MetricsCustomizationModal
        isOpen={isCustomizationModalOpen}
        onClose={handleCloseModal}
        visibleMetrics={visibleMetrics.map((metric) => metric.key)}
        onApplyVisibilityChanges={onApplyVisibilityChanges}
        availableMetrics={availableMetrics}
      />
    </div>
  );
};

export default MetricsContainer;
