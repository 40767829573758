import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { AdGroupDTO } from '../campaign-contracts';

export class AdGroupModel {
  public id: string;
  public name: string;
  public state: string;
  public defaultBid: number;
  public entityType: TargetEntityType;

  constructor(args: AdGroupArguments) {
    this.id = args.id;
    this.name = args.name;
    this.state = args.state;
    this.defaultBid = args.defaultBid;
    this.entityType = args.entityType;
  }

  /**
   * Converts an array of AdGroupDTOs (positional arrays) into an array of AdGroupModel instances.
   */
  public static fromResponseArray(dtos: AdGroupDTO[]): AdGroupModel[] {
    return dtos.map(
      (dto) =>
        new AdGroupModel({
          id: dto[0], // Position 0: id
          name: dto[1], // Position 1: name
          state: dto[2], // Position 2: state
          defaultBid: dto[3], // Position 3: defaultBid
          entityType: dto[4], // Position 4: entityType
        }),
    );
  }
}

export interface AdGroupArguments {
  id: string;
  name: string;
  state: string;
  defaultBid: number;
  entityType: TargetEntityType;
}
