import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';

import { DashboardCreateDTO, DashboardCreateProfileDTO, DashboardReadDTO, DashboardUpdateDTO } from './dashboard.contracts';
import { DashboardModel } from '../../types/DashboardModel';

class DashboardService {
  public basePath = 'dashboards';

  async create(dashboardToCreate: DashboardCreateDTO): Promise<ApiResponse<DashboardReadDTO>> {
    try {
      return await apiClient.post<DashboardReadDTO>(this.basePath + '/', dashboardToCreate);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(dashboardId: number, dashboardToUpdate: DashboardUpdateDTO): Promise<ApiResponse<DashboardReadDTO>> {
    try {
      return await apiClient.post<DashboardReadDTO>(`${this.basePath}/${dashboardId}`, dashboardToUpdate);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAll(): Promise<ApiResponse<DashboardModel[]>> {
    try {
      const applicationResponse = await apiClient.get<DashboardReadDTO[]>(this.basePath);
      return applicationResponse.processPayload((payload) => {
        return payload.map((p) => new DashboardModel(p));
      });
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getById(id: number): Promise<ApiResponse<DashboardModel>> {
    try {
      const applicationResponse = await apiClient.get<DashboardReadDTO>(`${this.basePath}/${id}`);
      return applicationResponse.processPayload((payload) => {
        return new DashboardModel(payload);
      });
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async delete(id: number): Promise<ApiResponse<null>> {
    try {
      return await apiClient.post(`${this.basePath}/${id}/delete`, {});
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async addCollaborator(dashboardId: number, userId: number): Promise<ApiResponse<null>> {
    try {
      return await apiClient.post(`${this.basePath}/${dashboardId}/collaborators/${userId}`, {});
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async removeCollaborator(dashboardId: number, userId: number): Promise<ApiResponse<null>> {
    try {
      return await apiClient.post(`${this.basePath}/${dashboardId}/collaborators/${userId}/delete`, {});
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updateDashboardProfiles(dashboardId: number, profiles: DashboardCreateProfileDTO[]): Promise<ApiResponse<DashboardReadDTO>> {
    try {
      return await apiClient.put<DashboardReadDTO>(`${this.basePath}/${dashboardId}/profiles`, profiles);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async duplicateDashboard(dashboardId: number): Promise<ApiResponse<DashboardReadDTO>> {
    try {
      return await apiClient.post<DashboardReadDTO>(`${this.basePath}/${dashboardId}/duplicate`, {});
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const dashboardService = new DashboardService();
