import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useCallback } from 'react';
import { CampaignMappingService, campaignMappingService } from '../api/campaign-mapping-service';
import { CampaignAdGroupPair, CampaignToCampaignDataWithCampaignMappingAdGroupDataType } from '../models/CampaignMappingModel';

const useCampaignToAdGroupsMappingData = () => {
  const { activeProfile } = useActiveTeamContext();

  const {
    data: campaignToAdGroupsMap,
    isLoading: isCampaignToAdGroupsMapLoading,
    isFetching,
  } = useQuery({
    queryKey: CampaignMappingService.getCampaignsToCampaignMappingAdGroupDataQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignMappingService.getCampaignToAdGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading campaign mappings ' + JSON.stringify(result));
        throw new Error('Error loading campaign mappings ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(activeProfile?.id),
  });

  useGlobalLoadingStateObserver('isFetching campaign to ad groups', isFetching);

  const getUnmappedCampaignAdgroupPairs = useCallback((): CampaignAdGroupPair[] => {
    if (!campaignToAdGroupsMap) {
      return [];
    }

    const result: CampaignAdGroupPair[] = [];
    for (const campaign of Object.values(campaignToAdGroupsMap)) {
      if (!campaign) {
        continue;
      }
      for (const adGroup of campaign.adGroups) {
        if (!adGroup || adGroup.mapCount > 0) {
          continue;
        }

        result.push({ campaignId: campaign.id, adGroupId: adGroup.id, campaignName: campaign.name, adGroupName: adGroup.name });
      }
    }

    return result;
  }, [campaignToAdGroupsMap]);

  const getCampaignNameToCampaignIdMap = useCallback((): Record<string, string[]> => {
    if (!campaignToAdGroupsMap) {
      return {};
    }
    const result: Record<string, string[]> = {};
    for (const campaign of Object.values(campaignToAdGroupsMap)) {
      if (!campaign) {
        continue;
      }
      if (result[campaign.name]) {
        result[campaign.name].push(campaign.id);
      } else {
        result[campaign.name] = [campaign.id];
      }
    }
    return result;
  }, [campaignToAdGroupsMap]);

  const getCampaignMappingCampaignOptions = useCallback(
    (campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType | undefined) => {
      if (!campaignToAdGroupsMap || Object.keys(campaignToAdGroupsMap).length === 0) {
        return [];
      }

      return Object.values(campaignToAdGroupsMap)
        .filter((campaign) => campaign.campaignAdType != CampaignAdType.DISPLAY && campaign.campaignAdType != CampaignAdType.TV)
        .map((campaign) => ({
          id: campaign.id,
          label: campaign.isNameDuplicate ? `${campaign.id} - ${campaign.name}` : campaign.name,
          canHide: campaign.adGroups.every((adGroup) => adGroup.mapCount > 0),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    [campaignToAdGroupsMap],
  );

  return {
    campaignToAdGroupsMap: campaignToAdGroupsMap ?? {},
    isCampaignToAdGroupsMapLoading,
    getUnmappedCampaignAdgroupPairs,
    getCampaignNameToCampaignIdMap,
    getCampaignMappingCampaignOptions,
  };
};

export default useCampaignToAdGroupsMappingData;
