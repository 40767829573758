import FilterControls from '@/components/filter-builder/FilterControls';
import { createCampaignMappingFilters } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from '@mui/lab';
import { Divider } from '@mui/material';
import { FunctionComponent } from 'react';
import { useCampaignMappingContext } from '../contexts/CampaignMappingContext';
import { CampaignMappingModel } from '../models/CampaignMappingModel';
import DownloadCampaignMappingsButton from './DownloadCampaignMappingsButton';
import DownloadCampaignMappingsTemplateButton from './DownloadCampaignMappingsTemplateButton';
import ImportCampaignMappingsButton from './ImportCampaignMappingsButton';
import OpenNewSingleMappingModalButton from './OpenNewSingleMappingModalButton';
import FilterBarCard from '@/components/cards/FilterBarCard';

interface CampaignMappingFilterBarProps {
  onlyDownloadButton?: boolean; // true in import view
  onDeleteAllConfirmed?: () => void;
  isDeleteLoading?: boolean;
  rowData?: CampaignMappingModel[];
}

const CampaignMappingFilterBar: FunctionComponent<CampaignMappingFilterBarProps> = ({
  onlyDownloadButton,
  onDeleteAllConfirmed,
  isDeleteLoading,
  rowData,
}) => {
  const { filters, setFilters } = useCampaignMappingContext();

  const availableCampaignMappingFilters = createCampaignMappingFilters();
  const defaultFilterKey = FilterKey.SOURCE_CAMPAIGN_NAME;

  // Delete
  const { ModalComponent, handleOpenModal: openDeleteConfirmationModal } = useDeleteConfirmation({
    questionText: 'Are you sure you want to delete all mappings currently shown in this table? This action cannot be undone.',
    headerText: 'Delete All Mappings',
    onDelete:
      onDeleteAllConfirmed ??
      (() => {
        // do nothing if onDeleteAllConfirmed is not defined
      }),
  });

  return (
    <FilterBarCard>
      {/* <CampaignMappingSearch /> */}
      <div className="flex flex-row gap-x-4">
        <>
          {onlyDownloadButton ? (
            <DownloadCampaignMappingsTemplateButton />
          ) : (
            <>
              <ImportCampaignMappingsButton />
              <OpenNewSingleMappingModalButton />
            </>
          )}
        </>
        <Divider className="my-2" orientation="vertical" flexItem />
        <FilterControls
          filters={filters}
          setFilters={setFilters}
          availableFilters={availableCampaignMappingFilters}
          defaultFilterKey={defaultFilterKey}
        />
      </div>
      <div className="flex flex-grow"></div>
      <div className="flex gap-x-4">
        {onDeleteAllConfirmed && (
          <>
            <LoadingButton
              loading={isDeleteLoading}
              disabled={isDeleteLoading}
              variant="outlined"
              size="small"
              color="error"
              onClick={openDeleteConfirmationModal}
              startIcon={<DeleteForeverIcon />}
            >
              {filters.length > 0 ? 'Delete Shown Mappings' : 'Delete All Mappings'}
            </LoadingButton>
            {ModalComponent}
          </>
        )}

        {rowData && rowData.length > 0 && <DownloadCampaignMappingsButton rowData={rowData} />}
      </div>
    </FilterBarCard>
  );
};

export default CampaignMappingFilterBar;
