import { RouteObject } from 'react-router';
import NegativeTargetingPage from '../pages/NegativeTargetsPage';

export const NEGATIVE_TARGETS_ROUTES: RouteObject[] = [
  {
    path: 'negative-targeting',
    children: [
      {
        index: true,
        element: <NegativeTargetingPage />,
      },
    ],
  },
];
