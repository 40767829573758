import Close from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { FileUploader } from '../../components/FileUploader';
import { useSheets } from '../../hooks/useSheets';
import { ImportModalProps } from '../../types/import-definition';
import { CAMPAIGN_MAPPING_IMPORT_ROW_KEYS, CampaignMappingImportRow } from './CampaignMappingImportRow';
import { CampaignMappingPreview } from './CampaignMappingPreview';
import { useNavigationConfirmationModal } from '@/components/modals/confirmation-modal/useNavigationConfirmationModal';
import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';

export const CampaignMappingImportModal: FunctionComponent<ImportModalProps> = ({ isOpen, onClose }) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadedFileParsed, setUploadedFileParsed] = useState<CampaignMappingImportRow[]>([]);
  const { getFirstSheetData } = useSheets();

  const { ModalComponent, handleOpenModal } = useConfirmationModal({
    questionText: 'Are you sure you want to cancel the import? All progress will be lost.',
    onConfirm: onClose,
    questionTitle: 'Cancel import',
    confirmButtonText: 'Cancel import',
    cancelButtonText: 'Continue importing',
  });

  const { ModalComponent: navigationModal, setBlock } = useNavigationConfirmationModal({
    questionText: 'Are you sure you want to cancel the import? All progress will be lost.',
  });

  async function onFileChanged(file: File | null) {
    setFile(file);

    if (!file) return;

    const parsedData = await getFirstSheetData(file, CAMPAIGN_MAPPING_IMPORT_ROW_KEYS);

    setUploadedFileParsed(parsedData as CampaignMappingImportRow[]);
  }

  useEffect(() => {
    if (file) {
      setBlock(true);
    } else {
      setBlock(false);
    }
  }, [file]);

  function onCloseModalClicked() {
    handleOpenModal();
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen fullWidth>
      <DialogTitle>
        <div className="flex flex-row">
          Campaign Mapping Import
          <div className="flex flex-grow"></div>
          <IconButton edge="start" color="inherit" onClick={onCloseModalClicked} aria-label="close">
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {!file && (
          <div className="flex w-full flex-1 h-full flex-col items-center justify-center ">
            <FileUploader file={file} onFileChanged={onFileChanged} />
          </div>
        )}
        {file && <CampaignMappingPreview uploadedFile={file} uploadedFileParsed={uploadedFileParsed} onCloseModal={onClose} />}
      </DialogContent>
      {ModalComponent}
      {navigationModal}
    </Dialog>
  );
};
