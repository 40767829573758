import AlSelect from '@/components/form/AlSelect';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { debounce, trim } from 'lodash-es';
import { ChangeEvent, FunctionComponent, SyntheticEvent, useCallback, useState } from 'react';
import { toastService } from '@/services/toast.service';
import { DashboardReadDTO } from '../../api/dashboard/dashboard.contracts';
import { dashboardService } from '../../api/dashboard/dashboard.service';
import { TeamProfile } from '../../types/TeamProfile';
import UserTeamSelect from '../forms/UserTeamSelect';
import TemplateButton from '../TemplateButton';
import { DASHBOARD_TEMPLATE_EMPTY, DASHBOARD_TEMPLATES } from '../../configuration/dashboard-templates';

interface CreateDashboardDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDashboardCreated: (createdDashboard: DashboardReadDTO) => void;
}

const validateDashboardName = debounce((name: string, setError: (error: boolean) => void) => {
  if (trim(name).length < 3) {
    setError(true);
  } else {
    setError(false);
  }
}, 1000);

const CreateDashboardDialog: FunctionComponent<CreateDashboardDialogProps> = ({ isOpen, onClose, onDashboardCreated }) => {
  const { user } = useUserContext();
  const { activeTeam } = useActiveTeamContext();
  const [isCreatingDashboard, setIsCreatingDashboard] = useState(false);

  const [dashboardName, setDashboardName] = useState('');
  const [currency, setCurrency] = useState(CurrencyCode.USD);
  const [selectedTeam, setSelectedTeam] = useState<string | ''>(activeTeam?.id.toString() ?? '');
  const [selectedProfiles, setSelectedProfiles] = useState<TeamProfile[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<{ id: number; name: string }[]>([]);
  const [nameHasValidationError, setNameHasValidationError] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(DASHBOARD_TEMPLATE_EMPTY.id);

  const profileOptions: TeamProfile[] | undefined = user?.teams
    .filter((team) => team.id === Number(selectedTeam))
    .flatMap<TeamProfile>((team) =>
      team.profiles.flatMap((p) => ({
        profileId: p.id,
        profileName: p.name,
        teamId: team.id,
        teamName: team.name,
        countryCode: p.countryCode,
        state: p.state,
      })),
    );

  let usersInAllTeams = user?.teams?.flatMap((team) => {
    return team.members
      .filter((member) => member.user.id !== user.id)
      .map((member) => {
        return { id: member.user.id, name: member.user.name };
      });
  });

  // Filter out duplicate users based on their ID
  usersInAllTeams = usersInAllTeams?.filter((user, index, self) => index === self.findIndex((u) => u.id === user.id));

  if (!usersInAllTeams) {
    usersInAllTeams = [];
  }

  const isCreateValid = trim(dashboardName).length >= 3 && selectedProfiles.length > 0;

  const currencyOptions = Object.values(CurrencyCode);

  const onDashboardNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.value;
      setDashboardName(name);
      validateDashboardName(name, setNameHasValidationError);
    },
    [setDashboardName, setNameHasValidationError],
  );

  function onCurrencyChange(event: SelectChangeEvent<CurrencyCode>) {
    setCurrency(event.target.value as CurrencyCode);
  }

  function onProfileSelectionChanged(event: SyntheticEvent<Element, Event>, value: TeamProfile[]) {
    setSelectedProfiles(value);
  }

  async function onCreateDashboardClicked() {
    setIsCreatingDashboard(true);
    const newDashboard = await dashboardService.create({
      name: dashboardName,
      currency_code: currency,
      profiles: selectedProfiles.map((p) => {
        return {
          profile_id: p.profileId,
          team_id: p.teamId,
        };
      }),
      collaborators: selectedUsers.map((user) => user.id),
      team_id: Number(selectedTeam),
      widgets: DASHBOARD_TEMPLATES.find((template) => template.id === selectedTemplateId)?.widgets ?? [],
    });
    if (newDashboard.isSuccess) {
      toastService.success('Dashboard created!');
      onDashboardCreated(newDashboard.payload);
    }
    setIsCreatingDashboard(false);
  }

  function onModalClose() {
    setDashboardName('');
    setCurrency(CurrencyCode.USD);
    setSelectedProfiles([]);
    setSelectedUsers([]);
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={onModalClose} maxWidth={'md'}>
      <DialogTitle>Create a new Dashboard</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the name of the Dashboard you would like to create, select the displayed currency format, select which profiles you would like
          to include in the dashboard, and add collaborators.
        </DialogContentText>
        <div className="grid grid-cols-2 mt-4 gap-x-8">
          <div className="font-medium">Details</div>
          <div className="font-medium">Template</div>

          <div className="flex flex-col mt-2">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              helperText={'At least 3 characters'}
              error={nameHasValidationError}
              onChange={onDashboardNameChange}
            />
            <div className="mb-5">
              <AlSelect
                label="Currency"
                value={currency}
                options={currencyOptions}
                renderOption={(option) => option}
                valueExtractor={(option) => option}
                disabled={false}
                onChange={onCurrencyChange}
              />
            </div>
            <div className="mb-5">
              <UserTeamSelect
                value={selectedTeam}
                onChange={(newValue) => {
                  setSelectedTeam(newValue);
                  setSelectedProfiles([]);
                }}
              />
            </div>
            {profileOptions && (
              <Autocomplete
                multiple
                id="tags-standard"
                options={profileOptions}
                value={selectedProfiles}
                groupBy={(option) => option.teamName}
                renderGroup={(params) => (
                  <div key={params.key}>
                    <div className="px-4 py-0.5 bg-gray-100 font-bold text-gray-600">{params.group}</div>
                    <div>{params.children}</div>
                  </div>
                )}
                disableCloseOnSelect
                getOptionDisabled={(option) => option.state !== 'ACTIVE'}
                getOptionLabel={(option) =>
                  option.profileName + ' (' + option.countryCode + ') ' + (option.state === 'ACTIVE' ? '' : ' - Inactive')
                }
                getOptionKey={(option) => `${option.profileId}_${option.teamId}`}
                filterSelectedOptions
                isOptionEqualToValue={(option, value) => option.profileId === value.profileId && option.teamId === value.teamId}
                onChange={onProfileSelectionChanged}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Profiles"
                    placeholder="Select Profiles"
                    slotProps={{
                      inputLabel: { shrink: true },
                    }}
                  />
                )}
              />
            )}
            <div className="mt-5">
              <Autocomplete
                id="collaborators-select"
                options={usersInAllTeams}
                disableCloseOnSelect
                multiple
                fullWidth
                value={selectedUsers}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  setSelectedUsers(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Collaborator(s)"
                    helperText={'Users that can also edit the dashboard (optional)'}
                    slotProps={{
                      inputLabel: { shrink: true },
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex items-start mt-4 ">
            <div className="grid auto-rows-fr items-start gap-y-2 ">
              {DASHBOARD_TEMPLATES.map((template) => (
                <TemplateButton
                  key={template.id}
                  title={template.title}
                  description={template.description}
                  isSelected={selectedTemplateId === template.id}
                  onClick={() => setSelectedTemplateId(template.id)}
                />
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton loading={isCreatingDashboard} variant="contained" onClick={onCreateDashboardClicked} disabled={!isCreateValid}>
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDashboardDialog;
