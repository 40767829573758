import { ButtonBase } from '@mui/material';
import { FunctionComponent, ReactElement, cloneElement } from 'react';

interface MainMenuTextButtonProps {
  isActive: boolean;
  label: string;
  icon: ReactElement;
  isExpanded: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const MainMenuTextButton: FunctionComponent<MainMenuTextButtonProps> = ({ onClick, icon, label, isExpanded, isActive }) => {
  return (
    <ButtonBase
      onClick={onClick}
      component="div"
      className={`flex  w-full  justify-start overflow-hidden   rounded-lg    px-2 py-1.5
      focus:text-gray-100 hover:text-gray-200   ${isActive ? ' text-gray-400  dark:text-gray-500' : ' text-gray-400  dark:text-gray-500 '}`}
    >
      {cloneElement(icon, { fontSize: 'inherit' })}
      <span className={`truncate pl-2.5  font-semibold transition-opacity ${isExpanded ? 'opacity-100' : 'opacity-0'}`}>{label}</span>
    </ButtonBase>
  );
};
