import AlSelect from '@/components/form/AlSelect';
import { useUserContext } from '@/modules/users';
import { SelectChangeEvent } from '@mui/material';
import { FunctionComponent } from 'react';

interface UserTeamSelectProps {
  value: string | '';
  onChange: (value: string | '') => void;
  helperText?: string;
}

const UserTeamSelect: FunctionComponent<UserTeamSelectProps> = ({ value, onChange, helperText }) => {
  const handleChange = (event: SelectChangeEvent<string | ''>) => {
    onChange(event.target.value as string | '');
  };

  const { user } = useUserContext();

  const options: { label: string; value: string }[] =
    user?.teams.map((team) => ({
      label: team.name,
      value: team.id.toString(),
    })) ?? [];

  return (
    <AlSelect
      label="Team"
      value={value}
      options={options}
      onChange={handleChange}
      renderOption={(v) => v.label}
      valueExtractor={(v) => v.value}
      helperText={helperText}
    />
  );
};

export default UserTeamSelect;
