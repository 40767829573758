import { Navigate, RouteObject } from 'react-router';
import { ConfigurationLayout } from '../layouts/ConfigurationLayout';
// import { USER_CONFIGURATION_ROUTES } from '@/modules/users/routes/user-configuration-routes';
import { SETTING_CONFIGURATION_ROUTES } from '@/modules/settings/routes/setting-configuration-routes';
import { DATA_MANAGEMENT_CONFIGURATION_ROUTES } from '@/modules/data-management/routes/data-management-routes';
export const CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'configuration',
    element: <ConfigurationLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="settings" replace />,
      },
      // ...USER_CONFIGURATION_ROUTES,
      ...SETTING_CONFIGURATION_ROUTES,
      ...DATA_MANAGEMENT_CONFIGURATION_ROUTES,
    ],
  },
];
