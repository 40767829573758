import MetricsContainer from '@/components/metrics/MetricsContainer';
import { createTargetingMetricsQueryKey, metricsService } from '@/components/metrics/api/metrics-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { FunctionComponent } from 'react';
import { useTargetingContext } from '../contexts/TargetingContext';

const TargetingMetrics: FunctionComponent = () => {
  const { filters, visibleMetrics, setVisibleMetrics, gridToggles } = useTargetingContext();

  const { activeProfile } = useActiveTeamContext();

  const {
    data: metricValues,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: createTargetingMetricsQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await metricsService.getTargetMetrics(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading metric values\n' + JSON.stringify(result));
      }
    },
    retry: 1,
    enabled: !isEmpty(filters),
  });

  return (
    <MetricsContainer
      metricValues={metricValues}
      isLoading={isLoading}
      isError={isError}
      error={error}
      visibleMetrics={visibleMetrics}
      setVisibleMetrics={setVisibleMetrics}
      showComparison={gridToggles.comparisonUnit != 'hidden'}
    />
  );
};

export default TargetingMetrics;
