import { MetricData } from '@/components/metrics/types/MetricData';
import { MetricField, stringToSellerMetricFieldString } from '@/components/metrics/types/MetricField';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { PerformanceMetricsModel, ProfileMetricsArguments } from '@/modules/profiles-stats/models/PerformanceMetricsModel';
import {
  PerformanceTargetEstimate,
  ProfileCommonMetricsDTO,
  ProfileSellerMetricsDTO,
  ProfilesMetricsWithTeamDTO,
} from '../../profiles/api/profile.contracts';
import { ProfileModel } from '../../profiles/types/ProfileModel';

export class ProfileWithMetricsModel extends ProfileModel {
  public teamId: number;
  public teamName: string;
  public metricsDTO: ProfileCommonMetricsDTO;
  public sellerMetricsDTO: ProfileSellerMetricsDTO;
  public comparisonMissing: boolean;
  public performanceTargetEstimates: PerformanceMetricsModel;
  public timeline: TimelineModel;
  public lastOptimizedAt: string | null;

  constructor(args: ProfileMetricsArguments) {
    super(args);
    this.teamId = args.teamId;
    this.teamName = args.teamName;
    this.metricsDTO = args.metrics;
    this.sellerMetricsDTO = args.sellerMetrics;
    this.comparisonMissing = args.comparisonMissing;

    this.performanceTargetEstimates = new PerformanceMetricsModel(args.performance, args.performanceLowData);

    const yAxisData: {
      key: MetricField;
      values: number[];
    }[] = [];

    for (const [key, values] of Object.entries(args.timeline.y_axis.metrics)) {
      yAxisData.push({ key: key as MetricField, values: values as number[] });
    }

    for (const [key, values] of Object.entries(args.timeline.y_axis.seller_metrics)) {
      yAxisData.push({ key: stringToSellerMetricFieldString(key) as MetricField, values: values as number[] });
    }

    this.timeline = new TimelineModel({
      xAxisData: args.timeline.x_axis,
      yAxisData: yAxisData,
    });

    this.lastOptimizedAt = args.lastOptimized;
  }

  public get impressions(): MetricData {
    return this.metricsDTO.impressions;
  }

  public get clicks(): MetricData {
    return this.metricsDTO.clicks;
  }

  public get orders(): MetricData {
    return this.metricsDTO.orders;
  }

  public get units(): MetricData {
    return this.metricsDTO.units;
  }

  public get ctr(): MetricData {
    return this.metricsDTO.ctr;
  }

  public get cvr(): MetricData {
    return this.metricsDTO.cvr;
  }

  public get cpc(): MetricData {
    return this.metricsDTO.cpc;
  }

  public get spend(): MetricData {
    return this.metricsDTO.spend;
  }

  public get sales(): MetricData {
    return this.metricsDTO.sales;
  }

  public get sso(): MetricData {
    return this.metricsDTO.sso;
  }

  public get sss(): MetricData {
    return this.metricsDTO.sss;
  }

  public get acos(): MetricData {
    return this.metricsDTO.acos;
  }

  public get roas(): MetricData {
    return this.metricsDTO.roas;
  }

  public get rpc(): MetricData {
    return this.metricsDTO.rpc;
  }

  public get cpa(): MetricData {
    return this.metricsDTO.cpa;
  }

  public get aov(): MetricData {
    return this.metricsDTO.aov;
  }

  public get cpm(): MetricData {
    return this.metricsDTO.cpm;
  }

  public get acots(): MetricData {
    return this.sellerMetricsDTO.acos;
  }

  public get asp(): MetricData {
    return this.sellerMetricsDTO.asp;
  }

  public get organicSales(): MetricData {
    return this.sellerMetricsDTO.org_sales;
  }

  public get organicTraffic(): MetricData {
    return this.sellerMetricsDTO.org_traffic;
  }

  public get totalViews(): MetricData {
    return this.sellerMetricsDTO.views;
  }

  public get seller_units(): MetricData {
    return this.sellerMetricsDTO.units;
  }

  public get totalSales(): MetricData {
    return this.sellerMetricsDTO.sales;
  }

  public get ups(): MetricData {
    return this.sellerMetricsDTO.unit_sess;
  }

  public get totalCvr(): MetricData {
    return this.sellerMetricsDTO.cvr;
  }

  public get totalClicks(): MetricData {
    return this.sellerMetricsDTO.clicks;
  }

  public get totalOrders(): MetricData {
    return this.sellerMetricsDTO.orders;
  }

  public get adSalesOfTotal(): MetricData {
    return this.sellerMetricsDTO.ad_sales_of_total;
  }
  public get totalCpa(): MetricData {
    return this.sellerMetricsDTO.cpa;
  }

  public get totalRoas(): MetricData {
    return this.sellerMetricsDTO.roas;
  }

  public get unitView(): MetricData {
    return this.sellerMetricsDTO.unit_view;
  }

  public get totalAov(): MetricData {
    return this.sellerMetricsDTO.aov;
  }

  public get unitRefunded(): MetricData {
    return this.sellerMetricsDTO.units_refunded;
  }

  public get unitRefundRate(): MetricData {
    return this.sellerMetricsDTO.units_refund_rate;
  }

  // Performance
  public get performanceAcos(): PerformanceTargetEstimate {
    return this.performanceTargetEstimates.acos;
  }

  public get performanceAcots(): PerformanceTargetEstimate {
    return this.performanceTargetEstimates.acots;
  }

  public get performanceAdSpend(): PerformanceTargetEstimate {
    return this.performanceTargetEstimates.adSpend;
  }

  public get performanceAdSales(): PerformanceTargetEstimate {
    return this.performanceTargetEstimates.adSales;
  }

  public get performanceTotalSales(): PerformanceTargetEstimate {
    return this.performanceTargetEstimates.totalSales;
  }

  public set performanceAcos(targetEstimates: PerformanceTargetEstimate) {
    this.performanceTargetEstimates.acos = targetEstimates;
  }

  public set performanceAcots(targetEstimates: PerformanceTargetEstimate) {
    this.performanceTargetEstimates.acots = targetEstimates;
  }

  public set performanceAdSpend(targetEstimates: PerformanceTargetEstimate) {
    this.performanceTargetEstimates.adSpend = targetEstimates;
  }

  public set performanceAdSales(targetEstimates: PerformanceTargetEstimate) {
    this.performanceTargetEstimates.adSales = targetEstimates;
  }

  public set performanceTotalSales(targetEstimates: PerformanceTargetEstimate) {
    this.performanceTargetEstimates.totalSales = targetEstimates;
  }

  public static fromUserProfilesWithMetricsResponse(dtos: ProfilesMetricsWithTeamDTO[]): ProfileWithMetricsModel[] {
    return dtos.map(
      (dto) =>
        new ProfileWithMetricsModel({
          ...ProfileModel.fromDTO(dto),
          teamId: dto.team_id,
          teamName: dto.team_name,
          metrics: dto.metrics,
          sellerMetrics: dto.seller_metrics,
          comparisonMissing: dto.comparison_missing,
          performanceLowData: dto.performance_low_data,
          performance: dto.performance,
          timeline: dto.timeline,
          lastOptimized: dto.last_optimized,
        }),
    );
  }
}
