import { PageLayoutTopBar, PageLayoutBody } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { useFirebaseAuth } from '@/modules/auth';
import {
  MAX_FIRST_NAME_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_FIRST_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MIN_SURNAME_LENGTH,
} from '@/modules/registration/pages/RegistrationStepCreateAccountPage';
import { useUserContext, userService } from '@/modules/users';
import { LoadingButton } from '@mui/lab';
import { TextField, Paper, Card, CardHeader, CardContent } from '@mui/material';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { FunctionComponent, useState } from 'react';
import { toastService } from '@/services/toast.service';

export const UserSettingsPage: FunctionComponent = () => {
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [isUpdatingUsername, setIsUpdatingUsername] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [isUpdatingPhoneNumber, setIsUpdatingPhoneNumber] = useState(false);

  const { user, setUser } = useUserContext();
  const auth = useFirebaseAuth();

  const handleNameUpdate = async () => {
    if (user) {
      setIsUpdatingUsername(true);
      const res = await userService.updateUserName(user.id, { name: firstName, surname });
      if (res.isSuccess) {
        toastService.success('Name Updated Successfully');
        user.name = res.payload.name;
        setUser(user);
        setFirstName('');
        setSurname('');
      } else {
        toastService.error('Error Updating name. Reason: ' + res.message);
      }
      setIsUpdatingUsername(false);
    }
  };

  const handlePhoneNumberUpdate = async () => {
    if (user) {
      setIsUpdatingPhoneNumber(true);
      const res = await userService.updateUserPhone(user.id, { phone: phoneNumber });
      if (res.isSuccess) {
        toastService.success('Phone Updated Successfully');
        user.phone = res.payload.phone;
        setUser(user);
        setPhoneNumber('');
      } else {
        toastService.error('Error Updating phone. Reason: ' + res.message);
      }
      setIsUpdatingPhoneNumber(false);
    }
  };

  const handlePasswordUpdate = async () => {
    if (user) {
      try {
        setIsUpdatingPassword(true);
        const firebaseUser = await auth.signIn(user.email, password);
        if (firebaseUser) {
          const res = await userService.updateUserPassword(user.id, { password: password, new_password: newPassword });
          if (res.isSuccess) {
            toastService.success('Password Updated Successfully');
            setPassword('');
            setNewPassword('');
          } else {
            toastService.error('Error Updating password. Reason: ' + res.message);
          }
        } else {
          toastService.error('Error. Please try again');
        }
      } catch (error) {
        console.log(error);
        toastService.error('Invalid Password');
      } finally {
        setIsUpdatingPassword(false);
      }

      setIsUpdatingPassword(false);
    }
  };

  return (
    <PageLayout>
      <PageLayoutTopBar header="My Settings"></PageLayoutTopBar>

      <PageLayoutBody>
        <Card title={user?.name + ' ' + user?.surname} className={'w-72 mt-6'}>
          <CardHeader
            title={user?.name + ' ' + user?.surname}
            titleTypographyProps={{ variant: 'body1' }} // Adjust the variant as needed
          />
          <CardContent>
            <div className="flex flex-col gap-2">
              <div> Email: {user?.email}</div>
              <div> Phone: {user?.phone}</div>
            </div>
          </CardContent>
        </Card>

        <section className="mt-6 flex flex-col md:flex-row gap-4">
          <Paper elevation={3} className="p-4 flex-1 max-w-84 flex flex-col">
            <div className="flex-grow">
              <div className="text-center font-semibold text-slate-600">Update Name</div>
              <TextField
                fullWidth
                autoComplete="off"
                id="firstName"
                label="First Name"
                placeholder="Enter your new first name"
                value={firstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)}
                error={firstName.length > MAX_FIRST_NAME_LENGTH}
                helperText={
                  firstName.length < MIN_FIRST_NAME_LENGTH
                    ? `Enter at least ${MIN_FIRST_NAME_LENGTH} characters`
                    : firstName.length > MAX_FIRST_NAME_LENGTH && `First name cannot be longer than ${MAX_FIRST_NAME_LENGTH} characters`
                }
              />

              <TextField
                fullWidth
                autoComplete="off"
                id="lastName"
                label="Last Name"
                placeholder="Enter your new last name"
                value={surname}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSurname(event.target.value)}
                error={surname.length > MAX_SURNAME_LENGTH}
                helperText={
                  surname.length < MIN_SURNAME_LENGTH
                    ? `Enter at least ${MIN_SURNAME_LENGTH} characters`
                    : surname.length > MAX_SURNAME_LENGTH && `Last name cannot be longer than ${MAX_SURNAME_LENGTH} characters`
                }
              />
            </div>
            <LoadingButton
              variant="contained"
              onClick={handleNameUpdate}
              disabled={
                firstName.length < MIN_FIRST_NAME_LENGTH ||
                firstName.length > MAX_FIRST_NAME_LENGTH ||
                surname.length < MIN_SURNAME_LENGTH ||
                surname.length > MAX_SURNAME_LENGTH
              }
              loading={isUpdatingUsername}
            >
              Update Name
            </LoadingButton>
          </Paper>
          <Paper elevation={3} className="p-4 flex-1 max-w-84 flex flex-col">
            <div className="flex-grow">
              <div className="text-center font-semibold text-slate-600">Update Password</div>
              <TextField
                fullWidth
                autoComplete="off"
                id="password"
                label="Current Password"
                type="password"
                placeholder="Enter your current Password"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
              />
              <TextField
                fullWidth
                autoComplete="off"
                id="new-password"
                label="New Password"
                type="password"
                placeholder="Enter your New Password"
                value={newPassword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
                helperText={newPassword.length < MIN_PASSWORD_LENGTH && `Enter at least ${MIN_PASSWORD_LENGTH} characters`}
              />
            </div>
            <LoadingButton
              variant="contained"
              onClick={handlePasswordUpdate}
              disabled={newPassword.length < MIN_PASSWORD_LENGTH}
              loading={isUpdatingPassword}
            >
              Update Password
            </LoadingButton>
          </Paper>

          <Paper elevation={3} className="p-4 flex-1 max-w-84 flex flex-col">
            <div className="flex-grow">
              <div className="text-center font-semibold text-slate-600">Update Phone</div>

              <MuiTelInput
                id="phone"
                label="Phone Number"
                forceCallingCode
                focusOnSelectCountry
                defaultCountry="US"
                value={phoneNumber}
                onChange={(value: string) => {
                  setPhoneNumber(value);
                }}
                helperText={phoneNumber.length > 1 && !matchIsValidTel(phoneNumber) ? 'Invalid phone number' : ''}
              />
            </div>
            <LoadingButton
              variant="contained"
              onClick={handlePhoneNumberUpdate}
              disabled={!matchIsValidTel(phoneNumber)}
              loading={isUpdatingPhoneNumber}
            >
              Update Phone
            </LoadingButton>
          </Paper>
        </section>
      </PageLayoutBody>
    </PageLayout>
  );
};
