import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { TeamModel } from '@/modules/teams/types/TeamModel';
import MemberList from '@/modules/teams/components/MemberList';
import AddIcon from '@mui/icons-material/Add';
import { AddTeamMemberDialog } from '@/modules/teams/components/AddTeamMemberDialog';
export const useEditTeam = (team: TeamModel | undefined) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const [isAddNewTeamMemberDialogOpen, setIsAddNewTeamMemberDialogOpen] = useState(false);
  const onCreateNewTeamClicked = () => {
    setIsAddNewTeamMemberDialogOpen(true);
  };

  const ModalComponent = (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth={'lg'}>
        <DialogTitle>Members: {team?.name}</DialogTitle>
        <DialogContent>
          <Button className="mb-2" onClick={onCreateNewTeamClicked} variant="contained" startIcon={<AddIcon />}>
            Add New Member
          </Button>

          {team?.members && <MemberList members={team.members} team={team} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {team && (
        <AddTeamMemberDialog
          setIsOpen={setIsAddNewTeamMemberDialogOpen}
          isOpen={isAddNewTeamMemberDialogOpen}
          team={team}
          onSuccess={() => onClose()}
          onError={() => {}}
        />
      )}
    </>
  );

  return { ModalComponent, handleOpenModal };
};
