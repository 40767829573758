import { Theme } from '@emotion/react';
import { ThemeOptions, createTheme } from '@mui/material';
import { amber, blue, gray, green, lime, orange, pink, red, sky, slate, violet, white, yellow } from 'tailwindcss/colors';

declare module '@mui/material/styles' {
  interface Palette {
    blue: Palette['primary'];
    orange: Palette['primary'];
    emerald: Palette['primary'];
    slate: Palette['primary'];
    violet: Palette['primary'];
    pink: Palette['primary'];
    lime: Palette['primary'];
    amber: Palette['primary'];
    sky: Palette['primary'];
  }

  interface PaletteOptions {
    blue: PaletteOptions['primary'];
    orange: PaletteOptions['primary'];
    emerald: PaletteOptions['primary'];
    slate: PaletteOptions['primary'];
    violet: PaletteOptions['primary'];
    pink: PaletteOptions['primary'];
    lime: PaletteOptions['primary'];
    amber: PaletteOptions['primary'];
    sky: PaletteOptions['primary'];
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    blue: true;
    orange: true;
    emerald: true;
    slate: true;
    violet: true;
    pink: true;
    lime: true;
    amber: true;
    sky: true;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsColorOverrides {
    blue: true;
    orange: true;
    emerald: true;
    slate: true;
    violet: true;
    pink: true;
    lime: true;
    amber: true;
    sky: true;
  }
}
const baseThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: blue[600],
      contrastText: '#fff',
    },
    secondary: {
      main: blue[950],
    },
    background: {
      default: slate[50],
    },

    success: {
      main: green[600],
    },
    warning: {
      main: orange[600],
    },
    info: {
      main: sky[600],
    },
    error: {
      main: red[600],
    },
    blue: {
      main: blue[600],
      light: blue[100],
      dark: blue[800],
      contrastText: blue[950],
    },
    orange: {
      main: orange[600],
      light: orange[100],
      dark: orange[800],
      contrastText: orange[950],
    },
    emerald: {
      main: green[600],
      light: green[100],
      dark: green[800],
      contrastText: green[950],
    },
    slate: {
      main: slate[600],
      light: slate[100],
      dark: slate[800],
      contrastText: slate[950],
    },
    violet: {
      main: violet[600],
      light: violet[100],
      dark: violet[800],
      contrastText: violet[950],
    },
    pink: {
      main: pink[600],
      light: pink[100],
      dark: pink[800],
      contrastText: pink[950],
    },
    lime: {
      main: lime[600],
      light: lime[100],
      dark: lime[800],
      contrastText: lime[950],
    },
    amber: {
      main: amber[600],
      light: amber[100],
      dark: amber[800],
      contrastText: amber[950],
    },
    sky: {
      main: sky[600],
      light: sky[100],
      dark: sky[800],
      contrastText: sky[950],
    },
  },

  components: {
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          // Default chip
          // backgroundColor: gray[300],
          // color: gray[900],
          '&.MuiChip-outlined': {
            // Filled chip variations
            // backgroundColor: transparent,
            // color: white,
            // '&:hover, &:focus': {
            //   backgroundColor: blue[600],
            // },
            '&.MuiChip-colorPrimary': {
              backgroundColor: blue[50],
              borderColor: blue[800],
              color: blue[800],
            },
            '&.MuiChip-colorSecondary': {
              backgroundColor: slate[50],
              borderColor: slate[800],
              color: slate[800],
            },
            '&.MuiChip-colorError': {
              backgroundColor: red[50],
              borderColor: red[800],
              color: red[800],
            },
            '&.MuiChip-colorInfo': {
              backgroundColor: sky[50],
              borderColor: sky[800],
              color: sky[800],
            },
            '&.MuiChip-colorSuccess': {
              backgroundColor: green[50],
              borderColor: green[800],
              color: green[800],
            },
            '&.MuiChip-colorWarning': {
              backgroundColor: yellow[50],
              borderColor: yellow[800],
              color: yellow[800],
            },
            '&.MuiChip-colorAmber': {
              backgroundColor: yellow[50],
              borderColor: yellow[800],
              color: yellow[800],
            },
            '&.MuiChip-colorBlue': {
              backgroundColor: blue[50],
              borderColor: blue[800],
              color: blue[800],
            },
            '&.MuiChip-colorEmerald': {
              backgroundColor: green[50],
              borderColor: green[800],
              color: green[800],
            },
            '&.MuiChip-colorLime': {
              backgroundColor: lime[50],
              borderColor: lime[800],
              color: lime[800],
            },
            '&.MuiChip-colorOrange': {
              backgroundColor: orange[50],
              borderColor: orange[800],
              color: orange[800],
            },
            '&.MuiChip-colorPink': {
              backgroundColor: pink[50],
              borderColor: pink[800],
              color: pink[800],
            },
            '&.MuiChip-colorSlate': {
              backgroundColor: slate[50],
              borderColor: slate[800],
              color: slate[800],
            },
            '&.MuiChip-colorViolet': {
              backgroundColor: violet[50],
              borderColor: violet[800],
              color: violet[800],
            },
            '&.MuiChip-colorSky': {
              backgroundColor: sky[50],
              borderColor: sky[800],
              color: sky[800],
            },
          },
          // '&$outlined': {
          //   // Outlined chip variations
          //   borderColor: red[500],
          //   color: red[500],
          //   '&:hover, &:focus': {
          //     borderColor: red[600],
          //     color: red[600],
          //   },
          //   '&.MuiChip-colorPrimary': {
          //     borderColor: blue[500],
          //     color: blue[500],
          //     '&:hover, &:focus': {
          //       borderColor: blue[600],
          //       color: blue[600],
          //     },
          //   },
          //   '&.MuiChip-colorSecondary': {
          //     borderColor: green[500],
          //     color: green[500],
          //     '&:hover, &:focus': {
          //       borderColor: green[600],
          //       color: green[600],
          //     },
          //   },
          //   '&.MuiChip-colorError': {
          //     borderColor: red[500],
          //     color: red[500],
          //     '&:hover, &:focus': {
          //       borderColor: red[600],
          //       color: red[600],
          //     },
          //   },
          //   '&.MuiChip-colorInfo': {
          //     borderColor: sky[500],
          //     color: sky[500],
          //     '&:hover, &:focus': {
          //       borderColor: sky[600],
          //       color: sky[600],
          //     },
          //   },
          //   '&.MuiChip-colorSuccess': {
          //     borderColor: green[500],
          //     color: green[500],
          //     '&:hover, &:focus': {
          //       borderColor: green[600],
          //       color: green[600],
          //     },
          //   },
          //   '&.MuiChip-colorWarning': {
          //     borderColor: yellow[500],
          //     color: yellow[500],
          //     '&:hover, &:focus': {
          //       borderColor: yellow[600],
          //       color: yellow[600],
          //     },
          //   },
          // },
        },
      },
    },
    MuiTypography: {
      variants: [
        // In the future, maybe make our own typography variants
        // That make sense for our app
        // {
        //   props: { variant: 'h1' },
        //   style: {
        //     fontSize: '3rem',
        //   },
        // },
        // {
        //   props: { variant: 'h2' },
        //   style: {
        //     fontSize: '2.5rem',
        //   },
        // },
        // {
        //   props: { variant: 'h3' },
        //   style: {
        //     fontSize: '2rem',
        //   },
        // },
        // {
        //   props: { variant: 'h4' },
        //   style: {
        //     fontSize: '1.75rem',
        //   },
        // },
        // {
        //   props: { variant: 'h5' },
        //   style: {
        //     fontSize: '1.5rem',
        //   },
        // },
        // {
        //   props: { variant: 'h6' },
        //   style: {
        //     fontSize: '1.25rem',
        //   },
        // },
        // {
        //   props: { variant: 'subtitle1' },
        //   style: {
        //     fontSize: '1rem',
        //   },
        // },
        // {
        //   props: { variant: 'subtitle2' },
        //   style: {
        //     fontSize: '0.875rem',
        //   },
        // },
        // {
        //   props: { variant: 'body1' },
        //   style: {
        //     fontSize: '1rem',
        //   },
        // },
        // {
        //   props: { variant: 'body2' },
        //   style: {
        //     fontSize: '0.875rem',
        //   },
        // },
        // {
        //   props: { variant: 'button' },
        //   style: {
        //     fontSize: '0.875rem',
        //   },
        // },
        // {
        //   props: { variant: 'caption' },
        //   style: {
        //     fontSize: '0.75rem',
        //   },
        // },
        // {
        //   props: { variant: 'overline' },
        //   style: {
        //     fontSize: '0.75rem',
        //   },
        // },
        // Handle all color variants and set color
        {
          props: { color: 'primary' },
          style: {
            color: blue[700],
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            color: blue[950],
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: red[700],
          },
        },
        {
          props: { color: 'info' },
          style: {
            color: sky[600],
          },
        },
        {
          props: { color: 'success' },
          style: {
            color: green[700],
          },
        },
        {
          props: { color: 'warning' },
          style: {
            color: orange[600],
          },
        },
        {
          props: { color: 'amber' },
          style: {
            color: yellow[600],
          },
        },
        {
          props: { color: 'blue' },
          style: {
            color: blue[600],
          },
        },
        {
          props: { color: 'emerald' },
          style: {
            color: green[700],
          },
        },
        {
          props: { color: 'lime' },
          style: {
            color: lime[600],
          },
        },
        {
          props: { color: 'orange' },
          style: {
            color: orange[600],
          },
        },
        {
          props: { color: 'pink' },
          style: {
            color: pink[600],
          },
        },
        {
          props: { color: 'slate' },
          style: {
            color: slate[600],
          },
        },
        {
          props: { color: 'violet' },
          style: {
            color: violet[600],
          },
        },
        {
          props: { color: 'sky' },
          style: {
            color: sky[600],
          },
        },
      ],
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          borderColor: slate[300],
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        disableElevation: true,
        variant: 'contained',
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            '& .MuiButton-label': {
              fontSize: '14px',
            },
            '&.MuiButton-root': {
              height: 35,
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            '& .MuiButton-label': {
              fontSize: 16,
            },
            '&.MuiButton-root': {
              height: 56,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          lineHeight: '1.2',
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiToggleButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          padding: 9,
          // Custom border color
          borderColor: slate[300],
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardInfo': {
            backgroundColor: sky[50],
          },
        },
        message: {
          fontWeight: 400,
        },
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: white,
        },
      },
    },
    MuiInputBase: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            '& .MuiInputBase-input.MuiInputBase-inputSizeSmall': {
              fontSize: '14px',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: slate[300], // Default border color
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: slate[300], // Focused border color
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: slate[300], // Error border color
          },

          '&.Mui-disabled:hover, &.Mui-disabled:hover *': {
            cursor: 'not-allowed',
          },
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            backgroundColor: slate[200],
          },
          '&.Mui-focused': {
            backgroundColor: slate[50], // Change this to your desired color
          },
          backgroundColor: white,
          color: slate[800],
        },
      },
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: blue[700], // Change this to your desired color
          },
          color: slate[700],
        },
      },
      defaultProps: {
        margin: 'dense',
        shrink: true,
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
        variant: 'outlined',
        slotProps: {
          inputLabel: {
            shrink: true,
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            '& .MuiInputBase-input.MuiInputBase-inputSizeSmall': {
              paddingTop: '7.445px ',
              paddingBottom: '7.445px',
            },
          },
        },
      ],
    },

    MuiSelect: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
        notched: true,
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            '& .MuiSelect-select.MuiInputBase-input.MuiInputBase-inputSizeSmall': {
              paddingTop: '5.945px',
              paddingBottom: '5.945px',
            },
            '& .MuiInputBase-input': {
              paddingTop: '8px',
              paddingBottom: '8px',
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            '& .MuiInputBase-input': {
              paddingTop: '1.445px !important',
              paddingBottom: '1.445px !important',
            },
            '& .MuiInputBase-root': {
              lineHeight: '22px',
            },
            '& .MuiChip-root': {
              height: '19px',
              marginBottom: '0px !important',
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            '& .MuiAutocomplete-input': {
              paddingTop: '12px !important',
              paddingBottom: '12px !important',
            },
            '& .MuiChip-root': {
              height: '32px',
              marginBottom: '0px !important',
            },
          },
        },
      ],
      styleOverrides: {
        listbox: {
          padding: '0px !important',
          fontSize: '14px !important',
          '& .MuiAutocomplete-option': {
            fontSize: '14px !important',
            padding: '6px 16px !important',
          },
          '& .MuiCheckbox-root': {
            padding: '0px !important',
          },
        },
        groupUl: {
          padding: '0px !important',
          fontSize: '14px !important',
          '& .MuiAutocomplete-option': {
            fontSize: '14px !important',
            padding: '6px 16px !important',
          },
          '& .MuiCheckbox-root': {
            padding: '0px !important',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: gray[600],
          opacity: 1,
          '& a': {
            color: '#ebebeb', // Light gray color for links
            fontWeight: 600, // Semi-bold
            textDecoration: 'underline', // Underlined
            '&:hover': {
              color: '#ffffff', // White color on hover
            },
          },
        },
      },
    },
  },

  shape: {
    borderRadius: 6,
  },

  typography: {
    fontFamily: `-apple-system, "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
    button: {
      textTransform: 'none',
      fontFamily: `-apple-system, "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
      fontWeight: 700,
    },
    body2: {
      fontWeight: 300,
    },
  },
  spacing: 1,
};

export const BASE_THEME: Theme = createTheme(baseThemeOptions);
