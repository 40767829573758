import { InfinitySpinner } from '@/modules/application';
import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface LoadingOverlayProps {
  isVisible: boolean;
  message: string;
}

const LoadingOverlay: FunctionComponent<LoadingOverlayProps> = ({ isVisible, message }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-[70] flex flex-col items-center justify-center bg-slate-100 bg-opacity-90 dark:bg-slate-900 dark:bg-opacity-90">
      <InfinitySpinner />
      <Typography variant="h6">{message}</Typography>
    </div>
  );
};

export default LoadingOverlay;
