import { FunctionComponent } from 'react';
import { IconButton } from '@mui/material';
import { CloseFullscreen, OpenInFull } from '@mui/icons-material';

interface ExpandToggleButtonProps {
  isExpanded: boolean;
  onExpandToggled: () => void;
}

const ExpandToggleButton: FunctionComponent<ExpandToggleButtonProps> = ({ isExpanded, onExpandToggled }) => {
  return (
    <IconButton
      size="small"
      onClick={onExpandToggled}
      className={`${isExpanded ? 'text-primary-500 bg-primary-500 bg-opacity-10' : 'text-gray-500'}`}
      aria-label="expand"
    >
      {isExpanded ? <CloseFullscreen fontSize="inherit" /> : <OpenInFull fontSize="inherit" />}
    </IconButton>
  );
};

export default ExpandToggleButton;
