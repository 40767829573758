import { VideoUrl } from '@/config/urls';
import useFormatting from '@/hooks/useFormatting';
import { DataGroupsPopover } from '@/modules/data-groups/components/DataGroupsPopover';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Routes } from '@/router/router-paths';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Button, Checkbox, Divider, Tooltip } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';
import { toastService } from '@/services/toast.service';
import OppositeModeOverlayBar from '../../../components/OppositeModeOverlayBar';
import { SelectedProduct } from '../models/ProductModel';

interface ProductsActionsBarProps {
  selectedProducts: SelectedProduct[];
  setSelectedProducts: (selectedProducts: SelectedProduct[]) => void;
  totalProductsCount: number;
  onDiscardClicked: () => void;
  productsTableGridApiRef: React.MutableRefObject<GridApi | null>;
}

const ProductsSelectionActionsBar: FunctionComponent<ProductsActionsBarProps> = ({
  selectedProducts,
  setSelectedProducts,
  totalProductsCount,
  onDiscardClicked,
  productsTableGridApiRef,
}) => {
  const { activeTeam } = useActiveTeamContext();
  const { formatWithThousandsSeparator } = useFormatting();

  // Paywall
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  // Tags
  const dataGroupsButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isDataGroupsPopoverOpen, setIsDataGroupsPopoverOpen] = useState(false);
  const onDataGroupsClicked = () => {
    if (isNil(activeTeam)) {
      toastService.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canAssignDataGroups) {
      setIsPaywallModalOpen(true);
      return;
    }

    setIsDataGroupsPopoverOpen(true);
  };

  // HANDLE SELECTION
  function onDeselectAll() {
    setSelectedProducts([]);
    if (productsTableGridApiRef.current) {
      productsTableGridApiRef.current.deselectAll();
    }
  }

  function onSelectAll() {
    if (productsTableGridApiRef.current) {
      productsTableGridApiRef.current.selectAll();
    }
  }

  return (
    <>
      <OppositeModeOverlayBar visible={selectedProducts.length > 0}>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <div onClick={onDiscardClicked} className="flex flex-row text-sm font-bold items-center hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selectedProducts.length)} of {formatWithThousandsSeparator(totalProductsCount)}
            </div>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row gap-4 whitespace-nowrap items-center">
            <Button
              ref={dataGroupsButtonRef}
              size="small"
              className="whitespace-nowrap "
              onClick={onDataGroupsClicked}
              variant={isDataGroupsPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AccountTreeIcon />}
            >
              Tags
            </Button>

            <Divider className="my-2" orientation="vertical" flexItem />
            <Button
              className="whitespace-nowrap"
              onClick={selectedProducts.length == totalProductsCount ? onDeselectAll : onSelectAll}
              variant="text"
            >
              {selectedProducts.length == totalProductsCount ? 'Deselect All' : 'Select All'}
            </Button>
          </div>
        </div>
      </OppositeModeOverlayBar>

      <DataGroupsPopover
        buttonRef={dataGroupsButtonRef}
        isOpen={isDataGroupsPopoverOpen}
        setIsOpen={setIsDataGroupsPopoverOpen}
        selectedItems={selectedProducts}
        dataGroupType={DataGroupType.PRODUCT}
      />

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.OPTIMIZER}
        headerText="Upgrade to Pro to Access Tags"
        videoUrl={VideoUrl.DATA_GROUPS}
      ></PaywallModal>
    </>
  );
};

export default ProductsSelectionActionsBar;
