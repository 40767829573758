import { useCallback } from 'react';
import * as XLSX from 'xlsx';

export const useExportToExcel = () => {
  const exportToExcel = useCallback((workbook: XLSX.WorkBook) => {
    // Write the workbook to a binary string
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

    // Convert binary string to ArrayBuffer
    const s2ab = (s: string) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };

    // Create a Blob from the ArrayBuffer and trigger the download
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'campaign_mappings.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, []);

  return { exportToExcel };
};
