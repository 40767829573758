import { GleapWorkflowType } from '@/lib/gleap';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import { Button } from '@mui/material';
import Gleap from 'gleap';
import { FunctionComponent } from 'react';

const BugButton: FunctionComponent = () => {
  const startGleapFeedbackFlow = () => {
    Gleap.startFeedbackFlow(GleapWorkflowType.BugReport, true);
  };

  return (
    <Button onClick={startGleapFeedbackFlow} variant="outlined" className="whitespace-nowrap px-4" startIcon={<BugReportOutlinedIcon />}>
      Report a bug
    </Button>
  );
};

export default BugButton;
