import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ExpandedGridContext } from '@/components/grid/types';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { GridApi } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { useEffect } from 'react';
import { campaignService, createCampaignGroupsQueryKey } from '../api/campaign/campaign-service';
import { CampaignGroupModel } from '../api/campaign/models/CampaignGroupModel';
import { CampaignModel } from '../api/campaign/models/CampaignModel';

interface UseCampaignGroupIdColumnDataProps {
  gridApiRef: React.MutableRefObject<GridApi<CampaignModel> | undefined>;
  gridContextRef: React.MutableRefObject<ExpandedGridContext | undefined>;
}

// Campaign metrics specific hook that extends useMetricColumnAggregates hook functionality
const useCampaignGroupIdColumnData = ({ gridApiRef, gridContextRef }: UseCampaignGroupIdColumnDataProps) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { data: campaignGroupIdToCampaignGroupMap } = useQuery({
    queryKey: createCampaignGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading groups');
      }
    },
    select: (campaignGroups): Record<number, CampaignGroupModel> => {
      const map: Record<number, CampaignGroupModel> = {};
      campaignGroups.forEach((group) => {
        map[group.id] = group;
      });
      return map;
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current) return;
    gridContextRef.current.campaignGroupIdToCampaignGroupMap = campaignGroupIdToCampaignGroupMap;
    gridApiRef.current.refreshCells({ columns: [ColumnId.GROUP_NAME], force: true });
  }, [campaignGroupIdToCampaignGroupMap]);

  const onGridReadyForCampaignGroupIdColumnData = () => {
    if (!gridApiRef.current || !gridContextRef.current) return;
    Object.assign(gridContextRef.current, {
      campaignGroupIdToCampaignGroupMap: campaignGroupIdToCampaignGroupMap,
    });
  };
  return { campaignGroupIdToCampaignGroupMap, onGridReadyForCampaignGroupIdColumnData };
};

export default useCampaignGroupIdColumnData;
