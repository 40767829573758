import { ApiResponse } from '@/lib/api/api-response';
import { JobActionModel as JobActionModel } from '../models/JobActionModel';
import { JobActionDTO as JobActionDTO } from './logs-contracts';
import { apiProfileClient } from '@/lib/api/base-client';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { MetricsRequest } from '@/components/metrics/api/metrics-contracts';

class LogsService {
  public jobsBasePath = 'jobs';
  public logsBasePath = 'logs';
  private JOBS_QUERY_KEY_BASE = 'jobs';
  private LOGS_QUERY_KEY_BASE = 'logs';

  createGetJobActionsQueryKey(activeProfileId: string | undefined) {
    return [this.JOBS_QUERY_KEY_BASE, activeProfileId];
  }

  async getJobActions(filters: AlFilterModel[]): Promise<ApiResponse<JobActionModel[]>> {
    const requestData: MetricsRequest = {
      filters: filters.map((filter) => filter.toDTO()),
    };

    const applicationResponse = await apiProfileClient.post<JobActionDTO[]>(`${this.jobsBasePath}`, requestData);
    return applicationResponse.processPayload(JobActionModel.fromResponseObject);
  }

  createGetLogsQueryKey(activeProfileId: string | undefined, actionId: string | undefined) {
    return [this.LOGS_QUERY_KEY_BASE, activeProfileId, actionId];
  }

  async getLogs(actionId: string): Promise<ApiResponse<object[]>> {
    const applicationResponse = await apiProfileClient.get<object[]>(`${this.logsBasePath}/${actionId}`);
    return applicationResponse;
  }
}

export const logsService = new LogsService();
