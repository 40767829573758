import { useLayoutContext } from '@/contexts/LayoutContext';
import LinearProgress from '@mui/material/LinearProgress';
import { FunctionComponent } from 'react';

const TopLoadingBar: FunctionComponent = () => {
  const { isGlobalLoading } = useLayoutContext();

  return isGlobalLoading ? <LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0, height: 2, zIndex: 2300 }} /> : null;
};

export default TopLoadingBar;
