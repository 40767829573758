import { AdLabsColorVariant } from '@/config/theme/color.type';

export class AlMultiSelectOptionModel {
  id: number | string;
  name: string;
  categoryName: string; // Used when options are grouped into categories
  chipLabel?: string;
  chipColor?: AdLabsColorVariant;

  constructor(name: string, id: number | string, groupName?: string, chipLabel?: string, chipColor?: AdLabsColorVariant) {
    this.id = id;
    this.name = name;
    this.categoryName = groupName ?? '';
    this.chipLabel = chipLabel;
    this.chipColor = chipColor;
  }
}

export function sortOptionsAlphabetically(options: AlMultiSelectOptionModel[], emptyName: string): AlMultiSelectOptionModel[] {
  return options
    .sort((a, b) => {
      // Move empty to the end
      if (a.name === '') return 1;
      if (b.name === '') return -1;
      // Otherwise, sort alphabetically
      return a.name.localeCompare(b.name);
    })
    .map((o) => {
      if (o.name === '') {
        o.name = emptyName;
      }
      return o;
    });
}
