import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';
import { VideoUrl } from '@/config/urls';
import { PlanType } from '@/modules/plans/types/SubscriptionPlan';
import PricingCard from '@/modules/settings/configuration/PricingCard';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { toastService } from '@/services/toast.service';
import { planService } from '../api/plan.service';
import { PRO_PLAN } from '../configuration/plans';

interface PaywallModalProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  headerText?: string;
  returnURLPath: Routes;
  onBeforeUpgradePlan?: () => Promise<void>;
  videoUrl?: string;
}

export const PaywallModal: FunctionComponent<PaywallModalProps> = ({
  isOpen,
  onClose,
  children,
  headerText = 'You discovered a Pro feature!',
  returnURLPath: returnURLPath,
  onBeforeUpgradePlan,
  videoUrl = VideoUrl.PAYWALL_DEFAULT,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { refetchUser } = useUserContext();
  const { activeTeam } = useActiveTeamContext();

  const { ModalComponent, handleOpenModal } = useConfirmationModal({
    questionText: (
      <p>
        Upgrading to AdLabs Pro gives access to Search Term Reports, Negative Targeting, and also includes all future releases (keyword
        harvesting, dayparting, organic sales, etc.).
        <br />
        <br />
        Sign up today to get <b>50% off for life</b>!
        <br />
        <br />
        You cannot downgrade to AdLabs Legacy after upgrading.
      </p>
    ),

    onConfirm: onUpgradeFromLegacyPlanClicked,
    questionTitle: 'Upgrade to the New Pro Plan',
    confirmButtonText: 'Upgrade',
    cancelButtonText: 'Cancel',
  });

  async function onUpgradeFromLegacyPlanClicked() {
    try {
      setIsLoading(true);

      const upgradeFromLegacyResponse = await planService.upgradeFromLegacyPlan();
      if (upgradeFromLegacyResponse.isSuccess) {
        await refetchUser();
        toastService.success('You have successfully upgraded to the new plan');
        onClose();
      } else {
        toastService.error(`Something went wrong. Please try again later. ${upgradeFromLegacyResponse.message}`);
      }
    } catch (error) {
      console.log(error);
      toastService.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  async function onUpgradeClicked(planToUpgradeTo: PlanType) {
    try {
      if (activeTeam?.isOnLegacyProfilesBasedProPlan) {
        handleOpenModal();
        return;
      }

      if (onBeforeUpgradePlan) {
        await onBeforeUpgradePlan(); // this callback is used to add selected profiles before calling getCheckoutLinkForPlan
      }

      setIsLoading(true);

      const upgradeResponse = await planService.getCheckoutLinkForPlan(planToUpgradeTo, returnURLPath);
      if (upgradeResponse.isSuccess) {
        // Navigate to checkout page
        window.location.href = upgradeResponse.payload.session_url;
      } else {
        toastService.error(`Something went wrong. Please try again later. ${upgradeResponse.message}`);
      }
    } catch (error) {
      console.log(error);
      toastService.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  async function onDowngradeClicked() {
    try {
      setIsLoading(true);

      const downgradeResponse = await planService.getCancellationLinkForPlan(returnURLPath);
      if (downgradeResponse.isSuccess) {
        // Navigate to checkout page
        window.location.href = downgradeResponse.payload.session_url;
      } else {
        toastService.error(`Something went wrong. Please try again later. ${downgradeResponse.message}`);
      }
    } catch (error) {
      console.log(error);
      toastService.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  const onResolveButtonClicked = () => {
    redirectToBillingPortal();
  };

  async function redirectToBillingPortal() {
    try {
      setIsLoading(true);

      const billingPortalLinkResponse = await planService.getBillingLinkForTeam(Routes.BILLING);
      if (billingPortalLinkResponse.isSuccess) {
        // Navigate to billing portal
        window.location.href = billingPortalLinkResponse.payload.session_url;
      } else {
        toastService.error(`Something went wrong. Please try again later. ${billingPortalLinkResponse.message}`);
      }
    } catch (error) {
      console.log(error);
      toastService.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      sx={{ '& .MuiDialog-paper': { backgroundImage: 'linear-gradient(25deg,#172554 84%, #0EA5E9 16%)', color: '#fff' } }}
    >
      <DialogContent>
        <div className="flex flex-row gap-x-4 p-0">
          <div className="flex w-2/3 flex-col mr-4">
            <div className="flex flex-col gap-y-4">
              <div>
                <Typography className="text-blue-300" fontSize={20} fontWeight={500}>
                  {headerText}
                </Typography>
              </div>
              <Typography className="text-white mb-4" variant="h5" color={'#93C5FD'} fontWeight={500}>
                {children}
              </Typography>
            </div>
            <div className="flex flex-row gap-x-4">
              <WorkspacePremiumIcon className=" rounded-md bg-blue-600 p-2" style={{ fontSize: 50 }} />

              <div className="ml-2 flex flex-col">
                <Typography>
                  <span className="text-orange-300 font-bold text-lg">Our Guarantee: </span>
                  If AdLabs isn’t the best bidding tool you’ve ever used (and if it doesn’t <b>
                    pay for itself
                  </b> through improved efficiency), we’ll refund you - no questions asked
                </Typography>
              </div>
            </div>

            <div className="mt-8" style={{ position: 'relative', aspectRatio: '16/9' }}>
              <iframe
                src={videoUrl}
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none', maxHeight: '450px' }}
                title="Upgrade to Pro"
              ></iframe>
            </div>
          </div>

          <div className="flex w-1/3 flex-col mx-2">
            {activeTeam?.isPastDue ? (
              <div className="flex flex-col gap-y-4 p-4 bg-white rounded-md">
                <div className="text-lg text-black font-bold">Your account is past due</div>
                <div className="text-gray-700">
                  Please update your payment information to resolve the payment issue and continue using all the Pro features.
                </div>
                <LoadingButton variant="contained" className="" size="large" onClick={onResolveButtonClicked} loading={isLoading}>
                  <div className="py-1 ">{isLoading ? 'Redirecting...' : 'Resolve'}</div>
                </LoadingButton>
              </div>
            ) : (
              <PricingCard
                isLoading={isLoading}
                onUpgrade={onUpgradeClicked}
                onDowngrade={onDowngradeClicked}
                plan={PRO_PLAN}
                isCurrentPlan={false}
              />
            )}
          </div>
        </div>
      </DialogContent>
      {ModalComponent}
    </Dialog>
  );
};
