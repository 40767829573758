import FilterBarCard from '@/components/cards/FilterBarCard';
import FilterControls from '@/components/filter-builder/FilterControls';
import useFilters from '@/components/filter-builder/hooks/useFilters';
import {
  AlFilterModel,
  createProfileStatsFilters,
  MIN_DATE_OFFSET_FROM_CREATION_DAYS,
} from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import CurrencySelect from '@/components/form/CurrencySelect';
import GridTools from '@/components/grid/components/GridTools';
import { GridToggles } from '@/components/grid/types';
import { AlDate } from '@/lib/date/AlDate';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import ExpandToggleButton from '@/modules/application/components/ExpandToggleButton';
import { IdNameDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { useUserContext } from '@/modules/users';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { Tooltip } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';
import { ProfileWithMetricsModel } from '../models/ProfileWithMetricsModel';

interface ProfileStatsFilterBarProps {
  profilesWithMetrics: ProfileWithMetricsModel[] | undefined;
  gridApiRef: React.MutableRefObject<GridApi<unknown> | null>;
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
  gridToggles: GridToggles;
  setGridToggles: React.Dispatch<React.SetStateAction<GridToggles>>;
  selectedCurrency: CurrencyCode;
  setSelectedCurrency: (currency: CurrencyCode) => void;
  onExpandTable: () => void;
  isExpanded: boolean;
}

const ProfileStatsFilterBar: FunctionComponent<ProfileStatsFilterBarProps> = ({
  profilesWithMetrics,
  gridApiRef,
  filters,
  setFilters,
  gridToggles,
  setGridToggles,
  selectedCurrency,
  setSelectedCurrency,
  onExpandTable,
  isExpanded,
}) => {
  const { setFilterValues } = useFilters({ filters, setFilters });

  const teamIdName: IdNameDTO[] =
    profilesWithMetrics?.reduce((acc, profile) => {
      const id = profile.teamId.toString();

      // Check if the ID already exists in the accumulator array
      if (!acc.some((item) => item.id === id)) {
        acc.push({ id, name: profile.teamName });
      }

      return acc;
    }, [] as IdNameDTO[]) ?? [];

  const profileIdName: IdNameDTO[] =
    profilesWithMetrics?.reduce((acc, profile) => {
      const id = profile.id.toString();

      // Check if the ID already exists in the accumulator array
      if (!acc.some((item) => item.id === id)) {
        acc.push({ id, name: profile.nameWithMarket });
      }

      return acc;
    }, [] as IdNameDTO[]) ?? [];

  const availableFilters = createProfileStatsFilters(teamIdName, profileIdName).filter((filter) => filter.isFilterBuilderFilter);
  const defaultFilterKey = FilterKey.TEAM;

  const someProfilesHaveMissingComparison = profilesWithMetrics?.some((profile) => profile.comparisonMissing);
  const comparisonMissingMessage = someProfilesHaveMissingComparison ? 'Some profiles have missing comparison data' : null;

  //Currency
  const usedCurrencies = profilesWithMetrics?.reduce((acc, profile) => {
    if (!acc.includes(profile.currencyCode)) {
      acc.push(profile.currencyCode);
    }
    return acc;
  }, [] as CurrencyCode[]);

  // min date for date range should be earliest profile created at
  const { user } = useUserContext();
  const earliestProfile = user?.teams
    .map((t) => t.profiles)
    .flat()
    .reduce((earliest, current) => {
      if (!earliest.createdAt || !current.createdAt) {
        return earliest;
      }

      return AlDate.parse(current.createdAt).isBefore(AlDate.parse(earliest.createdAt)) ? current : earliest;
    });

  return (
    <FilterBarCard>
      <FilterControls filters={filters} setFilters={setFilters} availableFilters={availableFilters} defaultFilterKey={defaultFilterKey} />

      <div className="flex flex-grow"></div>
      <div className="flex h-full justify-center items-center gap-x-3">
        <ExpandToggleButton isExpanded={isExpanded} onExpandToggled={onExpandTable} />
        <DateRangeButton
          title="Range"
          filters={filters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to stats"
          comparisonRangeWarning={comparisonMissingMessage}
          minDate={
            earliestProfile ? AlDate.parse(earliestProfile.createdAt).subtractDays(MIN_DATE_OFFSET_FROM_CREATION_DAYS).toString() : undefined
          }
        />
      </div>
      <Tooltip placement="left" title={'Display currency'}>
        <span>
          <CurrencySelect
            onCurrencyChange={setSelectedCurrency}
            selectedCurrency={selectedCurrency}
            optionallyAddedCurrencies={usedCurrencies}
          />
        </span>
      </Tooltip>
      <GridTools gridToggles={gridToggles} setGridToggles={setGridToggles} gridApiRef={gridApiRef} />
    </FilterBarCard>
  );
};

export default ProfileStatsFilterBar;
