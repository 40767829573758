import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { AlFilterModel, LogicalOperatorType, OperatorType } from '../models/AlFilterModel';

interface StringComparisonOperatorSelectProps {
  label: string;
  filter: AlFilterModel;
  defaultOperatorValue?: OperatorType;
  defaultLogicalOperatorValue?: LogicalOperatorType;
  handleChange: (newSelection: StringComparisonOperatorSelectOption) => void;
}

export interface StringComparisonOperatorSelectOption {
  value: number;
  operator: OperatorType;
  logicalOperator: LogicalOperatorType;
  label: string;
}

export const StringComparisonOperatorSelect: FunctionComponent<StringComparisonOperatorSelectProps> = ({
  label,
  filter,
  defaultOperatorValue,
  defaultLogicalOperatorValue,
  handleChange,
}) => {
  let OPERATOR_OPTIONS: StringComparisonOperatorSelectOption[] = [
    {
      value: 1,
      label: 'OR',
      operator: OperatorType.LIKE,
      logicalOperator: LogicalOperatorType.OR,
    },
    {
      value: 2,
      label: 'AND',
      operator: OperatorType.LIKE,
      logicalOperator: LogicalOperatorType.AND,
    },
  ];

  if (filter.isInverseFilter) {
    // For example, we have following campaigns
    // 1)  "christmas ice cubes special"
    // 2)  "christmas hats special"
    // 3)  "christmas calendars"
    // 4)  "ice cubes special"
    // 5)  "fast laptops"
    // So, searching with terms "christmas" and "special":
    // Doesn't contain (OR) will return only campaign "fast laptops" (search for campaigns whose name does not contain "christmas" nor "special")
    // Doesn't contain (AND) will return  "christmas calendars", "ice cubes special", "fast laptops"

    OPERATOR_OPTIONS = [
      {
        value: 3,
        label: 'OR',
        operator: OperatorType.NOT_LIKE,
        logicalOperator: LogicalOperatorType.AND, // we need to combine  NOT LIKE with AND to get expected result
      }, // search for campaigns whose name does not contain "word_x" nor "word_y". If campaign name contains "word_x" or "word_y" or both then it will NOT be shown
      {
        value: 4,
        label: 'AND',
        operator: OperatorType.NOT_LIKE,
        logicalOperator: LogicalOperatorType.OR, // we need to combine NOT LIKE with OR to get expected result
      },
    ];
  }

  const defaultSelectedOption = OPERATOR_OPTIONS.find(
    (option) => option.operator === defaultOperatorValue && option.logicalOperator === defaultLogicalOperatorValue,
  );
  const [selectedOption, setSelectedOption] = useState<StringComparisonOperatorSelectOption>(defaultSelectedOption ?? OPERATOR_OPTIONS[0]);

  function onSelectionChanged(event: SelectChangeEvent<number>) {
    if (!event.target.value) {
      return;
    }
    const newSelection = OPERATOR_OPTIONS.find((option) => option.value === event.target.value);
    if (!newSelection) {
      return;
    }
    setSelectedOption(newSelection);
    handleChange(newSelection);
  }

  return (
    <FormControl className={'w-24'}>
      <InputLabel id={'text-operator-label-' + filter.key}>{label}</InputLabel>
      <Select
        labelId={'string-comparison-operator-label-' + filter.key}
        label={label}
        id={'string-comparison-operator-' + filter.key}
        value={selectedOption.value}
        onChange={onSelectionChanged}
      >
        {OPERATOR_OPTIONS.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
