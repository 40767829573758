import useFormatting from '@/hooks/useFormatting';
import { PlanType } from '@/modules/plans/types/SubscriptionPlan';
import { LoadingButton } from '@mui/lab';
import { Button, Divider } from '@mui/material';
import Gleap from 'gleap';
import { FunctionComponent } from 'react';
import { AdLabsPlanModel } from '../../plans/types/AdLabPlanModel';
import { PricingCardCheckIcon } from './PricingCardCheckIcon';

interface PricingCardProps {
  plan: AdLabsPlanModel;
  onDowngrade: (planToDowngradeTo: PlanType) => void;
  onUpgrade: (planToUpgradeTo: PlanType) => void;
  onResubscribe?: (planToResubscribe: PlanType) => void;
  onAddPaymentMethod?: () => void;
  isCurrentPlan: boolean;
  renewDate?: string;
  isCancelled?: boolean;
  isLoading: boolean;
  trialPeriodEnd?: string | null;
  isTrialConvertedToSubscriptionAtPeriodEnd?: boolean;
}

const PricingCard: FunctionComponent<PricingCardProps> = ({
  plan,
  onDowngrade,
  onUpgrade,
  isCurrentPlan,
  renewDate,
  isCancelled,
  onResubscribe,
  isLoading,
  trialPeriodEnd,
  isTrialConvertedToSubscriptionAtPeriodEnd,
  onAddPaymentMethod,
}) => {
  const { formatDateStringTimeNoHours } = useFormatting();

  const isTrialing = trialPeriodEnd && new Date(trialPeriodEnd) > new Date();

  function onTalkToUsClicked() {
    Gleap.openConversations();
  }

  return (
    <div
      className={`flex flex-col p-4 max-w-md gap-2 text-gray-900 bg-white rounded-lg  border border-gray-100 shadow dark:border-gray-600 xl:p-6 dark:bg-gray-800 dark:text-white ${
        isCurrentPlan && plan.price > 0 ? ' border-green-600' : 'border-transparent'
      }`}
    >
      <div className="text-2xl font-semibold">{plan.name}</div>

      {plan.priceLabel}

      {/* Actions */}
      {isCurrentPlan ? (
        <div className="flex flex-col items-center">
          <div className="text-green-600 font-semibold text-lg uppercase">Current Plan</div>
          {plan.price === 0 ? (
            <span className="text-gray-500 dark:text-gray-400">Free forever</span>
          ) : (
            <>
              {renewDate && (
                <span className="text-gray-500 dark:text-gray-400">
                  {isTrialing && (isTrialConvertedToSubscriptionAtPeriodEnd ? 'Trial Renews at' : 'Trial ends at')}
                  {!isTrialing && (isCancelled ? 'Ends at' : 'Renews at')} {formatDateStringTimeNoHours(renewDate)}
                </span>
              )}
              {isTrialing ? (
                <Button
                  className="mt-2"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    if (onAddPaymentMethod) {
                      onAddPaymentMethod();
                    }
                  }}
                >
                  {isTrialConvertedToSubscriptionAtPeriodEnd ? 'Cancel Plan' : isCancelled ? 'Renew plan' : 'Add Payment Method'}
                </Button>
              ) : !isCancelled ? (
                <Button className="mt-2" variant="outlined" color="secondary" size="small" onClick={() => onDowngrade(plan.planType)}>
                  Cancel Plan
                </Button>
              ) : (
                onResubscribe && (
                  <LoadingButton
                    loading={isLoading}
                    className="mt-2"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => onResubscribe(plan.planType)}
                  >
                    Re-subscribe
                  </LoadingButton>
                )
              )}
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {plan.price > 0 && (
            <>
              <LoadingButton loading={isLoading} variant="contained" color="primary" size="large" onClick={() => onUpgrade(plan.planType)}>
                Upgrade
              </LoadingButton>
            </>
          )}
        </div>
      )}

      {plan.priceDescription}

      <Button
        variant="text"
        href="https://help.adlabs.app/en/articles/30-adlabs-pricing-update"
        target="_blank"
        rel="noopener noreferrer"
        size="large"
      >
        Learn More about Pricing
      </Button>

      <div className="flex-1"></div>

      {/* List */}
      <ul role="list" className="space-y-1 text-left p-0 mt-0 mb-0">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-center space-x-3">
            <PricingCardCheckIcon />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <Divider className="my-4" />
      <div className="flex flex-col text-center gap-2">
        Spending more than $500k/mo?
        <Button variant="outlined" color="primary" size="large" onClick={onTalkToUsClicked}>
          Talk to us
        </Button>
      </div>
    </div>
  );
};

export default PricingCard;
