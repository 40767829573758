import { MenuItemGroup } from '@/modules/application';
import { Routes } from '@/router/router-paths';

export const SETTING_CONFIGURATION_SUB_MENU_ITEMS: MenuItemGroup[] = [
  {
    label: 'Settings',
    children: [
      {
        activeRouteIds: [Routes.USER],
        label: 'My Settings',
        url: Routes.USER,
      },
      {
        activeRouteIds: [Routes.TEAMS],
        label: 'Teams',
        url: Routes.TEAMS,
      },
    ],
  },
  {
    label: 'Billing',
    children: [
      {
        activeRouteIds: [Routes.BILLING],
        label: 'Subscription',
        url: Routes.BILLING,
      },
    ],
  },
];
