import { read, utils, WorkBook, WorkSheet } from 'xlsx';

export function useSheets() {
  async function getFirstSheetData(file: File, rowKeys?: string[]) {
    const sheet = await loadFirstSheet(file);
    const jsonData = extractJSONFromSheet(sheet, rowKeys);
    return jsonData;
  }

  async function loadFirstSheet(file: File): Promise<WorkSheet> {
    const data = await file.arrayBuffer();
    const workbook: WorkBook = read(data, {
      raw: true,
      type: 'buffer',
      cellNF: true,
    });
    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    return firstSheet;
  }

  function extractJSONFromSheet<T>(sheet: WorkSheet, rowKeys?: string[]): T[] {
    // Docs at https://docs.sheetjs.com/docs/api/utilities/array#array-output
    const dataJSON = utils.sheet_to_json<T>(sheet, {
      header: rowKeys ?? 1,
      blankrows: false,
      skipHidden: true,
      defval: null,
      rawNumbers: true,
    });
    return dataJSON;
  }

  return { getFirstSheetData };
}
