import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Menu, MenuItem, ToggleButton, Tooltip } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, useState } from 'react';

interface ExportGridButtonProps {
  gridApiRef: React.MutableRefObject<GridApi<unknown> | null | undefined>;
}

const ExportGridButton: FunctionComponent<ExportGridButtonProps> = ({ gridApiRef }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportAllRows = () => {
    if (gridApiRef.current) {
      gridApiRef.current.exportDataAsExcel({
        allColumns: true,
        onlySelected: false,
        shouldRowBeSkipped: () => false, // Export all rows
      });
    }
    handleClose();
  };

  const exportVisibleRows = () => {
    if (gridApiRef.current) {
      gridApiRef.current.exportDataAsExcel({
        allColumns: true,
        onlySelected: false,
        shouldRowBeSkipped: (params) => !params.node.displayed, // Only export visible rows
      });
    }
    handleClose();
  };

  return (
    <div>
      <Tooltip title={'Export Table'} placement="left">
        <ToggleButton 
          value={''} 
          onClick={handleClick} 
          className="pt-[7px] pb-[8px] bg-white border-slate-300"
        >
          <FileDownloadOutlinedIcon sx={{ fontSize: 15 }} />
        </ToggleButton>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={exportAllRows}>Export as XLSX (all rows)</MenuItem>
        <MenuItem onClick={exportVisibleRows}>Export as XLSX (visible rows)</MenuItem>
      </Menu>
    </div>
  );
};

export default ExportGridButton;
