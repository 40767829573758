import { GridColors } from '@/types/colors.enum';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import React, { FunctionComponent } from 'react';
import { BooleanCountsType } from '../helpers';

const defaultClasses = 'rounded-full bg-opacity-10 flex items-center justify-center w-5 h-5';

const booleanStateConfig = [
  {
    state: true,
    tooltip: 'Yes',
    icon: <CheckCircleOutlineRoundedIcon color={'success'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-green-600 bg-green-600`,
  },
  {
    state: false,
    tooltip: 'No',
    icon: <RemoveCircleOutlineRoundedIcon color={'disabled'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-slate-600 bg-slate-600`,
  },
];

export interface IBooleanCellRendererParams extends ICellRendererParams {
  trueTooltip?: React.ReactNode;
  falseTooltip?: React.ReactNode;
}

const StateIcon: FunctionComponent<{ state: boolean; trueTooltip?: React.ReactNode; falseTooltip?: React.ReactNode }> = ({
  state,
  trueTooltip,
  falseTooltip,
}) => {
  const stateConfig = booleanStateConfig.find((config) => config.state === state);
  const tooltip = (state ? trueTooltip : falseTooltip) ?? stateConfig?.tooltip;
  return (
    <div className="flex flex-row mr-0.5 h-full items-center">
      <Tooltip title={tooltip}>
        <div className={`rounded-full bg-opacity-10 flex items-center justify-center w-5 h-5 ${stateConfig?.styleClasses}`}>
          {stateConfig?.icon}
        </div>
      </Tooltip>
    </div>
  );
};

const BooleanCellRenderer: FunctionComponent<IBooleanCellRendererParams> = ({ node, colDef, value, trueTooltip, falseTooltip }) => {
  if (node.group && !isNil(value)) {
    const isAutoGroupColumn = colDef?.colId?.includes('ag-Grid-AutoColumn') ?? false;

    if (isAutoGroupColumn) {
      return (
        <div className="flex flex-row items-center gap-1">
          <StateIcon state={value} trueTooltip={trueTooltip} falseTooltip={falseTooltip} />{' '}
          <span className={GridColors.GRAY}>{node.allChildrenCount}</span>
        </div>
      );
    } else {
      return <>{createElements(value)}</>;
    }
  }

  return <StateIcon state={value} trueTooltip={trueTooltip} falseTooltip={falseTooltip} />;
};

export default BooleanCellRenderer;

function createElements(booleanCounts: BooleanCountsType): React.JSX.Element {
  return (
    <>
      <div className="flex flex-row gap-2">
        {booleanCounts.trueCount > 0 && (
          <div className="flex flex-row items-center mr-2 gap-1">
            <StateIcon state={true} /> <span className={GridColors.GRAY}>{booleanCounts.trueCount}</span>
          </div>
        )}
      </div>
      <div className="flex flex-row gap-2">
        {booleanCounts.falseCount > 0 && (
          <div className="flex flex-row items-center mr-2 gap-1">
            <StateIcon state={false} /> <span className={GridColors.GRAY}>{booleanCounts.falseCount}</span>
          </div>
        )}
      </div>
    </>
  );
}
