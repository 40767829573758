import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import { FunctionComponent, useState } from 'react';
import { ImportType } from '../../api/importer.contracts';
import { ImportOption } from '../../components/ImportOption';
import { ImportModalProps } from '../../types/import-definition';
import { ImportDefinitionFactory } from '../../types/import-factory';

export const ImporterOptionsPage: FunctionComponent = () => {
  const allImporters = ImportDefinitionFactory.getAllImportDefinitions();
  const [ActiveImportComponent, setActiveImportComponent] = useState<FunctionComponent<ImportModalProps> | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onImportClick(importerType: ImportType) {
    allImporters.forEach((importer) => {
      if (importer.type === importerType) {
        setActiveImportComponent(() => importer.component);
        setIsModalOpen(true);
      }
    });
  }

  function handleCloseModal() {
    setIsModalOpen(false);
    setActiveImportComponent(null);
  }

  return (
    <PageLayout>
      <PageLayoutTopBar
        header="Import Data"
        actions={
          <div className="flex flex-row gap-2">
            <TeamSelect />
            <ProfileSyncSelectButton />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        {/* A List of import options */}
        <div className="flex flex-wrap gap-4 mt-4">
          {allImporters.map((importer) => {
            return (
              <ImportOption
                key={importer.type}
                importerType={importer.type}
                iconColor={importer.iconColor}
                icon={importer.icon}
                title={importer.title}
                description={importer.description}
                templateFileUrl={importer.templateFileUrl}
                onImportClick={onImportClick}
              />
            );
          })}
        </div>
        {ActiveImportComponent && <ActiveImportComponent isOpen={isModalOpen} onClose={handleCloseModal} />}
      </PageLayoutBody>
    </PageLayout>
  );
};
