import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { FunctionComponent, useCallback } from 'react';
import TinyAccordion from './TinyAccordion';

interface CollapsibleCopyableDetailsProps {
  headerText: string;
  message: string;
}

const CollapsibleCopyableDetails: FunctionComponent<CollapsibleCopyableDetailsProps> = ({ headerText, message }) => {
  const handleCopyClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(message);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }, [message]);

  return (
    <TinyAccordion headerText={headerText}>
      <TextField
        fullWidth
        multiline
        rows={3}
        variant="outlined"
        value={message}
        slotProps={{
          input: {
            style: {
              fontFamily: "'Monaco', 'Courier New', monospace",
            },
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Copy">
                  <IconButton edge="end" onClick={handleCopyClick}>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          },
        }}
      />
    </TinyAccordion>
  );
};

export default CollapsibleCopyableDetails;
