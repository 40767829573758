import { useLayoutContext } from '@/contexts/LayoutContext';
import { useEffect } from 'react';

const useGlobalLoadingStateObserver = (key: string | (string | string[] | undefined)[], isFetching: boolean) => {
  const { setGlobalLoadingStateForKey } = useLayoutContext();

  useEffect(() => {
    setGlobalLoadingStateForKey(key, isFetching);
  }, [key, isFetching]);

  useEffect(() => {
    return () => {
      setGlobalLoadingStateForKey(key, false);
    };
  }, [key]);

  return {};
};

export default useGlobalLoadingStateObserver;
