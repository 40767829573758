import { Button, Card } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import { ImportType } from '../api/importer.contracts';
import { DownloadRounded } from '@mui/icons-material';

interface ImportOptionProps {
  importerType: ImportType;
  iconColor: string;
  icon: ReactNode;
  title: string;
  description: string;
  onImportClick: (importerType: ImportType) => void;
  templateFileUrl: string;
}

export const ImportOption: FunctionComponent<ImportOptionProps> = ({
  importerType,
  iconColor,
  icon,
  title,
  description,
  onImportClick,
  templateFileUrl,
}) => {
  return (
    <Card className="h-60  w-96 flex-col items-center  ">
      <div className="flex h-full flex-col items-center pt-5">
        <div className={`text-2xl font-bold`} style={{ color: iconColor }}>
          {icon}
        </div>
        <div className="text-2xl font-bold">{title}</div>
        <div className="mt-2 px-6 text-center text-gray-600 dark:text-gray-400">{description}</div>
        <div className="grow"></div>
        <div className="my-4 flex gap-x-4">
          <Button variant="outlined" className="" href={templateFileUrl} download={true} startIcon={<DownloadRounded />}>
            Download Template
          </Button>

          <Button
            variant="contained"
            className=""
            onClick={() => {
              onImportClick(importerType);
            }}
          >
            Start Import
          </Button>
        </div>
      </div>
    </Card>
  );
};
