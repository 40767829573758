import { ButtonBase, Radio } from '@mui/material';
import { FunctionComponent } from 'react';

interface TemplateButtonProps {
  title: string;
  description: string;
  isSelected: boolean;
  onClick: () => void;
}

const TemplateButton: FunctionComponent<TemplateButtonProps> = ({ title, description, isSelected, onClick }) => {
  return (
    <ButtonBase
      onClick={onClick}
      className={`flex flex-row items-start justify-start w-full h-full rounded-lg p-2 
        ${isSelected ? 'border-2 border-primary text-primary bg-primary-500 bg-opacity-20' : 'border border-transparent bg-opacity-30 text-black bg-gray-300 hover:bg-primary-500 hover:bg-opacity-10 hover:text-primary active:bg-primary active:bg-opacity-20 '}
         transition-all`}
    >
      <div className="text-2xl mb-1 h-full flex items-center">
        <Radio checked={isSelected} />
      </div>
      <div className="flex flex-col ml-2 self-center items-start ">
        <span className="font-semibold mb-1">{title}</span>
        <div className="text-xs text-left">{description}</div>
      </div>
    </ButtonBase>
  );
};

export default TemplateButton;
