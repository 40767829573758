import SetNoStatsFilterButton from '@/components/buttons/SetNoStatsFiltersButton';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { Alert, AlertTitle } from '@mui/material';
import { FunctionComponent } from 'react';

interface DataLimitReachedAlertProps {
  setFilters: (filters: AlFilterModel[]) => void;
}

const DataLimitReachedAlert: FunctionComponent<DataLimitReachedAlertProps> = ({ setFilters }) => {
  return (
    <Alert className="py-4 my-2" severity="warning">
      <AlertTitle>Not all of the data can be displayed</AlertTitle>
      <p>Metrics and Timeline are not displayed. Data Table is limited to 250,000 targets, sorted by spend.</p>
      <p>
        Apply filters to reduce the number of displayed targets. <strong>We recommend archiving targets that are no longer relevant</strong>{' '}
        <SetNoStatsFilterButton setFilters={setFilters} />
      </p>
    </Alert>
  );
};

export default DataLimitReachedAlert;
