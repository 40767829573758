import PopoverLikePopper from '@/components/PopoverLikePopper';
import { useDataGroups } from '@/hooks/useDataGroups';
import { sleep } from '@/lib/api/api-utils';
import { CircularProgress } from '@mui/material';
import { Dispatch, FunctionComponent, RefObject, SetStateAction, useState } from 'react';
import { DataGroupModel } from '../models/DataGroup';
import { SelectedEntityForDataGroup } from '../models/DataItem';
import { DataGroupType } from '../models/data-groups-contracts';
import { AssignRemoveDataGroups } from './AssignRemoveDataGroups';
import { CreateDataGroup } from './CreateDataGroup';

interface DataGroupsPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedItems: SelectedEntityForDataGroup[];
  dataGroupType: DataGroupType;
}

export const DataGroupsPopover: FunctionComponent<DataGroupsPopoverProps> = ({
  buttonRef,
  isOpen,
  setIsOpen,
  selectedItems,
  dataGroupType,
}) => {
  const [isInCreateMode, setIsInCreateMode] = useState(false);

  const { dataGroups, refetchDataGroups, isDataGroupsLoading } = useDataGroups([dataGroupType]);

  const [preferredAssignDataGroupName, setPreferredAssignDataGroupName] = useState<string | undefined>(undefined);

  const handlePopoverClose = async () => {
    setIsOpen(false);
    await sleep(200);
    setIsInCreateMode(false);
  };

  const onCreateConfirmed = async (dataGroup: DataGroupModel): Promise<void> => {
    await sleep(200);
    refetchDataGroups();
    setPreferredAssignDataGroupName(dataGroup.name);
    setIsInCreateMode(false);
  };

  // Used to set assign values when just created a new group and moving back to update
  const preferredAssignDataGroup = dataGroups?.find((group) => group.name === preferredAssignDataGroupName);

  return (
    <PopoverLikePopper open={isOpen} anchorEl={buttonRef.current} onClose={handlePopoverClose} style={{ width: 500 }}>
      <>
        {isDataGroupsLoading ? (
          <CircularProgress />
        ) : isInCreateMode ? (
          <CreateDataGroup
            existingGroups={dataGroups ?? []}
            // selectedCampaignsOrTargets.length === 0 means we're on Tags page, when something is selected it's either optimizer or targeting page where group type should be predefined
            fixedDataGroupType={selectedItems.length === 0 ? undefined : dataGroupType}
            onClose={() => setIsInCreateMode(false)}
            onCreateConfirmed={onCreateConfirmed}
          />
        ) : (
          <AssignRemoveDataGroups
            existingGroups={dataGroups ?? []}
            selectedItems={selectedItems}
            onClose={handlePopoverClose}
            onCreateClicked={() => setIsInCreateMode(true)}
            dataGroupType={dataGroupType}
            preferredAssignDataGroup={preferredAssignDataGroup}
          />
        )}
      </>
    </PopoverLikePopper>
  );
};
