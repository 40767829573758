import { DataItemModel } from './DataItem';
import { DataGroupDTO, DataGroupType } from './data-groups-contracts';

export class DataGroupModel {
  public id: number;
  public name: string;
  public type: DataGroupType;
  public items: DataItemModel[];

  constructor(args: DataGroupArguments) {
    this.id = args.id;
    this.name = args.name;
    this.type = args.type;
    this.items = args.items;
  }

  public static fromResponse(response: DataGroupDTO): DataGroupModel {
    return new DataGroupModel({
      id: response.id,
      name: response.name,
      type: response.type,
      items: response.items.map((item) => new DataItemModel({ id: item.id, name: item.name })),
    });
  }

  public static fromResponseArray(response: DataGroupDTO[]): DataGroupModel[] {
    return response.map((dataGroup) => DataGroupModel.fromResponse(dataGroup));
  }

  public static toDTO(dataGroup: DataGroupModel): DataGroupDTO {
    return {
      id: dataGroup.id,
      name: dataGroup.name,
      type: dataGroup.type,
      items: dataGroup.items.map((item) => ({ id: item.id, name: item.name })),
    };
  }
}

interface DataGroupArguments {
  id: number;
  name: string;
  type: DataGroupType;
  items: DataItemModel[];
}

export type DataGroupColumnId = `data_group_${number}`;
