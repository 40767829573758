import VideoModal from '@/components/modals/video-modal/VideoModal';
import { VideoUrl } from '@/config/urls';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { invalidateProfile_campaignGroupsQueryKey } from '@/modules/optimizer/api/campaign/campaign-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import AddIcon from '@mui/icons-material/Add';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Button, Popover } from '@mui/material';
import { QueryObserverResult, RefetchOptions, useQueryClient } from '@tanstack/react-query';
import { FunctionComponent, useRef, useState } from 'react';
import { CampaignGroupsWithMetrics } from '../../api/campaign/campaign-contracts';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import { UserSettingKey } from '@/modules/users';
import { CreateUpdateCampaignGroup } from './campaign-groups-popover/CreateUpdateCampaignGroup';

interface CampaignGroupsActionsProps {
  campaignGroupsWithMetrics: CampaignGroupsWithMetrics | undefined;
  // isLoading: boolean;
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<CampaignGroupsWithMetrics | undefined, Error>>;
}

const CampaignGroupsActions: FunctionComponent<CampaignGroupsActionsProps> = ({ campaignGroupsWithMetrics, refetch }) => {
  const { activeProfile } = useActiveTeamContext();
  const queryClient = useQueryClient();
  const rowData = campaignGroupsWithMetrics?.campaignGroups ?? [];

  // CREATE GROUP
  const createButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isCreatePopoverOpen, setIsCreatePopoverOpen] = useState(false); // state to control the popover
  const onCreateClicked = () => {
    setIsCreatePopoverOpen((previousValue) => !previousValue);
  };

  const onCreateConfirmed = () => {
    invalidateProfile_campaignGroupsQueryKey(queryClient, activeProfile?.id);
    refetch();
  };

  const handleCreatePopoverClose = () => {
    setIsCreatePopoverOpen((previousValue) => !previousValue);
  };

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(
    UserSettingKey.OPTIMIZATION_GROUPS_TUTORIAL_DISPLAY_COUNT,
  );

  return (
    <>
      <div className="flex flex-row mb-4 mt-5 gap-x-2">
        <Button ref={createButtonRef} onClick={onCreateClicked} variant="contained" startIcon={<AddIcon />}>
          Create New Opt Group
        </Button>

        <Button onClick={() => openTutorialModal()} variant="text" startIcon={<OndemandVideoIcon />}>
          How to Use Optimization Groups
        </Button>
        <div className="flex-grow">{/* Filler div */}</div>
      </div>

      <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={VideoUrl.OPTIMIZATION_GROUPS}
        title={'How to Use Optimization Groups'}
      />

      <Popover
        id={'create-group-popover'}
        open={isCreatePopoverOpen}
        anchorEl={createButtonRef.current}
        onClose={handleCreatePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{ paper: { style: { maxWidth: 500 } } }}
      >
        <CreateUpdateCampaignGroup
          existingGroups={rowData}
          onClose={handleCreatePopoverClose}
          onSuccess={onCreateConfirmed}
          flowType={FlowType.OPTIMIZATION_GROUPS}
        />
      </Popover>
    </>
  );
};

export default CampaignGroupsActions;
