import { useEffect, useRef } from 'react';
import { EChartsType } from 'echarts';

/**
 * A custom React hook to handle ECharts resize event.
 * It observes the container div resize and triggers the echarts resize method accordingly.
 *
 * @param {EChartsType | undefined} echarts The ECharts instance to be resized. Replace `EChartsType` with the actual type of your ECharts instance.
 * @returns A ref to be assigned to the chart container div.
 *
 * Usage:
 * ```jsx
 * import React, { useState } from 'react';
 * import useEchartsResize from './useEchartsResize'; // Adjust the import path as necessary
 *
 * const MyChartComponent = () => {
 *   const [echarts, setEcharts] = useState<EChartsType>(); // Replace `EChartsType` with the actual type
 *   const chartContainerRef = useEchartsResize(echarts);
 *
 *   // Your chart rendering logic here, using `chartContainerRef` for the container div
 *
 *   return (
 *     <div ref={chartContainerRef} style={{ width: '100%', height: '400px' }}>
 *
 *     </div>
 *   );
 * };
 * ```
 */
const useEchartsResize = (echarts?: EChartsType) => {
  const chartContainerDivRef = useRef<HTMLDivElement>(null);
  const isWindowResizing = useRef(false);

  const onContainerResize = () => {
    if (echarts && !isWindowResizing.current) {
      echarts.resize();
    }
  };

  useEffect(() => {
    if (!chartContainerDivRef.current || !echarts) {
      return;
    }

    const resizeObserver = new ResizeObserver(onContainerResize);
    resizeObserver.observe(chartContainerDivRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [echarts, chartContainerDivRef]);

  useEffect(() => {
    const handleResize = () => {
      isWindowResizing.current = true;
      if (echarts) {
        echarts.resize();
      }

      // Reset the flag after a short delay to allow the resize observer to catch up
      setTimeout(() => {
        isWindowResizing.current = false;
      }, 100);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [echarts]);

  return chartContainerDivRef;
};

export default useEchartsResize;
