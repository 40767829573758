import React, { createContext, useState, useContext, FunctionComponent, PropsWithChildren } from 'react';
import { UserSettingModel } from '../types/UserSettingModel';
import { userSettingService } from '../api/user-settings/user-setting.service';
import { SettingValue, UserSettingKey, UserSettingValue } from '../api/user-settings/user-setting.contracts';
import { useUserContext } from './UserContext';
import { isEqual } from 'lodash-es';

interface UserSettingsContextProps {
  userSettings: UserSettingModel[];
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettingModel[]>>;
  loadUserSettings: (userId: number) => Promise<UserSettingModel[]>;
  upsertUserSetting: (key: UserSettingKey, value: UserSettingValue) => Promise<boolean>;
  getUserSettingByKey: (key: UserSettingKey) => UserSettingModel | undefined;
  getUserSettingValueByKey: <T = SettingValue>(key: UserSettingKey) => T | undefined;
}

const UserSettingsContext = createContext<UserSettingsContextProps | null>(null);

interface UserSettingsProviderProps extends PropsWithChildren {
  initialSettings: UserSettingModel[];
}

/**
 * UserSettingsProvider is used to load and store user settings.
 */
export const UserSettingsProvider: FunctionComponent<UserSettingsProviderProps> = ({ children, initialSettings }) => {
  const { user } = useUserContext();

  const [userSettings, setUserSettings] = useState<UserSettingModel[]>(initialSettings);

  async function loadUserSettings(userId: number) {
    const settings = await userSettingService.getAll(userId);
    if (settings.isSuccess) {
      setUserSettings(settings.payload);
      return settings.payload;
    }
    return [];
  }

  async function upsertUserSetting(key: UserSettingKey, value: UserSettingValue) {
    if (user && user.id) {
      const updateValueResponse = await userSettingService.updateValue(user.id, key, value);
      if (updateValueResponse.isSuccess) {
        const updatedUserSettings = userSettings.map((setting) => {
          if (setting.key === key) {
            setting.value = value;
            return setting;
          }
          return setting;
        });

        if (isEqual(updatedUserSettings, userSettings)) {
          return updateValueResponse.isSuccess; // Don't change anything if the same value
        }
        setUserSettings(updatedUserSettings);
      }
      return updateValueResponse.isSuccess;
    }
    return false;
  }

  function getUserSettingByKey(key: UserSettingKey) {
    return userSettings.find((setting) => setting.key === key);
  }

  function getUserSettingValueByKey<T = SettingValue>(key: UserSettingKey) {
    const setting = userSettings.find((setting) => setting.key === key);
    if (setting) {
      return setting.value as T;
    }
    return undefined;
  }

  return (
    <UserSettingsContext.Provider
      value={{
        userSettings,
        setUserSettings,
        loadUserSettings,
        upsertUserSetting,
        getUserSettingByKey,
        getUserSettingValueByKey,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

/**
 * useUserSettingsContext is used to access user settings
 *
 * It can only be used inside UserSettingsProvider
 */
export const useUserSettingsContext = (): UserSettingsContextProps => {
  const context = useContext(UserSettingsContext);
  if (!context) {
    throw new Error('useUserSettings must be used within a UserSettingsProvider');
  }
  return context;
};
