import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import EditableText from '@/components/form/EditableText';
import { useNavigationConfirmationModal } from '@/components/modals/confirmation-modal/useNavigationConfirmationModal';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import { useFullscreenToggle } from '@/hooks/useFullscreenToggle';

import { AlDate } from '@/lib/date/AlDate';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { toastService } from '@/services/toast.service';
import { Close } from '@mui/icons-material';
import { IconButton, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { v4 } from 'uuid';
import { dashboardService } from '../api/dashboard/dashboard.service';
import Dashboard from '../components/dashboard/Dashboard';
import DashboardActionsButton from '../components/dashboard/DashboardActionsButton';
import RenameDashboardDialog from '../components/dashboard/RenameDasbhoardDialog';
import { DASHBOARD_MAX_ROW_COUNT } from '../configuration/dashboard-dimensions';
import { DashboardContext, useDashboard } from '../contexts/DashboardContextProvider';
import { generateDashboardQueryKey } from '../hooks/useDashboardQueries';
import { DashboardCalculatedDataModel } from '../types/DashboardCalculatedDataModel';

export const DashboardPage: FunctionComponent = () => {
  const { dashboardId } = useParams();
  const dashboardContainerDivRef = useRef<HTMLDivElement>(null);

  const [isDashboardFullscreen, toggleIsDashboardFullscreen] = useFullscreenToggle();

  const {
    editingWidgetId,
    setEditingWidgetId,
    widgets,
    setWidgets,
    dashboard,
    isLoadingDashboard,
    removeWidget,
    updateWidgetConfiguration,
    dates,
    setDates,
    comparisonDates,
    setComparisonDates,
    dashboardDateFilters,
    setDragginWidgetId,
    dragginWidgetId,
    addWidget,
    saveDashboard,
    setWidgetIdsRequestingData,
    widgetIdsRequestingData,
    updateWidgetLayout,
    setDashboardVersion,
    dashboardVersion,
    removeAllWidgets,
    duplicateWidget,
  } = useDashboard(dashboardId);

  const dashboardContextValue = useMemo(
    () => ({
      editingWidgetId,
      setEditingWidgetId,
      widgets,
      setWidgets,
      dashboard,
      isLoadingDashboard,
      removeWidget,
      updateWidgetConfiguration,
      dates,
      setDates,
      comparisonDates,
      setComparisonDates,
      dashboardDateFilters,
      setDragginWidgetId,
      dragginWidgetId,
      addWidget,
      saveDashboard,
      setWidgetIdsRequestingData,
      widgetIdsRequestingData,
      updateWidgetLayout,
      setDashboardVersion,
      dashboardVersion,
      removeAllWidgets,
      duplicateWidget,
    }),
    [
      editingWidgetId,
      setEditingWidgetId,
      widgets,
      setWidgets,
      dashboard,
      isLoadingDashboard,
      removeWidget,
      updateWidgetConfiguration,
      dates,
      setDates,
      comparisonDates,
      setComparisonDates,
      dashboardDateFilters,
      setDragginWidgetId,
      dragginWidgetId,
      removeAllWidgets,
      duplicateWidget,
    ],
  );

  const { ModalComponent: navigationModal, setBlock } = useNavigationConfirmationModal({
    questionText: 'Are you sure you want to leave this page? All your current changes are saved.',
  });

  useEffect(() => {
    setBlock(true);
  }, []);

  const navigate = useNavigate();

  const { ModalComponent, handleOpenModal } = useDeleteConfirmation({
    questionText: 'Are you sure you want to delete this dashboard?',
    headerText: 'Delete Dashboard',
    onDelete: deleteDashboard,
  });

  function onDeleteDashboardClicked() {
    handleOpenModal();
  }

  async function deleteDashboard() {
    if (!dashboardId) return;

    const result = await dashboardService.delete(+dashboardId);
    if (result.isSuccess) {
      toastService.success('Dashboard deleted');
      navigate('/dashboards', { replace: true });
    }
  }

  function onRenameDashboardClicked() {
    setIsRenameDashboardDialogOpen(true);
  }

  const [isRenameDashboardDialogOpen, setIsRenameDashboardDialogOpen] = useState(false);

  function onDashboardRenamed() {
    setIsRenameDashboardDialogOpen(false);
  }

  async function onSetDates(filters: AlFilterModel[]) {
    const getFilterValues = (key: FilterKey) => {
      const filter = filters.find((f) => f.key === key);
      return [filter?.conditions?.[0]?.values?.[0] as string | undefined, filter?.conditions?.[1]?.values?.[0] as string | undefined];
    };

    const [dateFilterStart, dateFilterEnd] = getFilterValues(FilterKey.DATE);
    const [comparisonDateFilterStart, comparisonDateFilterEnd] = getFilterValues(FilterKey.COMPARE_DATE);

    if (!dateFilterStart || !dateFilterEnd || !comparisonDateFilterStart || !comparisonDateFilterEnd) return;

    const dates = [AlDate.parse(dateFilterStart), AlDate.parse(dateFilterEnd)];
    const comparisonDates = [AlDate.parse(comparisonDateFilterStart), AlDate.parse(comparisonDateFilterEnd)];

    if (!dates[0] || !dates[1] || !comparisonDates[0] || !comparisonDates[1]) return;

    setDates(dates);
    setComparisonDates(comparisonDates);
    if (dashboardId) {
      if (dashboard) {
        dashboard.dto.filter_start_date_offset_in_days = dates[0].diff(AlDate.now(), 'days');
        dashboard.dto.filter_end_date_offset_in_days = dates[1].diff(AlDate.now(), 'days');
        dashboard.dto.filter_compare_start_date_offset_in_days = comparisonDates[0].diff(AlDate.now(), 'days');
        dashboard.dto.filter_compare_end_date_offset_in_days = comparisonDates[1].diff(AlDate.now(), 'days');
      }

      await dashboardService.update(+dashboardId, {
        filter_start_date_offset_in_days: dates[0].diff(AlDate.now(), 'days'),
        filter_end_date_offset_in_days: dates[1].diff(AlDate.now(), 'days'),
        filter_compare_start_date_offset_in_days: comparisonDates[0].diff(AlDate.now(), 'days'),
        filter_compare_end_date_offset_in_days: comparisonDates[1].diff(AlDate.now(), 'days'),
      });

      setDashboardVersion(v4());
    }
  }

  useEffect(() => {
    if (isLoadingDashboard) return;
    if (!dashboard) return;

    if (isNil(dashboard.dto.filter_start_date_offset_in_days)) return;
    if (isNil(dashboard.dto.filter_end_date_offset_in_days)) return;
    if (isNil(dashboard.dto.filter_compare_start_date_offset_in_days)) return;
    if (isNil(dashboard.dto.filter_compare_end_date_offset_in_days)) return;

    setDates([
      AlDate.now().plus({ days: dashboard.dto.filter_start_date_offset_in_days }),
      AlDate.now().plus({ days: dashboard.dto.filter_end_date_offset_in_days }),
    ]);
    setComparisonDates([
      AlDate.now().plus({ days: dashboard.dto.filter_compare_start_date_offset_in_days }),
      AlDate.now().plus({ days: dashboard.dto.filter_compare_end_date_offset_in_days }),
    ]);
  }, [isLoadingDashboard]);

  const { data: dashboardData } = useQuery<DashboardCalculatedDataModel, Error, DashboardCalculatedDataModel, (string | number)[]>({
    queryKey: generateDashboardQueryKey(dashboard),
    enabled: false,
  });

  // TODO: Add error state for when dashboard is not found
  if (dashboardId && !dashboard) {
    return (
      <div className="h-full max-h-screen min-h-screen">
        <LoadingBlock />
      </div>
    );
  }

  async function onDashboardNameChanged(newDashboardName: string) {
    if (dashboard) {
      const renamedDashboard = await dashboardService.update(dashboard.dto.id, { name: newDashboardName });
      if (renamedDashboard.isSuccess) {
        dashboard.dto.name = renamedDashboard.payload.name;
        toastService.success('Dashboard renamed');
      }
    }
  }

  function onWidgetAdded() {
    // Scroll the widget into view
    setTimeout(() => {
      const rowHeight = widgets.reduce((acc, widget) => {
        return widget.layout.y + widget.layout.h > acc ? widget.layout.y + widget.layout.h : acc;
      }, 0);
      if (dashboardContainerDivRef.current) {
        dashboardContainerDivRef.current.scrollTo({
          top: (dashboardContainerDivRef.current.scrollHeight / DASHBOARD_MAX_ROW_COUNT) * rowHeight - 50,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 200);
  }

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <PageLayout showFullscreen={isDashboardFullscreen}>
        {isDashboardFullscreen ? (
          <div className="flex w-full items-center justify-between px-4">
            <div>
              {dashboard ? (
                <div className="text-2xl font-semibold">{dashboard.dto.name}</div>
              ) : (
                <div className="text-2xl font-semibold">Dashboard</div>
              )}
            </div>

            <div>
              <IconButton
                onClick={() => {
                  toggleIsDashboardFullscreen();
                }}
              >
                <Close />
              </IconButton>
            </div>
          </div>
        ) : (
          ''
        )}
        <PageLayoutTopBar
          header={
            dashboard ? (
              <EditableText
                onTextChange={onDashboardNameChanged}
                text={dashboard.dto.name}
                className="border-b-2 normal-case border-dotted border-opacity-0 hover:border-opacity-100 border-gray-200"
              />
            ) : (
              'Dashboard'
            )
          }
          navigateBackPathname="/dashboards"
          actions={
            <>
              {!dashboard || isLoadingDashboard ? (
                <Skeleton className="rounded-xl" variant="rectangular" animation="wave" width={200} height={35} />
              ) : (
                <div className="bg-white p-0.5 rounded-md">
                  <DateRangeButton
                    title="Select Dates"
                    filters={dashboardDateFilters}
                    setFilterValues={onSetDates}
                    tooltip="Date range to use"
                    comparisonRangeWarning={
                      dashboardData && dashboardData.dto.comparison_missing
                        ? 'Comparison data is missing for the selected date range'
                        : undefined
                    }
                  />
                </div>
              )}
              <DashboardActionsButton
                onRenameDashboard={onRenameDashboardClicked}
                onDeleteDashboard={onDeleteDashboardClicked}
                onWidgetAdded={() => {
                  onWidgetAdded();
                }}
              />
            </>
          }
        />

        <PageLayoutBody>
          {isLoadingDashboard ? (
            <LoadingBlock />
          ) : !dashboard ? (
            <div className="mt-4">
              <ErrorLoadingDataAlert />
            </div>
          ) : (
            <AlErrorBoundary>
              <div
                ref={dashboardContainerDivRef}
                className={`absolute bottom-0 left-0 right-0 top-0 h-full overflow-auto scrollbar scrollbar-track-gray-200 scrollbar-thumb-gray-300 dark:scrollbar-track-gray-700 dark:scrollbar-thumb-gray-950`}
              >
                <div className="pb-4">{widgets && <Dashboard></Dashboard>}</div>
              </div>
              <div className={'w-96'}></div>
            </AlErrorBoundary>
          )}
        </PageLayoutBody>
      </PageLayout>

      {dashboard !== undefined && isRenameDashboardDialogOpen && (
        <RenameDashboardDialog
          isOpen={isRenameDashboardDialogOpen}
          onClose={() => setIsRenameDashboardDialogOpen(false)}
          onDashboardRenamed={onDashboardRenamed}
          dashboard={dashboard}
        />
      )}
      {ModalComponent}
      {navigationModal}
    </DashboardContext.Provider>
  );
};
