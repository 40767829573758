import { Navigate, RouteObject } from 'react-router';
import { RegistrationLayout } from '@/modules/registration/layouts/RegistrationLayout';
import RegistrationStepCreateAccountPage from '@/modules/registration/pages/RegistrationStepCreateAccountPage';
import RegistrationStepAboutYouPage from '../pages/RegistrationStepAboutYouPage';
import RegistrationStepBookCallPage from '../pages/RegistrationStepBookCallPage';
import RegistrationConnectAmazonAccountPage from '../pages/RegistrationConnectAmazonAccountPage';

export const REGISTRATION_ROUTES: RouteObject[] = [
  {
    path: 'register',
    element: <RegistrationLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="create-your-account" replace />,
      },
      {
        path: 'create-your-account',
        element: <RegistrationStepCreateAccountPage />,
      },
      {
        path: 'about-you',
        element: <RegistrationStepAboutYouPage />,
      },
      {
        path: 'book-call',
        element: <RegistrationStepBookCallPage />,
      },
      {
        path: 'connect-amazon',
        element: <RegistrationConnectAmazonAccountPage />,
      },
    ],
  },
];
