import { KeywordHarvestingResponse } from '../api/keyword-harvesting-contracts';
import { KeywordHarvestingPreviewDataRow } from './KeywordHarvestingPreviewDataRow';

export class KeywordHarvestingModel {
  public keywordHarvestingPreviewData: KeywordHarvestingPreviewDataRow[];

  constructor(args: KeywordHarvestingCreateArguments) {
    this.keywordHarvestingPreviewData = args.keywordHarvestingPreviewData;
  }

  public static fromResponse(response: KeywordHarvestingResponse): KeywordHarvestingModel {
    const keywordHarvestingPreviewData = KeywordHarvestingPreviewDataRow.fromResponseArray(response.items);

    return new KeywordHarvestingModel({
      keywordHarvestingPreviewData,
    });
  }
}

export interface KeywordHarvestingCreateArguments {
  keywordHarvestingPreviewData: KeywordHarvestingPreviewDataRow[];
}

export interface HarvestingParams {
  tacos: number;
  useGroupSettings: boolean;
}
