import { Autocomplete, TextField, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { METRICS } from '../metrics/MetricsConfig';
import { MetricField } from '../metrics/types/MetricField';

interface SingleMetricSelectProps {
  availableMetrics: Map<string, MetricField[]>; // Map of group titles to metrics
  value: MetricField;
  onChange: (selectedMetric: MetricField) => void;
}

const SingleMetricSelect: FunctionComponent<SingleMetricSelectProps> = ({ availableMetrics, value, onChange }) => {
  // Flatten the Map into an array of metrics, preserving group information
  const flattenedMetrics = Array.from(availableMetrics.entries()).flatMap(([group, metrics]) =>
    metrics.map((metric) => ({ key: metric, group })),
  );

  const getOptionLabel = (metricKey: MetricField) => {
    const metric = METRICS[metricKey];
    return metric?.title || metricKey;
  };

  return (
    <Autocomplete
      options={flattenedMetrics}
      value={flattenedMetrics.find((metric) => metric.key === value) || undefined}
      onChange={(_, selectedMetric) => {
        if (selectedMetric) {
          onChange(selectedMetric.key);
        }
      }}
      disableClearable
      groupBy={(option) => option.group}
      getOptionLabel={(option) => getOptionLabel(option.key)}
      renderInput={(params) => <TextField {...params} label="Select a Metric" variant="outlined" />}
      renderGroup={(params) => (
        <div key={params.key}>
          <Typography variant="subtitle2" className="pl-2 text-gray-500">
            {params.group}
          </Typography>
          {params.children}
        </div>
      )}
    />
  );
};

export default SingleMetricSelect;
