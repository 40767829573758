import { UnitType } from '@/components/metrics/MetricsConfig';
import useFormatting, { CurrencyPosition } from '@/hooks/useFormatting';
import { SelectChangeEvent, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { isEmpty, isNil, isNumber } from 'lodash-es';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel, FilterCondition, LogicalOperatorType, OperatorType } from '../models/AlFilterModel';
import { BetweenOperatorType } from '../types/Operators';
import { BetweenOperatorSelect } from './BetweenOperatorSelect';
interface FilterBetweenProps {
  filter: AlFilterModel;
  isOperatorDisabled?: boolean;
}
export const FilterBetween: FunctionComponent<FilterBetweenProps> = ({ filter, isOperatorDisabled = false }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const { getCurrencySymbolPosition, getCurrencySymbol } = useFormatting();
  const currencyPosition = getCurrencySymbolPosition();
  const currencySymbol = getCurrencySymbol();

  // TODO: Merge first and second condition + logical operator to one object
  const [firstCondition, setFirstCondition] = useState<number | string>(
    filter.conditions && filter.conditions?.length > 0 ? filter.conditions[0].values[0] : '',
  );
  const [firstConditionOperator, setFirstConditionOperator] = useState<OperatorType>(
    filter.conditions && filter.conditions?.length > 0 ? filter.conditions[0].operator : filter.defaultConditionOperators[0],
  );

  const [secondCondition, setSecondCondition] = useState<number | string>(
    filter.conditions && filter.conditions?.length > 1 ? filter.conditions[1].values[0] : '',
  );
  const [secondConditionOperator, setSecondConditionOperator] = useState<OperatorType>(
    filter.conditions && filter.conditions?.length > 1 ? filter.conditions[1].operator : filter.defaultConditionOperators[1],
  );

  const [logicalOperator, setLogicalOperator] = useState<LogicalOperatorType>(filter.logicalOperator ?? LogicalOperatorType.AND);

  const handleFirstConditionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const condition = event.target.value;
    setFirstCondition(condition);
  };

  const handleSecondConditionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const condition = event.target.value;
    setSecondCondition(condition);
  };

  useEffect(() => {
    const conditions: FilterCondition[] = [];

    conditions.push({
      values: isEmpty(firstCondition) ? ['0'] : isNumber(firstCondition) ? [firstCondition as number] : [firstCondition as string],
      operator: firstConditionOperator,
    });

    if (!isEmpty(secondCondition)) {
      conditions.push({
        values: isNumber(secondCondition) ? [secondCondition as number] : [secondCondition as string],
        operator: secondConditionOperator,
      });
    }

    filter.logicalOperator = logicalOperator;
    filter.conditions = conditions;
    setFilterValue(filter);
  }, [firstCondition, secondCondition, firstConditionOperator, secondConditionOperator]);

  const getInputProps = (unitType: UnitType | undefined, currencyPosition: CurrencyPosition, currencySymbol: string) => {
    if (!unitType) {
      return {};
    }

    if (unitType === UnitType.PERCENTAGE) {
      return { endAdornment: <InputAdornment position="end">%</InputAdornment> };
    }

    if (unitType === UnitType.CURRENCY) {
      return currencyPosition === CurrencyPosition.LEFT
        ? { startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment> }
        : { endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment> };
    }

    return {};
  };

  let initialOperator = BetweenOperatorType.BETWEEN;
  if (filter.conditions) {
    const operator = filter.conditions[0].operator;
    if (filter.conditions.length > 1) {
      initialOperator = BetweenOperatorType.BETWEEN;
    } else if (operator == OperatorType.LESS_THAN) {
      initialOperator = BetweenOperatorType.LESS_THAN;
    } else if (operator == OperatorType.LESS_THAN_OR_EQUAL) {
      initialOperator = BetweenOperatorType.LESS_THAN_OR_EQUAL;
    } else if (operator == OperatorType.EQUAL) {
      initialOperator = BetweenOperatorType.EQUAL;
    } else if (operator == OperatorType.GREATER_THAN) {
      initialOperator = BetweenOperatorType.GREATER_THAN;
    } else if (operator == OperatorType.GREATER_THAN_OR_EQUAL) {
      initialOperator = BetweenOperatorType.GREATER_THAN_OR_EQUAL;
    }
  }

  const [defaultBetweenOperator, setDefaultBetweenOperator] = useState<BetweenOperatorType | null>();
  if (isNil(defaultBetweenOperator)) {
    setDefaultBetweenOperator(initialOperator);
  }

  const [betweenOperator, setBetweenOperator] = useState<BetweenOperatorType>(initialOperator);

  const handleOperatorChange = (event: SelectChangeEvent) => {
    const operator = event.target.value;
    setLogicalOperator(LogicalOperatorType.AND);
    setBetweenOperator(operator as BetweenOperatorType);

    if (operator == BetweenOperatorType.LESS_THAN) {
      setFirstConditionOperator(OperatorType.LESS_THAN);
      setSecondCondition('');
    } else if (operator == BetweenOperatorType.LESS_THAN_OR_EQUAL) {
      setFirstConditionOperator(OperatorType.LESS_THAN_OR_EQUAL);
      setSecondCondition('');
    } else if (operator == BetweenOperatorType.GREATER_THAN) {
      setFirstConditionOperator(OperatorType.GREATER_THAN);
      setSecondCondition('');
    } else if (operator == BetweenOperatorType.GREATER_THAN_OR_EQUAL) {
      setFirstConditionOperator(OperatorType.GREATER_THAN_OR_EQUAL);
      setSecondCondition('');
    } else if (operator == BetweenOperatorType.BETWEEN) {
      setFirstConditionOperator(OperatorType.GREATER_THAN_OR_EQUAL);
      setSecondConditionOperator(OperatorType.LESS_THAN_OR_EQUAL);
    } else if (operator == BetweenOperatorType.EQUAL) {
      setFirstConditionOperator(OperatorType.EQUAL);
      setSecondCondition('');
    }
  };

  return (
    <div className="flex flex-row gap-2 w-full">
      <div>
        {defaultBetweenOperator && (
          <BetweenOperatorSelect
            label={'Operator'}
            isDisabled={isOperatorDisabled}
            filterKey={filter.key}
            defaultValue={defaultBetweenOperator}
            handleChange={handleOperatorChange}
          />
        )}
      </div>

      <div className="flex-1">
        <TextField
          id={'first-condition-' + filter.key}
          label={(betweenOperator == BetweenOperatorType.BETWEEN ? 'Min ' : '') + filter.shortName}
          variant="outlined"
          defaultValue={firstCondition}
          onChange={handleFirstConditionChange}
          className="flex-1 w-full"
          placeholder="No Value"
          slotProps={{
            input: getInputProps(filter.unitType, currencyPosition, currencySymbol),
          }}
        />
      </div>

      {betweenOperator == BetweenOperatorType.BETWEEN && <div className={'mt-3'}>to</div>}
      {betweenOperator == BetweenOperatorType.BETWEEN && (
        <div className="flex-1">
          <TextField
            id={'second-condition-' + filter.key}
            label={'Max ' + filter.shortName}
            variant="outlined"
            defaultValue={secondCondition}
            onChange={handleSecondConditionChange}
            className="flex-1"
            placeholder="No Value"
            slotProps={{
              input: getInputProps(filter.unitType, currencyPosition, currencySymbol),
            }}
          />
        </div>
      )}
    </div>
  );
};
