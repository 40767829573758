import { Button } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { useDashboardQueries } from '../../hooks/useDashboardQueries';
import { IDashboardWidgetBaseConfiguration } from '../../types/IDashboardWidgetBaseConfiguration';

interface DashboardWidgetConfigurationDrawerApplyButtonProps {
  onCloseDrawer: () => void;
}

export const DashboardWidgetConfigurationDrawerApplyButton: FunctionComponent<DashboardWidgetConfigurationDrawerApplyButtonProps> = ({
  onCloseDrawer,
}) => {
  const { widgetId, modifiedConfiguration } = useContext(DashboardWidgetContext);

  const updateWidgetConfiguration = useDashboardContextValue((context) => context.updateWidgetConfiguration);
  const { refetchForWidgetWithId } = useDashboardQueries();

  async function onApplyWidgetClicked() {
    if (widgetId && modifiedConfiguration) {
      await updateWidgetConfiguration<IDashboardWidgetBaseConfiguration>(widgetId, modifiedConfiguration, true);
      refetchForWidgetWithId(widgetId);
      onCloseDrawer();
    }
  }

  return (
    <Button variant="contained" color="primary" className="w-full" size="large" onClick={onApplyWidgetClicked}>
      Apply
    </Button>
  );
};
