export enum GroupByField {
  AD_TYPE = 'AD_TYPE',
  PLACEMENT_TYPE = 'PLACEMENT_TYPE',
  ASIN = 'ASIN',
  PARENT_ASIN = 'PARENT_ASIN',
  SKU = 'SKU',
  CATEGORY = 'CATEGORY',
  BRAND = 'BRAND',
  AVAILABILITY = 'AVAILABILITY',
}
