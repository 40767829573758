import MetricTimelineChart from '@/components/chart/MetricTimelineChart';
import { createPlacementsWithTimelineQueryKey, placementsService } from '@/modules/placements/api/placements-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { FunctionComponent } from 'react';
import { usePlacementsContext } from '../contexts/PlacementsContext';

const PlacementsTimelineChart: FunctionComponent = () => {
  const { filters, visibleMetrics } = usePlacementsContext();
  const { activeProfile } = useActiveTeamContext();

  const {
    data: placementsWithTimeline,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: createPlacementsWithTimelineQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await placementsService.getPlacementsWithTimeline(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading timeline data ' + JSON.stringify(result));
      }
    },

    enabled: !isEmpty(visibleMetrics.filter((metric) => metric.isSelected)) && !isEmpty(filters),
    retry: false,
  });

  return (
    <MetricTimelineChart
      visibleMetrics={visibleMetrics}
      timelineData={placementsWithTimeline?.timeline}
      isLoading={isLoading}
      error={error}
      isError={isError}
    />
  );
};

export default PlacementsTimelineChart;
