import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiTeamClient } from '@/lib/api/base-client';
import { UserModel } from '../../types/UserModel';
import {
  AuthorizeUserDTO,
  UpdateUserNameDTO,
  UpdateUserPasswordDTO,
  RegisterUserDTO,
  UserAndTeamCreateResponse,
  UserCreateDTO,
  UserDTO,
  UserGleapDataDTO,
  UserMetadataDTO,
  CheckEmailDTO,
  AdsAuthorizeUserDTO,
  UpdateUserPhoneDTO,
  VendorAuthorizationDTO,
} from './users.contracts';
import axios from 'axios';
import { Environment } from '@/config/Environment';
import { AccountType } from '@/modules/registration/types/wizard_step_state.enum';

export enum HookEvent {
  SIGN_UP = 'SIGN_UP',
  ABOUT_YOU = 'ABOUT_YOU',
  CONNECT_AMAZON = 'CONNECT_AMAZON',
  ADD_PROFILE = 'ADD_PROFILE',
  SUCCESSFULLY_BOOKED_MEETING = 'SUCCESSFULLY_BOOKED_MEETING',
}

class UserService {
  public basePath = 'users';

  async getUsers(): Promise<ApiResponse<UserModel[]>> {
    const response = await apiTeamClient.get<UserDTO[]>(this.basePath + '/');
    return response.processPayload((p) => p.map(UserModel.fromDTO));
  }

  async getUserById(userId: number): Promise<ApiResponse<UserModel>> {
    const response = await apiClient.get<UserDTO>(`${this.basePath}/${userId}`);
    return response.processPayload(UserModel.fromDTO);
  }

  async checkIfEmailExists(email: string): Promise<ApiResponse<CheckEmailDTO>> {
    return await apiClient.get<CheckEmailDTO>(`${this.basePath}/check-email/${email}`);
  }

  async getUserByFirebaseId(firebaseId: string): Promise<ApiResponse<UserModel>> {
    const response = await apiClient.get<UserDTO>(`${this.basePath}/f/${firebaseId}`);
    return response.processPayload(UserModel.fromDTO);
  }

  async createUserAndAddToTeam(teamId: number, newUser: UserCreateDTO): Promise<ApiResponse<null>> {
    return await apiClient.post(`teams/${teamId}/${this.basePath}`, newUser);
  }

  async createUserAndTeam(request: RegisterUserDTO): Promise<ApiResponse<UserAndTeamCreateResponse>> {
    return await apiClient.post<UserAndTeamCreateResponse>(`${this.basePath}/register-user`, request);
  }

  async updateUserName(userId: number, payload: UpdateUserNameDTO): Promise<ApiResponse<UserDTO>> {
    return await apiClient.post<UserDTO>(`${this.basePath}/update/${userId}`, payload);
  }

  async updateUserPhone(userId: number, payload: UpdateUserPhoneDTO): Promise<ApiResponse<UserDTO>> {
    return await apiClient.post<UserDTO>(`${this.basePath}/update/${userId}`, payload);
  }

  async updateUserPassword(userId: number, payload: UpdateUserPasswordDTO): Promise<ApiResponse<UserDTO>> {
    return await apiClient.post<UserDTO>(`${this.basePath}/update/${userId}`, payload);
  }

  async getAmazonAuthorizationUrl(userId: number, teamId: number, is_registration: boolean = false): Promise<ApiResponse<string>> {
    return await apiClient.get<string>(
      `teams/${teamId}/users/${userId}/amazon-authorization-url${is_registration ? '?type=registration' : ''}`,
    );
  }

  async authorizeUser(teamId: number, userId: number, payload: AdsAuthorizeUserDTO): Promise<ApiResponse<null>> {
    return await apiClient.post(`teams/${teamId}/users/${userId}/amazon-authorization`, payload);
  }

  async getSellerCentralAuthorizationUrl(marketCountryCode: string): Promise<ApiResponse<string>> {
    return await apiTeamClient.get<string>(`users/sellercentral-authorization-url?market=${marketCountryCode}`);
  }

  async authorizeSellerCentral(teamId: number, profileId: string, payload: AuthorizeUserDTO): Promise<ApiResponse<null>> {
    return await apiClient.post(`teams/${teamId}/profiles/${profileId}/sellercentral-authorization`, payload);
  }

  async getVendorCentralAuthorizationUrl(marketCountryCode: string): Promise<ApiResponse<string>> {
    return await apiTeamClient.get<string>(`users/vendorcentral-authorization-url?market=${marketCountryCode}`);
  }
  async authorizeVendorCentral(
    teamId: number,
    sellingPartnerId: string,
    profileIds: string[],
    authUserPayload: AuthorizeUserDTO,
  ): Promise<ApiResponse<null>> {
    const payload: VendorAuthorizationDTO = {
      ...authUserPayload,
      selling_partner_id: sellingPartnerId,
      profile_ids: profileIds,
    };

    return await apiClient.post(`teams/${teamId}/vendorcentral-authorization`, payload);
  }

  async getCustomTokenForFirebaseId(firebaseUserId: string): Promise<ApiResponse<string>> {
    return await apiClient.post<string>(`${this.basePath}/impersonate?impersonationFirebaseID=${firebaseUserId}`, {});
  }

  async isEmailInWaitList(email: string): Promise<boolean> {
    if (Environment.isDev()) {
      return true;
    }

    try {
      const axiosResponse = await axios.post('https://hook.eu2.make.com/fn9hquw2laxgu7n597a9y1non153dswf', {
        email,
      });
      return axiosResponse && axiosResponse.status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async updateUserWithMetadata(userId: number, metadata: UserMetadataDTO): Promise<ApiResponse<UserDTO>> {
    return await apiClient.post<UserDTO>(`${this.basePath}/${userId}/metadata`, metadata);
  }

  async sendNewUserDataToHook(dto: {
    event: HookEvent;
    timezone: string;
    name: string;
    email: string;
    firebaseId: string;
    phone?: string;
    accountType?: AccountType;
    averageAdSpend?: string;
    hearAboutAdlabs?: string;
  }): Promise<boolean> {
    try {
      const axiosResponse = await axios.post('https://hook.eu2.make.com/1h7m9njrnle7wt6i21afrg3wrolcdihj', {
        dto,
      });
      return axiosResponse && axiosResponse.status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getUserGleapData(userId: number, forceUpdate: boolean): Promise<ApiResponse<UserGleapDataDTO>> {
    return await apiClient.get<UserGleapDataDTO>(`${this.basePath}/${userId}/gleap-data?forceUpdate=${forceUpdate}`);
  }
}

export const userService = new UserService();
