import ExportGridButton from '@/components/buttons/ExportGridButton';
import FormatterToggleButton from '@/components/buttons/FormatterToggleButton';
import SortByToggleButton from '@/components/buttons/SortByToggleButton';
import UnitToggleButton from '@/components/buttons/UnitToggleButton';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';
import { ComparisonUnit, FormatterType, GridToggles, SortByUnit } from '../types';

interface GridToolsProps {
  gridToggles: GridToggles;
  setGridToggles: React.Dispatch<React.SetStateAction<GridToggles>>;
  gridApiRef: React.MutableRefObject<GridApi<unknown> | null>;
  overlayColumnGrouping?: boolean;
}

const GridTools: FunctionComponent<GridToolsProps> = ({ gridToggles, setGridToggles, gridApiRef, overlayColumnGrouping = false }) => {
  const handleComparisonUnitChange = (newValue: ComparisonUnit) => {
    setGridToggles((previous) => ({ ...previous, comparisonUnit: newValue }));
  };

  const handleSortChange = (newValue: SortByUnit) => {
    setGridToggles((previous) => ({ ...previous, sortByUnit: newValue }));
  };

  const handleFormatterChange = (newValue: FormatterType) => {
    setGridToggles((previous) => ({ ...previous, formatterType: newValue }));
  };
  return (
    <div className={`absolute -bottom-[37px] right-2 justify-between z-10 flex gap-x-4 bg-gray-50 ${overlayColumnGrouping && 'left-0'}`}>
      <div className="flex-1"></div>
      <div className="flex gap-x-4">
        {gridToggles.comparisonUnit && (
          <UnitToggleButton handleComparisonUnitChange={handleComparisonUnitChange} comparisonUnit={gridToggles.comparisonUnit} />
        )}
        {gridToggles.sortByUnit && gridToggles.comparisonUnit && (
          <SortByToggleButton
            handleSortChange={handleSortChange}
            sortBy={gridToggles.sortByUnit}
            comparisonUnit={gridToggles.comparisonUnit}
          />
        )}
        {gridToggles.formatterType && (
          <FormatterToggleButton handleFormatterChange={handleFormatterChange} formatterType={gridToggles.formatterType} />
        )}
        <ExportGridButton gridApiRef={gridApiRef} />
      </div>
    </div>
  );
};

export default GridTools;
