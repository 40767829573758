export const DEFAULT_BID_DECIMALS = 2;

export type BidLimits = {
  minBidSP: number;
  maxBidSP: number;
  minBidSBCPC: number;
  maxBidSBCPC: number;
  minBidSBVCPC: number;
  maxBidSBVCPC: number;
  minBidSDCPC: number;
  maxBidSDCPC: number;
  decimals?: number;
};

export type MarketplaceBidLimits = Record<string, BidLimits>;

export type MinMaxLimits = {
  min: number;
  max: number;
};

export const marketplaceBidLimits: MarketplaceBidLimits = {
  MX: {
    minBidSP: 0.1,
    maxBidSP: 20000,
    minBidSBCPC: 0.1,
    maxBidSBCPC: 20000,
    minBidSBVCPC: 0.15,
    maxBidSBVCPC: 20000,
    minBidSDCPC: 0.1,
    maxBidSDCPC: 20000,
  },
  UK: {
    minBidSP: 0.02,
    maxBidSP: 1000,
    minBidSBCPC: 0.1,
    maxBidSBCPC: 31,
    minBidSBVCPC: 0.15,
    maxBidSBVCPC: 31,
    minBidSDCPC: 0.02,
    maxBidSDCPC: 1000,
  },
  DE: {
    minBidSP: 0.02,
    maxBidSP: 1000,
    minBidSBCPC: 0.1,
    maxBidSBCPC: 39,
    minBidSBVCPC: 0.15,
    maxBidSBVCPC: 39,
    minBidSDCPC: 0.02,
    maxBidSDCPC: 1000,
  },
  CA: {
    minBidSP: 0.02,
    maxBidSP: 1000,
    minBidSBCPC: 0.1,
    maxBidSBCPC: 49,
    minBidSBVCPC: 0.15,
    maxBidSBVCPC: 49,
    minBidSDCPC: 0.02,
    maxBidSDCPC: 1000,
  },
  AU: {
    minBidSP: 0.1,
    maxBidSP: 1410,
    minBidSBCPC: 0.1,
    maxBidSBCPC: 70,
    minBidSBVCPC: 0.15,
    maxBidSBVCPC: 70,
    minBidSDCPC: 0.2,
    maxBidSDCPC: 1000,
  },
  US: {
    minBidSP: 0.02,
    maxBidSP: 1000,
    minBidSBCPC: 0.1,
    maxBidSBCPC: 49,
    minBidSBVCPC: 0.25,
    maxBidSBVCPC: 49,
    minBidSDCPC: 0.02,
    maxBidSDCPC: 1000,
  },
  FR: {
    minBidSP: 0.02,
    maxBidSP: 1000,
    minBidSBCPC: 0.1,
    maxBidSBCPC: 39,
    minBidSBVCPC: 0.15,
    maxBidSBVCPC: 39,
    minBidSDCPC: 0.02,
    maxBidSDCPC: 1000,
  },
  ES: {
    minBidSP: 0.02,
    maxBidSP: 1000,
    minBidSBCPC: 0.1,
    maxBidSBCPC: 39,
    minBidSBVCPC: 0.15,
    maxBidSBVCPC: 39,
    minBidSDCPC: 0.02,
    maxBidSDCPC: 1000,
  },
  IT: {
    minBidSP: 0.02,
    maxBidSP: 1000,
    minBidSBCPC: 0.1,
    maxBidSBCPC: 39,
    minBidSBVCPC: 0.15,
    maxBidSBVCPC: 39,
    minBidSDCPC: 0.02,
    maxBidSDCPC: 1000,
  },
  NL: {
    minBidSP: 0.02,
    maxBidSP: 1000,
    minBidSBCPC: 0.1,
    maxBidSBCPC: 39,
    minBidSBVCPC: 0.15,
    maxBidSBVCPC: 39,
    minBidSDCPC: 0.02,
    maxBidSDCPC: 1000,
  },
  JP: {
    minBidSP: 2,
    maxBidSP: 100000,
    minBidSBCPC: 10,
    maxBidSBCPC: 7760,
    minBidSBVCPC: 15,
    maxBidSBVCPC: 7760,
    minBidSDCPC: 2,
    maxBidSDCPC: 100000,
    decimals: 0,
  },
  AE: {
    minBidSP: 0.24,
    maxBidSP: 184,
    minBidSBCPC: 0.4,
    maxBidSBCPC: 184,
    minBidSBVCPC: 0.6,
    maxBidSBVCPC: 184,
    minBidSDCPC: 0.2,
    maxBidSDCPC: 3670,
  },
  BR: {
    minBidSP: 0.07,
    maxBidSP: 3700,
    minBidSBCPC: 0.53,
    maxBidSBCPC: 200,
    minBidSBVCPC: 0.8,
    maxBidSBVCPC: 25000,
    minBidSDCPC: 0.07,
    maxBidSDCPC: 3700,
  },
  SG: {
    minBidSP: 0.02,
    maxBidSP: 1100,
    minBidSBCPC: 0.14,
    maxBidSBCPC: 100,
    minBidSBVCPC: 0.2,
    maxBidSBVCPC: 1400,
    minBidSDCPC: 0.14,
    maxBidSDCPC: 1410,
  },
  SE: {
    minBidSP: 0.18,
    maxBidSP: 9300,
    minBidSBCPC: 0.9,
    maxBidSBCPC: 500,
    minBidSBVCPC: 1.3,
    maxBidSBVCPC: 500,
    minBidSDCPC: 0.18,
    maxBidSDCPC: 1000,
  },
  IN: {
    minBidSP: 1,
    maxBidSP: 5000,
    minBidSBCPC: 1,
    maxBidSBCPC: 500,
    minBidSBVCPC: 1.5,
    maxBidSBVCPC: 500,
    minBidSDCPC: 1,
    maxBidSDCPC: 5000,
  },
  PL: {
    minBidSP: 0.04,
    maxBidSP: 2000,
    minBidSBCPC: 0.2,
    maxBidSBCPC: 200,
    minBidSBVCPC: 0.3,
    maxBidSBVCPC: 200,
    minBidSDCPC: 0.02,
    maxBidSDCPC: 1000,
  },
  TR: {
    minBidSP: 0.05,
    maxBidSP: 2500,
    minBidSBCPC: 0.2,
    maxBidSBCPC: 200,
    minBidSBVCPC: 0.3,
    maxBidSBVCPC: 200,
    minBidSDCPC: 0.05,
    maxBidSDCPC: 2500,
  },
  EG: {
    minBidSP: 0.15,
    maxBidSP: 5.5,
    minBidSBCPC: 0.7,
    maxBidSBCPC: 400,
    minBidSBVCPC: 1,
    maxBidSBVCPC: 400,
    minBidSDCPC: 0.15,
    maxBidSDCPC: 7400,
  },
  SA: {
    minBidSP: 0.1,
    maxBidSP: 3670,
    minBidSBCPC: 0.4,
    maxBidSBCPC: 184,
    minBidSBVCPC: 0.6,
    maxBidSBVCPC: 184,
    minBidSDCPC: 0.1,
    maxBidSDCPC: 3670,
  },
  BE: {
    minBidSP: 0.02,
    maxBidSP: 1000,
    minBidSBCPC: 0.1,
    maxBidSBCPC: 39,
    minBidSBVCPC: 0.15,
    maxBidSBVCPC: 39,
    minBidSDCPC: 0.02,
    maxBidSDCPC: 1000,
  },
};

export const placementsBidLimits = {
  minSP: 0,
  maxSP: 900,
  minSB: -99,
  maxSB: 900,
};
