import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { DTO } from '@/types/dto-wrapper';
import { CurrencyRatesDTO } from '../api/currency.contracts';

export class CurrencyRatesModel extends DTO<CurrencyRatesDTO> {
  constructor(dto: CurrencyRatesDTO) {
    super(dto);
  }

  public static fromResponse(dto: CurrencyRatesDTO): CurrencyRatesModel {
    return new CurrencyRatesModel(dto);
  }

  /**
   * Converts a value from one currency to another using the rates in the DTO.
   * @param value - The amount to convert.
   * @param fromCurrencyCode - The currency code of the value.
   * @param toCurrencyCode - The currency code to convert to.
   * @returns The converted value.
   */
  public convertValueFromTo(value: number, fromCurrencyCode: CurrencyCode, toCurrencyCode: CurrencyCode): number {
    const fromRate = this.dto[fromCurrencyCode];
    const toRate = this.dto[toCurrencyCode];
    if (fromRate === undefined || toRate === undefined) {
      throw new Error(`Invalid currency code: ${fromCurrencyCode} or ${toCurrencyCode}`);
    }

    // Conversion logic: Convert from the base currency to the target currency
    const convertedValue = (value / fromRate) * toRate;
    return convertedValue;
  }
}
