import { FunctionComponent, MouseEvent, PropsWithChildren, ReactElement } from 'react';
import { To, Link } from 'react-router';

interface TextLinkProps extends PropsWithChildren {
  to?: To;
  onClick?: () => void;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  fullWidth?: boolean;
  className?: string;
}

const TextLink: FunctionComponent<TextLinkProps> = ({ children, to, onClick, leftIcon, rightIcon, fullWidth = true, className }) => {
  function handleClick(e: MouseEvent) {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  }

  return (
    <Link
      to={to ?? '/'}
      className={`${
        fullWidth ? 'flex w-full' : 'inline-flex'
      } items-center text-inherit no-underline hover:text-primary-600 dark:hover:text-primary-700  ${className} `}
      onClick={handleClick}
    >
      {leftIcon && <div className="flex pr-1">{leftIcon}</div>}
      {children}
      {rightIcon && <div className="flex pl-1">{rightIcon}</div>}
    </Link>
  );
};

export default TextLink;
