import useFormatting from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { BidCeilingType } from '@/modules/optimizer/types/BidCeilingType';
import { BidFloorType } from '@/modules/optimizer/types/BidFloorType';
import { SmartBidCeilingType } from '@/modules/optimizer/types/SmartBidCeilingType';
import { toastService } from '@/services/toast.service';
import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useState } from 'react';
import { ChangeCampaignGroupDTO, NewCampaignGroupDTO } from '../../../api/campaign/campaign-contracts';
import { campaignService } from '../../../api/campaign/campaign-service';
import { CampaignGroupModel, getDefaultValues } from '../../../api/campaign/models/CampaignGroupModel';
import { SelectedCampaignDTO } from '../../../api/campaign/models/CampaignModel';
import { BidLimitChangeUnitType } from '../../optimization/api/optimization-contracts';
import { OptimizationPreset, availableOptimizationPresets } from '../../optimization/OptimizerConfig';

interface CreateUpdateCampaignGroupProps {
  campaignGroup?: CampaignGroupModel;
  selectedCampaigns?: SelectedCampaignDTO[];
  existingGroups: CampaignGroupModel[];
  onClose: () => void;
  applyChanges?: (changeGroups: ChangeCampaignGroupDTO[], isNewGroup?: boolean) => void;
  isApplyPending?: boolean;
  onSuccess: (campaignGroup?: CampaignGroupModel) => void;
  flowType: FlowType;
}

export const CreateUpdateCampaignGroup: FunctionComponent<CreateUpdateCampaignGroupProps> = ({
  campaignGroup,
  selectedCampaigns,
  existingGroups,
  onClose,
  applyChanges,
  isApplyPending = false,
  onSuccess,
  flowType,
}) => {
  const { t } = useTranslation();

  const { getCurrencySymbol } = useFormatting();
  const currencySymbol = getCurrencySymbol();

  // TODO: Refactor. Make params as single object or use campaign group model. If not provided create new class
  const defaultValues = getDefaultValues(campaignGroup);

  const [groupName, setGroupName] = useState(defaultValues.groupName);
  const [nameError, setNameError] = useState(false);
  const [targetAcos, setTargetACOS] = useState<string>(defaultValues.tacos);
  const [prioritization, setPrioritization] = useState<OptimizationPreset>(defaultValues.prioritization);
  const [bidCeilingType, setBidCeilingType] = useState<BidCeilingType>(defaultValues.bidCeilingType);
  const [smartBidCeilingType, setSmartBidCeilingType] = useState<SmartBidCeilingType>(defaultValues.smartBidCeilingType);
  const [bidFloorType, setBidFloorType] = useState<BidFloorType>(defaultValues.bidFloorType);
  const [bidCeiling, setBidCeiling] = useState<string>(defaultValues.bidCeiling);
  const [bidFloor, setBidFloor] = useState<string>(defaultValues.bidFloor);
  const [optimizationEnabled, setOptimizationEnabled] = useState<boolean>(defaultValues.optimizationEnabled);

  const checkGroupNameExists = (name: string): boolean => {
    return !isNil(existingGroups) && existingGroups.some((group) => group.name === name);
  };

  const handleGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setGroupName(name);
    setNameError(checkGroupNameExists(name));
  };

  const handleTargetACOSChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setTargetACOS(value);
    }
  };

  const handlePrioritizationChange = (event: SelectChangeEvent<OptimizationPreset>) => {
    setPrioritization(event.target.value as OptimizationPreset);
  };

  const handleBidCeilingOptionChange = (event: SelectChangeEvent<BidCeilingType>) => {
    setBidCeilingType(event.target.value as BidCeilingType);
  };

  const handleBidCeilingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setBidCeiling(value);
    }
  };

  const handleBidFloorOptionChange = (event: SelectChangeEvent<BidFloorType>) => {
    setBidFloorType(event.target.value as BidFloorType);
  };

  const handleBidFloorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setBidFloor(value);
    }
  };

  const handleSmartBidCeilingChange = (event: SelectChangeEvent<SmartBidCeilingType>) => {
    setSmartBidCeilingType(event.target.value as SmartBidCeilingType);
  };

  // const handleOptimizationEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { checked, type } = event.target;

  //   if (type === 'checkbox') {
  //     setOptimizationEnabled(checked);
  //   }
  // };

  const handleCreateUpdate = async () => {
    const numberTargetAcos = Number(targetAcos);

    const bidCeilingValue = !isNaN(Number(bidCeiling)) && bidCeiling != '' ? Number(bidCeiling) : undefined;
    const bidFloorValue = !isNaN(Number(bidFloor)) && bidFloor != '' ? Number(bidFloor) : undefined;

    // validate only if optimize is ON
    if (optimizationEnabled) {
      // TODO: Use marketplace bid limits, see bid-limits.ts
      if (bidCeilingType == BidCeilingType.MANUAL && (!bidCeilingValue || (bidCeilingValue && bidCeilingValue < 0.01))) {
        toastService.error('Bid Ceiling cannot be less than 0.01');
        return;
      }

      // TODO: Use marketplace bid limits, see bid-limits.ts
      if (bidFloorType == BidFloorType.MANUAL && (!bidFloorValue || (bidFloorValue && bidFloorValue < 0.01))) {
        toastService.error('Bid Floor cannot be less than 0.01');
        return;
      }

      if (
        bidFloorType == BidFloorType.MANUAL &&
        bidCeilingType == BidCeilingType.MANUAL &&
        bidFloorValue &&
        bidCeilingValue &&
        bidFloorValue >= bidCeilingValue
      ) {
        toastService.error('Bid Floor cannot be larger than Bid Ceiling');
        return;
      }
    }

    // TODO: Add these fields to creation
    // placement_max_increase?: number;
    // placement_max_decrease?: number;
    // bid_max_increase?: MaxChange;
    // bid_max_decrease?: MaxChange;
    // bid_max_increase_off?: boolean;
    // bid_max_decrease_off?: boolean;
    // placement_max_increase_off?: boolean;
    // placement_max_decrease_off?: boolean;

    const groupData: NewCampaignGroupDTO = {
      name: groupName,
      tacos: !isNaN(numberTargetAcos) && targetAcos != '' ? numberTargetAcos / 100 : undefined, // always in fractions when not displaying
      preset: !isEmpty(prioritization) && prioritization != OptimizationPreset.NOT_SET ? prioritization : undefined,
      no_optimize: !optimizationEnabled,
      bid_floor_off: bidFloorType == BidFloorType.OFF,
      bid_ceiling_off: bidCeilingType == BidCeilingType.OFF,
    };

    if (bidFloorType == BidFloorType.MANUAL) {
      groupData.bid_floor = bidFloorValue;
    }

    if (bidCeilingType == BidCeilingType.MANUAL && bidCeilingValue) {
      groupData.bid_ceiling = {
        unit: BidLimitChangeUnitType.CURRENCY,
        value: bidCeilingValue,
      };
    }

    if (bidCeilingType == BidCeilingType.SMART) {
      let smartBidCeilingValue = 1;
      switch (smartBidCeilingType) {
        case SmartBidCeilingType.TARGET_CPC_1X:
          smartBidCeilingValue = 1;
          break;
        case SmartBidCeilingType.TARGET_CPC_2X:
          smartBidCeilingValue = 2;
          break;
        case SmartBidCeilingType.TARGET_CPC_3X:
          smartBidCeilingValue = 3;
          break;
      }

      groupData.bid_ceiling = {
        unit: BidLimitChangeUnitType.TIMES_CPC,
        value: smartBidCeilingValue,
      };
    }

    if (campaignGroup) {
      // TODO: Refactor
      campaignGroup.name = groupData.name;
      campaignGroup.tacos = groupData.tacos;
      campaignGroup.preset = groupData.preset;
      campaignGroup.optimizationEnabled = !groupData.no_optimize;
      campaignGroup.bidFloorOff = groupData.bid_floor_off;
      campaignGroup.bidCeilingOff = groupData.bid_ceiling_off;
      campaignGroup.bidCeiling = groupData.bid_ceiling;
      campaignGroup.bidFloor = groupData.bid_floor;

      await updateCampaignGroup(campaignGroup);
    } else {
      createGroup(groupData);
    }

    onClose();
  };

  async function updateCampaignGroup(campaignGroupModel: CampaignGroupModel) {
    const res = await campaignService.updateGroups([campaignGroupModel]);
    if (res.isSuccess) {
      toastService.success(`Optimization group updated successfully`);
      onSuccess();
    } else {
      toastService.error(`Did not receive a response from server: ${res.message}`);
    }
  }

  // TODO: Refactor. Remove useMutation
  const { mutate: createGroup, isPending: isLoadingCreateGroup } = useMutation({
    mutationFn: (groupData: NewCampaignGroupDTO) => campaignService.createGroups([groupData], flowType),
    onSuccess: (res) => {
      if (res.isSuccess) {
        const newGroup = res.payload[0];
        if (selectedCampaigns && selectedCampaigns.length > 0 && applyChanges) {
          const changeGroups: ChangeCampaignGroupDTO[] = selectedCampaigns.map((campaign) => ({
            campaign_id: campaign.id,
            group_id: newGroup.id,
          }));

          applyChanges(changeGroups, true);
        } else {
          toastService.success(`Optimization group created successfully`);
        }

        onSuccess(newGroup);
      } else {
        toastService.error(`Did not receive a response from server: ${res.message}`);
      }
    },
  });

  const title = campaignGroup ? 'Edit Opt Group ' + campaignGroup?.name : 'Create New Optimization Group';

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off">
          <div className="flex flex-col">
            <div className="flex flex-row gap-2">
              <TextField
                className="min-w-64"
                autoFocus
                fullWidth
                error={nameError}
                label="Group Name"
                value={groupName}
                onChange={handleGroupNameChange}
                helperText={nameError ? 'This group already exists' : ''}
              />
            </div>

            <div className="flex flex-row gap-2">
              <TextField
                fullWidth
                label="Target ACOS"
                onChange={handleTargetACOSChange}
                value={targetAcos}
                placeholder={targetAcos === '' ? 'Not Set' : ''}
                disabled={!optimizationEnabled}
                slotProps={{
                  input: {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  },
                }}
              />

              <FormControl fullWidth>
                <InputLabel>Prioritization</InputLabel>
                <Select
                  value={prioritization}
                  onChange={handlePrioritizationChange}
                  label="Prioritization"
                  disabled={!optimizationEnabled}
                  className={`${prioritization == OptimizationPreset.NOT_SET ? 'text-gray-400' : ''}`}
                >
                  {availableOptimizationPresets.map((preset) => (
                    <MenuItem key={preset} value={preset}>
                      {t(`enums.optimization_presets.${preset}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* <CollapsableSection hideText="Hide Advanced" showText="Show Advanced"> */}
            <div className="flex flex-row gap-2">
              <FormControl className="w-1/2">
                <InputLabel>Bid Floor</InputLabel>
                <Select
                  fullWidth
                  label="Bid Floor"
                  value={bidFloorType}
                  onChange={handleBidFloorOptionChange}
                  displayEmpty
                  disabled={!optimizationEnabled}
                  className={`${bidFloorType == BidFloorType.NOT_SET ? 'text-gray-400' : ''}`}
                >
                  {Object.values(BidFloorType).map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(`enums.bid_floor_type.${option}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="w-1/2">
                {bidFloorType == BidFloorType.MANUAL && (
                  <TextField
                    fullWidth
                    defaultValue={bidFloor}
                    type="number"
                    onChange={handleBidFloorChange}
                    name="bidFloor"
                    label="Bid Floor"
                    disabled={!optimizationEnabled}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                      },
                    }}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <FormControl className="w-1/2">
                <InputLabel>Bid Ceiling</InputLabel>
                <Select
                  fullWidth
                  label="Bid Ceiling"
                  value={bidCeilingType}
                  onChange={handleBidCeilingOptionChange}
                  disabled={!optimizationEnabled}
                  displayEmpty
                  className={`${bidCeilingType == BidCeilingType.NOT_SET ? 'text-gray-400' : ''}`}
                >
                  {Object.values(BidCeilingType).map((option) => (
                    <MenuItem className="h-6" key={option} value={option}>
                      {t(`enums.bid_ceiling_type.${option}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="w-1/2">
                {bidCeilingType == BidCeilingType.MANUAL && (
                  <TextField
                    fullWidth
                    type="number"
                    onChange={handleBidCeilingChange}
                    name="bidCeiling"
                    value={bidCeiling}
                    label="Bid Ceiling"
                    disabled={!optimizationEnabled}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                      },
                    }}
                  />
                )}
                {bidCeilingType == BidCeilingType.SMART && (
                  <FormControl fullWidth>
                    <InputLabel>Smart Bid Ceiling</InputLabel>
                    <Select
                      fullWidth
                      displayEmpty
                      label="Smart Bid Ceiling"
                      value={smartBidCeilingType}
                      onChange={handleSmartBidCeilingChange}
                      disabled={!optimizationEnabled}
                    >
                      {Object.values(SmartBidCeilingType).map((option) => (
                        <MenuItem key={option} value={option}>
                          {t(`enums.smart_bid_ceiling_type.${option}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            </div>
            {/* <div className="flex flex-col">
                <div className="flex mt-2 ml-1">
                  <InputLabel>
                    Optimize campaigns in the group
                    <Tooltip title="Disable to avoid optimizing campaigns in this group">
                      <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
                    </Tooltip>
                  </InputLabel>
                </div>

                <div className="flex flex-row">
                  <FormGroup row>
                    <div className="flex items-center">
                      <Switch checked={optimizationEnabled} onChange={handleOptimizationEnabledChange} name="useGroupSettings" />
                      <span className={`ml-2 text-sm font-bold ${optimizationEnabled ? 'text-blue-600' : 'text-orange-500'}`}>
                        {optimizationEnabled ? 'Optimize campaigns' : "Don't optimize campaigns"}
                      </span>
                    </div>
                  </FormGroup>
                </div>
              </div> */}
            {/* </CollapsableSection> */}
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>

        <Tooltip title={groupName === '' ? 'Group Name not specified' : nameError ? 'Problem with Group Name' : ''}>
          <span>
            <LoadingButton
              onClick={handleCreateUpdate}
              loading={isApplyPending || isLoadingCreateGroup}
              loadingPosition="start"
              startIcon={<Check />}
              variant="contained"
              disabled={nameError || groupName === ''}
            >
              {campaignGroup ? (
                'Update'
              ) : (
                <span>
                  Create
                  {selectedCampaigns && selectedCampaigns.length > 0
                    ? ` and Assign to ${selectedCampaigns.length} Campaign` + (selectedCampaigns.length > 1 ? 's' : '')
                    : ''}
                </span>
              )}
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  );
};
