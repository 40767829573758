import { DARK_THEME } from '@/config/theme/dark-theme';
import { BASE_THEME } from '@/config/theme/light-theme';
import { useTheming } from '@/config/theme/useTheming';
import { ColorModeContext } from '@/modules/application';
import { ThemeProvider } from '@emotion/react';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
import { FunctionComponent, useContext, useRef, useState } from 'react';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import { CampaignGroupsPopover } from './campaign-groups/campaign-groups-popover/CampaignGroupsPopover';
import { Button } from '@mui/material';

interface OptGroupsButtonProps {
  selectedCampaigns: SelectedCampaignDTO[];
  setSelectedCampaigns: React.Dispatch<React.SetStateAction<SelectedCampaignDTO[]>>;
}

const OptGroupsButton: FunctionComponent<OptGroupsButtonProps> = ({ selectedCampaigns, setSelectedCampaigns }) => {
  // Optimization groups
  const [isOptimizationGroupsPopoverOpen, setIsOptimizationGroupsPopoverOpen] = useState(false);

  // Data groups
  const dropdownButtonRef = useRef<HTMLButtonElement | null>(null);

  // Theming, because it's inside OppositeModeOverlayBar, the theme needs to be opposite again
  const { mode } = useContext(ColorModeContext);
  const { colorMode } = useTheming();
  return (
    <>
      <Button
        ref={dropdownButtonRef}
        size="small"
        startIcon={<AutoAwesomeMotionIcon />}
        variant={'outlined'}
        onClick={() => setIsOptimizationGroupsPopoverOpen(true)}
      >
        Opt Groups
      </Button>
      <ColorModeContext.Provider
        value={{ toggleColorMode: colorMode.toggleColorMode, mode: mode === 'light' ? 'dark' : 'light', isDarkMode: mode === 'light' }}
      >
        <ThemeProvider theme={mode === 'light' ? DARK_THEME : BASE_THEME}>
          <CampaignGroupsPopover
            buttonRef={dropdownButtonRef}
            isOpen={isOptimizationGroupsPopoverOpen}
            setIsOpen={setIsOptimizationGroupsPopoverOpen}
            selectedCampaigns={selectedCampaigns}
            setSelectedCampaigns={setSelectedCampaigns}
          />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
};

export default OptGroupsButton;
