import { FunctionComponent, useState, useEffect, useContext } from 'react';
import RichTextEditor from '@/components/form/RichTextEditor';
import { TextWidgetConfiguration } from './TextWidgetConfiguration';
import { cloneDeep } from 'lodash-es';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';

interface TextWidgetConfigurationFormProps {
  configuration: TextWidgetConfiguration;
  id: string;
}

const TextWidgetConfigurationForm: FunctionComponent<TextWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);

  const [localConfiguration, setLocalConfiguration] = useState<TextWidgetConfiguration>(cloneDeep(configuration));

  // Update the modified configuration in the context whenever localConfiguration changes
  useEffect(() => {
    localConfiguration.isConfigured = true;
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration, setModifiedConfiguration]);

  const onContentChange = (html: string) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.content = html;
      return newConfig;
    });
  };

  return (
    <div>
      <RichTextEditor content={localConfiguration.content} onContentChange={onContentChange} />
    </div>
  );
};

export default TextWidgetConfigurationForm;
