import FilterControls from '@/components/filter-builder/FilterControls';
import { SearchBox } from '@/components/filter-builder/SearchBox';
import { SearchTermFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import GridTools from '@/components/grid/components/GridTools';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import ExpandToggleButton from '@/modules/application/components/ExpandToggleButton';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';
import { SearchTermsWithTimeline } from '../api/search-terms-contracts';
import { useSearchTermsContext } from '../contexts/SearchTermsContext';
import useAvailableSearchTermFilters from '../hooks/useAvailableSearchTermFilters';
import FilterBarCard from '@/components/cards/FilterBarCard';

interface SearchTermsFilterBarProps {
  withTimeline: SearchTermsWithTimeline | undefined;
  gridApiRef: React.MutableRefObject<GridApi<unknown> | null>;
  onExpandTable: () => void;
  isExpanded: boolean;
}

const SearchTermsFilterBar: FunctionComponent<SearchTermsFilterBarProps> = ({ withTimeline, gridApiRef, isExpanded, onExpandTable }) => {
  const { filters, setFilters, setFilterValue, setFilterValues, gridToggles, setGridToggles } = useSearchTermsContext();

  const { getComparisonDataMissingMessage } = useComparisonMissing({});

  const { availableSearchTermFilters: availableFilters } = useAvailableSearchTermFilters();
  const defaultFilterKey = FilterKey.SEARCH_TERM;

  return (
    <FilterBarCard>
      <SearchBox
        filterKey={FilterKey.SEARCH_TERM}
        newFilterModel={() => new SearchTermFilterModel()}
        placeholder="Search Term"
        filters={filters}
        setFilterValue={setFilterValue}
        setFilters={setFilters}
      />

      <FilterControls filters={filters} setFilters={setFilters} availableFilters={availableFilters} defaultFilterKey={defaultFilterKey} />

      <div className="flex flex-grow"></div>
      <div className="flex h-full justify-center items-center gap-x-3">
        <ExpandToggleButton isExpanded={isExpanded} onExpandToggled={onExpandTable} />

        <DateRangeButton
          title="Range"
          filters={filters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to stats"
          comparisonRangeWarning={getComparisonDataMissingMessage(withTimeline?.isComparisonDataMissing ?? false)}
        />
      </div>
      <GridTools gridToggles={gridToggles} setGridToggles={setGridToggles} gridApiRef={gridApiRef} />
    </FilterBarCard>
  );
};

export default SearchTermsFilterBar;
