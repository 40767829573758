import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiTeamClient } from '@/lib/api/base-client';
import { SellerCentralReportsStatusDTO } from './seller-central-contracts';

class SellerCentralService {
  public basePath = 'sellercentral';

  async createReport(profileId: string): Promise<ApiResponse<null>> {
    return await apiTeamClient.post(`profiles/${profileId}/${this.basePath}/reports/create`, {});
  }

  async getStatus(activeTeamId: number, profileId: string): Promise<ApiResponse<SellerCentralReportsStatusDTO>> {
    // Not using apiTeamClient because active team might not be set in apiClient yet
    return await apiClient.get<SellerCentralReportsStatusDTO>(`teams/${activeTeamId}/profiles/${profileId}/${this.basePath}/reports/status`);
  }
}

export const sellerCentralService = new SellerCentralService();
