import AlGrid, { DEFAULT_GRID_OPTIONS, GRID_DEFAULT_SORTING_ORDER } from '@/components/grid/AlGrid';
import ButtonCallbackCellRenderer, { IButtonCallbackCellRendererParams } from '@/components/grid/cells/ButtonCallbackCellRenderer';
import ButtonGroupCellRenderer, { IButtonGroupCellRendererParams } from '@/components/grid/cells/ButtonGroupCellRenderer';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import ToggleCellRenderer, { IToggleCellRendererParams } from '@/components/grid/cells/ToggleCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import RowActionButton from '@/components/grid/components/RowActionButton';
import DefaultHeaderRenderer from '@/components/grid/headers/DefaultHeaderRenderer';
import { AdLabsColorVariant, MuiColorVariant, TailwindColorVariant } from '@/config/theme/color.type';
import useFormatting from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { sleep } from '@/lib/api/api-utils';
import { AlDate } from '@/lib/date/AlDate';
import { getErrorMessage } from '@/modules/application/utils';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import useProfileSettingsUpdate from '@/modules/profiles-stats/hooks/useProfileSettingsUpdate';
import { ReportingStatusType, StatusType } from '@/modules/profiles/api/profile.contracts';
import { profileService } from '@/modules/profiles/api/profile.service';
import { ProfileReportsStatus, SyncStatusReason } from '@/modules/profiles/types/ProfileStatus';
import { sellerCentralService } from '@/modules/seller-central/api/seller-central-service';
import { targetingService } from '@/modules/targeting/api/targets-service';
import { useUserContext } from '@/modules/users';
import { CountryCode } from '@/modules/users/types/CountryCode';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { ProfileState } from '@/modules/users/types/ProfileState';
import { Timezone } from '@/modules/users/types/Timezone';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { getColorForProfileDataStatus } from '@/types/colors.enum';
import { Cached, FindInPage } from '@mui/icons-material';
import {
  ColDef,
  GridOptions,
  ICellRendererParams,
  NewValueParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { useReportsContext } from '../contexts/ReportsContext';

interface ProfileTableRow {
  profileId: string;
  profileName: string;
  state: ProfileState;
  billingStatus: boolean;
  market: CountryCode;
  currency: CurrencyCode;
  timezone: Timezone;
  type: string;
  onboardDate: string;
  reportData: string | undefined;
  isNotAuthorized: boolean;
  createSTVReports: boolean;
  teamId: number;
  sellerCentralAuthorizedAt?: string;
}

const ProfileTable = () => {
  const navigate = useNavigate();
  const { setActiveProfile, activeTeam } = useActiveTeamContext();
  const {
    updateProfileData,
    refetchProfileStatus,
    getReportStatusInfoByProfileId,
    getSellerCentralReportStatusInfoByProfileId,
    profileSyncStatus,
    getCombinedUpdatedAtByProfileId,
  } = useReportsContext();

  const { isAdminModeActive } = useUserContext();
  const { t } = useTranslation();

  const { formatDateStringTimeNoSeconds } = useFormatting();

  const { updateProfileSettings } = useProfileSettingsUpdate();

  const rowData: ProfileTableRow[] =
    activeTeam?.profiles?.map((profile) => ({
      profileId: profile.id,
      profileName: profile.nameWithMarket,
      state: profile.state,
      billingStatus: true,
      market: profile.countryCode,
      currency: profile.currencyCode,
      timezone: profile.timeZone,
      type: profile.type,
      onboardDate: profile.createdAt,
      reportData: undefined,
      isNotAuthorized: profile.isNotAuthorized,
      createSTVReports: profile.createSTVReports,
      sellerCentralAuthorizedAt: profile.sellerCentralAuthAt,
      teamId: activeTeam.id,
    })) || [];

  const { refetchUser } = useUserContext();

  async function onProfileNameClicked(profileRowData: ProfileTableRow) {
    try {
      if (!profileRowData.profileId) {
        return;
      }
      setActiveProfile(profileRowData.profileId);
      await sleep(50); // wait for 50 ms before navigate
      navigate(Routes.OPTIMIZER);
    } catch (error) {
      console.log(error);
      toastService.error('Unable to navigate to profile. Please try again later.');
    }
  }

  async function onUpdateProfileClicked(profileRowData: ProfileTableRow | undefined) {
    const profileId = profileRowData?.profileId;
    if (!profileId) {
      return;
    }

    try {
      if (profileSyncStatus(profileId).reason == SyncStatusReason.PLAN_RESTRICTION) {
        setIsPaywallModalOpen(true);
        return;
      }

      await updateProfileData(profileId);

      toastService.success('Report generation started successfully');
    } catch (error) {
      console.log(error);
      toastService.error(getErrorMessage(error));
    }
  }

  async function onDebugProfileClicked(profileRowData: ProfileTableRow | undefined) {
    const profileId = profileRowData?.profileId;
    if (activeTeam && profileId) {
      const res = await profileService.getReports(activeTeam.id, profileId);
      if (res.isSuccess) {
        console.log(res.payload);
      }
      toastService.success('Fetch success! Look console or network tab');
    } else {
      toastService.error('Profile ID not found');
    }
  }

  async function onRestartInitialReportsClicked(profileRowData: ProfileTableRow) {
    const profileId = profileRowData.profileId;
    if (!profileId) {
      return;
    }

    try {
      const response = await profileService.restartInitialReports(profileId);
      if (!response.isSuccess) {
        toastService.error(response.message);
        return;
      }
      toastService.success('Initial reports generation started successfully');

      const sellerCentralResponse = await sellerCentralService.createReport(profileId);
      if (!sellerCentralResponse.isSuccess) {
        toastService.error(sellerCentralResponse.message);
        return;
      }

      toastService.success('Seller central reports generation started successfully');

      refetchProfileStatus(profileId);
    } catch (error) {
      console.log(error);
      toastService.error('Unable to restart initial reports');
    }
  }

  async function onDeleteArchivedTargetsClicked(profileRowData: ProfileTableRow) {
    const profileId = profileRowData.profileId;
    if (!profileId) {
      return;
    }

    try {
      const response = await targetingService.deleteArchivedTargets(profileId);
      if (!response.isSuccess) {
        toastService.error(response.message);
        return;
      }
      toastService.success('Archived targets deleted successfully');

      refetchProfileStatus(profileId);
    } catch (error) {
      console.log(error);
      toastService.error('Unable to delete archived targets');
    }
  }

  const columnDefs: ColDefOrGroup<ProfileTableRow>[] = [
    {
      colId: ColumnId.PROFILE_NAME,
      headerName: 'Profile Name',
      minWidth: 150,
      cellRenderer: LinkCallbackCellRenderer,
      cellRendererParams: (params: ICellRendererParams<ProfileTableRow>): ILinkCallbackCellRendererParams<ProfileTableRow> => {
        return {
          buttonText: params.data?.profileName || '',
          tooltip: 'Start optimizing this Profile',
          tooltipPlacement: 'right',
          callback: onProfileNameClicked,
        };
      },
    },
    {
      colId: ColumnId.ACTIONS,
      headerName: 'Actions',
      minWidth: 140,
      cellRenderer: ButtonGroupCellRenderer,
      valueGetter: (params: ValueGetterParams<ProfileTableRow>) => {
        return getReportStatusInfoByProfileId(params.data?.profileId);
      },

      cellRendererParams: (params: ICellRendererParams<ProfileTableRow>): IButtonGroupCellRendererParams => {
        const profileId = params.data?.profileId;
        const thisProfileSyncStatus = profileSyncStatus(profileId);

        if (params.data?.isNotAuthorized) {
          return { buttons: [] };
        }

        return {
          buttons: [
            <RowActionButton
              key="update"
              text="Update Data"
              isLoadingText="Updating..."
              color="default"
              width={110}
              isDisabled={!isAdminModeActive && (!thisProfileSyncStatus.canClickSync || thisProfileSyncStatus.isLoading)}
              tooltipText={thisProfileSyncStatus.reason ? t(`enums.labels.${thisProfileSyncStatus.reason}`) : undefined}
              tooltipPlacement="right"
              isLoading={!isAdminModeActive && thisProfileSyncStatus.isLoading}
              onClick={() => onUpdateProfileClicked(params.data)}
              icon={<Cached />}
            ></RowActionButton>,
          ],
        };
      },
    },
    {
      colId: ColumnId.SYNC_STV,
      headerName: 'Sync Sponsored TV',
      field: 'createSTVReports',
      editable: true,
      cellRenderer: ToggleCellRenderer,
      cellRendererParams: (params: ICellRendererParams<ProfileTableRow>): IToggleCellRendererParams => {
        return {
          ...params,
          tooltip: 'Turn this on to enable Sponsored TV reports',
        };
      },
      onCellValueChanged: (event: NewValueParams<ProfileTableRow, any>) => {
        const data = event.data;

        updateProfileSettings({
          teamId: data.teamId,
          profileId: data.profileId,
          createSTVReports: data.createSTVReports,
          refetch: refetchUser,
        });
      },
    },
    {
      colId: ColumnId.DATA_STATUS,
      headerName: 'Ad Data Status',
      minWidth: 130,
      cellRenderer: TextCellRenderer,
      cellRendererParams: (params: ICellRendererParams<ProfileTableRow, ProfileReportsStatus | undefined>): ITextCellRendererParams => {
        if (params.data?.isNotAuthorized) {
          return {
            textColor: MuiColorVariant.Error,
            textLabel: 'Not authorized',
            tooltip: 'Cannot access profile. Please contact profile owner or remove it.',
          };
        }

        const dataStatus: ReportingStatusType | StatusType | null = params.value?.status || null;
        const color: AdLabsColorVariant = getColorForProfileDataStatus(dataStatus);

        const value = dataStatus ? t(`enums.profile_status_labels.${dataStatus}`) : '';
        return {
          textColor: color,
          textLabel: value,
          tooltip: dataStatus
            ? t(`enums.profile_status_labels.${dataStatus}` + ': ' + t(`enums.profile_status_descriptions.${dataStatus}`))
            : '',
        };
      },
      valueGetter: (params: ValueGetterParams<ProfileTableRow>) => {
        return getReportStatusInfoByProfileId(params.data?.profileId);
      },
      valueSetter: (params: ValueSetterParams & { data: ProfileTableRow; newValue: ProfileReportsStatus }) => {
        const prevValue = params.data[ColumnId.DATA_STATUS];
        if (prevValue !== params.newValue.status) {
          params.data[ColumnId.DATA_STATUS] = params.newValue.status;
          return true; // This tells the grid the value has changed, and it needs to be refreshed.
        }
        return false; // This tells the grid the value hasn't changed, no need to refresh.
      },
    },

    {
      colId: ColumnId.UPDATED_AT,
      headerName: 'Last Updated',
      minWidth: 160,
      valueFormatter: (params: ValueFormatterParams & { value: string }): string => {
        if (!isNil(params.value) && AlDate.parse(params.value).isValid()) {
          return formatDateStringTimeNoSeconds(params.value);
        }

        return params.value;
      },
      valueGetter: (params: ValueGetterParams<ProfileTableRow>) => {
        return getCombinedUpdatedAtByProfileId(params.data?.profileId);
      },
    },
    {
      colId: ColumnId.CURRENCY,
      headerName: 'Currency',
      field: 'currency',
      minWidth: 120,
    },
    {
      colId: ColumnId.TIMEZONE,
      headerName: 'Timezone',
      field: 'timezone',
      minWidth: 185,
    },
    {
      colId: ColumnId.TYPE,
      headerName: 'Type',
      field: 'type',
      minWidth: 100,
    },
    {
      colId: ColumnId.ONBOARD_DATE,
      headerName: 'Onboarded On AdLabs',
      field: 'onboardDate',
      minWidth: 250,
      valueFormatter: (params) => formatDateStringTimeNoSeconds(params.value),
    },
  ];

  columnDefs.splice(
    3,
    0,
    {
      colId: ColumnId.SELLER_CENTRAL_AUTHORIZED,
      headerName: 'Selling Partner Auth',
      field: 'sellerCentralAuthorizedAt',
      minWidth: 160,
      valueFormatter: (params) => formatDateStringTimeNoSeconds(params.value),
    },
    {
      colId: ColumnId.SELLING_PARTNER_DATA_STATUS,
      headerName: 'Selling Partner Data Status',
      minWidth: 130,
      cellRenderer: TextCellRenderer,
      cellRendererParams: (params: ICellRendererParams<ProfileTableRow, ProfileReportsStatus | undefined>): ITextCellRendererParams => {
        if (isNil(params.data?.sellerCentralAuthorizedAt)) {
          return {
            textColor: TailwindColorVariant.SLATE,
            textLabel: 'Not connected',
            tooltip: 'Connect Selling Partner to see additional data',
          };
        }

        const dataStatus: ReportingStatusType | StatusType | null = params.value?.status || null;
        const color: AdLabsColorVariant = getColorForProfileDataStatus(dataStatus);

        const value = dataStatus ? t(`enums.profile_status_labels.${dataStatus}`) : '';
        return {
          textColor: color,
          textLabel: value,
          tooltip: dataStatus
            ? t(`enums.profile_status_labels.${dataStatus}` + ': ' + t(`enums.profile_status_descriptions.${dataStatus}`))
            : '',
        };
      },
      valueGetter: (params: ValueGetterParams<ProfileTableRow>) => {
        return getSellerCentralReportStatusInfoByProfileId(params.data?.profileId);
      },
      valueSetter: (params: ValueSetterParams & { data: ProfileTableRow; newValue: ProfileReportsStatus }) => {
        const prevValue = params.data[ColumnId.DATA_STATUS];
        if (prevValue !== params.newValue.status) {
          params.data[ColumnId.DATA_STATUS] = params.newValue.status;
          return true; // This tells the grid the value has changed, and it needs to be refreshed.
        }
        return false; // This tells the grid the value hasn't changed, no need to refresh.
      },
    },
  );

  if (isAdminModeActive) {
    const restartInitialReportsColDef: ColDef<ProfileTableRow> = {
      colId: ColumnId.RESTART_INITIAL_REPORTS,
      headerName: 'Restart Initial Reports',
      minWidth: 180,
      cellRenderer: ButtonCallbackCellRenderer,
      cellRendererParams: (): IButtonCallbackCellRendererParams<ProfileTableRow> => {
        return {
          buttonText: 'Restart Initial Reports',
          callback: onRestartInitialReportsClicked,
          isDisabled: false,
        };
      },
    };

    columnDefs.unshift(restartInitialReportsColDef);

    const deleteArchivedTargetsColDef: ColDef<ProfileTableRow> = {
      colId: ColumnId.DELETE_ARCHIVED_TARGETS,
      headerName: 'Delete Archived Targets',
      minWidth: 180,
      cellRenderer: ButtonCallbackCellRenderer,
      cellRendererParams: (): IButtonCallbackCellRendererParams<ProfileTableRow> => {
        return {
          buttonText: 'Delete Archived Targets',
          color: 'warning',
          callback: onDeleteArchivedTargetsClicked,
          isDisabled: false,
        };
      },
    };

    columnDefs.unshift(deleteArchivedTargetsColDef);

    const debugColDef: ColDef<ProfileTableRow> = {
      colId: ColumnId.REPORT_INFO,
      headerName: 'Report Info',
      minWidth: 140,
      cellRenderer: ButtonGroupCellRenderer,
      cellRendererParams: (params: ICellRendererParams<ProfileTableRow>): IButtonGroupCellRendererParams => {
        return {
          buttons: [
            <RowActionButton
              key="debug-button"
              text="Debug"
              color="default"
              width={100}
              onClick={() => onDebugProfileClicked(params.data)}
              icon={<FindInPage />}
            ></RowActionButton>,
          ],
        };
      },
    };

    columnDefs.unshift(debugColDef);
    const idColDef: ColDef<ProfileTableRow> = {
      colId: ColumnId.ID,
      headerName: 'Id',
      field: 'profileId',
      minWidth: 125,
    };

    columnDefs.unshift(idColDef);
  }

  const customGridOptions: GridOptions<ProfileTableRow> = {
    ...DEFAULT_GRID_OPTIONS,
    getRowId: (params) => params.data.profileId.toString(),
    overlayNoRowsTemplate: '<span style="padding: 10px;">No Profiles added yet</span>',
    defaultColDef: {
      resizable: true,
      sortable: true,
      minWidth: 100,
      headerComponent: DefaultHeaderRenderer,
      sortingOrder: GRID_DEFAULT_SORTING_ORDER,
    },
  };

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  return (
    <>
      <AlGrid colDefs={columnDefs} rowData={rowData} gridOptions={customGridOptions} />
      <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal} returnURLPath={Routes.PROFILES}>
        {`Free sync limit in one day reached. Upgrade your subscription to update date more often.`}
      </PaywallModal>
    </>
  );
};

export default ProfileTable;
