import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import EnabledPausedArchivedStateCellRenderer from '@/components/grid/cells/EnabledPausedArchivedStateCellRenderer';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useDynamicHeight from '@/components/grid/hooks/useDynamicHeight';
import { AlColDef } from '@/components/grid/types';
import { TailwindColorVariant } from '@/config/theme/color.type';
import { useLayoutContext } from '@/contexts/LayoutContext';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useTranslation } from '@/lib/i18n/useTranslate';
import { EnabledPausedArchivedState } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { UserSettingKey } from '@/modules/users';
import { Card } from '@mui/material';
import {
  BodyScrollEvent,
  CellClickedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  SelectionChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { NegativeTargetModel, SelectedNegativeTarget } from '../../models/NegativeTargetsModel';
import { generateNegativeTargetingTableColumnState } from './negative-targeting-table.default-column-state';

const VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = 60;

interface NegativeTargetingTableProps {
  rowData: NegativeTargetModel[] | undefined;
  isLoading: boolean;
  setSelectedNegativeTargeting: (selectedNegativeTargeting: SelectedNegativeTarget[]) => void;
  negativeTargetingLoadingErrorMessage: string;
  isNegativeTargetingLoadingError: boolean;
  noTopBorderRadius?: boolean;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
}

const NegativeTargetingTable: FunctionComponent<NegativeTargetingTableProps> = ({
  rowData,
  isLoading,
  setSelectedNegativeTargeting,
  negativeTargetingLoadingErrorMessage,
  isNegativeTargetingLoadingError,
  noTopBorderRadius = false,
  onGridReadyCallback,
}) => {
  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.NEGATIVE_TARGETING_TABLE_COLUMN_STATE,
    generateNegativeTargetingTableColumnState(),
  );

  const gridApiRef = useRef<GridApi<NegativeTargetModel>>();

  const { onSourceScroll } = useLayoutContext();
  const { t } = useTranslation();
  const { heightCssValue, onGridReadyCallback: onGridReadyForDynamicHeight } = useDynamicHeight({
    VISIBLE_ABOVE_PX_ON_SCROLL_DOWN,
  });
  const { checkboxColumnType } = useColumnTypes();
  const { getCampaignAdTypeCellRendererParams } = useColDefsFunctions();
  const { formatDateStringTimeNoHours } = useFormatting();

  const emptyCellRenderer = { component: () => <div className="h-full flex items-center pb-1">–</div> };

  const columnDefs: ColDef<NegativeTargetModel>[] = useMemo(() => {
    const colDefs: AlColDef<NegativeTargetModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        type: 'checkboxColumnType',
      },
      {
        colId: ColumnId.ID,
        headerName: 'ID',
        field: 'id',
        hide: true,
      },
      {
        colId: ColumnId.TARGETING,
        headerName: 'Neg. Target',
        field: 'targeting',
        minWidth: 120,
      },
      {
        colId: ColumnId.TARGETING_TYPE,
        headerName: 'Campaign Targeting type',
        field: 'targetingType',
        minWidth: 120,
      },
      {
        colId: ColumnId.ENTITY_TYPE,
        headerName: 'Type',
        field: 'entityType',
        width: 50,
        aggFunc: 'stringToCountAggFunc',
        cellRendererSelector: (params: ICellRendererParams<NegativeTargetModel>) => {
          if (params.node.rowPinned && !params.data?.entityType) {
            return emptyCellRenderer;
          }
          return undefined;
        },
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => (key ? t(`enums.bidding_entity_short.${key}`) : '-'),
            valueToColor: (key: string) => {
              switch (key) {
                case TargetEntityType.PRODUCT_TARGET:
                  return TailwindColorVariant.BLUE;
                default:
                  return TailwindColorVariant.GREEN;
              }
            },
            valueToTooltip: (key: string) => t(`enums.bidding_entity.${key}`),
          };
        },
      },
      {
        colId: ColumnId.NEGATIVE_MATCH_TYPE,
        headerName: 'Neg. Match Type',
        field: 'negativeMatchType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.negative_match_type.${key}`),
          };
        },
      },
      {
        colId: ColumnId.STATE,
        headerName: 'Target State',
        field: 'targetState',
        hide: false,
        width: 64,
        cellClass: 'flex flex-row items-center justify-center',
        cellRenderer: EnabledPausedArchivedStateCellRenderer,
      },
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Campaign',
        field: 'campaignName',
        cellRenderer: TextCellRenderer,
        width: 150,
      },
      {
        colId: ColumnId.CAMPAIGN_AD_TYPE,
        headerName: 'Campaign Ad Type',
        field: 'campaignAdType',
        width: 100,
        cellRenderer: TextCellRenderer,
        cellRendererParams: getCampaignAdTypeCellRendererParams,
      },
      {
        colId: ColumnId.CAMPAIGN_STATE,
        headerName: 'Campaign State',
        field: 'campaignState',
        hide: false,
        cellClass: 'flex flex-row items-center justify-center',
        cellRenderer: EnabledPausedArchivedStateCellRenderer,
        width: 81,
      },
      {
        colId: ColumnId.AD_GROUP,
        headerName: 'Ad Group',
        field: 'adGroupName',
        cellRenderer: TextCellRenderer,
        width: 100,
      },
      {
        colId: ColumnId.CREATED_AT,
        headerName: 'Created Date',
        field: 'createdAt',
        filter: 'agDateColumnFilter',
        width: 120,
        valueFormatter: (params: ValueFormatterParams<NegativeTargetModel>) => formatDateStringTimeNoHours(params.value),
      },
    ];

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, []);

  const updateSelectedNegativeTargeting = (api: GridApi<NegativeTargetModel>) => {
    if (!api || api.isDestroyed()) {
      console.warn('[NegativeTargetingTable.tsx] Grid API is not available or has been destroyed.');
      return;
    }

    try {
      const selectedRows = api.getSelectedRows();
      const selectedNegativeTargetingDTO = selectedRows.map(
        (row): SelectedNegativeTarget => ({
          id: row.id,
          adType: row.campaignAdType,
          entityType: row.entityType,
          isCampaign: isNil(row.adGroupName) || isEmpty(row.adGroupName),
          targeting: row.targeting,
          adGroupName: row.adGroupName,
          campaignName: row.campaignName,
          state: row.targetState,
        }),
      );
      setSelectedNegativeTargeting(selectedNegativeTargetingDTO);
    } catch (error) {
      console.error('Error updating selected negative targeting:', error);
    }
  };

  const handleRowSelection = (event: SelectionChangedEvent<NegativeTargetModel>) => {
    updateSelectedNegativeTargeting(event.api);
  };

  const onCellClicked = useCallback((params: CellClickedEvent<NegativeTargetModel>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<NegativeTargetModel> = useMemo(() => {
    return {
      ...DEFAULT_GRID_OPTIONS,
      getRowId: (params) => params.data.id.toString(),
      isRowSelectable: (params) => params.data?.targetState !== EnabledPausedArchivedState.ARCHIVED,
      onSelectionChanged: handleRowSelection,
      onCellClicked: onCellClicked,
      maintainColumnOrder: true,
      onBodyScroll: (event: BodyScrollEvent) => onSourceScroll(event.top),
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      columnTypes: { checkboxColumnType },
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    onGridReadyCallback?.(params);
    onGridReadyForDynamicHeight(params);
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      {isNegativeTargetingLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={negativeTargetingLoadingErrorMessage} />
        </Card>
      ) : (
        <div style={{ height: heightCssValue }}>
          <AlGrid
            colDefs={columnDefs}
            rowData={rowData}
            gridOptions={customGridOptions}
            isLoading={isLoading}
            onGridReadyCallback={onGridReady}
            noTopBorderRadius={noTopBorderRadius}
            fitToResizeEnabled={false}
            addExtraBottomPadding={true}
          />
        </div>
      )}
    </>
  );
};

export default NegativeTargetingTable;
