import { Paper } from '@mui/material';
import { FunctionComponent } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface DimensionProps {
  label: string;
  isDragging?: boolean;
}

const Dimension: FunctionComponent<DimensionProps> = ({ label, isDragging = false }) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        minWidth: 140,
        borderRadius: '12px',
        textAlign: 'center',
        paddingY: '16px',
        border: `2px dashed`,
        borderColor: isDragging ? 'currentColor' : 'transparent',
        cursor: 'move',
      }}
      className="hover:shadow-lg"
    >
      <div className="flex flex-row items-center">
        {!isDragging && <DragIndicatorIcon style={{ opacity: 0.7, paddingLeft: '6px' }} />}
        <div className={`flex items-center justify-center pr-${isDragging ? '6' : '4'} w-full`}>{label}</div>
      </div>
    </Paper>
  );
};

export default Dimension;
