import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import RGL, { Layout, WidthProvider, ItemCallback } from 'react-grid-layout';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import DashboardWidget from '../dashboard-widget/DashboardWidget';
import {
  WIDGET_WIDTH_IN_PX,
  WIDGET_HEIGHT_IN_PX,
  DASHBOARD_MAX_COLUMN_COUNT,
  DASHBOARD_MAX_ROW_COUNT,
} from '../../configuration/dashboard-dimensions';
import { useDashboardQueries } from '../../hooks/useDashboardQueries';
import dashboardGrid from '@/assets/dashboard_grid.svg';

const ReactGridLayout = WidthProvider(RGL);

interface DashboardProps extends RGL.ReactGridLayoutProps, RGL.WidthProviderProps {}

const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  const { widgets, updateWidgetLayout, dashboardVersion } = useDashboardContextValue((context) => ({
    widgets: context.widgets,
    updateWidgetLayout: context.updateWidgetLayout,
    setDragginWidgetId: context.setDragginWidgetId,
    addWidget: context.addWidget,
    dates: context.dates,
    comparisonDates: context.comparisonDates,
    dashboardVersion: context.dashboardVersion,
    setEditingWidgetId: context.setEditingWidgetId,
  }));

  const [isResizingWidget, setIsResizingWidget] = useState(false);
  const { refetchDashboardData } = useDashboardQueries();
  const [draggedItem, setDraggedItem] = useState<Layout | null>(null);

  const widgetElements = useMemo(() => {
    return widgets.map((x) => {
      return <DashboardWidget key={x.id} widgetid={x.id} data-grid={x.layout} />;
    });
  }, [widgets]);

  const [dragClass, setDragClass] = useState('');
  const onLayoutChange = useCallback<ItemCallback>(
    (_, oldItem, newItem) => {
      const widgetToUpdateLayout = widgets.find((widget) => widget.layout.i === oldItem.i || widget.id === oldItem.i);
      if (!widgetToUpdateLayout) {
        return;
      }
      updateWidgetLayout(widgetToUpdateLayout.id, newItem, true);
    },
    [widgets],
  );

  // Memoize styles
  const gridStyle = useMemo(
    () => ({
      minWidth: DASHBOARD_MAX_COLUMN_COUNT * WIDGET_WIDTH_IN_PX + DASHBOARD_MAX_COLUMN_COUNT * 10 - 5,
      minHeight: 5 + DASHBOARD_MAX_ROW_COUNT * WIDGET_HEIGHT_IN_PX + DASHBOARD_MAX_ROW_COUNT * 10,
      width: DASHBOARD_MAX_COLUMN_COUNT * WIDGET_WIDTH_IN_PX + DASHBOARD_MAX_COLUMN_COUNT * 10 - 5,
      height: 5 + DASHBOARD_MAX_ROW_COUNT * WIDGET_HEIGHT_IN_PX + DASHBOARD_MAX_ROW_COUNT * 10,
    }),
    [],
  );

  useEffect(() => {
    if (dashboardVersion) {
      refetchDashboardData();
    }
  }, [dashboardVersion]);

  let gridIndicatorLinesStyle = {};
  if (draggedItem || isResizingWidget) {
    gridIndicatorLinesStyle = {
      background: `url(${dashboardGrid})`,
      backgroundPositionX: `5px`,
      backgroundPositionY: `5px`,
      backgroundSize: `${89.5 + DASHBOARD_MAX_COLUMN_COUNT * 0.00575}px 55px`,
      backgroundRepeat: 'repeat',
    };
  }
  return (
    <div style={{ ...gridStyle, ...gridIndicatorLinesStyle }}>
      <ReactGridLayout
        {...props}
        className={`dashboard-grid  ${dragClass}`}
        cols={DASHBOARD_MAX_COLUMN_COUNT}
        rowHeight={WIDGET_HEIGHT_IN_PX}
        maxRows={DASHBOARD_MAX_ROW_COUNT}
        compactType={null}
        width={gridStyle.width}
        autoSize={false}
        useCSSTransforms={true}
        style={{
          ...gridStyle,
        }}
        measureBeforeMount={false}
        preventCollision={true}
        // Override draggable handle because toggling draggable on either the grid or the grid items, does not work
        draggableHandle=".draggable"
        onDrag={(layout: Layout[], oldItem: Layout, newItem: Layout, placeholder: Layout, event: MouseEvent) => {
          setDraggedItem(newItem);
          if (event.movementX > 0 && dragClass !== 'dragging-right') {
            setDragClass('dragging-right');
          } else if (event.movementX < 0 && dragClass !== 'dragging-left') {
            setDragClass('dragging-left');
          }
        }}
        onResizeStart={() => {
          setIsResizingWidget(true);
        }}
        onDragStop={(layout: Layout[], oldItem: Layout, newItem: Layout, placeholder: Layout, event: MouseEvent, element: HTMLElement) => {
          onLayoutChange(layout, oldItem, newItem, placeholder, event, element);
          setDraggedItem(null);
        }}
        onResizeStop={(layout: Layout[], oldItem: Layout, newItem: Layout, placeholder: Layout, event: MouseEvent, element: HTMLElement) => {
          onLayoutChange(layout, oldItem, newItem, placeholder, event, element);
          setIsResizingWidget(false);
        }}
      >
        {widgetElements}
      </ReactGridLayout>
    </div>
  );
};

export default Dashboard;
