import { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnConfiguration } from './TableWidgetConfiguration';

interface ColumnVisibilitySelectProps {
  value: ColumnConfiguration[];
  onChange: (metricColumnSettings: ColumnConfiguration[]) => void;
}

const ColumnVisibilitySelect: FunctionComponent<ColumnVisibilitySelectProps> = ({ value, onChange }) => {
  const handleCheckboxChange = (columnId: string | ColumnId | undefined) => {
    const updatedValue = value.map((column) => {
      if (column.columnId === columnId) {
        return {
          ...column,
          isVisible: !column.isVisible,
        };
      }
      return column;
    });
    onChange(updatedValue);
  };

  return (
    <FormGroup className="grid grid-cols-2 gap-x-1" style={{ margin: 0 }}>
      {value.map((column) => (
        <FormControlLabel
          key={column.columnId}
          control={<Checkbox checked={column.isVisible} onChange={() => handleCheckboxChange(column.columnId)} />}
          label={column.label}
          className="truncate"
        />
      ))}
    </FormGroup>
  );
};

export default ColumnVisibilitySelect;
