import { AlDate } from '@/lib/date/AlDate';
import { ProfileCreateArguments, ProfileModel } from '@/modules/profiles/types/ProfileModel';
import { AmazonAccount } from '@/modules/users/types/AmazonAccount';
import { isEmpty } from 'lodash-es';
import {
  ADLABS_PLAN_CAPABILITIES_MAP,
  ADLABS_PLAN_PRICE_LOOKUP_KEY_CAPABILITIES_MAP,
  FREE_PLAN_CAPABILITIES,
  LEGACY_PRO_PLAN_CAPABILITIES,
  PlanCapabilities,
  PlanPriceLookupKey,
  PlanType,
} from '../../plans/types/SubscriptionPlan';
import { TeamDTO } from '../api/teams.contracts';
import { TeamMemberModel } from './TeamMemberModel';

export const MAX_TEAM_NAME_LENGTH = 50;

export class TeamModel {
  public id: number;
  public name: string;
  public amazonAccount: AmazonAccount | undefined;
  public preferredUpdateTime: string;
  public preferredLastDone: string;
  public profiles: ProfileModel[];
  public members: TeamMemberModel[];

  public stripeSubscriptionStatus: string;
  public stripeSubscriptionCancelAtPeriodEnd: boolean;
  public stripeSubscriptionCurrentPeriodEnd: string;
  public stripeSubscriptionTrialEnd: string | null;
  public stripeSubscriptionDefaultPaymentMethod: string | null;
  public stripeSubscriptionLastStatusChangeAt: string | null;
  public adlabsPlan: PlanType;
  public adlabsPlanPriceLookupKey: PlanPriceLookupKey;
  public currentValidPlan: PlanType;
  public isCustomer: boolean = false;
  public isOnLegacyProfilesBasedProPlan: boolean;
  public actualAdSpendPercentagePaid: number;
  public monthlyInvoiceCap: number;

  public spend: number;
  public estimatedMonthlySpend: number;
  public estimatedCost: number;

  constructor(args: TeamCreateArguments) {
    this.id = args.id;
    this.name = args.name;
    this.amazonAccount = args.amazonAccount;
    this.preferredUpdateTime = args.preferredUpdateTime;
    this.preferredLastDone = args.preferredLastDone;
    // More complex due to Profile nameWithMarket getter:
    this.profiles = args.profiles.map((profileArgs) => new ProfileModel(profileArgs));
    this.members = args.members;

    // Billing related fields
    this.stripeSubscriptionStatus = args.stripeSubscriptionStatus;
    this.stripeSubscriptionCancelAtPeriodEnd = args.stripeSubscriptionCancelAtPeriodEnd;
    this.stripeSubscriptionCurrentPeriodEnd = args.stripeSubscriptionCurrentPeriodEnd;
    this.stripeSubscriptionTrialEnd = args.stripeSubscriptionTrialEnd;
    this.stripeSubscriptionDefaultPaymentMethod = args.stripeSubscriptionDefaultPaymentMethod;
    this.stripeSubscriptionLastStatusChangeAt = args.stripeSubscriptionLastStatusChangeAt;
    this.adlabsPlan = args.adlabsPlan;
    this.adlabsPlanPriceLookupKey = args.adlabsPlanPriceLookupKey;
    this.currentValidPlan = args.currentValidPlan;
    this.isCustomer = args.isCustomer;
    this.isOnLegacyProfilesBasedProPlan = args.isOnLegacyProfilesBasedProPlan;
    this.actualAdSpendPercentagePaid = args.actualAdSpendPercentagePaid;
    this.monthlyInvoiceCap = args.monthlyInvoiceCap;

    // Team stats
    this.spend = args.spend;
    this.estimatedMonthlySpend = args.estimatedMonthlySpend;
    this.estimatedCost = args.estimatedCost;
  }

  get subscriptionPlan(): PlanCapabilities {
    // Only get other capabilities by lookup key if it's there, and if the plan is not free
    if (this.hasProPlan && !isEmpty(this.adlabsPlanPriceLookupKey)) {
      return ADLABS_PLAN_PRICE_LOOKUP_KEY_CAPABILITIES_MAP[this.adlabsPlanPriceLookupKey] ?? FREE_PLAN_CAPABILITIES;
    }

    // Handle legacy pro plan
    if (this.isOnLegacyProfilesBasedProPlan) {
      if (this.hasProPlan) {
        return LEGACY_PRO_PLAN_CAPABILITIES;
      } else {
        return FREE_PLAN_CAPABILITIES;
      }
    }

    return ADLABS_PLAN_CAPABILITIES_MAP[this.currentValidPlan] ?? FREE_PLAN_CAPABILITIES;
  }

  get hasProPlan(): boolean {
    return this.currentValidPlan === PlanType.PRO_PLAN;
  }

  get hasDefaultPaymentMethod(): boolean {
    return !isEmpty(this.stripeSubscriptionDefaultPaymentMethod);
  }

  get daysBeforeTrialEnd(): number {
    if (!this.stripeSubscriptionTrialEnd) {
      return 0;
    }

    const trialEnd = AlDate.parse(this.stripeSubscriptionTrialEnd);
    const daysDifference = trialEnd.isValid() ? AlDate.now().diff(trialEnd, 'days') : 0;

    if (daysDifference < 0) {
      return 0;
    }

    return daysDifference;
  }

  get isTrialing(): boolean {
    return this.daysBeforeTrialEnd > 0 && this.stripeSubscriptionStatus === 'trialing';
  }

  get isPastDue(): boolean {
    return this.stripeSubscriptionStatus === 'past_due';
  }

  get isTrialConvertedToSubscriptionAtPeriodEnd(): boolean {
    return this.isTrialing && this.hasDefaultPaymentMethod && !this.stripeSubscriptionCancelAtPeriodEnd;
  }

  get hasPriceLookupKey(): boolean {
    return !isEmpty(this.adlabsPlanPriceLookupKey);
  }

  get isProAdSpendFlatFee(): boolean {
    return this.adlabsPlanPriceLookupKey === PlanPriceLookupKey.PRO_AD_SPEND_FLAT_FEE;
  }

  get isProAdSpendDynamic(): boolean {
    return this.adlabsPlanPriceLookupKey === PlanPriceLookupKey.PRO_AD_SPEND_DYNAMIC;
  }

  get isProMonthly(): boolean {
    return this.adlabsPlanPriceLookupKey === PlanPriceLookupKey.PRO_MONTHLY;
  }

  get isFreeMonthly(): boolean {
    return this.adlabsPlanPriceLookupKey === PlanPriceLookupKey.FREE_MONTHLY;
  }

  get isProProfileBased80(): boolean {
    return this.adlabsPlanPriceLookupKey === PlanPriceLookupKey.PRO_PROFILE_BASED_80;
  }

  get isProProfileBased120(): boolean {
    return this.adlabsPlanPriceLookupKey === PlanPriceLookupKey.PRO_PROFILE_BASED_120;
  }

  get isProProfileBased250(): boolean {
    return this.adlabsPlanPriceLookupKey === PlanPriceLookupKey.PRO_PROFILE_BASED_250;
  }

  get isProBrandBased250(): boolean {
    return this.adlabsPlanPriceLookupKey === PlanPriceLookupKey.PRO_BRAND_BASED_250;
  }

  get isOnProfilesBasedPlan(): boolean {
    return (
      (this.hasPriceLookupKey &&
        (this.isProProfileBased80 || this.isProProfileBased120 || this.isProProfileBased250 || this.isProBrandBased250)) ||
      this.isOnLegacyProfilesBasedProPlan
    );
  }

  get isPastDueAndGracePeriodHasPassed(): boolean {
    try {
      if (!this.isPastDue || !this.stripeSubscriptionLastStatusChangeAt) {
        return false;
      }

      const lastStatusChangeAt = AlDate.parse(this.stripeSubscriptionLastStatusChangeAt);
      const daysDifference = AlDate.now().diff(lastStatusChangeAt, 'days');

      return daysDifference > 3;
    } catch (e) {
      console.error('Error while checking if past due grace period passed', e);
      return false;
    }
  }

  public canSyncProfileByLastSync(lastReportCreatedAt: AlDate): boolean {
    if (!lastReportCreatedAt.isValid()) {
      return true;
    }
    const diffInMinutes = AlDate.now().diff(lastReportCreatedAt, 'minutes');
    return diffInMinutes >= this.subscriptionPlan.updateIntervalLimitMinutes;
  }

  public static fromDTO(dto: TeamDTO): TeamModel {
    return new TeamModel({
      id: dto.id,
      name: dto.name,
      amazonAccount:
        dto.amazon_account.id == 0
          ? undefined
          : {
              id: dto.amazon_account.id,
              isAuthorized: dto.amazon_account.is_authorized,
              authEmail: dto.amazon_account.auth_email,
              authName: dto.amazon_account.auth_name,
              owner: { id: dto.amazon_account.owner.id, name: dto.amazon_account.owner.name, email: dto.amazon_account.owner.email },
            },
      preferredUpdateTime: dto.preferred_update_time,
      preferredLastDone: dto.preferred_last_done,
      profiles: dto.profiles ? dto.profiles.map((profileDTO) => ProfileModel.fromDTO(profileDTO)) : [],
      members: dto.members ? dto.members.map((memberDTO) => TeamMemberModel.fromDTO(memberDTO)) : [],

      // Billing related fields
      stripeSubscriptionStatus: dto.stripe_subscription_status,
      stripeSubscriptionCancelAtPeriodEnd: dto.stripe_subscription_cancel_at_period_end,
      stripeSubscriptionCurrentPeriodEnd: dto.stripe_subscription_current_period_end,
      stripeSubscriptionTrialEnd: dto.stripe_subscription_trial_end,
      stripeSubscriptionDefaultPaymentMethod: dto.stripe_subscription_default_payment_method,
      stripeSubscriptionLastStatusChangeAt: dto.stripe_subscription_last_status_change_at,
      actualAdSpendPercentagePaid: dto.actual_ad_spend_percentage_paid,
      monthlyInvoiceCap: dto.monthly_invoice_cap,

      adlabsPlan: dto.adlabs_plan as PlanType,
      adlabsPlanPriceLookupKey: dto.adlabs_plan_price_lookup_key as PlanPriceLookupKey,
      currentValidPlan: dto.current_valid_plan as PlanType,
      isCustomer: dto.is_customer,
      isOnLegacyProfilesBasedProPlan: dto.is_on_legacy_profiles_based_pro_plan,

      // Team stats
      spend: dto.spend,
      estimatedMonthlySpend: dto.estimated_monthly_spend,
      estimatedCost: dto.estimated_cost,
    });
  }
}

export interface TeamCreateArguments {
  id: number;
  name: string;
  amazonAccount: AmazonAccount | undefined;
  preferredUpdateTime: string;
  preferredLastDone: string;
  profiles: ProfileCreateArguments[];
  members: TeamMemberModel[];

  // Billing related fields
  stripeSubscriptionStatus: string;
  stripeSubscriptionCancelAtPeriodEnd: boolean;
  stripeSubscriptionCurrentPeriodEnd: string;
  stripeSubscriptionTrialEnd: string | null;
  stripeSubscriptionDefaultPaymentMethod: string | null;
  stripeSubscriptionLastStatusChangeAt: string | null;
  adlabsPlan: PlanType;
  adlabsPlanPriceLookupKey: PlanPriceLookupKey;
  currentValidPlan: PlanType;
  isCustomer: boolean;
  isOnLegacyProfilesBasedProPlan: boolean;
  actualAdSpendPercentagePaid: number;
  monthlyInvoiceCap: number;

  // Team stats
  spend: number;
  estimatedMonthlySpend: number;
  estimatedCost: number;
}
