import { useState, FunctionComponent, PropsWithChildren } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface TinyAccordionProps extends PropsWithChildren {
  headerText: string;
}

const TinyAccordion: FunctionComponent<TinyAccordionProps> = ({ headerText, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const maxHeight = isOpen ? '100vh' : '0';

  return (
    <div>
      <div onClick={toggleAccordion} className="cursor-pointer">
        <span className="flex items-center text-xs font-semibold">
          {headerText}
          {isOpen ? <ArrowDropUpIcon fontSize="small" /> : <ArrowDropDownIcon fontSize="small" />}
        </span>
      </div>
      <div className="overflow-hidden transition-all duration-300 ease-in-out" style={{ maxHeight: maxHeight }}>
        {children}
      </div>
    </div>
  );
};

export default TinyAccordion;
