import { useMemo } from 'react';

import { CampaignAdType, EnabledPausedArchivedState } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { SelectedTarget } from '../models/TargetsModel';

interface TargetingInfo {
  warnings: string[];
  selectedLegacyTargetsCount: number;
  selectedArchivedTargetsCount: number;
  selectedTargetsWithOptRulesCount: number;
  selectedAutoTargetCount: number;
  selectedTvTargetsCount: number;
}

interface UseTargetingWarningsProps {
  selectedTargets: SelectedTarget[];
}

const useTargetingWarnings = ({ selectedTargets }: UseTargetingWarningsProps) => {
  const preBulkEditInfo: TargetingInfo = useMemo(() => {
    const warningMessages: string[] = [];

    const selectedLegacyTargetsCount = selectedTargets.filter((t) => t.isLegacyAutoTarget).length;
    if (selectedLegacyTargetsCount > 0) {
      warningMessages.push(`Cannot change ${selectedLegacyTargetsCount} Legacy Auto-targets manually`);
    }

    const selectedArchivedTargetsCount = selectedTargets.filter((target) => target.state === EnabledPausedArchivedState.ARCHIVED).length;
    if (selectedArchivedTargetsCount > 0) {
      warningMessages.push(`Cannot change ${selectedArchivedTargetsCount} archived targets`);
    }

    const selectedTargetsWithOptRulesCount = selectedTargets.filter((t) => t.hasOptRule).length;
    if (selectedTargetsWithOptRulesCount > 0) {
      warningMessages.push(
        `Cannot change bids for ${selectedTargetsWithOptRulesCount} target${selectedTargetsWithOptRulesCount > 1 ? 's' : ''} with optimization rules`,
      );
    }

    const selectedAutoTargetCount = selectedTargets.filter((target) => target.matchType == MatchType.AUTO).length;
    if (selectedAutoTargetCount > 0) {
      warningMessages.push(`Cannot archive ${selectedAutoTargetCount} target${selectedAutoTargetCount > 1 ? 's' : ''} with match type auto`);
    }

    const selectedTvTargetsCount = selectedTargets.filter((t) => t.adType == CampaignAdType.TV).length;
    if (selectedTvTargetsCount > 0) {
      warningMessages.push(`Cannot update ${selectedTvTargetsCount} Sponsored TV target${selectedTvTargetsCount > 1 ? 's' : ''}`);
    }

    return {
      warnings: warningMessages,
      selectedLegacyTargetsCount,
      selectedArchivedTargetsCount,
      selectedTargetsWithOptRulesCount,
      selectedAutoTargetCount,
      selectedTvTargetsCount,
    };
  }, [selectedTargets]);

  return { ...preBulkEditInfo };
};

export default useTargetingWarnings;
