import { initializeApp, type FirebaseOptions } from 'firebase/app';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyB8DXpsTDw1SrT7WBU_pUFoXYdi5vk3thk',
  authDomain: 'adlabs-app.firebaseapp.com',
  projectId: 'adlabs-app',
  storageBucket: 'adlabs-app.appspot.com',
  messagingSenderId: '230527421823',
  appId: '1:230527421823:web:26c667ad887a361bf85e4a',
};

//If an firebase app hasn't already been created
export const app = initializeApp(firebaseConfig);
