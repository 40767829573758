import { VideoUrl } from '@/config/urls';
import useFormatting from '@/hooks/useFormatting';
import { DataGroupsPopover } from '@/modules/data-groups/components/DataGroupsPopover';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Routes } from '@/router/router-paths';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, Checkbox, Divider, Tooltip } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';
import { toastService } from '@/services/toast.service';
import OppositeModeOverlayBar from '../../../components/OppositeModeOverlayBar';
import { SelectedSearchTerm } from '../models/SearchTermModel';

interface SearchTermsActionsBarProps {
  selectedSearchTerms: SelectedSearchTerm[];
  setSelectedSearchTerms: (selectedSearchTerms: SelectedSearchTerm[]) => void;
  totalSearchTermsCount: number;
  onDiscardClicked: () => void;
  onCreateNegativesParamsClicked: () => void;
  onHarvestClicked: () => void;
  searchTermsTableGridApiRef: React.MutableRefObject<GridApi | null>;
}

const SearchTermsSelectionActionsBar: FunctionComponent<SearchTermsActionsBarProps> = ({
  selectedSearchTerms,
  setSelectedSearchTerms,
  totalSearchTermsCount,
  onDiscardClicked,
  onCreateNegativesParamsClicked,
  onHarvestClicked,
  searchTermsTableGridApiRef,
}) => {
  const { activeTeam } = useActiveTeamContext();
  const { formatWithThousandsSeparator } = useFormatting();

  // Paywall
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  // Add Negatives Params
  const createNegativesParamsButtonRef = useRef<HTMLButtonElement | null>(null);

  // Tags
  const dataGroupsButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isDataGroupsPopoverOpen, setIsDataGroupsPopoverOpen] = useState(false);
  const onDataGroupsClicked = () => {
    if (isNil(activeTeam)) {
      toastService.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canAssignDataGroups) {
      setIsPaywallModalOpen(true);
      return;
    }

    setIsDataGroupsPopoverOpen(true);
  };

  // HANDLE SELECTION
  function onDeselectAll() {
    setSelectedSearchTerms([]);
    if (searchTermsTableGridApiRef.current) {
      searchTermsTableGridApiRef.current.deselectAll();
    }
  }

  function onSelectAll() {
    if (searchTermsTableGridApiRef.current) {
      searchTermsTableGridApiRef.current.selectAll();
    }
  }

  return (
    <>
      <OppositeModeOverlayBar visible={selectedSearchTerms.length > 0}>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <div onClick={onDiscardClicked} className="flex flex-row text-sm font-bold items-center hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selectedSearchTerms.length)} of {formatWithThousandsSeparator(totalSearchTermsCount)}
            </div>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row gap-4 whitespace-nowrap items-center">
            <Button
              ref={createNegativesParamsButtonRef}
              size="small"
              className="whitespace-nowrap"
              onClick={onCreateNegativesParamsClicked}
              startIcon={<RemoveCircleOutlineIcon />}
              variant="outlined"
            >
              Add Negatives
            </Button>
            <Button
              ref={dataGroupsButtonRef}
              size="small"
              className="whitespace-nowrap "
              onClick={onDataGroupsClicked}
              variant={isDataGroupsPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AccountTreeIcon />}
            >
              Tags
            </Button>
            <Divider className="my-2" orientation="vertical" flexItem />
            <Button
              className="whitespace-nowrap"
              onClick={selectedSearchTerms.length == totalSearchTermsCount ? onDeselectAll : onSelectAll}
              variant="text"
            >
              {selectedSearchTerms.length == totalSearchTermsCount ? 'Deselect All' : 'Select All'}
            </Button>
            <Button className="whitespace-nowrap" onClick={onHarvestClicked} variant="contained" endIcon={<AddCircleOutlineIcon />}>
              Harvest
            </Button>
          </div>
        </div>
      </OppositeModeOverlayBar>

      <DataGroupsPopover
        buttonRef={dataGroupsButtonRef}
        isOpen={isDataGroupsPopoverOpen}
        setIsOpen={setIsDataGroupsPopoverOpen}
        selectedItems={selectedSearchTerms}
        dataGroupType={DataGroupType.SEARCHTERM}
      />

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.OPTIMIZER}
        headerText="Upgrade to Pro to Access Tags"
        videoUrl={VideoUrl.DATA_GROUPS}
      ></PaywallModal>
    </>
  );
};

export default SearchTermsSelectionActionsBar;
