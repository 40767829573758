import { AlDate } from '@/lib/date/AlDate';
import { DTO } from '@/types/dto-wrapper';
import { DashboardReadDTO } from '../api/dashboard/dashboard.contracts';
import { TeamProfile } from './TeamProfile';

export class DashboardModel extends DTO<DashboardReadDTO> {
  constructor(dto: DashboardReadDTO) {
    super(dto);
  }

  get teamProfiles(): TeamProfile[] {
    return this.dto?.profiles?.map<TeamProfile>((teamProfile) => {
      return {
        profileId: teamProfile.profile_id.toString(),
        profileName: teamProfile.profile_name,
        teamId: teamProfile.team_id,
        teamName: teamProfile.profile_name,
        countryCode: teamProfile.country_code,
        state: teamProfile.state,
      };
    });
  }

  get teamId(): number {
    return this.dto.team_id;
  }

  get filterDateRangeStart(): AlDate {
    const startDate = this.dto.filter_start_date_offset_in_days
      ? AlDate.now().subtractDays(this.dto.filter_start_date_offset_in_days)
      : AlDate.now();
    return startDate.startOf('day');
  }

  get filterDateRangeEnd(): AlDate {
    const endDate = this.dto.filter_end_date_offset_in_days
      ? AlDate.now().subtractDays(this.dto.filter_end_date_offset_in_days)
      : AlDate.now();
    return endDate.endOf('day');
  }

  get filterComparisonDateRangeStart(): AlDate {
    const startDate = this.dto.filter_compare_start_date_offset_in_days
      ? AlDate.now().subtractDays(this.dto.filter_compare_start_date_offset_in_days)
      : AlDate.now();
    return startDate.startOf('day');
  }

  get filterComparisonDateRangeEnd(): AlDate {
    const endDate = this.dto.filter_compare_end_date_offset_in_days
      ? AlDate.now().subtractDays(this.dto.filter_compare_end_date_offset_in_days)
      : AlDate.now();
    return endDate.endOf('day');
  }
}
