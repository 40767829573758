import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import { QueryClient } from '@tanstack/react-query';
import { NegativeTargetModel, NegativeTargetingResponse, NegativeTargetsStatsModel } from '../models/NegativeTargetsModel';
import {
  CreateNegativesDTO,
  CreateNegativesResponseDTO,
  NegativeTargetsAllDTO,
  NegativeTargetsUpdateDTO,
  UpdatedNegativeTargetsDTO,
} from './negative-targets-contracts';
import { CreateNegativeModel } from '../models/CreateNegativeModel';
import { PayloadWithFiltersDTO } from '@/components/filter-builder/api/filters-contracts';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';

// Don't expose this and only use creators
const _NEGATIVE_TARGETS_WITH_TIMELINE_QUERY_KEY = 'negative-targets-with-timeline';

// Main key to use
export function createNegativeTargetingQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  const queryKey_withoutFilters = createNegativeTargetingQueryKey_withoutFilters(activeProfileId);
  return [...queryKey_withoutFilters, filters.map((filter) => filter.toQueryKey())];
}

function createNegativeTargetingQueryKey_withoutFilters(activeProfileId: string | undefined) {
  return [_NEGATIVE_TARGETS_WITH_TIMELINE_QUERY_KEY, activeProfileId];
}

// export function invalidateAll_negativeTargetingQueryKeys(queryClient: QueryClient) {
//   const key = [_NEGATIVE_TARGETS_WITH_TIMELINE_QUERY_KEY];
//   queryClient.invalidateQueries({
//     predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
//   });
// }
export function invalidateProfile_negativeTargetingQueryKeys(queryClient: QueryClient, activeProfileId: string | undefined) {
  const key = createNegativeTargetingQueryKey_withoutFilters(activeProfileId);
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

class NegativeTargetingService {
  public basePath = 'negative-targets';

  async getNegativeTargets(filters?: AlFilterModel[]): Promise<ApiResponse<NegativeTargetingResponse>> {
    try {
      const filterData = filters?.filter((f) => f.key != FilterKey.DATE && f.key != FilterKey.COMPARE_DATE).map((filter) => filter.toDTO());
      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }
      const applicationResponse = await apiProfileClient.post<NegativeTargetsAllDTO>(`${this.basePath}/`, requestData);
      return applicationResponse.processPayload((payload) => ({
        negativeTargeting: NegativeTargetModel.fromResponseArray(payload.negatives),
        stats: NegativeTargetsStatsModel.fromResponse(payload),
      }));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async createNegativeTargets(negativeTargets: CreateNegativeModel[], flowType: FlowType): Promise<ApiResponse<CreateNegativesResponseDTO>> {
    const payload: CreateNegativesDTO = {
      flow_type: flowType,
      negative_targets: negativeTargets.map((negativeTarget) => negativeTarget.toDTO()),
    };

    return apiProfileClient.post<CreateNegativesResponseDTO>(`${this.basePath}/create`, payload);
  }

  async updateNegativeTargeting(targetUpdates: NegativeTargetsUpdateDTO[]): Promise<ApiResponse<UpdatedNegativeTargetsDTO[]>> {
    try {
      const payload = {
        updates: targetUpdates,
      };

      return await apiProfileClient.post<UpdatedNegativeTargetsDTO[]>(`${this.basePath}/update`, payload);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const negativeTargetingService = new NegativeTargetingService();
