import { sleep } from '@/lib/api/api-utils';
import { Routes } from '@/router/router-paths';
import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';

const SeeLogsButton: FunctionComponent = () => {
  const navigate = useNavigate();
  async function onClick() {
    await sleep(50); // wait for 50 ms before navigate
    navigate(Routes.LOGS);
  }

  return (
    <Button variant="contained" onClick={onClick}>
      See Logs for more details
    </Button>
  );
};

export default SeeLogsButton;
