import { FunctionComponent, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { CloudUploadOutlined } from '@mui/icons-material';

interface DropZoneProps {
  file: File | null;
  onFileDragged: (file: File | null) => void;
  acceptedFileTypes: { [key: string]: string[] };
}

const DropZone: FunctionComponent<DropZoneProps> = ({ file, onFileDragged, acceptedFileTypes }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        onFileDragged(acceptedFiles[0]);
      } else {
        onFileDragged(null);
      }
    },
    [onFileDragged],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
  });

  return (
    <div
      {...getRootProps()}
      className={`border-dashed border  rounded-lg  px-6 py-12 text-center w-full max-w-screen-md hover:cursor-pointer
      ${isDragActive ? 'border-primary-500' : 'border-gray-400 hover:border-primary-500'}
      ${isDragActive ? 'bg-primary-100' : 'bg-gray-100 hover:bg-primary-100'}
    ${isDragReject ? '!border-red-500 !bg-red-100 !cursor-not-allowed' : ''}
        `}
    >
      <input {...getInputProps()} />
      <div className="text-lg text-gray-600 flex justify-center items-center">
        {<CloudUploadOutlined fontSize="inherit" className="mr-2" />}
        {isDragReject
          ? 'File type not accepted, please select a valid file type'
          : isDragActive
            ? 'Drop the file here...'
            : 'Drag & drop the file here, or click to select the file'}

        {file && <div className="text-sm text-gray-400 mt-2">File: {file.name}</div>}
      </div>
    </div>
  );
};

export default DropZone;
