import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { TeamModel } from '@/modules/teams/types/TeamModel';
import { useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import { ButtonBase, ListItemIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { Fragment, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

export const TeamSelect = () => {
  const { user } = useUserContext();
  const { activeTeam, setActiveTeam } = useActiveTeamContext();
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleClick = () => {
    setIsDropdownOpen(true);
  };
  const handleClose = () => {
    setIsDropdownOpen(false);
  };

  const onTeamClicked = (team: TeamModel) => {
    if (team) {
      setActiveTeam({ teamId: team.id });
    }

    setIsDropdownOpen(false);
  };

  const manageTeamsClicked = () => {
    navigate(Routes.TEAMS);
  };

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Switch Teams">
          <ButtonBase
            ref={buttonRef}
            className={`min-w-32 h-9 flex cursor-pointer justify-center rounded-md p-2 font-semibold text-gray-850 bg-white hover:bg-primary-50 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700`}
            onClick={handleClick}
            aria-roledescription="button"
            role="button"
            tabIndex={0}
          >
            <div className="flex items-center gap-1 px-1 ">
              <GroupsRoundedIcon fontSize="small" />
              <div className="pl-2">{activeTeam?.name}</div>
              <ArrowDropDownRoundedIcon fontSize="small" />
            </div>
          </ButtonBase>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={buttonRef.current}
        id="account-menu"
        open={isDropdownOpen}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {user?.teams
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((team) => {
            return (
              <MenuItem key={team.id} onClick={() => onTeamClicked(team)} disabled={activeTeam?.id == team.id}>
                {team.name}
              </MenuItem>
            );
          })}

        <Divider />

        <MenuItem onClick={manageTeamsClicked}>
          <ListItemIcon>
            <GroupsRoundedIcon />
          </ListItemIcon>
          Manage teams
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
