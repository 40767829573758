import { ReportingStatusType } from '@/modules/profiles/api/profile.contracts';
import { useReportsContext } from '@/modules/teams/contexts/ReportsContext';
import { useMemo } from 'react';
import {
  BidStrategyType,
  CampaignAdType,
  CostType,
  EnabledPausedArchivedState,
  MultiAdGroupsEnabledType,
} from '../api/campaign/campaign-contracts';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';

export interface PreOptimizationInfo {
  warnings: string[];
  stats: PreOptimizationDataStats;
}

interface PreOptimizationDataStats {
  selectedCampaignsGroupedCount: number;
  selectedCampaignsUnGroupedCount: number;
  cantOptimizeCampaignsCount: number;
}

interface UseOptimizationWarningsProps {
  selectedCampaigns: SelectedCampaignDTO[];
}

const useOptimizationWarnings = ({ selectedCampaigns }: UseOptimizationWarningsProps) => {
  const { activeProfileReportsStatusInfo } = useReportsContext();

  const preOptimizationInfo: PreOptimizationInfo = useMemo(() => {
    const warningMessages: string[] = [];

    // Rules that restrict optimization (also listed in OptimizerPage)
    const selectedCampaignsWithCostTypeVCPMIds = selectedCampaigns.filter((c) => c.costType == CostType.VCPM).map((c) => c.id);

    const selectedCampaignsWithMultiAdGroupsFalseIds = selectedCampaigns
      .filter((c) => c.multiAdGroupsEnabled == MultiAdGroupsEnabledType.FALSE)
      .map((c) => c.id);

    const selectedCampaignsWithStatePausedArchivedIds = selectedCampaigns
      .filter((c) => c.state == EnabledPausedArchivedState.PAUSED || c.state == EnabledPausedArchivedState.ARCHIVED)
      .map((c) => c.id);

    const selectedCampaignsWithEndDatePassedIds = selectedCampaigns
      .filter((c) => c.endDate && new Date(c.endDate) < new Date())
      .map((c) => c.id);

    const sponsoredTVIds = selectedCampaigns.filter((c) => c.adType == CampaignAdType.TV).map((c) => c.id);

    const allIdsThatCantBeOptimized = new Set([
      ...selectedCampaignsWithCostTypeVCPMIds,
      ...selectedCampaignsWithMultiAdGroupsFalseIds,
      ...selectedCampaignsWithStatePausedArchivedIds,
      ...selectedCampaignsWithEndDatePassedIds,
      ...sponsoredTVIds,
    ]);

    // Other
    const selectedCampaignsGroupedCount = selectedCampaigns.filter((c) => c.groupId).length;
    const selectedCampaignsUnGroupedCount = selectedCampaigns.length - selectedCampaignsGroupedCount;
    const selectedCampaignsWithOptRuleCount = selectedCampaigns.filter((c) => c.hasOptRule).length; // opt rule can be true for only SD campaigns

    const selectedCampaignsWithDynamicBiddingUpAndDownCount = selectedCampaigns.filter((c) => {
      return c.bidStrategy == BidStrategyType.AUTO_FOR_SALES;
    }).length;

    if (activeProfileReportsStatusInfo.status == ReportingStatusType.OUTDATED) {
      warningMessages.push(`Data is outdated`);
    }

    if (selectedCampaignsWithDynamicBiddingUpAndDownCount > 0) {
      warningMessages.push(
        `${selectedCampaignsWithDynamicBiddingUpAndDownCount} SP campaigns with Dynamic Bidding (Up & Down).  We recommend Dynamic (Down Only) or Fixed Bids to get the best results from the bid optimizer`,
      );
    }

    if (selectedCampaignsWithCostTypeVCPMIds.length > 0) {
      warningMessages.push(`${selectedCampaignsWithCostTypeVCPMIds.length} vCPM campaign(s) do not support bid optimization.`);
    }

    if (selectedCampaignsWithMultiAdGroupsFalseIds.length > 0) {
      warningMessages.push(`${selectedCampaignsWithMultiAdGroupsFalseIds.length} Legacy SB campaign(s) do not support bid optimizations.`);
    }

    if (selectedCampaignsWithOptRuleCount > 0) {
      warningMessages.push(
        `${selectedCampaignsWithOptRuleCount} Campaign(s) with an Amazon Optimization Rule ad group do not support bid optimizations`,
      );
    }

    if (sponsoredTVIds.length > 0) {
      warningMessages.push(`Sponsored TV Campaigns (${sponsoredTVIds.length}) do not support bid optimizations`);
    }

    if (allIdsThatCantBeOptimized.size == selectedCampaigns.length) {
      warningMessages.push(`None of the selected campaigns can be optimized`);
    }

    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    const numberOfCampaignsOptimizedToday = selectedCampaigns.reduce((count, campaign) => {
      if (campaign.lastOptimizedAt) {
        const lastOptimizedDate = new Date(campaign.lastOptimizedAt).toISOString().split('T')[0];
        if (lastOptimizedDate === today) {
          return count + 1;
        }
      }
      return count;
    }, 0);

    if (numberOfCampaignsOptimizedToday > 0) {
      warningMessages.push(`${numberOfCampaignsOptimizedToday} of selected campaigns already optimized today`);
    }

    return {
      warnings: warningMessages,
      stats: { selectedCampaignsGroupedCount, selectedCampaignsUnGroupedCount, cantOptimizeCampaignsCount: allIdsThatCantBeOptimized.size },
    };
  }, [selectedCampaigns]);

  return { preOptimizationInfo };
};

export default useOptimizationWarnings;
