import MetricTimelineChart from '@/components/chart/MetricTimelineChart';
import { createAdvertisedProductsWithTimelineQueryKey, productsService } from '@/modules/products/api/products-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { FunctionComponent } from 'react';
import { useAdvertisedProductsContext } from '../contexts/AdvertisedProductsContext';

const AdvertisedProductsTimelineChart: FunctionComponent = () => {
  const { filters, visibleMetrics } = useAdvertisedProductsContext();
  const { activeProfile } = useActiveTeamContext();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: createAdvertisedProductsWithTimelineQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await productsService.getAdvertisedProductsWithTimeline(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading timeline data ' + JSON.stringify(result));
      }
    },

    enabled: !isEmpty(visibleMetrics.filter((metric) => metric.isSelected)) && !isEmpty(filters),
    retry: false,
  });

  return (
    <MetricTimelineChart
      visibleMetrics={visibleMetrics}
      timelineData={data?.timeline}
      isLoading={isLoading}
      error={error}
      isError={isError}
    />
  );
};

export default AdvertisedProductsTimelineChart;
