import { CommonMetricsDTO } from '../api/metrics-contracts';
import { EMPTY_METRIC_DATA, EMPTY_METRIC_DATA_WITH_PREVIOUS_DAYS, MetricData, MetricDataWithPreviousDays } from '../types/MetricData';
import { CommonMetricField, MetricField, ProfileStatsSellerMetricField, SellerMetricField } from '../types/MetricField';

export class CommonMetrics implements Record<CommonMetricField, MetricData | MetricDataWithPreviousDays> {
  public impressions: MetricData;
  public clicks: MetricData;
  public orders: MetricData;
  public units: MetricData;
  public ctr: MetricData;
  public cvr: MetricData;
  public cpc: MetricData;
  public spend: MetricDataWithPreviousDays;
  public sales: MetricDataWithPreviousDays;
  public sso: MetricData;
  public sss: MetricData;
  public acos: MetricData;
  public roas: MetricData;
  public rpc: MetricData;
  public cpa: MetricData;
  public aov: MetricData;
  public cpm: MetricData;

  constructor(args: CommonMetricsCreateArguments) {
    this.impressions = args.impressions;
    this.clicks = args.clicks;
    this.orders = args.orders;
    this.units = args.units;
    this.ctr = args.ctr;
    this.cvr = args.cvr;
    this.cpc = args.cpc;
    this.spend = args.spend;
    this.sales = args.sales;
    this.sso = args.sso;
    this.sss = args.sss;
    this.acos = args.acos;
    this.roas = args.roas;
    this.rpc = args.rpc;
    this.cpa = args.cpa;
    this.aov = args.aov;
    this.cpm = args.cpm;
  }

  public static fromResponse(response: CommonMetricsDTO): CommonMetrics {
    return new CommonMetrics({
      impressions: response.impressions,
      clicks: response.clicks,
      orders: response.orders,
      units: response.units,
      ctr: response.ctr,
      cvr: response.cvr,
      cpc: response.cpc,
      spend: response.spend,
      sales: response.sales,
      sso: response.sso,
      sss: response.sss,
      acos: response.acos,
      roas: response.roas,
      rpc: response.rpc,
      cpa: response.cpa,
      aov: response.aov,
      cpm: response.cpm,
    });
  }

  public static withZeroMetrics(): CommonMetrics {
    const zeroMetrics = new CommonMetrics({
      impressions: EMPTY_METRIC_DATA,
      clicks: EMPTY_METRIC_DATA,
      orders: EMPTY_METRIC_DATA,
      units: EMPTY_METRIC_DATA,
      ctr: EMPTY_METRIC_DATA,
      cvr: EMPTY_METRIC_DATA,
      cpc: EMPTY_METRIC_DATA,
      spend: EMPTY_METRIC_DATA_WITH_PREVIOUS_DAYS,
      sales: EMPTY_METRIC_DATA_WITH_PREVIOUS_DAYS,
      sso: EMPTY_METRIC_DATA,
      sss: EMPTY_METRIC_DATA,
      acos: EMPTY_METRIC_DATA,
      roas: EMPTY_METRIC_DATA,
      rpc: EMPTY_METRIC_DATA,
      cpa: EMPTY_METRIC_DATA,
      aov: EMPTY_METRIC_DATA,
      cpm: EMPTY_METRIC_DATA,
    });
    return zeroMetrics;
  }
}

export interface CommonMetricsCreateArguments {
  impressions: MetricData;
  clicks: MetricData;
  orders: MetricData;
  units: MetricData;
  ctr: MetricData;
  cvr: MetricData;
  cpc: MetricData;
  spend: MetricDataWithPreviousDays;
  sales: MetricDataWithPreviousDays;
  sso: MetricData;
  sss: MetricData;
  acos: MetricData;
  roas: MetricData;
  rpc: MetricData;
  cpa: MetricData;
  aov: MetricData;
  cpm: MetricData;
}

export const calculatedFieldDependencies: Partial<Record<MetricField, MetricField[]>> = {
  [CommonMetricField.CTR]: [CommonMetricField.CLICKS, CommonMetricField.IMPRESSIONS],
  [CommonMetricField.CVR]: [CommonMetricField.ORDERS, CommonMetricField.CLICKS],
  [CommonMetricField.CPC]: [CommonMetricField.SPEND, CommonMetricField.CLICKS],
  [CommonMetricField.ACOS]: [CommonMetricField.SPEND, CommonMetricField.SALES],
  [CommonMetricField.ROAS]: [CommonMetricField.SALES, CommonMetricField.SPEND],
  [CommonMetricField.RPC]: [CommonMetricField.SALES, CommonMetricField.CLICKS],
  [CommonMetricField.CPA]: [CommonMetricField.SPEND, CommonMetricField.ORDERS],
  [CommonMetricField.AOV]: [CommonMetricField.SALES, CommonMetricField.ORDERS],
  [CommonMetricField.CPM]: [CommonMetricField.SPEND, CommonMetricField.IMPRESSIONS],

  [SellerMetricField.SELLER_ACOS]: [CommonMetricField.SPEND, SellerMetricField.SELLER_SALES],
  [SellerMetricField.SELLER_ASP]: [SellerMetricField.SELLER_SALES, SellerMetricField.SELLER_UNITS],
  [SellerMetricField.SELLER_CVR]: [SellerMetricField.SELLER_ORDERS, SellerMetricField.SELLER_CLICKS],
  [SellerMetricField.SELLER_UNIT_SESS]: [SellerMetricField.SELLER_UNITS, SellerMetricField.SELLER_CLICKS],
  [SellerMetricField.SELLER_AD_SALES_OF_TOTAL]: [CommonMetricField.SALES, SellerMetricField.SELLER_SALES],
  [SellerMetricField.SELLER_AOV]: [SellerMetricField.SELLER_SALES, SellerMetricField.SELLER_ORDERS],
  [SellerMetricField.SELLER_CPA]: [CommonMetricField.SPEND, SellerMetricField.SELLER_ORDERS],
  [SellerMetricField.SELLER_ROAS]: [SellerMetricField.SELLER_SALES, CommonMetricField.SPEND],
  [SellerMetricField.SELLER_UNIT_VIEW]: [SellerMetricField.SELLER_UNITS, SellerMetricField.SELLER_VIEWS],
  [ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE]: [
    ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED,
    SellerMetricField.SELLER_UNITS,
  ],
};
