import { createContext, FunctionComponent, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
interface LayoutContext {
  // SCROLLING
  onSourceScroll: (scrollPosition: number) => void;
  scrollDownTargetDivRef: React.MutableRefObject<HTMLDivElement | null>;
  isDisplayingBanner: boolean;
  setIsDisplayingBanner: (isDisplayingBanner: boolean) => void;

  // LOADING
  startGlobalLoading: (id: string | (string | string[] | undefined)[]) => void;
  stopGlobalLoading: (id: string | (string | string[] | undefined)[]) => void;
  setGlobalLoadingStateForKey: (id: string | (string | string[] | undefined)[], isLoading: boolean) => void;
  isGlobalLoading: boolean;
}

export const LayoutContext = createContext<LayoutContext>({
  // SCROLLING
  onSourceScroll: () => null,
  scrollDownTargetDivRef: { current: null },
  isDisplayingBanner: false,
  setIsDisplayingBanner: () => null,

  // LOADING
  startGlobalLoading: () => null,
  stopGlobalLoading: () => null,
  setGlobalLoadingStateForKey: () => null,
  isGlobalLoading: false,
});

export const LayoutProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [isDisplayingBanner, setIsDisplayingBanner] = useState(false);
  const lastScrollTopRef = useRef(0);
  const scrollDownTargetDivRef = useRef<HTMLDivElement>(null);
  const requestAnimationFrameRef = useRef<number>();

  const onSourceScroll = (scrollPosition: number) => {
    if (!scrollDownTargetDivRef.current) return;
    const diff = scrollPosition - lastScrollTopRef.current;
    // Only scroll down (diff positive)
    if (diff > 0) {
      // Frame wrapper is more performant - updates with browser natural render instead of separately
      requestAnimationFrameRef.current = requestAnimationFrame(() => {
        if (!scrollDownTargetDivRef.current) return;
        scrollDownTargetDivRef.current.scrollBy({
          top: diff,
          behavior: 'instant', // 'smooth' jumps back and is not _smooth_
        });
      });
    }
    lastScrollTopRef.current = scrollPosition;
  };

  useEffect(() => {
    return () => {
      // Cleanup
      if (requestAnimationFrameRef.current) cancelAnimationFrame(requestAnimationFrameRef.current);
    };
  }, []);

  // GLOBAL LOADING
  const [loadingItems, setGlobalLoadingItems] = useState<Map<string, NodeJS.Timeout>>(new Map());

  function loadingIdStringGenerator(id: string | (string | string[] | undefined)[]) {
    return Array.isArray(id) ? JSON.stringify(id.flat(Infinity)) : JSON.stringify([id]);
  }

  const startGlobalLoading = useCallback((id: string | (string | string[] | undefined)[]) => {
    const jsonId = loadingIdStringGenerator(id);

    if (!loadingItems.has(jsonId)) {
      const timeout = setTimeout(() => {
        stopGlobalLoading(jsonId);
        console.error(`Loading task "${jsonId}" timed out after 2 minutes.`);
      }, 120000); // 2 minutes timeout

      setGlobalLoadingItems((prev) => new Map(prev).set(jsonId, timeout));
    }
  }, []);

  const stopGlobalLoading = useCallback((id: string | (string | string[] | undefined)[]) => {
    const jsonId = loadingIdStringGenerator(id);
    setGlobalLoadingItems((prev) => {
      const newItems = new Map(prev);
      const timeout = newItems.get(jsonId);
      if (timeout) clearTimeout(timeout);
      newItems.delete(jsonId);
      return newItems;
    });
  }, []);

  const setGlobalLoadingStateForKey = useCallback((id: string | (string | string[] | undefined)[], isLoading: boolean) => {
    const jsonId = loadingIdStringGenerator(id);
    if (isLoading) {
      startGlobalLoading(jsonId);
    } else {
      stopGlobalLoading(jsonId);
    }
  }, []);

  const isGlobalLoading = loadingItems.size > 0;
  return (
    <LayoutContext.Provider
      value={{
        onSourceScroll,
        scrollDownTargetDivRef,
        isDisplayingBanner,
        setIsDisplayingBanner,

        startGlobalLoading,
        stopGlobalLoading,
        setGlobalLoadingStateForKey,
        isGlobalLoading,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = (): LayoutContext => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayoutContext must be used within a LayoutProvider');
  }
  return context;
};
