import { NegativeMatchType } from '@/modules/negative-targets/api/negative-targets-contracts';

// TODO: most is duplicate with the other BidUpdateType
export enum BidUpdateType {
  SET_BID_TO_AMOUNT = 'SET_BID_TO_AMOUNT',
  INCREASE_BID_BY_AMOUNT = 'INCREASE_BID_BY_AMOUNT',
  DECREASE_BID_BY_AMOUNT = 'DECREASE_BID_BY_AMOUNT',
  INCREASE_BID_BY_PERCENTAGE = 'INCREASE_BID_BY_PERCENTAGE',
  DECREASE_BID_BY_PERCENTAGE = 'DECREASE_BID_BY_PERCENTAGE',
  SET_NEGATIVES = 'SET_NEGATIVES',

  NO_CHANGE = 'NO_CHANGE',
}

export interface BidUpdateData {
  bidUpdateType: BidUpdateType;
  newBidValue: number;
  selectedNegatives: NegativeMatchType[];
}
