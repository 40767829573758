import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { toastService } from '@/services/toast.service';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { useUserContext, userService } from '@/modules/users';

interface AuthorizeAmazonDialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const AuthorizeAmazonDialog: FunctionComponent<AuthorizeAmazonDialogProps> = ({ isOpen, setIsOpen }) => {
  const { activeTeam } = useActiveTeamContext();
  const { user } = useUserContext();

  const [isGetAuthUrlLoading, setIsGetAuthUrlLoading] = useState(false);
  function onClose() {
    setIsOpen(false);
  }

  const handleConfirm = async () => {
    onClose();
    setIsGetAuthUrlLoading(true);
    const authUrl = await getAuthUrl();
    setIsGetAuthUrlLoading(false);
    if (isEmpty(authUrl)) {
      toastService.error('Something went wrong. Auth url is empty. Please try again later.');
    } else {
      window.location.replace(authUrl); // user will not be able to use browser's back button
    }
  };

  async function getAuthUrl(): Promise<string> {
    if (user?.id && activeTeam?.id) {
      const res = await userService.getAmazonAuthorizationUrl(user?.id, activeTeam?.id);
      if (res.isSuccess) {
        return res.payload;
      } else {
        toastService.error('Unable to get auth url. Please try again later.');
      }
    } else {
      toastService.error('User or active team not set.');
    }

    return '';
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Amazon account not connected!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your team is not connected to any amazon account. Please connect to an amazon account to add profiles.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton variant="contained" onClick={handleConfirm} autoFocus loading={isGetAuthUrlLoading}>
          Go to auth page
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AuthorizeAmazonDialog;
