import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import { Card, CardActionArea, CardContent } from '@mui/material';
import { FunctionComponent } from 'react';

type AddMetricButtonProps = {
  onClick: () => void;
};

const AddMetricButton: FunctionComponent<AddMetricButtonProps> = ({ onClick }) => {
  return (
    <Card className="min-w-36 rounded-lg border-dashed border-gray-300 bg-gray-100 dark:bg-gray-900 dark:border-gray-700">
      <CardActionArea className="flex h-full items-center justify-center pl-1" onClick={onClick}>
        <CardContent>
          <AddchartRoundedIcon className={'disabled-opacity-reduce'} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AddMetricButton;
