import { CommonMetricField, MetricField, ProfileStatsSellerMetricField, SellerMetricField } from '@/components/metrics/types/MetricField';
import { AlDate, FULL_WEEEK_DAY_FORMAT } from '@/lib/date/AlDate';

import { assertUnhandledCase } from '@/modules/application/utils';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';

export interface FormattingParams {
  customCurrencyCode: string | undefined;
}

type FormatterFunction = (value: number | null | undefined, formattingParams?: FormattingParams) => string;

const PERCENT_MAX = 999;

export enum CurrencyPosition {
  LEFT,
  RIGHT,
}

function useFormatting() {
  const { activeProfile } = useActiveTeamContext();
  const activeProfileCurrencyCode = activeProfile && isValidCurrencyCode(activeProfile.currencyCode) ? activeProfile.currencyCode : 'USD';

  const percentageFormatter = useMemo(() => {
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, []);

  const formatNumbersAsAbbreviationsFormatter = (number: number | null | undefined): string => {
    if (isNil(number)) return '';

    let fractionDigits;
    if (number >= 1000) {
      fractionDigits = isExactThousandOrMillion(number) ? 0 : 1;
    } else {
      fractionDigits = number % 1 !== 0 ? 2 : 0;
    }

    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: fractionDigits,
    }).format(number);
  };

  const formatPercent = (number: number | null | undefined): string => {
    if (isNil(number)) return '';

    const formatted = percentageFormatter.format(number);
    // 23.33% to 23.3%, but 0.25% stays as is
    if (number >= 0.1 || number <= -0.1) {
      return formatted.slice(0, -2) + formatted.slice(-1); // remove 1 digit with '%' and add trailing '%' back
    }

    return formatted;
  };

  const formatChangeInPercent = (current: number, previous: number): string => {
    if (current === previous) return '0.00%';

    if (previous === 0) return '-';

    const change = (current - previous) / Math.abs(previous);
    return formatPercent(change);
  };

  const formatChangeInUnsignedPercentWithArrow = (current: number, previous: number): string => {
    if (current === previous) return '0.00%';

    if (previous === 0) return '-';

    const change = current / previous - 1;
    const arrow = getDirectionArrow(change);
    if (change * 100 > PERCENT_MAX) {
      return `${PERCENT_MAX}+% ` + arrow;
    } else if (change * 100 < -1 * PERCENT_MAX) {
      return `-${PERCENT_MAX}% ` + arrow;
    }
    return formatPercent(change) + ' ' + arrow;
  };

  const formatPercentWithArrow = (number: number): string => {
    let changeDirection = 0;
    if (number > 0) {
      changeDirection = 1;
    } else if (number < 0) {
      changeDirection = -1;
    }
    const arrow = getDirectionArrow(changeDirection);
    if (number * 100 > PERCENT_MAX) {
      return `${PERCENT_MAX}+% ` + arrow;
    } else if (number * 100 < -1 * PERCENT_MAX) {
      return `-${PERCENT_MAX}% ` + arrow;
    }
    return formatPercent(number) + ' ' + arrow;
  };

  const formatWithThousandsSeparatorFormatter = (number: number | null | undefined): string => {
    if (isNil(number)) return '';
    return new Intl.NumberFormat('en-US').format(number);
  };

  const formatWithThousandsSeparator = (number: number | null | undefined): string => {
    if (isNil(number)) return '';
    return formatWithThousandsSeparatorFormatter(parseFloat(number.toFixed(2)));
  };

  const formatCurrency = (number: number | null | undefined, formattingParams?: FormattingParams): string => {
    if (isNil(number)) return '';

    const customCurrencyCode = formattingParams?.customCurrencyCode;

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: customCurrencyCode && isValidCurrencyCode(customCurrencyCode) ? customCurrencyCode : activeProfileCurrencyCode,
      currencyDisplay: 'narrowSymbol',
    }).format(number);
  };

  const formatCurrencyWholeNumbers = (number: number | null | undefined, formattingParams?: FormattingParams): string => {
    if (isNil(number)) return '';

    const fractionDigits = number % 1 !== 0 ? 2 : 0;

    const customCurrencyCode = formattingParams?.customCurrencyCode;

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: customCurrencyCode && isValidCurrencyCode(customCurrencyCode) ? customCurrencyCode : activeProfileCurrencyCode,
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: fractionDigits,
    }).format(number);
  };

  const getDirectionArrow = (number: number): string => {
    if (number == 0) return '';
    return number > 0 ? '↑' : '↓';
  };

  const formatWholePercent = (number: number | null | undefined): string => {
    if (isNil(number)) return '';

    return (number * 100).toFixed(0) + '%';
  };

  const formatCurrencyWithAbbreviations = (number: number | null | undefined, formattingParams?: FormattingParams): string => {
    if (isNil(number)) return '';

    let fractionDigits;
    if (number >= 1000) {
      fractionDigits = isExactThousandOrMillion(number) ? 0 : 1;
    } else {
      fractionDigits = number % 1 !== 0 ? 2 : 0;
    }

    const customCurrencyCode = formattingParams?.customCurrencyCode;

    return number.toLocaleString('en-US', {
      style: 'currency',
      currency: customCurrencyCode && isValidCurrencyCode(customCurrencyCode) ? customCurrencyCode : activeProfileCurrencyCode,
      notation: 'compact',
      maximumFractionDigits: fractionDigits,
      currencyDisplay: 'narrowSymbol',
    });
  };

  const formatDateStringTimeNoSeconds = (dateString: string | null | undefined): string => {
    try {
      if (isNil(dateString) || isNullTimestamp(dateString)) return '';
      const dateObj = new Date(dateString);

      // Format the date object to local time without seconds or milliseconds
      const formattedDate = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      }).format(dateObj);

      return formattedDate;
    } catch (err) {
      console.error(err);
      return dateString ?? '';
    }
  };

  const formatDateAsTimeWithTimezoneNoSeconds = (dateString: string | null | undefined): string => {
    try {
      if (isNil(dateString) || isNullTimestamp(dateString)) return '';
      const dateObj = new Date(dateString);

      // Format the date object to local time without seconds or milliseconds
      const formattedDate = new Intl.DateTimeFormat(undefined, {
        year: undefined,
        month: undefined,
        day: undefined,
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      }).format(dateObj);

      return formattedDate;
    } catch (err) {
      console.error(err);
      return dateString ?? '';
    }
  };

  const formatDateStringTimeNoHours = (dateString: string | null | undefined): string => {
    try {
      if (isNil(dateString) || isNullTimestamp(dateString)) return '';
      const dateObj = new Date(dateString);

      const formattedDate = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }).format(dateObj);

      return formattedDate;
    } catch (err) {
      console.log('Error parsing date: ', err);
      return dateString ?? '';
    }
  };

  function getCurrencySymbol(currencyCode?: CurrencyCode) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode ?? activeProfileCurrencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      currencyDisplay: 'narrowSymbol',
    });

    // Extracts and returns the currency symbol from formatted string
    const value = formatter.format(0);
    return value.replace(/\d/g, '').trim();
  }

  function getCurrencySymbolPosition(currencyCode?: CurrencyCode): CurrencyPosition {
    const formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode ?? activeProfileCurrencyCode }).format(0);
    const zeroPosition = formatted.indexOf('0');

    if (zeroPosition === 0) {
      return CurrencyPosition.RIGHT;
    } else {
      return CurrencyPosition.LEFT;
    }
  }

  function isExactThousandOrMillion(number: number): boolean {
    if (number < 1000) {
      return false; // Exclude numbers less than 1000
    }
    // Check if the number is a round number in thousands, millions, etc.
    let divisor = 1000;
    while (number / divisor >= 10) {
      divisor *= 10;
    }
    return number % divisor === 0;
  }

  const getShortFormatterForMetricField = (field: MetricField): FormatterFunction => {
    switch (field) {
      case CommonMetricField.IMPRESSIONS:
      case CommonMetricField.CLICKS:
      case CommonMetricField.ORDERS:
      case CommonMetricField.ROAS:
      case CommonMetricField.SAME_SKU_ORDERS:
      case CommonMetricField.UNITS:
      case SellerMetricField.SELLER_CLICKS:
      case SellerMetricField.SELLER_ORDERS:
      case SellerMetricField.SELLER_UNITS:
      case SellerMetricField.SELLER_VIEWS:
      case SellerMetricField.SELLER_ORG_TRAFFIC:
      case SellerMetricField.SELLER_ROAS:
      case ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED:
        return formatNumbersAsAbbreviationsFormatter;

      case CommonMetricField.CTR:
      case CommonMetricField.CVR:
      case CommonMetricField.ACOS:
      case SellerMetricField.SELLER_ACOS:
      case SellerMetricField.SELLER_CVR:
      case SellerMetricField.SELLER_UNIT_SESS:
      case SellerMetricField.SELLER_AD_SALES_OF_TOTAL:
      case SellerMetricField.SELLER_UNIT_VIEW:
      case ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE:
        return formatPercent;

      case CommonMetricField.CPC:
      case CommonMetricField.SPEND:
      case CommonMetricField.SALES:
      case CommonMetricField.RPC:
      case CommonMetricField.CPA:
      case CommonMetricField.AOV:
      case CommonMetricField.CPM:
      case CommonMetricField.SAME_SKU_SALES:
      case SellerMetricField.SELLER_ASP:
      case SellerMetricField.SELLER_ORG_SALES:
      case SellerMetricField.SELLER_SALES:
      case SellerMetricField.SELLER_AOV:
      case SellerMetricField.SELLER_CPA:
        return formatCurrencyWithAbbreviations;

      default:
        return assertUnhandledCase(field);
    }
  };

  //TODO: replace switch with data from METRICS: CompleteMetricConfigMap
  const getLongFormatterForMetricField = (field: MetricField): FormatterFunction => {
    switch (field) {
      case CommonMetricField.IMPRESSIONS:
      case CommonMetricField.CLICKS:
      case CommonMetricField.ORDERS:
      case CommonMetricField.UNITS:
      case CommonMetricField.ROAS:
      case CommonMetricField.SAME_SKU_ORDERS:
      case SellerMetricField.SELLER_CLICKS:
      case SellerMetricField.SELLER_ORDERS:
      case SellerMetricField.SELLER_UNITS:
      case SellerMetricField.SELLER_VIEWS:
      case SellerMetricField.SELLER_ORG_TRAFFIC:
      case SellerMetricField.SELLER_ROAS:
      case ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED:
        return formatWithThousandsSeparator;

      case CommonMetricField.CTR:
      case CommonMetricField.CVR:
      case CommonMetricField.ACOS:
      case SellerMetricField.SELLER_ACOS:
      case SellerMetricField.SELLER_CVR:
      case SellerMetricField.SELLER_UNIT_SESS:
      case SellerMetricField.SELLER_AD_SALES_OF_TOTAL:
      case SellerMetricField.SELLER_UNIT_VIEW:
      case ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE:
        return formatPercent;

      case CommonMetricField.CPC:
      case CommonMetricField.SPEND:
      case CommonMetricField.SALES:
      case CommonMetricField.RPC:
      case CommonMetricField.CPA:
      case CommonMetricField.AOV:
      case CommonMetricField.CPM:
      case CommonMetricField.SAME_SKU_SALES:
      case SellerMetricField.SELLER_ASP:
      case SellerMetricField.SELLER_ORG_SALES:
      case SellerMetricField.SELLER_SALES:
      case SellerMetricField.SELLER_AOV:
      case SellerMetricField.SELLER_CPA:
        return formatCurrency;

      default:
        return assertUnhandledCase(field);
    }
  };

  const getWeekday = (dateString: string): string => {
    const date = AlDate.parse(dateString);
    if (!date.isValid()) {
      return '';
    }
    return date.toFormat(FULL_WEEEK_DAY_FORMAT);
  };

  return {
    formatPercent,
    formatWholePercent,
    formatNumbersAsAbbreviations: formatNumbersAsAbbreviationsFormatter,
    formatWithThousandsSeparator,
    formatChangeInPercent,
    formatChangeInUnsignedPercentWithArrow,
    formatPercentWithArrow,
    getDirectionArrow,
    formatCurrency,
    formatCurrencyWithAbbreviations,
    formatCurrencyWholeNumbers,
    getCurrencySymbolPosition,
    getCurrencySymbol,
    formatDateStringTimeNoSeconds,
    formatDateStringTimeNoHours,
    getShortFormatterForMetricField,
    getLongFormatterForMetricField,
    formatDateAsTimeWithTimezoneNoSeconds,
    getWeekday,
  };
}

function isValidCurrencyCode(code: string): boolean {
  try {
    new Intl.NumberFormat('en-US', { style: 'currency', currency: code }).format(0);
    return true;
  } catch (e) {
    console.error('Not a valid currency code: ', code, e);
    return false;
  }
}

export default useFormatting;

function isNullTimestamp(date: string | null | undefined): boolean {
  return date === '' || date === '0000-00-00 00:00:00' || date === '0001-01-01T00:53:28+00:53';
}
