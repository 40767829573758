import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import useFilters from '@/components/filter-builder/hooks/useFilters';
import { AlFilterModel, getMonthToDateDateRange } from '@/components/filter-builder/models/AlFilterModel';
import { FormatterType, GridToggles } from '@/components/grid/types';
import { MetricAggregates, SellerMetricField } from '@/components/metrics/types/MetricField';
import useEscapableToggle from '@/hooks/useEscapableToggle';
import useMetricsInContext from '@/hooks/useMetricsInContext';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { profileService } from '@/modules/profiles/api/profile.service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { CurrencyCode, getMostCommonCurrencyCodeForUser } from '@/modules/users/types/CurrencyCode';
import { toastService } from '@/services/toast.service';
import { DEFAULT_AVAILABLE_METRICS } from '@/types/context-shared';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { GridApi, GridReadyEvent } from 'ag-grid-enterprise';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import ProfileStatsFilterBar from '../components/ProfileStatsFilterBar';
import ProfilesStatsMetrics from '../components/ProfilesStatsMetrics';
import ProfilesStatsTimelineChart from '../components/ProfilesStatsTimelineChart';
import ProfilesStatsTable from '../components/profile-stats-table/ProfilesStatsTable';

const ProfilesStatsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { hasSellerProfiles } = useUserContext();

  // GRID
  const gridApiRef = useRef<GridApi | null>(null);
  function onProductsTableGridReady(params: GridReadyEvent) {
    gridApiRef.current = params.api;
  }

  // FILTERS
  const { user } = useUserContext();
  const { activeTeam } = useActiveTeamContext();

  // Frontend filters added in uef
  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    return getMonthToDateDateRange();
  });

  const { dates, comparisonDates } = useFilters({ filters, setFilters });

  useEffect(() => {
    if (!activeTeam?.hasProPlan) {
      return;
    }
    filtersService.saveDateFilters(filters);
  }, [filters]);

  // QUERY
  const PROFILE_STATS_QUERY_KEY = [
    'profile-stats',
    dates.map((d) => d.toDefaultFormat()),
    comparisonDates.map((d) => d.toDefaultFormat()),
    user?.teams.map((t) => t.name), // If team name is changed we need to refresh
    user?.teams.map((t) => t.profiles.map((p) => p.id)).flat(), // If a profile is added or removed we need to refresh
  ];

  const {
    data: rowData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: PROFILE_STATS_QUERY_KEY,
    queryFn: async () => {
      const result = await profileService.getUserProfilesWithMetrics({
        userId: user?.id as number,
        dates: {
          startDate: dates[0].toDefaultFormat(),
          endDate: dates[1].toDefaultFormat(),
        },
        compareDates: {
          startDate: comparisonDates[0].toDefaultFormat(),
          endDate: comparisonDates[1].toDefaultFormat(),
        },
      });

      if (result.isSuccess) {
        return result.payload.filter((p) => p.isActive);
      } else {
        toastService.error('Error loading profile stats');
      }
    },
  });

  // TODO: create a a function applyFiltersToRow<T>(rowData: T[], filters: AlFilterModel[]): T[] - requires some refactoring in doesFilterPass
  const filteredRowData = useMemo(() => {
    if (!rowData) return undefined;

    if (!filters || filters.length === 0) {
      return rowData; // No filters applied, return all rows
    }

    return rowData.filter((row) => {
      if (!row) {
        return false; // Exclude rows without data
      }

      // Check if any filter fails; exclude the row if it doesn't pass
      return !filters.some((filter) => !filter.doesFilterPass(row));
    });
  }, [rowData, filters]);

  // Table toggles
  const [gridToggles, setGridToggles] = useState<GridToggles>({
    comparisonUnit: 'nominal',
    // sortByUnit: SortByUnit.Count, // TODO
    formatterType: FormatterType.LONG,
  });

  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyCode>(() => getMostCommonCurrencyCodeForUser(user) ?? CurrencyCode.USD);

  // Metrics and timeline data
  const [metricColumnAggregates, setMetricColumnAggregates] = useState<MetricAggregates | undefined>(undefined);
  const availableMetrics = [...DEFAULT_AVAILABLE_METRICS, ...(hasSellerProfiles ? Object.values(SellerMetricField) : [])];
  const { visibleMetrics, setVisibleMetrics } = useMetricsInContext(UserSettingKey.VISIBLE_METRICS_PROFILES_STATS, availableMetrics);

  const [isTableExpanded, toggleTableExpanded] = useEscapableToggle(false);

  return (
    <PageLayout showFullscreen={isTableExpanded}>
      {!isTableExpanded && (
        <PageLayoutTopBar
          header={
            <div className="flex items-center gap-x-2">
              <div>{t('profiles_overview')}</div>
              <div>
                <Tooltip title={'Profile totals include all campaigns (incl. archived)'}>
                  <span className="flex items-center font-medium">
                    <InfoIcon fontSize="small" style={{ marginRight: '6px' }} />
                  </span>
                </Tooltip>
              </div>
            </div>
          }
        ></PageLayoutTopBar>
      )}
      <PageLayoutBody suppressBottomPadding={isTableExpanded} suppressHorizontalPadding={isTableExpanded}>
        {!isTableExpanded && (
          <div className={`mt-2 space-y-2 `}>
            <AlErrorBoundary>
              <ProfilesStatsMetrics
                metricColumnAggregates={metricColumnAggregates}
                visibleMetrics={visibleMetrics}
                setVisibleMetrics={setVisibleMetrics}
                availableMetrics={availableMetrics}
                selectedCurrency={selectedCurrency}
                showComparison={gridToggles.comparisonUnit != 'hidden'}
              />
            </AlErrorBoundary>

            <AlErrorBoundary>
              <ProfilesStatsTimelineChart
                profilesWithMetrics={filteredRowData}
                visibleMetrics={visibleMetrics}
                selectedCurrency={selectedCurrency}
              />
            </AlErrorBoundary>
          </div>
        )}

        <div className={`flex flex-grow flex-1 flex-col ${!isTableExpanded && 'mt-2 '}`}>
          <AlErrorBoundary>
            <ProfileStatsFilterBar
              profilesWithMetrics={filteredRowData}
              gridApiRef={gridApiRef}
              filters={filters}
              setFilters={setFilters}
              gridToggles={gridToggles}
              setGridToggles={setGridToggles}
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
              isExpanded={isTableExpanded}
              onExpandTable={toggleTableExpanded}
            />
          </AlErrorBoundary>

          <AlErrorBoundary>
            <ProfilesStatsTable
              onGridReadyCallback={onProductsTableGridReady}
              isLoading={isLoading}
              rowData={filteredRowData}
              gridToggles={gridToggles}
              selectedCurrency={selectedCurrency}
              isExpanded={isTableExpanded}
              refetch={refetch}
              setMetricColumnAggregates={setMetricColumnAggregates}
            />
          </AlErrorBoundary>
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default ProfilesStatsPage;
