import { ImportType } from '@/modules/data-management/api/importer.contracts';
import { ImportDefinitionFactory } from '@/modules/data-management/types/import-factory';
import { Button } from '@mui/material';
import { FunctionComponent } from 'react';

const DownloadCampaignMappingsTemplateButton: FunctionComponent = () => {
  const onDownloadTemplateClicked = () => {
    const importer = ImportDefinitionFactory.create(ImportType.CAMPAIGN_MAPPING);
    window.open(importer.templateFileUrl, '_blank');
  };

  return (
    <Button variant="outlined" onClick={onDownloadTemplateClicked}>
      Download Import Template
    </Button>
  );
};

export default DownloadCampaignMappingsTemplateButton;
