import { Delete } from '@mui/icons-material';
import { useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { ButtonBase } from '@mui/material';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';

export function DashboardWidgetConfigurationDrawerDeleteButton() {
  const { widgetId } = useContext(DashboardWidgetContext);

  const removeWidget = useDashboardContextValue((context) => context.removeWidget);
  const setEditingWidgetId = useDashboardContextValue((context) => context.setEditingWidgetId);

  function onDeleteWidgetClicked() {
    if (widgetId) {
      removeWidget(widgetId);
      setEditingWidgetId('');
    }
  }

  const { ModalComponent, handleOpenModal } = useDeleteConfirmation({
    questionText: 'Are you sure you want to delete this widget?',
    headerText: 'Delete Widget',
    onDelete: onDeleteWidgetClicked,
    confirmButtonText: 'Delete',
  });

  return (
    <>
      <ButtonBase className="flex rounded py-1 px-2 hover:bg-red-200 hover:bg-opacity-20" onClick={handleOpenModal}>
        <div className="flex items-center text-sm text-red-600 cursor-pointer">
          <Delete fontSize="inherit" />
          <div className="pl-1">Delete Widget</div>
        </div>
      </ButtonBase>
      {ModalComponent}
    </>
  );
}
