import { useReportsContext } from '@/modules/teams/contexts/ReportsContext';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import { LoadingButton } from '@mui/lab';
import { Alert, Card, Typography } from '@mui/material';
import { FunctionComponent, useEffect } from 'react';

interface ProfileDataStatusAlerts {
  isShowingAlerts: boolean;
  setIsShowingAlerts: (value: boolean) => void;
}

const ProfileDataStatusAlerts: FunctionComponent<ProfileDataStatusAlerts> = ({ setIsShowingAlerts }) => {
  const { activeProfileHasProcessedReports } = useReportsContext();

  useEffect(() => {
    setIsShowingAlerts(!activeProfileHasProcessedReports);
  }, [activeProfileHasProcessedReports]);

  return (
    <>
      {!activeProfileHasProcessedReports && (
        <Card className="m-4 rounded-xl">
          <Alert severity="info">
            <div className="flex w-full flex-col gap-4">
              <div className="flex flex-col gap-2 items-start">
                <Typography variant="body1">Data is being processed. This may take up to several minutes.</Typography>
                <LoadingButton loadingPosition="center" variant="text" loading={true}>
                  <CachedRoundedIcon />
                </LoadingButton>
              </div>
            </div>
          </Alert>
        </Card>
      )}
    </>
  );
};

export default ProfileDataStatusAlerts;
