import { AlDate, AlDateRawType } from '@/lib/date/AlDate';
import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';

export const getCalenderShortcutsItems = (minDate?: AlDateRawType): PickersShortcutsItem<DateRange<AlDateRawType>>[] => {
  const getValidMinDate = (desiredStartDate: AlDate): AlDate => {
    if (!minDate) {
      return desiredStartDate;
    }
    const wrappedMinDate = AlDate.fromRaw(minDate);
    return wrappedMinDate && wrappedMinDate.isAfter(desiredStartDate) ? wrappedMinDate : desiredStartDate;
  };

  return [
    {
      label: 'Last 7 days',
      getValue: (): DateRange<AlDateRawType> => {
        const yesterday = AlDate.now().subtractDays(1);
        const startDate = getValidMinDate(yesterday.subtractDays(6));
        return [startDate.toRaw(), yesterday.toRaw()];
      },
    },
    {
      label: 'Last 14 days',
      getValue: (): DateRange<AlDateRawType> => {
        const yesterday = AlDate.now().subtractDays(1);
        const startDate = getValidMinDate(yesterday.subtractDays(13));
        return [startDate.toRaw(), yesterday.toRaw()];
      },
    },
    {
      label: 'Last 30 days',
      getValue: (): DateRange<AlDateRawType> => {
        const yesterday = AlDate.now().subtractDays(1);
        const startDate = getValidMinDate(yesterday.subtractDays(29));
        return [startDate.toRaw(), yesterday.toRaw()];
      },
    },
    {
      label: 'Last 60 days',
      getValue: (): DateRange<AlDateRawType> => {
        const yesterday = AlDate.now().subtractDays(1);
        const startDate = getValidMinDate(yesterday.subtractDays(59));
        return [startDate.toRaw(), yesterday.toRaw()];
      },
    },
    {
      label: 'Last 90 days',
      getValue: (): DateRange<AlDateRawType> => {
        const yesterday = AlDate.now().subtractDays(1);
        const startDate = getValidMinDate(yesterday.subtractDays(89));
        return [startDate.toRaw(), yesterday.toRaw()];
      },
    },
    {
      label: 'Last Month',
      getValue: (): DateRange<AlDateRawType> => {
        const startOfLastMonth = AlDate.now().minus({ months: 1 }).startOf('month');
        const endOfLastMonth = AlDate.now().minus({ months: 1 }).endOf('month');
        const startDate = getValidMinDate(startOfLastMonth);
        return [startDate.toRaw(), endOfLastMonth.toRaw()];
      },
    },
    {
      label: 'Month-to-Date',
      getValue: (): DateRange<AlDateRawType> => {
        const today = AlDate.now();
        if (today.isFirstDayOfMonth()) {
          return [today.toRaw(), today.toRaw()];
        }
        const startDate = getValidMinDate(today.startOf('month'));
        return [startDate.toRaw(), today.subtractDays(1).toRaw()];
      },
    },
    {
      label: 'Year-to-Date',
      getValue: (): DateRange<AlDateRawType> => {
        const today = AlDate.now();
        if (today.isFirstDayOfYear()) {
          return [today.startOf('year').toRaw(), today.toRaw()];
        }
        const startDate = getValidMinDate(today.startOf('year'));
        return [startDate.toRaw(), today.subtractDays(1).toRaw()];
      },
    },
    { label: 'Reset', getValue: (): DateRange<AlDateRawType> => [null, null] },
  ];
};

export const comparisonCalenderDefaultShortcutsItems: PickersShortcutsItem<DateRange<AlDateRawType>>[] = [
  { label: 'Custom', getValue: (): DateRange<AlDateRawType> => [null, null] },
];
