import { FilterCondition } from '@/components/filter-builder/models/AlFilterModel';
import { AlDate, DAY_WITH_MONTH_FORMAT, DISPLAY_DATE_FORMAT, YEAR_FORMAT } from '@/lib/date/AlDate';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, ButtonBase, Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';

interface DateRangeButtonBaseProps {
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>;
  onDateRangeButtonClicked: () => void;
  isDisabled: boolean;
  dateRange: FilterCondition[] | undefined;
  comparisonDateRange: FilterCondition[] | undefined;
  hideComparison?: boolean;
  comparisonRangeWarning?: string | null;
}

const DateRangeButtonBase: FunctionComponent<DateRangeButtonBaseProps> = ({
  buttonRef,
  onDateRangeButtonClicked,
  isDisabled,
  dateRange,
  comparisonDateRange,
  hideComparison = false,
  comparisonRangeWarning,
}) => {
  const startDate = dateRange && dateRange.length > 0 ? AlDate.parse(dateRange[0].values[0].toString()) : null;

  const endDate = dateRange && dateRange.length > 0 ? AlDate.parse(dateRange[1].values[0].toString()) : null;

  const startComparisonDate =
    comparisonDateRange && comparisonDateRange.length > 0 ? AlDate.parse(comparisonDateRange[0].values[0].toString()) : null;

  const endComparisonDate =
    comparisonDateRange && comparisonDateRange.length > 0 ? AlDate.parse(comparisonDateRange[1].values[0].toString()) : null;

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <ButtonBase
        ref={buttonRef}
        onClick={onDateRangeButtonClicked}
        className="flex min-w-44 rounded-lg py-0.5  pl-4 pr-2 transition duration-100 ease-in-out

      hover:bg-primary-700 hover:bg-opacity-10  dark:hover:bg-opacity-10
      "
        disabled={isDisabled}
        focusRipple={!isDisabled}
      >
        <div className="flex flex-col justify-center">
          {startDate && endDate && (
            <div className="flex font-semibold text-primary-600 dark:text-primary-500">
              <div>{startDate.toFormat(DAY_WITH_MONTH_FORMAT)}</div>
              <div>{startDate.hasSame(endDate, 'year') ? '' : `, ${startDate.toFormat(YEAR_FORMAT)}`}</div>
              <div className="px-1">-</div>
              <div>{endDate.toFormat(DISPLAY_DATE_FORMAT)}</div>
              {!isDisabled && (
                <div className="text-primary-600 dark:text-primary-500" style={{ height: '18px' }}>
                  <ArrowDropDownRoundedIcon style={{ fontSize: '16px' }} />
                </div>
              )}
            </div>
          )}
          {!startDate && !endDate && (
            <div className="flex ">
              <div className="text-sm">-</div>
            </div>
          )}
          {!hideComparison && startComparisonDate && endComparisonDate && (
            <div style={{ fontSize: '0.7rem' }} className="flex items-center opacity-70">
              {comparisonRangeWarning && (
                <Tooltip title={comparisonRangeWarning} placement="top">
                  <WarningIcon
                    sx={{
                      marginRight: '4px',
                      width: 12,
                      height: 12,
                      color: 'darkorange',
                    }}
                  />
                </Tooltip>
              )}
              <div>{startComparisonDate.toFormat(DAY_WITH_MONTH_FORMAT)}</div>
              <div>{startComparisonDate.hasSame(endComparisonDate, 'year') ? '' : `, ${startComparisonDate.toFormat(YEAR_FORMAT)}`}</div>
              <div className="px-1">-</div>
              <div>{endComparisonDate.toFormat(DISPLAY_DATE_FORMAT)}</div>
            </div>
          )}
          {!hideComparison && !startComparisonDate && !endComparisonDate && (
            <div className="flex">
              <div className="text-sm">-</div>
            </div>
          )}
        </div>
      </ButtonBase>
    </Box>
  );
};

export default DateRangeButtonBase;
