import { ButtonBase, CircularProgress, Tooltip } from '@mui/material';
import { FunctionComponent, ReactElement, cloneElement } from 'react';

interface RowActionButtonProps {
  color: 'default' | 'red';
  onClick: () => void;
  icon: ReactElement;
  text?: string;
  isLoading?: boolean;
  isLoadingText?: string;
  tooltipText?: string;
  isDisabled?: boolean;
  width?: number;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
}

const RowActionButton: FunctionComponent<RowActionButtonProps> = ({
  color,
  onClick,
  icon,
  text,
  isLoading,
  isLoadingText,
  tooltipText,
  isDisabled,
  width,
  tooltipPlacement = 'bottom',
}) => {
  let textColor = 'text-slate-700';
  let buttonClasses = 'border-gray-300 hover:border-gray-600 bg-gray-50';
  let buttonText = text;

  switch (color) {
    case 'default':
      break;
    case 'red':
      textColor = 'text-red-700';
      buttonClasses = 'border-gray-300  hover:border-red-700 bg-gray-50 hover:bg-red-50 ';
      break;
  }

  if (isLoading) {
    textColor = 'text-gray-400';
    buttonClasses = 'border-gray-200 !cursor-progress bg-gray-50';

    if (isLoadingText) {
      buttonText = isLoadingText;
    } else {
      buttonText = 'Loading...';
    }
  }

  if (isDisabled) {
    textColor = 'text-gray-400';
    buttonClasses = 'border-gray-200 bg-gray-50';
  }

  return (
    <Tooltip title={tooltipText} placement={tooltipPlacement}>
      <span>
        <ButtonBase
          disabled={isDisabled}
          className={`rounded-md h-7 border border-solid px-3 transition-colors flex items-center ${buttonClasses} `}
          onClick={onClick}
          style={{ width: width ? width : 'auto' }}
        >
          <span className={`flex ${textColor}`}>
            {isLoading ? (
              <CircularProgress size={15} className="mr-1.5" />
            ) : (
              cloneElement(icon, { fontSize: 'inherit', className: text ? 'mr-1 mt-px' : '' })
            )}
            {buttonText}
          </span>
        </ButtonBase>
      </span>
    </Tooltip>
  );
};

export default RowActionButton;
