import { TeamModel } from '@/modules/teams/types/TeamModel';
import { UserDTO } from '../api/users/users.contracts';

export class UserModel {
  public id: number;
  public firebaseId: string;
  public email: string;
  public name: string; // first name
  public surname: string;
  public phone: string;
  public teams: TeamModel[];
  public isAdmin: boolean;
  public hash: string | undefined;

  constructor(args: UserCreateArguments) {
    this.id = args.id;
    this.firebaseId = args.firebaseId;
    this.email = args.email;
    this.name = args.name;
    this.surname = args.surname;
    this.phone = args.phone;
    this.teams = args.teams;
    this.isAdmin = args.isAdmin;
    this.hash = args.hash;
  }

  public static fromDTO(dto: UserDTO) {
    return new UserModel({
      id: dto.id,
      email: dto.email,
      name: dto.name ?? '',
      surname: dto.surname ?? '',
      phone: dto.phone ?? '',
      firebaseId: dto.firebase_id,
      teams: dto.teams ? dto.teams.map((teamDTO) => TeamModel.fromDTO(teamDTO)) : [],
      isAdmin: dto.is_admin,
      hash: dto.gleap_user_hash,
    });
  }
}

interface UserCreateArguments {
  id: number;
  firebaseId: string;
  email: string;
  name: string;
  surname: string;
  phone: string;
  teams: TeamModel[];
  isAdmin: boolean;
  hash: string | undefined;
}
