import { FunctionComponent } from 'react';
import { WizardStepState } from '../types/wizard_step_state.enum';
import RegistrationWizardStepIcon from './RegistrationWizardStepIcon';

interface RegistrationWizardStepProps {
  title: string;
  description: string;
  state: WizardStepState;
  isLastStep?: boolean;
}
export const RegistrationWizardStep: FunctionComponent<RegistrationWizardStepProps> = ({ title, description, state, isLastStep = false }) => {
  return (
    <>
      <div className="flex justify-center">
        <RegistrationWizardStepIcon state={state} />
      </div>
      <div className="row-span-2 w-full  pl-4 pt-2">
        <div className="text-base font-bold text-white">{title}</div>
        <div className="pt-1 text-sm text-primary-200">{description}</div>
      </div>
      <div className="flex justify-center">{!isLastStep && <div className="my-2 h-16 w-0.5 rounded bg-primary-200"></div>}</div>
    </>
  );
};
