import { userService, useUserContext } from '@/modules/users';
import { SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY } from '@/modules/users/api/users/users.contracts';
import { CountryCode } from '@/modules/users/types/CountryCode';
import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Button, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { isEmpty, isNil } from 'lodash-es';
import { useState } from 'react';
import { toastService } from '@/services/toast.service';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';

export interface VendorCentralAuthorizationDialogProps {
  open: boolean;
  onClose: () => void;
}

export function VendorCentralAuthorizationDialog({ onClose, open }: VendorCentralAuthorizationDialogProps) {
  const { user } = useUserContext();
  const { activeTeam } = useActiveTeamContext();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isGetAuthUrlLoading, setIsGetAuthUrlLoading] = useState(false);
  const [countryCode, setCountryCode] = useState<string>(CountryCode.US);

  const handleClose = () => {
    onClose();
  };

  function setError(message: string) {
    setIsError(true);
    setErrorMessage(message);
  }

  function resetError() {
    setIsError(false);
    setErrorMessage('');
  }

  async function onAuthorizeClicked() {
    resetError();

    if (isNil(activeTeam)) {
      setError('Active team not found');
      return;
    }

    try {
      setIsGetAuthUrlLoading(true);
      localStorage.setItem(SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY, countryCode);
      const authUrl = await getVendorCentralAuthUrl(countryCode);

      if (isEmpty(authUrl)) {
        setIsError(true);
        setErrorMessage('Something went wrong. Auth url is empty. Please try again later.');
      } else {
        window.open(authUrl, '_blank'); // Open the URL in a new tab
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsGetAuthUrlLoading(false);
    }
  }

  async function getVendorCentralAuthUrl(market: string): Promise<string> {
    if (user?.id) {
      const res = await userService.getVendorCentralAuthorizationUrl(market);
      if (res.isSuccess) {
        return res.payload;
      } else {
        toastService.error('Unable to get auth url. Please try again later.');
      }
    } else {
      toastService.error('User or active team not set.');
    }

    return '';
  }

  const priorityOrder = [CountryCode.US, CountryCode.UK, CountryCode.CA, CountryCode.MX];
  const availableCountryCodes = Object.values(CountryCode).sort((a, b) => {
    // Get the position of a and b in the priorityOrder array
    const indexA = priorityOrder.indexOf(a);
    const indexB = priorityOrder.indexOf(b);

    // If either a or b is in the priorityOrder, compare their positions
    if (indexA !== -1 || indexB !== -1) {
      // If one of them is not in the priorityOrder, give it a large index (Infinity)
      return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    }

    // If neither a nor b is in the priorityOrder, keep their original order
    return 0;
  });

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth={'xs'}>
        <DialogTitle>Select Market</DialogTitle>

        <DialogContent>
          <p>Connect a selling partner account to access the data on orders, shipments, payments, and much more in AdLabs.</p>
          <p>You can also skip this step and do the authorization anytime in Manage Profiles.</p>
          {isError && (
            <Alert className="mb-4" severity="error">
              {errorMessage}
            </Alert>
          )}

          <div className="flex flex-col justify-stretch gap-y-3">
            <Autocomplete
              options={availableCountryCodes}
              value={countryCode}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCountryCode(newValue);
                }
              }}
              renderInput={(params) => <TextField {...params} label="Region" />}
            />
          </div>
        </DialogContent>

        <DialogActions className="mb-2 px-4">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            className="w-52"
            variant="contained"
            color="primary"
            onClick={onAuthorizeClicked}
            loading={isGetAuthUrlLoading}
            disabled={isGetAuthUrlLoading}
          >
            Authorize
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
