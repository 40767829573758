import { DataGroupType } from './data-groups-contracts';

export class DataItemModel {
  public id: number;
  public name: string;

  constructor(args: DataItemArguments) {
    this.id = args.id;
    this.name = args.name;
  }
}

interface DataItemArguments {
  id: number;
  name: string;
}

export interface NewDataItem {
  id?: number;
  name: string;
}

export interface DataItemMapElement {
  name: string;
  groupId: number;
}

export function countNameOccurancesCaseInsensitive(item: DataItemModel | NewDataItem, items: (DataItemModel | NewDataItem)[]): number {
  // Count how many items have the same name as the given item
  const count = items.reduce((acc, currentItem) => {
    return acc + (currentItem.name.toLowerCase() === item.name.toLowerCase() ? 1 : 0);
  }, 0);

  return count;
}

export function hasAnyDuplicateNames(items: (DataItemModel | NewDataItem)[]): boolean {
  return items.some((item) => countNameOccurancesCaseInsensitive(item, items) > 1);
}

export function getOriginalName(item: DataItemModel | NewDataItem, originalItems: (DataItemModel | NewDataItem)[]): string | null {
  // Check if the item has an id, return null if not
  if (!('id' in item) || item.id === undefined) {
    return null;
  }

  // Find the item in originalItems by id
  const originalItem = originalItems.find((original) => 'id' in original && original.id === item.id);

  // Return the name if found, else return null
  return originalItem ? originalItem.name : null;
}

export interface SelectedEntityForDataGroup {
  id: string;
  dataItemIds: number[];
  dataGroupType: DataGroupType;
}
