import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FunctionComponent } from 'react';
import { FilterKey } from '../types/FilterKey';
import { DateOperatorType } from '../types/Operators';

interface DateOperatorSelectProps {
  label: string;
  filterKey: FilterKey;
  defaultValue?: DateOperatorType;
  handleChange: (event: SelectChangeEvent) => void;
  isDisabled?: boolean;
}

export const DateOperatorSelect: FunctionComponent<DateOperatorSelectProps> = ({
  label,
  filterKey,
  defaultValue,
  handleChange,
  isDisabled = false,
}) => {
  const DATE_OPERATOR_OPTIONS = [
    { value: DateOperatorType.BETWEEN, label: 'BETWEEN' },
    { value: DateOperatorType.BEFORE, label: 'BEFORE' },
    { value: DateOperatorType.AFTER, label: 'AFTER' },
    { value: DateOperatorType.EQUAL, label: 'IS' },
    { value: DateOperatorType.NEVER, label: 'NEVER' },
  ];

  return (
    <FormControl className="w-full">
      <InputLabel id={'logical-operator-label-' + filterKey}>{label}</InputLabel>
      <Select
        labelId={'logical-operator-label-' + filterKey}
        label={label}
        id={'logical-operator-' + filterKey}
        disabled={isDisabled}
        defaultValue={DATE_OPERATOR_OPTIONS.find((option) => option.value === defaultValue)?.value ?? DateOperatorType.BETWEEN}
        onChange={handleChange}
      >
        {DATE_OPERATOR_OPTIONS.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
