import OppositeModeOverlayBar from '@/components/OppositeModeOverlayBar';
import useFormatting from '@/hooks/useFormatting';
import BugButton from '@/modules/application/components/BugButton';
import HelpButton from '@/modules/application/components/HelpButton';
import { campaignMappingService, invalidateProfile_campaignMappingQueryKeys } from '@/modules/campaign-mapping/api/campaign-mapping-service';
import { CampaignMappingModel } from '@/modules/campaign-mapping/models/CampaignMappingModel';
import { CampaignMappingModelWithValidation } from '@/modules/campaign-mapping/models/CampaignMappingModelWithValidation';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LoadingButton } from '@mui/lab';
import { Divider, Tooltip, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { GridApi } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import React, { FunctionComponent, useCallback, useState } from 'react';

export const MAX_ERROR_WARNING_SAMPLES = 5;
export interface CampaignMappingImportDetails {
  rowCount: number;
  errorMessageSamples: string[];
  warningSamples: string[];
  errorCount: number;
  warningCount: number;
}

interface CampaignMappingImportPreviewActionBarProps {
  importDetails: CampaignMappingImportDetails;
  campaignMappingTableGridApiRef: React.MutableRefObject<GridApi<CampaignMappingModelWithValidation> | null>;
  onCloseModal: () => void;
}

const CampaignMappingImportPreviewActionBar: FunctionComponent<CampaignMappingImportPreviewActionBarProps> = ({
  importDetails,
  campaignMappingTableGridApiRef,
  onCloseModal,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();
  const { formatWithThousandsSeparator } = useFormatting();

  const errorTooltipContent = (
    <div className="flex flex-col gap-1">
      {importDetails.errorMessageSamples.slice(0, MAX_ERROR_WARNING_SAMPLES).map((errorMessageSample, index) => (
        <span key={index}>{'❗ ' + errorMessageSample}</span>
      ))}
    </div>
  );

  const warningTooltipContent = (
    <div className="flex flex-col gap-1">
      {importDetails.warningSamples.slice(0, MAX_ERROR_WARNING_SAMPLES).map((warningSample, index) => (
        <span key={index}>{'⚠️ ' + warningSample}</span>
      ))}
    </div>
  );

  // TODO: when clicking on error or warning, scroll to the row first of that row

  // Create
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const onCreateClicked = useCallback(async () => {
    if (!campaignMappingTableGridApiRef.current) {
      return;
    }
    setIsLoadingCreate(true);

    try {
      const campaignMappingsWithValidation: CampaignMappingModelWithValidation[] = [];
      campaignMappingTableGridApiRef.current.forEachNode((node) => {
        if (isNil(node.data)) return;

        const data: CampaignMappingModelWithValidation = node.data;
        campaignMappingsWithValidation.push(data);
      });
      const campaignMappings: CampaignMappingModel[] =
        CampaignMappingModel.convertCampaignMappingWithValidationArrayToCampaignMappingArray(campaignMappingsWithValidation);

      const response = await campaignMappingService.createCampaignMappings(campaignMappings);

      if (!response.isSuccess) {
        toastService.error(response.message);
      } else {
        toastService.success('Created new campaign mapping');
        invalidateProfile_campaignMappingQueryKeys(queryClient, activeProfile?.id);
        onCloseModal();
      }
    } catch (error) {
      console.error(error);
      toastService.error('Failed to create campaign mappings: ' + error);
    }

    setIsLoadingCreate(false);
  }, [campaignMappingTableGridApiRef, activeProfile?.id]);

  return (
    <>
      <OppositeModeOverlayBar visible={true} marginBottom={'36px'}>
        <div className="flex flex-row gap-4 items-center px-1.5">
          {formatWithThousandsSeparator(importDetails.rowCount)} Rows
          <Divider className="my-2" orientation="vertical" flexItem />
          <HelpButton />
          <BugButton />
          <Divider className="my-2" orientation="vertical" flexItem />
          <div className="flex flex-row gap-4 whitespace-nowrap items-center">
            {importDetails.errorCount > 0 && (
              <Tooltip title={errorTooltipContent}>
                <span className="flex items-center font-medium">
                  <ErrorOutlineIcon fontSize="small" style={{ marginRight: '6px', color: theme.palette.error.light }} />
                  {importDetails.errorCount} {importDetails.errorCount == 1 ? 'Error' : 'Errors'}
                </span>
              </Tooltip>
            )}

            {importDetails.warningCount > 0 && (
              <Tooltip title={warningTooltipContent}>
                <span className="flex items-center font-medium">
                  <WarningAmberIcon fontSize="small" style={{ marginRight: '6px', color: theme.palette.warning.light }} />
                  {importDetails.warningCount} {importDetails.warningCount == 1 ? 'Warning' : 'Warnings'}
                </span>
              </Tooltip>
            )}

            <LoadingButton
              variant="contained"
              loading={isLoadingCreate}
              onClick={onCreateClicked}
              color="primary"
              size="small"
              disabled={importDetails.errorCount > 0 || isLoadingCreate || importDetails.rowCount == 0}
            >
              Create New Mappings
            </LoadingButton>
          </div>
        </div>
      </OppositeModeOverlayBar>
    </>
  );
};

export default CampaignMappingImportPreviewActionBar;
