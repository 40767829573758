import { getConfigForMetric, UnitType } from '@/components/metrics/MetricsConfig';
import { CommonMetricField, MetricField, SellerMetricField, stringToSellerMetricFieldString } from '@/components/metrics/types/MetricField';
import { CurrencyRatesModel } from '@/modules/currency/models/CurrencyRatesModel';
import { TimelineDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { ProductsTimelineDTO } from '@/modules/products/api/products-contracts';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';

export class TimelineModel {
  public xAxisData: string[];
  public yAxisData: {
    key: MetricField;
    values: number[];
  }[];

  constructor(args: TimelineCreateArguments) {
    this.xAxisData = args.xAxisData;
    this.yAxisData = args.yAxisData;
  }

  public static fromResponse(response: TimelineDTO): TimelineModel {
    return new TimelineModel({
      xAxisData: response.x_axis,
      yAxisData: Object.keys(response.y_axis).map((key) => {
        return {
          key: key as CommonMetricField,
          values: response.y_axis[key as CommonMetricField],
        };
      }),
    });
  }

  public static fromProductsResponse(response: ProductsTimelineDTO): TimelineModel {
    const xAxisData = response.x_axis;
    const yAxisData = [];

    for (const key of Object.keys(response.y_axis)) {
      if (!Object.values(CommonMetricField).includes(key as CommonMetricField)) {
        continue;
      }
      yAxisData.push({ key: key as CommonMetricField, values: response.y_axis[key as CommonMetricField] });
    }

    for (const key of Object.keys(response.y_axis.seller_metrics)) {
      const sellerMetricKey = stringToSellerMetricFieldString(key);
      if (!Object.values(SellerMetricField).includes(sellerMetricKey as SellerMetricField)) {
        continue;
      }
      yAxisData.push({ key: sellerMetricKey as SellerMetricField, values: response.y_axis.seller_metrics[key as SellerMetricField] });
    }

    return new TimelineModel({
      xAxisData,
      yAxisData,
    });
  }

  public static fromResponseArray(responses: TimelineDTO[]): TimelineModel[] {
    return responses.map((response) => TimelineModel.fromResponse(response));
  }

  public static createAggregatedTimeline(
    timelinesWithCurrency: TimelineWithCurrency[],
    selectedCurrency: CurrencyCode,
    conversionRatesModel: CurrencyRatesModel,
    getCalculatedMetricAggDataForArrays: (metricField: MetricField, aggData: Record<string, number[]>) => number[],
  ): TimelineModel | undefined {
    if (!timelinesWithCurrency || timelinesWithCurrency.length === 0) {
      return undefined; // No timelines to aggregate
    }

    // Initialize aggregated xAxisData and yAxisData
    const aggregatedXAxisData: Set<string> = new Set();
    const aggData: Record<string, number[]> = {};

    // Iterate over each timeline with currency
    for (const { timeline, currency } of timelinesWithCurrency) {
      timeline.xAxisData.forEach((date) => aggregatedXAxisData.add(date));

      for (const { key, values } of timeline.yAxisData) {
        const config = getConfigForMetric(key);
        if (!config) continue;

        if (!aggData[key]) {
          aggData[key] = Array(values.length).fill(0); // Initialize accumulator
        }

        // Aggregate non-calculated metrics
        if (!config.isCalculatedMetric) {
          for (let i = 0; i < values.length; i++) {
            const convertedValue =
              config.unitType == UnitType.CURRENCY
                ? conversionRatesModel.convertValueFromTo(values[i], currency ?? selectedCurrency, selectedCurrency)
                : values[i];
            aggData[key][i] += convertedValue;
          }
        }
      }
    }

    // Handle calculated metrics
    for (const key in aggData) {
      const config = getConfigForMetric(key as MetricField);
      if (!config || !config.isCalculatedMetric) continue;

      aggData[key] = getCalculatedMetricAggDataForArrays(config.key, aggData);
    }

    // Convert aggregated data to TimelineModel format
    const transformedYAxisData = Object.entries(aggData).map(([key, values]) => ({
      key: key as MetricField,
      values,
    }));

    const sortedXAxisData = Array.from(aggregatedXAxisData).sort();

    return new TimelineModel({
      xAxisData: sortedXAxisData,
      yAxisData: transformedYAxisData,
    });
  }
}

interface TimelineCreateArguments {
  xAxisData: string[];
  yAxisData: {
    key: MetricField;
    values: number[];
  }[];
}

export interface TimelineWithCurrency {
  timeline: TimelineModel;
  currency?: CurrencyCode;
}
