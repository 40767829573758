import { FilterBuilderDialog } from '@/components/filter-builder/FilterBuilderDialog';
import { FilterList } from '@/components/filter-builder/FilterList';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { Button } from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';

interface FilterListEditorProps {
  filters: AlFilterModel[];
  onFiltersChange: (filters: AlFilterModel[]) => void;
  availableFilters: AlFilterModel[];
}

const FilterListEditor: FunctionComponent<FilterListEditorProps> = ({ filters, onFiltersChange, availableFilters }) => {
  const filtersButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

  const onFilterChipClicked = () => {
    setIsFilterDialogOpen((previousValue) => !previousValue);
  };

  const onFilterDeleteButtonClicked = (filter: AlFilterModel) => {
    onFiltersChange(filters.filter((f) => f.key !== filter.key));
  };

  const onFiltersChanged = (newFilters: AlFilterModel[]) => {
    onFiltersChange(newFilters);
  };

  return (
    <div className="flex flex-col">
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={onFilterChipClicked}
        startIcon={<FilterListRoundedIcon />}
        ref={filtersButtonRef}
        className="flex-shrink-0"
      >
        Adjust Filters
      </Button>

      <FilterBuilderDialog
        buttonRef={filtersButtonRef}
        setIsOpen={setIsFilterDialogOpen}
        isOpen={isFilterDialogOpen}
        availableFilters={availableFilters}
        defaultFilterKey={FilterKey.CAMPAIGN_ID}
        appliedFilters={filters}
        onFiltersChanged={onFiltersChanged}
      />
      <div className="mt-2">
        <FilterList filters={filters} handleClick={onFilterChipClicked} handleDelete={onFilterDeleteButtonClicked} />
      </div>
    </div>
  );
};

export default FilterListEditor;
