import { ImportType } from '../api/importer.contracts';
import { ImportDefinition } from './import-definition';
import { CampaignMappingImport } from '../importers/campaign-mapping/campaign-mapping-import';

export class ImportDefinitionFactory {
  public static create(type: ImportType): ImportDefinition {
    switch (type) {
      case ImportType.CAMPAIGN_MAPPING:
        return new CampaignMappingImport();
      default:
        throw new Error('Invalid import definition type');
    }
  }

  public static getAllImportDefinitions(): ImportDefinition[] {
    const definitions: ImportDefinition[] = [];
    for (const type in ImportType) {
      definitions.push(this.create(type as ImportType));
    }

    return definitions;
  }
}
