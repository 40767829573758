import { MenuItemGroup } from '@/modules/application';
import { Routes } from '@/router/router-paths';

export const DATA_MANAGEMENT_CONFIGURATION_SUB_MENU_ITEMS: MenuItemGroup[] = [
  {
    label: 'Data',
    children: [
      {
        activeRouteIds: [Routes.IMPORTER],
        label: 'Import',
        url: Routes.IMPORTER,
      },
    ],
  },
];
