import { FunctionComponent } from 'react';
import { WizardStepState } from '../types/wizard_step_state.enum';
import { blue, green } from 'tailwindcss/colors';

interface RegistrationWizardStepIconProps {
  state: WizardStepState;
}

const RegistrationWizardStepIcon: FunctionComponent<RegistrationWizardStepIconProps> = ({ state }) => {
  switch (state) {
    case WizardStepState.InProgress:
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="  rounded-full border-4 border-primary-100"
        >
          <g clipPath="url(#clip0_638_1663)">
            <rect width="32" height="32" rx="16" fill={blue[200]} />
            <circle cx="16" cy="16" r="5" fill={blue[500]} />
            <rect x="1" y="1" width="30" height="30" rx="15" stroke={blue[400]} strokeWidth="2" />
          </g>
          <defs>
            <clipPath id="clip0_638_1663">
              <rect width="32" height="32" rx="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case WizardStepState.NotStarted:
      return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="m-1">
          <g clipPath="url(#clip0_638_1663)">
            <rect width="32" height="32" rx="16" fill={blue[200]} />
            <circle cx="16" cy="16" r="5" fill={blue[200]} />
            <rect x="1" y="1" width="30" height="30" rx="15" stroke={blue[200]} strokeWidth="2" />
          </g>
          <defs>
            <clipPath id="clip0_638_1663">
              <rect width="32" height="32" rx="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case WizardStepState.Completed:
      return (
        <svg width="40" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="16" fill={green[500]} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.7952 9.85322L13.2485 19.0666L10.7152 16.3599C10.2485 15.9199 9.51516 15.8932 8.98182 16.2666C8.46182 16.6532 8.31515 17.3332 8.63515 17.8799L11.6352 22.7599C11.9285 23.2132 12.4352 23.4932 13.0085 23.4932C13.5552 23.4932 14.0752 23.2132 14.3685 22.7599C14.8485 22.1332 24.0085 11.2132 24.0085 11.2132C25.2085 9.98655 23.7552 8.90655 22.7952 9.83989V9.85322Z"
            fill="white"
          />
          <rect x="1" y="1" width="30" height="30" rx="15" stroke={green[500]} strokeWidth="2" />
        </svg>
      );
    default:
      break;
  }
};

export default RegistrationWizardStepIcon;
