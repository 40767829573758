import { cloneDeep, isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { AlDate } from '../../../lib/date/AlDate';
import {
  AlFilterModel,
  ComparisonDateFilterModel,
  DateFilterModel,
  LogicalOperatorType,
  OperatorType,
  getUpdatedFiltersValue,
} from '../models/AlFilterModel';
import { FilterKey } from '../types/FilterKey';
import { StartEndDatePair } from '../types/StartEndDatePair';

interface UseFiltersProps {
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
}

const useFilters = ({ filters, setFilters }: UseFiltersProps) => {
  const { dates, comparisonDates } = useMemo(() => {
    let localDates: AlDate[] = [];
    let localComparisonDates: AlDate[] = [];

    filters.forEach((filterModel) => {
      if (filterModel instanceof DateFilterModel) {
        localDates = extractDatesFromFilterModel(filterModel);
      } else if (filterModel instanceof ComparisonDateFilterModel) {
        localComparisonDates = extractDatesFromFilterModel(filterModel);
      }
    });

    return { dates: localDates, comparisonDates: localComparisonDates };
  }, [filters]);

  const setFilterValue = (filter: AlFilterModel) => {
    const newFilters = getUpdatedFiltersValue(filters, filter);
    setFilterValues(newFilters);
  };

  const setFilterValues = (newFilters: AlFilterModel[]) => {
    if (!newFilters || newFilters.length === 0) return;

    const updatedFilters = newFilters.reduce((acc, newFilter) => {
      return getUpdatedFiltersValue(acc, newFilter);
    }, cloneDeep(filters));

    if (isEqual(filters, updatedFilters)) return;

    setFilters(updatedFilters);
  };

  function onSetDates(dates: AlDate[], comparisonDates: AlDate[]) {
    const newFilters: AlFilterModel[] = [
      new DateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [dates[0].toDefaultFormat()],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [dates[1].toDefaultFormat()],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      }),
      new ComparisonDateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [comparisonDates[0].toDefaultFormat()],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [comparisonDates[1].toDefaultFormat()],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      }),
    ];

    setFilterValues(newFilters);
  }

  return { dates, comparisonDates, setFilterValues, onSetDates, setFilterValue };
};

export default useFilters;

function extractDatesFromFilterModel(filterModel: AlFilterModel): AlDate[] {
  if (filterModel instanceof DateFilterModel || filterModel instanceof ComparisonDateFilterModel) {
    if (filterModel.conditions) {
      return filterModel.conditions.map((condition) => {
        const parsed = AlDate.parse(condition.values[0].toString());
        if (!parsed.isValid()) {
          console.error('Date is not valid', condition.values[0].toString(), new Error().stack);
          return AlDate.now();
        }
        return parsed;
      });
    }
  }

  return [];
}

export function uniqueFiltersByKeys(filters: AlFilterModel[]): AlFilterModel[] {
  const uniqueFilters = new Map<string, AlFilterModel>();

  filters.forEach((filter) => {
    uniqueFilters.set(filter.key, filter); // If a key is duplicated, it will overwrite the previous one
  });

  return Array.from(uniqueFilters.values()); // Convert to an array of FilterModel
}

export function getStartEndDateFromFilters(filters: AlFilterModel[]): StartEndDatePair | undefined {
  const startDate = filters.find((f) => f.key === FilterKey.DATE)?.conditions?.[0]?.values?.[0] as string | undefined;
  const endDate = filters.find((f) => f.key === FilterKey.DATE)?.conditions?.[1]?.values?.[0] as string | undefined;

  return startDate && endDate ? { startDate, endDate } : undefined;
}
