import useFormatting from '@/hooks/useFormatting';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import * as Sentry from '@sentry/react';
import { round } from 'lodash-es';
import { useMemo } from 'react';
import { BidLimits, DEFAULT_BID_DECIMALS, marketplaceBidLimits, MinMaxLimits, placementsBidLimits } from '../types/bid-limits';
import { BidUpdateOption, BidUpdateType, NewBidUpdateDetails } from '../types/bulk-edit';

function useBidLimits() {
  const { formatCurrency } = useFormatting();

  const { activeProfile } = useActiveTeamContext();
  const currentProfileMarketplaceLimits: BidLimits | undefined = useMemo(() => {
    if (!activeProfile?.countryCode || !marketplaceBidLimits[activeProfile?.countryCode]) {
      Sentry.captureMessage(`currentProfileMarketplaceLimits: unknown country code: ${activeProfile?.countryCode}`, 'info');
      console.log('Unknown country code: ', activeProfile?.countryCode);
      return undefined;
    }

    return marketplaceBidLimits[activeProfile?.countryCode];
  }, [activeProfile?.countryCode]);

  const getBidLimits = (adType: CampaignAdType, limits: BidLimits, isVideo?: boolean): MinMaxLimits | null => {
    switch (adType) {
      case CampaignAdType.PRODUCTS:
        return { min: limits.minBidSP, max: limits.maxBidSP };
      case CampaignAdType.BRANDS:
        return isVideo ? { min: limits.minBidSBVCPC, max: limits.maxBidSBVCPC } : { min: limits.minBidSBCPC, max: limits.maxBidSBCPC };
      case CampaignAdType.DISPLAY:
        return { min: limits.minBidSDCPC, max: limits.maxBidSDCPC };
      default:
        // Handle unknown ad type
        Sentry.captureMessage(`getBidLimits: unknown campaign ad type: ${adType}`, 'info');
        console.log('Unknown campaign ad type: ', adType);
        return null;
    }
  };

  const clampBid = (oldValue: number, min: number, max: number, adTypeDescription: string, warnings: Set<string>) => {
    if (oldValue < min) {
      const message = `${adTypeDescription} bid is too low. Adjusted to the minimum of ${formatCurrency(min)}`;
      warnings.add(message);
      return min;
    }

    if (oldValue > max) {
      const message = `${adTypeDescription} bid is too high. Adjusted to the maximum of ${formatCurrency(max)}`;
      warnings.add(message);
      return max;
    }
    return oldValue;
  };

  const getNewBidValue_byCurrentProfileMarketplaceLimits = (
    oldValue: number,
    adType: CampaignAdType,
    campaignIsVideo: boolean,
    warnings: Set<string>,
  ): number => {
    if (!currentProfileMarketplaceLimits) {
      return oldValue;
    }

    const limits = getBidLimits(adType, currentProfileMarketplaceLimits, campaignIsVideo);

    // For example we don't allow any decimals for Japanese yen
    let roundedValue;
    if (currentProfileMarketplaceLimits.decimals) {
      roundedValue = round(oldValue, currentProfileMarketplaceLimits.decimals);
    } else {
      roundedValue = round(oldValue, DEFAULT_BID_DECIMALS);
    }

    if (!limits) {
      return roundedValue; // Return old value if there are no limits (handles unknown ad type)
    }

    const adTypeMapping = {
      [CampaignAdType.PRODUCTS]: 'Product',
      [CampaignAdType.BRANDS]: 'Brand',
      [CampaignAdType.DISPLAY]: 'Display',
      [CampaignAdType.TV]: 'TV',
    };

    const adTypeDescription = `${campaignIsVideo ? 'Sponsored Brands Video' : 'Sponsored ' + adTypeMapping[adType] || adType}`;

    const clampedValue = clampBid(roundedValue, limits.min, limits.max, adTypeDescription, warnings);

    return clampedValue;
  };

  const getNewPlacementValue_byCurrentProfileMarketplaceLimits = (value: number, adType: CampaignAdType, warnings: Set<string>): number => {
    if (!currentProfileMarketplaceLimits) {
      return value;
    }

    const min = adType === CampaignAdType.PRODUCTS ? placementsBidLimits.minSP : placementsBidLimits.minSB;
    const max = adType === CampaignAdType.PRODUCTS ? placementsBidLimits.maxSP : placementsBidLimits.maxSB;

    if (value < min) {
      const message = `New placement value is too low. Adjusted to the minimum of ${min}%`;
      warnings.add(message);
      return min;
    }
    if (value > max) {
      const message = `New placement value bid is too high. Adjusted to the maximum of ${max}`;
      warnings.add(message);
      return max;
    }

    return value;
  };

  function getNewBidValue_byUpdateType(newBidUpdateDetails: NewBidUpdateDetails): number {
    let newValue;
    switch (newBidUpdateDetails.updateData.bidUpdateType) {
      case BidUpdateType.SET_BID_TO_AMOUNT:
        newValue = newBidUpdateDetails.updateData.newBidValue;
        break;
      case BidUpdateType.INCREASE_BID_BY_AMOUNT:
        newValue = newBidUpdateDetails.oldValue + newBidUpdateDetails.updateData.newBidValue;
        break;
      case BidUpdateType.DECREASE_BID_BY_AMOUNT:
        newValue = newBidUpdateDetails.oldValue - newBidUpdateDetails.updateData.newBidValue;
        break;
      case BidUpdateType.INCREASE_BID_BY_PERCENTAGE:
        newValue = newBidUpdateDetails.oldValue * (1 + newBidUpdateDetails.updateData.newBidValue / 100);
        break;
      case BidUpdateType.DECREASE_BID_BY_PERCENTAGE:
        newValue = newBidUpdateDetails.oldValue * (1 - newBidUpdateDetails.updateData.newBidValue / 100);
        break;
      case BidUpdateType.SET_CPC_TIMES_X:
        newValue =
          newBidUpdateDetails.currentCPC > 0
            ? newBidUpdateDetails.updateData.newBidValue * newBidUpdateDetails.currentCPC
            : newBidUpdateDetails.oldValue;
        break;
      case BidUpdateType.NO_CHANGE:
      default:
        newValue = newBidUpdateDetails.oldValue;
    }

    const clampedValue = getNewBidValue_byCurrentProfileMarketplaceLimits(
      newValue,
      newBidUpdateDetails.campaignAdType,
      newBidUpdateDetails.campaignIsVideo,
      newBidUpdateDetails.warnings,
    );

    return clampedValue;
  }

  // TODO: also move placement updates here (applyPlacementUpdate)

  function getBidUpdateOptions(currencySymbol: string): BidUpdateOption[] {
    return [
      { value: BidUpdateType.NO_CHANGE, label: 'No change' },
      { value: BidUpdateType.SET_BID_TO_AMOUNT, label: `Set bid to (${currencySymbol})` },
      { value: BidUpdateType.INCREASE_BID_BY_AMOUNT, label: `Increase bid by (${currencySymbol})` },
      { value: BidUpdateType.DECREASE_BID_BY_AMOUNT, label: `Decrease bid by (${currencySymbol})` },
      { value: BidUpdateType.DECREASE_BID_BY_PERCENTAGE, label: 'Decrease bid by (%)' },
      { value: BidUpdateType.INCREASE_BID_BY_PERCENTAGE, label: 'Increase bid by (%)' },
      { value: BidUpdateType.SET_CPC_TIMES_X, label: `Set CPC * X (${currencySymbol})` },
    ];
  }
  return {
    getNewBidValue_byUpdateType,
    getNewBidValue_byCurrentProfileMarketplaceLimits,
    getNewPlacementValue_byCurrentProfileMarketplaceLimits,
    getBidUpdateOptions,
  };
}

export default useBidLimits;
