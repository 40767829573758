import { useLayoutContext } from '@/contexts/LayoutContext';
import { FunctionComponent, PropsWithChildren } from 'react';

interface PageLayoutProps extends PropsWithChildren {
  showFullscreen?: boolean;
}

export const PageLayout: FunctionComponent<PageLayoutProps> = ({ children, showFullscreen }) => {
  const { scrollDownTargetDivRef, isDisplayingBanner } = useLayoutContext();

  return (
    <div
      ref={scrollDownTargetDivRef}
      className={`flex w-full flex-col overflow-y-auto scroll-smooth
     ${showFullscreen ? 'fixed inset-0 z-50 bg-white' : ''}
      ${isDisplayingBanner ? 'mt-0 max-h-[calc(100vh-30px)] min-h-[calc(100vh-30px)]' : 'max-h-screen min-h-screen'}
     `}
    >
      {children}
    </div>
  );
};
