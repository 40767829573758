export enum MuiColorVariant {
  Default = 'default',
  Primary = 'primary',
  Secondary = 'secondary',
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export enum TailwindColorVariant {
  BLUE = 'blue',
  ORANGE = 'orange',
  GREEN = 'emerald',
  SLATE = 'slate',
  VIOLET = 'violet',
  PINK = 'pink',
  LIME = 'lime',
  AMBER = 'amber',
  SKY = 'sky',
}

export type AdLabsColorVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | 'blue'
  | 'orange'
  | 'emerald'
  | 'slate'
  | 'violet'
  | 'pink'
  | 'lime'
  | 'amber'
  | 'sky';
