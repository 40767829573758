import loginImage from '@/assets/login_bg.jpg';
import { FunctionComponent, PropsWithChildren } from 'react';

const LoginLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex">
      <div
        className="h-screen w-2/5 bg-blue-800 lg:w-3/5"
        style={{
          backgroundImage: `url(${loginImage})`,
          backgroundSize: 'cover',
        }}
      />
      <div className="shape w-3/5 px-8 lg:w-2/5">{children}</div>
    </div>
  );
};

export default LoginLayout;
