import React, { createContext, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { Routes } from '@/router/router-paths';
import { PaywallModal } from '../components/PaywallModal';
import { VideoUrl } from '@/config/urls';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';

export interface ShowPaywallParams {
  message: string;
  videoUrl?: VideoUrl;
  delayInSeconds?: number;
}

interface PaywallContextProps {
  showPaywallWithDelay: (params: ShowPaywallParams) => void;
  closePaywall: () => void;
}

const PaywallContext = createContext<PaywallContextProps | undefined>(undefined);

export const PaywallProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [videoUrl, setVideoUrl] = useState<VideoUrl | undefined>(undefined);
  const { activeTeam } = useActiveTeamContext();
  const { isAdminModeActive } = useUserContext();

  const isPastDueAndGracePeriodHasPassed = activeTeam?.isPastDueAndGracePeriodHasPassed;

  const navigate = useNavigate();

  const showPaywallWithDelay = useCallback(
    ({ message, videoUrl = VideoUrl.PAYWALL_DEFAULT, delayInSeconds = 0 }: ShowPaywallParams) => {
      if (isAdminModeActive) {
        return;
      }

      if (isPastDueAndGracePeriodHasPassed) {
        navigate(Routes.PROFILES);
        return;
      }

      setMessage(message);
      setVideoUrl(videoUrl);

      const timer = setTimeout(() => {
        navigate(Routes.PROFILES);
        setIsOpen(true);
      }, delayInSeconds * 1000);

      return () => clearTimeout(timer);
    },
    [isAdminModeActive, isPastDueAndGracePeriodHasPassed],
  );

  const closePaywall = useCallback(() => {
    setIsOpen(false);
  }, [navigate]);

  return (
    <PaywallContext.Provider value={{ showPaywallWithDelay, closePaywall }}>
      {children}
      <PaywallModal isOpen={isOpen} onClose={closePaywall} returnURLPath={Routes.PROFILES} videoUrl={videoUrl}>
        {message}
      </PaywallModal>
    </PaywallContext.Provider>
  );
};

export const usePaywall = (): PaywallContextProps => {
  const context = useContext(PaywallContext);
  if (!context) {
    throw new Error('usePaywall must be used within a PaywallProvider');
  }
  return context;
};
