import i18n, { Resource } from 'i18next';
import { enTranslations } from './en.translations';

// the translations
const resources: Resource = {
  en: enTranslations,
};

i18n.init({
  resources,
  lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  fallbackLng: 'en', // fallback language is english
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
