import { FilterBuilder } from '@/components/filter-builder/FilterBuilder';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { isNil } from 'lodash-es';
import { Dispatch, FunctionComponent, RefObject, SetStateAction } from 'react';
import { toastService } from '@/services/toast.service';
import { AlFilterModel, OperatorType } from './models/AlFilterModel';
import { FilterKey, FilterType } from './types/FilterKey';

interface FilterBuilderDialogProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  availableFilters: AlFilterModel[];
  defaultFilterKey: FilterKey;
  appliedFilters: AlFilterModel[];
  onFiltersChanged: (filters: AlFilterModel[]) => void;
}

export const FilterBuilderDialog: FunctionComponent<FilterBuilderDialogProps> = ({
  isOpen,
  setIsOpen,
  availableFilters,
  defaultFilterKey,
  appliedFilters,
  onFiltersChanged,
}) => {
  function onClose() {
    setIsOpen(false);
  }

  function splitFiltersToApplicableAndDiscarded(filters: AlFilterModel[]) {
    const applicableFilters: AlFilterModel[] = [];
    const discardedFilters: AlFilterModel[] = [];

    for (const filter of filters) {
      const isSpecialType = [
        FilterType.SELECT,
        FilterType.MULTI_SELECT,
        FilterType.STRING_COMPARISON,
        FilterType.STRING_EXACT_MATCH,
      ].includes(filter.type);

      if (isSpecialType && (isNil(filter.conditions) || !filter.conditions[0]?.values || filter.conditions[0].values.length === 0)) {
        discardedFilters.push(filter);
      } else if (filter.type == FilterType.DATE_SELECT && filter.conditions) {
        if (
          filter.conditions[0]?.values.length == 0 ||
          filter.conditions[0]?.values[0] === 'Invalid Date' ||
          (filter.conditions[0].operator != OperatorType.IS_NULL && filter.conditions[0]?.values[0] === '') ||
          (filter.conditions.length == 2 && filter.conditions[1]?.values[0] == '')
        ) {
          discardedFilters.push(filter);
        } else {
          applicableFilters.push(filter);
        }
      } else {
        applicableFilters.push(filter);
      }
    }

    return { applicableFilters, discardedFilters };
  }

  function notifyAboutDiscardedFilters(filters: AlFilterModel[]) {
    const discardedFiltersNames = filters.map((filter) => filter.shortName).join(', ');

    toastService.info(`Not applying filters with empty fields: ${discardedFiltersNames}`);
  }

  function onFilterBuilderFiltersChanged(filters: AlFilterModel[]) {
    const { applicableFilters, discardedFilters } = splitFiltersToApplicableAndDiscarded(filters);
    onFiltersChanged(applicableFilters);
    if (discardedFilters.length > 0) notifyAboutDiscardedFilters(discardedFilters);
    onClose();
  }

  const defaultFilter = availableFilters.find((f) => f.key == defaultFilterKey);
  const filtersShownOnOpen =
    appliedFilters.filter((f) => f.isFilterBuilderFilter).length > 0 || !defaultFilter ? appliedFilters : [defaultFilter, ...appliedFilters];

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        <div className="flex flex-row justify-between">
          <div>Filters</div>

          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <FilterBuilder
          onSearch={onFilterBuilderFiltersChanged}
          filtersDefaultValue={filtersShownOnOpen}
          availableFilters={availableFilters}
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};
