import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnState } from 'ag-grid-enterprise';

export function profilesStatsTableDefaultColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.TEAM_NAME, hide: false, pinned: false },
    { colId: ColumnId.PROFILE_NAME, hide: false, pinned: false },

    // Performance
    { colId: ColumnId.PERFORMANCE_ACOS, hide: false, pinned: false },
    { colId: ColumnId.PERFORMANCE_ACOTS, hide: false, pinned: false },
    { colId: ColumnId.PERFORMANCE_AD_SPEND, hide: true, pinned: false },
    { colId: ColumnId.PERFORMANCE_AD_SALES, hide: true, pinned: false },
    { colId: ColumnId.PERFORMANCE_TOTAL_SALES, hide: true, pinned: false },

    { colId: ColumnId.LAST_OPTIMIZED, hide: false, pinned: false },

    // Metrics
    { colId: ColumnId.SPEND, hide: false, pinned: false },
    { colId: ColumnId.SALES, hide: false, pinned: false },
    { colId: ColumnId.ACOS, hide: false, pinned: false },
    { colId: ColumnId.TOTAL_SALES, hide: false, pinned: false },
    { colId: ColumnId.ACOTS, hide: false, pinned: false },
    { colId: ColumnId.TOTAL_CVR, hide: false, pinned: false },
    { colId: ColumnId.CVR, hide: false, pinned: false },
    { colId: ColumnId.CPC, hide: false, pinned: false },

    // Hidden
    { colId: ColumnId.UPS, hide: true, pinned: false },
    { colId: ColumnId.AD_SALES_OF_TOTAL, hide: true, pinned: false },
    { colId: ColumnId.ORGANIC_SALES, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_ORDERS, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_UNITS, hide: true, pinned: false },
    { colId: ColumnId.IMPRESSIONS, hide: true, pinned: false },
    { colId: ColumnId.CLICKS, hide: true, pinned: false },
    { colId: ColumnId.ORDERS, hide: true, pinned: false },
    { colId: ColumnId.CTR, hide: true, pinned: false },
    { colId: ColumnId.RPC, hide: true, pinned: false },
    { colId: ColumnId.ROAS, hide: true, pinned: false },
    { colId: ColumnId.UNITS, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_ROAS, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_CLICKS, hide: true, pinned: false },
    { colId: ColumnId.ORGANIC_TRAFFIC, hide: true, pinned: false },
    { colId: ColumnId.ASP, hide: true, pinned: false },
    { colId: ColumnId.AOV, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_AOV, hide: true, pinned: false },
    { colId: ColumnId.CPA, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_CPA, hide: true, pinned: false },
    { colId: ColumnId.CPM, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_VIEWS, hide: true, pinned: false },
    { colId: ColumnId.UNIT_VIEW, hide: true, pinned: false },
    { colId: ColumnId.UNITS_REFUNDED, hide: true, pinned: false },
    { colId: ColumnId.UNITS_REFUND_RATE, hide: true, pinned: false },
  ];
}
