import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import { useTranslation } from '@/lib/i18n/useTranslate';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { FunctionComponent } from 'react';
import UpgradeSubscriptionButton from '../../teams/components/UpgradeSubscriptionButton';
import ProfileSyncSelectButton from '../ProfileSyncSelectButton';
import OptimizationLogsTable from '../components/optimization-logs/OptimizationLogsTable';

const OptimizationLogsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam } = useActiveTeamContext();
  return (
    <PageLayout>
      <PageLayoutTopBar
        header={t('time_machine')}
        actions={
          <div className="flex flex-row items-center gap-2">
            {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
            <TeamSelect />
            <ProfileSyncSelectButton />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <AlErrorBoundary>
          <div className="mt-4 flex flex-grow">
            <OptimizationLogsTable />
          </div>
        </AlErrorBoundary>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default OptimizationLogsPage;
