import { Alert } from '@mui/material';
import { FunctionComponent } from 'react';
import CollapsibleCopyableDetails from './CollapsibleCopyableDetails';

interface ErrorLoadingDataAlertProps {
  details?: string;
}

const ErrorLoadingDataAlert: FunctionComponent<ErrorLoadingDataAlertProps> = ({ details }) => {
  return (
    <Alert severity="error">
      <div className="flex w-96 flex-col">
        There was an error loading the data.
        {details && <CollapsibleCopyableDetails headerText={'Details'} message={details} />}
      </div>
    </Alert>
  );
};

export default ErrorLoadingDataAlert;
