import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import { createPlacementsMetricsQueryKey, metricsService } from '@/components/metrics/api/metrics-service';
import { MetricAggregates } from '@/components/metrics/types/MetricField';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { toastService } from '@/services/toast.service';
import { PlacementsGridContext } from '../components/placements-table/PlacementsTable';
import { usePlacementsContext } from '../contexts/PlacementsContext';
import { PlacementModel } from '../models/PlacementsModel';

interface UsePlacementsMetricColumnAggregatesProps {
  gridApiRef: React.MutableRefObject<GridApi<PlacementModel> | undefined>;
  gridContextRef: React.MutableRefObject<PlacementsGridContext | undefined>;
}

const usePlacementsMetricColumnAggregates = ({ gridApiRef, gridContextRef }: UsePlacementsMetricColumnAggregatesProps) => {
  const { filters } = usePlacementsContext();
  const { activeProfile } = useActiveTeamContext();

  const {
    data: metricColumnAggregates,
    isLoading: isMetricsLoading,
    error: metricsError,
  } = useQuery({
    queryKey: createPlacementsMetricsQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await metricsService.getPlacementsMetrics(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading metric values');
      }
    },
    select: (metricValues): MetricAggregates => {
      return isNil(metricValues)
        ? ({} as MetricAggregates)
        : metricValues.reduce((aggregates, metric) => {
            aggregates[metric.key] = { current: metric.current };
            return aggregates;
          }, {} as MetricAggregates);
    },
    enabled: !isEmpty(filters),
  });

  const { onGridReadyForMetricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates,
  });

  return { metricColumnAggregates, isMetricsLoading, metricsError, onGridReadyForMetricColumnAggregates };
};

export default usePlacementsMetricColumnAggregates;
