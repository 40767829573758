import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { campaignMappingService, createCampaignMappingQueryKey } from '../api/campaign-mapping-service';

const useCampaignMapping = (isExistingCheckEnabled = true) => {
  const { activeProfile } = useActiveTeamContext();

  // Not using filters because for existingMappingKeys we need all.
  const { data: campaignMappingRowData, isFetching } = useQuery({
    queryKey: createCampaignMappingQueryKey(activeProfile?.id, []),
    queryFn: async () => {
      const result = await campaignMappingService.getCampaignMappingData([]);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading campaign mappings ' + JSON.stringify(result));
        throw new Error('Error loading campaign mappings ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(activeProfile?.id) && isExistingCheckEnabled,
  });

  useGlobalLoadingStateObserver('isFetching campaign mapping', isFetching);

  const existingMappingKeys = useMemo(() => {
    return (
      new Set(
        campaignMappingRowData?.map((row) =>
          createMappingSpecificKey({
            sourceCampaignId: row.sourceCampaignId,
            sourceAdGroupId: row.sourceAdGroupId ?? '',
            destinationCampaignId: row.destinationCampaignId,
            destinationAdGroupId: row.destinationAdGroupId,
          }),
        ),
      ) ?? []
    );
  }, [campaignMappingRowData]);

  return {
    existingMappingKeys,
  };
};

export default useCampaignMapping;

function createMappingSpecificKey({
  sourceCampaignId,
  sourceAdGroupId,
  destinationCampaignId,
  destinationAdGroupId,
}: {
  sourceCampaignId: string;
  sourceAdGroupId: string;
  destinationCampaignId: string;
  destinationAdGroupId: string;
}) {
  return sourceCampaignId + sourceAdGroupId + destinationCampaignId + destinationAdGroupId;
}
