import { sleep } from '@/lib/api/api-utils';
import { userService, useUserContext } from '@/modules/users';
import { SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY } from '@/modules/users/api/users/users.contracts';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContentText, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { toastService } from '@/services/toast.service';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { Routes } from '@/router/router-paths';
import { useNavigate } from 'react-router';
import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import { AlMultiSelectOptionModel } from '@/components/filter-builder/models/AlMultiSelectOptionModel';
import { LoadingButton } from '@mui/lab';

export interface VendorProfileSelectorDialogProps {
  open: boolean;
  onClose: () => void;
  spapiOauthCode: string | null;
  state: string | null;
  sellingPartnerId: string | null;
}

export function VendorProfileSelectorDialog({ onClose, open, spapiOauthCode, state, sellingPartnerId }: VendorProfileSelectorDialogProps) {
  const { refetchUser } = useUserContext();
  const { activeTeam } = useActiveTeamContext();
  const navigate = useNavigate();

  const market = localStorage.getItem(SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY);
  const profiles = activeTeam?.profiles.filter((profile) => profile.countryCode === market);
  const options = profiles?.map((profile) => new AlMultiSelectOptionModel(profile.name, profile.id));
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>([]);

  const onAuthVendorCentralClicked = () => {
    console.log(activeTeam?.id, spapiOauthCode, state);

    if (market && activeTeam && spapiOauthCode && state && sellingPartnerId) {
      authorizeVendorCentral({ teamId: activeTeam.id, code: spapiOauthCode, state, sellingPartnerId, profileIds: selectedProfileIds });
    }
  };

  const handleClose = () => {
    onClose();
  };

  const { mutate: authorizeVendorCentral, isPending } = useMutation({
    mutationFn: ({
      teamId,
      code,
      state,
      sellingPartnerId,
      profileIds,
    }: {
      teamId: number;
      code: string;
      state: string;
      sellingPartnerId: string;
      profileIds: string[];
    }) => userService.authorizeVendorCentral(teamId, sellingPartnerId, profileIds, { code, state }),

    onSuccess: async (res) => {
      if (res.isSuccess) {
        toastService.success(`Amazon seller central authorization successful`);
      } else {
        toastService.error(`Amazon seller central authorization failed: ${res.message}`);
      }

      localStorage.removeItem(SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY);
      await sleep(300);
      await refetchUser();

      onClose();
      navigate(Routes.PROFILES, { replace: true });
    },
  });

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
        <DialogTitle>
          <div className="flex flex-row">
            Map Ad Profiles to Vendor account
            <div className="flex flex-grow"></div>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText className="mb-4">* To correct inaccurate account mapping, you have the option to reauthorize</DialogContentText>

          <div className="max-w-2xl">
            {profiles && (
              <AlMultiSelect
                options={options}
                selectedOptionIds={selectedProfileIds}
                setSelectedOptionIds={setSelectedProfileIds}
                label="Profiles"
                placeholderText="Select Profiles"
              />
            )}
            <LoadingButton loading={isPending} variant="contained" className="mt-4" onClick={onAuthVendorCentralClicked}>
              Select profiles
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
