import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { IDashboardWidgetBaseConfiguration } from '../../types/IDashboardWidgetBaseConfiguration';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import EditableText from '@/components/form/EditableText';

const DashboardWidgetTitle: FunctionComponent<PropsWithChildren<{ configuration: IDashboardWidgetBaseConfiguration }>> = ({
  configuration,
}) => {
  const { widgetId } = useContext(DashboardWidgetContext);
  const updateWidgetConfiguration = useDashboardContextValue((context) => context.updateWidgetConfiguration);

  const handleTitleChange = async (newTitle: string) => {
    if (!widgetId) {
      return;
    }
    configuration.title = newTitle;
    await updateWidgetConfiguration(widgetId, configuration, false);
  };

  return (
    <div className="text-sm pt-2 min-h-[30px] px-2 font-bold text-primary-950 opacity-90 flex">
      <EditableText
        text={configuration.title}
        onTextChange={handleTitleChange}
        className="border-b-2 min-w-20 normal-case border-dotted border-opacity-0 hover:border-opacity-100 border-gray-600"
      />
    </div>
  );
};

export default DashboardWidgetTitle;
