import { Box, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import React, { useRef, useState } from 'react';

interface BigTooltipProps extends TooltipProps {
  title: React.ReactNode;
  enterDelay?: number; // Delay before opening tooltip (ms)
  childrenMouseLeaveDelay?: number; // Delay before closing after leaving children (ms)
  tooltipMouseLeaveDelay?: number; // Delay before closing after leaving tooltip (ms)
}

const BigTooltip: React.FC<BigTooltipProps> = ({
  children,
  title,
  enterDelay = 400,
  childrenMouseLeaveDelay = 300,
  tooltipMouseLeaveDelay = 600,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const timerRef = useRef<number>();

  // Handle opening the tooltip with delay
  const handleTooltipOpen = () => {
    clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setOpen(true);
    }, enterDelay);
  };

  // Handle closing the tooltip with a short delay when mouse leaves the children
  const handleChildrenMouseLeave = () => {
    clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setOpen(false);
    }, childrenMouseLeaveDelay);
  };

  // Handle closing the tooltip with a longer delay when mouse leaves the tooltip
  const handleTooltipMouseLeave = () => {
    clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setOpen(false);
    }, tooltipMouseLeaveDelay);
  };

  // Don't render the Tooltip if the title is undefined or empty
  if (!title) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      {...props}
      open={open}
      onOpen={handleTooltipOpen}
      arrow
      title={
        <Box sx={{ position: 'relative', padding: 2 }} onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipMouseLeave}>
          {/* Tooltip content */}
          <Typography variant="body1" className="font-base p-2">
            {title}
          </Typography>
        </Box>
      }
      leaveDelay={0} // We manage delays manually
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
              marginTop: '6px',
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
              marginBottom: '6px',
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
              marginLeft: '6px',
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
              marginRight: '6px',
            },
          },
        },
      }}
    >
      {/* Wrap children to attach mouse event handlers */}
      <span
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleChildrenMouseLeave}
        style={{ display: 'inline-block' }} // Ensure span wraps content properly
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default BigTooltip;
