import { PageLayoutTopBar, PageLayoutBody } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { t } from 'i18next';
import { FunctionComponent, useState } from 'react';
import { Button } from '@mui/material';
import CreateDashboardDialog from '../components/dashboard/CreateDashboardDialog';
import { useQuery } from '@tanstack/react-query';
import { dashboardService } from '../api/dashboard/dashboard.service';
import DashboardsTable from '../components/dashboards/DashboardsTable';
import { DASHBOARDS_QUERY_KEY } from '../constants/query-keys';
import { DashboardReadDTO } from '../api/dashboard/dashboard.contracts';
import { useNavigate } from 'react-router';
import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';

const DashboardsOverviewPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { data, isFetching, refetch } = useQuery({
    queryKey: [DASHBOARDS_QUERY_KEY],
    queryFn: async () => {
      const result = await dashboardService.getAll();
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading dashboards ' + JSON.stringify(result));
      }
    },
  });

  const [isCreateDashboardDialogOpen, setIsCreateDashboardDialogOpen] = useState(false);

  function onCreateDashboardClicked() {
    setIsCreateDashboardDialogOpen(true);
  }

  function onCreateDashboardClosing() {
    setIsCreateDashboardDialogOpen(false);
  }

  function onDashboardCreated(createdDashboard: DashboardReadDTO) {
    setIsCreateDashboardDialogOpen(false);
    refetch();
    navigate(`/dashboards/${createdDashboard.id}`);
  }

  return (
    <PageLayout>
      <PageLayoutTopBar header={t('dashboards')}></PageLayoutTopBar>
      <PageLayoutBody>
        <div className="mt-4 flex flex-grow flex-1 flex-col">
          <div className="flex  justify-between items-center mb-4">
            <div></div>
            <div>
              <Button onClick={onCreateDashboardClicked}>Create Dashboard</Button>
            </div>
          </div>
          <AlErrorBoundary>
            <DashboardsTable dashboards={data} isLoading={isFetching} />
          </AlErrorBoundary>
        </div>
        <AlErrorBoundary>
          <CreateDashboardDialog
            isOpen={isCreateDashboardDialogOpen}
            onClose={onCreateDashboardClosing}
            onDashboardCreated={onDashboardCreated}
          />
        </AlErrorBoundary>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default DashboardsOverviewPage;
