import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import { ComboGraphWidgetConfiguration } from './ComboGraphWidgetConfiguration';
import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { v4 } from 'uuid';
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded';

export const COMBO_GRAPH_WIDGET_CATALOG_ITEM: IDashboardWidgetCatalogItem<ComboGraphWidgetConfiguration> = {
  title: 'Combo Graph',
  description: 'Shows a Line/Bar combo graph of up to 4 Metrics.',
  icon: <ShowChartRoundedIcon />,
  id: v4(),
  type: DashboardWidgetType.ComboGraphWidget,
  configuration: new ComboGraphWidgetConfiguration(),
  layout: {
    h: 6,
    w: 11,
    i: v4(),
    x: 0,
    y: 0,
    maxH: 16,
    maxW: 16,
    minH: 4,
    minW: 4,
  },
};
