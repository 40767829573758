/* eslint-disable no-useless-escape */
import axios, { type AxiosRequestConfig, type AxiosError, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import { isNil, isEmpty } from 'lodash-es';
import { ApiResponse } from './api-response';
import { ApiResponseCode } from './api-response-code';
import { encodeQueryString } from './api-utils';

const DEFAULT_HEADERS: RawAxiosRequestHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};
class BaseClient {
  public baseUrl = 'unset';
  public token = '';
  public profileId: string | undefined = undefined;
  public teamId: number | undefined = undefined;

  public setBaseUrl(apiURL: string) {
    this.baseUrl = apiURL;
  }

  public setToken(token: string) {
    this.token = token;
  }

  setProfileId(profileId: string) {
    this.profileId = profileId;
  }

  setTeamId(teamId: number) {
    this.teamId = teamId;
  }

  getBaseUrl() {
    if (!isNil(this.profileId) && !isNil(this.teamId)) {
      const baseUrlWithoutTrailingSlash = this.baseUrl.replace(/\/$/, '');
      return `${baseUrlWithoutTrailingSlash}/teams/${this.teamId}/profiles/${this.profileId}/`;
    } else if (!isNil(this.teamId)) {
      const baseUrlWithoutTrailingSlash = this.baseUrl.replace(/\/$/, '');
      return `${baseUrlWithoutTrailingSlash}/teams/${this.teamId}/`;
    }
    return this.baseUrl;
  }

  public getHeaders(multipart = false): RawAxiosRequestHeaders | undefined {
    const defaultHeaders = DEFAULT_HEADERS;

    if (multipart) {
      return undefined;
    }

    if (!isNil(this.token)) {
      defaultHeaders.Authorization = `Bearer ${this.token}`;
    }

    return defaultHeaders;
  }

  public async post<T>(uri: string, data: unknown, parameters: Record<string, unknown> = {}) {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }
    try {
      const config: AxiosRequestConfig = {
        headers: this.getHeaders(),
        withCredentials: false,
        baseURL: this.getBaseUrl(),
      };

      const response = await axios.post<T>(uri, data, config);

      return ApiResponse.responseWithPayload(response.data, response.status);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<T>;
        if (axiosError && axiosError.response) {
          return ApiResponse.responseWithCodeAndMessage<T>(this.getErrorResponse(axiosError.response));
        }
      }
      return ApiResponse.responseWithCode<T>(ApiResponseCode.UNKNOWN_ERROR, 0);
    }
  }

  public async patch<T>(uri: string, data: unknown, parameters: Record<string, unknown> = {}) {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }
    try {
      const config: AxiosRequestConfig = {
        headers: this.getHeaders(),
        withCredentials: false,
        baseURL: this.getBaseUrl(),
      };

      const response = await axios.patch<T>(uri, data, config);

      return ApiResponse.responseWithPayload(response.data, response.status);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<T>;
        if (axiosError && axiosError.response) {
          return ApiResponse.responseWithCodeAndMessage<T>(this.getErrorResponse(axiosError.response));
        }
      }
      return ApiResponse.responseWithCode<T>(ApiResponseCode.UNKNOWN_ERROR, 0);
    }
  }

  public async put<T>(uri: string, data: unknown, parameters: Record<string, unknown> = {}) {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }
    try {
      const config: AxiosRequestConfig = {
        headers: this.getHeaders(),
        withCredentials: false,
        baseURL: this.getBaseUrl(),
      };

      const response = await axios.put<T>(uri, data, config);

      return ApiResponse.responseWithPayload(response.data, response.status);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError && axiosError.response) {
          return ApiResponse.responseWithCodeAndMessage<T>(this.getErrorResponse(axiosError.response));
        }
      }
      return ApiResponse.responseWithCode<T>(ApiResponseCode.UNKNOWN_ERROR, 0);
    }
  }

  public async delete<T>(uri: string) {
    try {
      const config: AxiosRequestConfig = {
        headers: this.getHeaders(),
        withCredentials: false,
        baseURL: this.getBaseUrl(),
      };

      const response = await axios.delete<T>(uri, config);

      return ApiResponse.responseWithPayload(response.data, response.status);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError && axiosError.response) {
          return ApiResponse.responseWithCodeAndMessage<T>(this.getErrorResponse(axiosError.response));
        }
      }
      return ApiResponse.responseWithCode<T>(ApiResponseCode.UNKNOWN_ERROR, 0);
    }
  }

  public async get<T>(uri: string, data: Record<string, unknown> = {}) {
    if (Object.keys(data).length > 0) {
      uri = `${uri}?${encodeQueryString(data)}`;
    }

    try {
      const response = await axios({
        method: 'get',
        url: uri,
        baseURL: this.getBaseUrl(),
        withCredentials: false,
        headers: this.getHeaders(),
      });
      return new ApiResponse<T>({
        payload: response.data as T,
        responseCode: ApiResponseCode.SUCCESS,
        httpResponseCode: response.status,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError && axiosError.response) {
          return ApiResponse.responseWithCodeAndMessage<T>(this.getErrorResponse(axiosError.response));
        }
      }
      return ApiResponse.responseWithCode<T>(ApiResponseCode.UNKNOWN_ERROR, 0);
    }
  }

  public postWithoutCredentials<T>(uri: string, data: unknown, parameters: Record<string, unknown> = {}) {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }

    return axios
      .post<T>(uri, data, {
        withCredentials: false,
      })
      .then((res: AxiosResponse<T, unknown>) => res.data);
  }

  public getRaw<T>(uri: string, data = {}) {
    if (Object.keys(data).length > 0) {
      uri = `${uri}?${encodeQueryString(data)}`;
    }

    return axios
      .get<T>(uri, {
        headers: this.getHeaders(),
        withCredentials: false,
      })
      .then((res: AxiosResponse<T, unknown>) => res);
  }

  // public upload(uri: string, data: any) {
  //   return fetch(uri, {
  //     headers: this.getHeaders(true),
  //     method: 'POST',
  //     body: data,
  //   });
  // }

  // public uploadFiles<T>(uri: string, formData: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) {
  //   return axios
  //     .post<T>(uri, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //       onUploadProgress,
  //     })
  //     .then((res: any) => res.data);
  // }

  /* public async downloadFile<T>(
    uri: string,
    data: any,
    parameters: any = {}
  ): Promise<{
    filename: string
    data: any
  }> {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`
    }

    const headers = this.getHeaders()
    headers['Access-Control-Expose-Headers'] = 'Content-Disposition'

    const response = await axios.post<T>(uri, data, {
      headers: this.getHeaders(),
      withCredentials: false,
      responseType: 'blob',
    })

    // Extract filename from header
    const filename = response.headers['content-disposition']
      .split(';')
      .find((n: string) => n.includes('filename='))
      .replace('filename=', '')
      .replace(/\"/g, '')
      .trim()

    return {
      filename,
      data: response.data,
    }
  } */

  private getErrorResponse(axiosResponse: AxiosResponse): {
    message: string;
    code: ApiResponseCode;
    httpResponseCode?: number | undefined;
  } {
    return {
      message: !isNil(axiosResponse.data.message) && !isEmpty(axiosResponse.data.message) ? axiosResponse.data.message : 'Unknown error',
      code:
        !isNil(axiosResponse.data.statusCode) && !isEmpty(axiosResponse.data.statusCode)
          ? (axiosResponse.data.statusCode as ApiResponseCode)
          : ApiResponseCode.UNKNOWN_ERROR,
      httpResponseCode: axiosResponse?.status,
    };
  }
}

const apiClient = new BaseClient();
const apiProfileClient = new BaseClient();
const apiTeamClient = new BaseClient();

export { apiClient, apiProfileClient, apiTeamClient };
