import { v4 } from 'uuid';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';

export class TextWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  id: string;
  title: string;
  content: string;
  isConfigured: boolean;

  constructor() {
    this.id = v4();
    this.title = 'Text Title';
    this.content = 'Your text here...';
    this.isConfigured = true;
  }
}
