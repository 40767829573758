import { PageLayoutBody } from '@/modules/application';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import { Button, Dialog } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, useState } from 'react';
import { CreateNegativeModel } from '../../models/CreateNegativeModel';
import { CreateNegativeWithSelectedSearchTermModel } from '../../models/CreateNegativePreviewModel';
import CreateNegativesPreviewActionsBar from './CreateNegativesPreviewActionsBar';
import CreateNegativesPreviewTable from './CreateNegativesPreviewTable';

interface CreateNegativesPreviewModalProps {
  negativeTargetsToAdd: CreateNegativeWithSelectedSearchTermModel[];
  isOpen: boolean;
  onClose: () => void;
  onApplyAddingNegativeKeywords: (negativeTargets: CreateNegativeModel[]) => void;
}

export const CreateNegativesPreviewModal: FunctionComponent<CreateNegativesPreviewModalProps> = ({
  negativeTargetsToAdd,
  isOpen,
  onClose,
  onApplyAddingNegativeKeywords: onApplyCreateNegativeKeywords,
}) => {
  const [createNegativesPreviewTableApi, setCreateNegativesPreviewTableApi] =
    useState<GridApi<CreateNegativeWithSelectedSearchTermModel> | null>(null);
  const [selection, setSelection] = useState<CreateNegativeWithSelectedSearchTermModel[]>([]);

  const [isApplyLoading, setIsApplyLoading] = useState(false);
  const onApply = () => {
    setIsApplyLoading(true);
    onApplyCreateNegativeKeywords(selection.map((keyword) => keyword.createNegativeModel));
    setSelection([]);
    setIsApplyLoading(false);
    onClose();
  };

  function onCreateNegativesPreviewTableGridReady(api: GridApi<CreateNegativeWithSelectedSearchTermModel>) {
    setCreateNegativesPreviewTableApi(api);
  }

  return (
    <Dialog fullScreen open={isOpen} onClose={onClose} fullWidth={true}>
      <PageLayoutBody suppressBottomPadding>
        <div className="flex w-full flex-row justify-between mt-4 mb-2">
          <div className="flex gap-x-4">
            <Button className="flex-shrink-0" onClick={onClose} variant="text" startIcon={<WestRoundedIcon />}>
              Back to Search Terms
            </Button>
          </div>
        </div>
        <CreateNegativesPreviewTable
          negativeTargets={negativeTargetsToAdd}
          setSelection={setSelection}
          onCreateNegativesPreviewTableGridReady={onCreateNegativesPreviewTableGridReady}
        />

        <CreateNegativesPreviewActionsBar
          selection={selection}
          setSelection={setSelection}
          visibleRowCount={negativeTargetsToAdd.length}
          onApply={onApply}
          isApplyLoading={isApplyLoading}
          createNegativesPreviewTableApi={createNegativesPreviewTableApi}
        />
      </PageLayoutBody>
    </Dialog>
  );
};
