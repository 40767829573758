import { RouteObject } from 'react-router';
import TargetingPage from '../pages/TargetingPage';

export const TARGETING_ROUTES: RouteObject[] = [
  {
    path: 'targeting',
    children: [
      {
        index: true,
        element: <TargetingPage />,
      },
    ],
  },
];
