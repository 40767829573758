import { useMemo } from 'react';

import {
  AmazonBrandsBidOptimization,
  BidStrategyType,
  CampaignAdType,
  EnabledPausedArchivedState,
} from '@/modules/optimizer/api/campaign/campaign-contracts';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import { isNil } from 'lodash-es';
import { NO_CHANGE, NoChange } from '../types/no_change';

interface CampaignInfo {
  warnings: string[];
  productCampaignsCount: number;
  brandCampaignsCount: number;
  displayCampaignsCount: number;
  endedArchivedCampaignsCount: number;
}

interface UseCampaignWarningsProps {
  selectedCampaigns: SelectedCampaignDTO[];
  campaignState: EnabledPausedArchivedState | NoChange;
  campaignBiddingStrategy: BidStrategyType | NoChange;
  bidOptimization: AmazonBrandsBidOptimization | NoChange;
}

function hasCampaignEnded(campaign: SelectedCampaignDTO) {
  return !isNil(campaign.endDate) && new Date(campaign.endDate) < new Date();
}

const useCampaignWarnings = ({ selectedCampaigns, campaignState, campaignBiddingStrategy, bidOptimization }: UseCampaignWarningsProps) => {
  const preBulkEditInfo: CampaignInfo = useMemo(() => {
    const warningMessages: string[] = [];

    const productCampaignsCount = selectedCampaigns.filter((campaign) => campaign.adType === CampaignAdType.PRODUCTS).length;

    const brandCampaignsCount = selectedCampaigns.filter((campaign) => campaign.adType === CampaignAdType.BRANDS).length;

    const displayCampaignsCount = selectedCampaigns.filter((campaign) => campaign.adType === CampaignAdType.DISPLAY).length;

    const endedArchivedCampaignsCount = selectedCampaigns.filter(
      (campaign) => campaign.state === EnabledPausedArchivedState.ARCHIVED || hasCampaignEnded(campaign),
    ).length;

    if (campaignState != NO_CHANGE && endedArchivedCampaignsCount > 0) {
      warningMessages.push(`State change will not be applied to ${endedArchivedCampaignsCount} archived/ended campaigns`);
    }

    if (campaignBiddingStrategy != NO_CHANGE && productCampaignsCount > 0 && brandCampaignsCount + displayCampaignsCount > 0) {
      warningMessages.push(`Bid strategy will not be applied to ${brandCampaignsCount + displayCampaignsCount} brand/display campaigns`);
    }

    if (bidOptimization != NO_CHANGE && brandCampaignsCount > 0 && productCampaignsCount + displayCampaignsCount > 0) {
      warningMessages.push(`Bid optimization will not be applied to ${productCampaignsCount + displayCampaignsCount} non-brand campaigns`);
    }

    return { warnings: warningMessages, productCampaignsCount, brandCampaignsCount, displayCampaignsCount, endedArchivedCampaignsCount };
  }, [selectedCampaigns, campaignState, campaignBiddingStrategy, bidOptimization]);

  return { ...preBulkEditInfo };
};

export default useCampaignWarnings;
