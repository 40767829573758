import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, useTheme } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

interface InfoIconWithTooltipProps extends PropsWithChildren {
  text: string | undefined;
}

const InfoIconWithTooltip: FunctionComponent<InfoIconWithTooltipProps> = ({ text }) => {
  const theme = useTheme();
  return (
    <div className="flex flex-row w-full">
      <Tooltip title={text}>
        <InfoOutlinedIcon fontSize="small" style={{ color: theme.palette.info.main }} />
      </Tooltip>
    </div>
  );
};

export default InfoIconWithTooltip;
