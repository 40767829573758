import { FunctionComponent, ReactNode } from 'react';

interface DashboardWidgetCatalogItemImageProps {
  icon: ReactNode;
}

const DashboardWidgetCatalogItemImage: FunctionComponent<DashboardWidgetCatalogItemImageProps> = ({ icon }) => {
  return <div className={`flex h-full w-full items-center justify-center rounded-lg   text-3xl  `}>{icon}</div>;
};

export default DashboardWidgetCatalogItemImage;
