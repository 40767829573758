import { FunctionComponent, PropsWithChildren } from 'react';

const RegistrationWizardPage: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <div className="relative flex flex-grow flex-col items-center">
      {/* Cannot click on wait list link if image is used
      <img className="absolute bg-primary-100 opacity-0" src={RegisterWizardBackgroundCircles} alt="SVG as an image"></img> */}
      <div className="pt-10">{children}</div>
    </div>
  );
};

export default RegistrationWizardPage;
