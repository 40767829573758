import { createCampaignFiltersForMultipleProfiles } from '@/components/filter-builder/models/AlFilterModel';
import { TeamProfile } from '@/modules/dashboards/types/TeamProfile';
import { createGetAllGroupsForMultipleTeamsQueryKey, dataGroupsService } from '@/modules/data-groups/api/data-groups-service';
import { campaignService, createCampaignGroupsQueryKey } from '@/modules/optimizer/api/campaign/campaign-service';
import { emptyUnAssignedCampaignGroup } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';

const useMultiProfileCampaignFilters = (teamProfiles: TeamProfile[]) => {
  const uniqueTeamIds = Array.from(new Set(teamProfiles.map((profile) => profile.teamId)));

  const {
    data: campaignGroups,
    isLoading: isCampaignGroupsLoading,
    error,
  } = useQuery({
    queryKey: createCampaignGroupsQueryKey(uniqueTeamIds.join('_')),
    queryFn: async () => {
      const results = await Promise.all(teamProfiles.map((tp) => campaignService.getGroupsByTeamAndProfile(tp.teamId, tp.profileId)));

      return results.flatMap((result) => result.payload);
    },
    select: (campaignGroups) => {
      // Ensure the empty unassigned group is always present
      if (!campaignGroups.find((group) => group.name === emptyUnAssignedCampaignGroup.name)) {
        campaignGroups.unshift(emptyUnAssignedCampaignGroup);
      }
      return campaignGroups;
    },
    enabled: !isEmpty(teamProfiles),
  });

  const { data: dataGroups, isLoading: isDataGroupsLoading } = useQuery({
    queryKey: createGetAllGroupsForMultipleTeamsQueryKey(teamProfiles.map((profile) => profile.teamId)),
    queryFn: async () => {
      const results = await Promise.all(
        teamProfiles.map((profile) => dataGroupsService.getAllGroupsForProfile(profile.teamId, profile.profileId)),
      );
      return results.flatMap((result) => result.payload);
    },
    enabled: !isEmpty(teamProfiles),
  });

  const availableCampaignFilters = createCampaignFiltersForMultipleProfiles(campaignGroups || [], dataGroups || [], teamProfiles);

  const availableCampaignFilterBuilderFilters = availableCampaignFilters.filter((filter) => filter.isFilterBuilderFilter);

  const isLoading = isCampaignGroupsLoading || isDataGroupsLoading;
  return {
    availableCampaignFilters,
    availableCampaignFilterBuilderFilters,
    isLoading,
    error,
  };
};

export default useMultiProfileCampaignFilters;
