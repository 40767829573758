import { FunctionComponent, memo, useState } from 'react';
import { IDashboardWidget } from '../../../types/IDashboardWidget';
import DashboardWidgetProvider from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import { isBaseWidgetConfigurationEqual } from '@/modules/dashboards/lib/areWidgetsEqual';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DashboardWidgetConfigurationDrawer';
import TextWidgetConfigurationForm from './TextWidgetConfigurationForm';
import { TextWidgetConfiguration } from './TextWidgetConfiguration';
import RichTextEditor from '@/components/form/RichTextEditor';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { IDashboardWidgetBaseConfiguration } from '@/modules/dashboards/types/IDashboardWidgetBaseConfiguration';

interface TextWidgetProps extends IDashboardWidget<TextWidgetConfiguration> {
  configurationId: string;
}

const TextWidget: FunctionComponent<TextWidgetProps> = ({ configuration, id }) => {
  const [isMouseOnWidget, setIsMouseOnWidget] = useState(false);

  const updateWidgetConfiguration = useDashboardContextValue((context) => context.updateWidgetConfiguration);

  async function onContentChange(html: string) {
    configuration.content = html;
    await updateWidgetConfiguration<IDashboardWidgetBaseConfiguration>(id, configuration, false);
  }

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={false} isConfigured={configuration.isConfigured}>
      <DashboardWidgetContent onMouseChange={setIsMouseOnWidget}>
        <div className="p-2 tiptap overflow-auto w-full h-full">
          <RichTextEditor hideMenuBar content={configuration.content} onContentChange={onContentChange} />
        </div>
        <DashboardWidgetConfigurationButton isMouseOnWidget={isMouseOnWidget}></DashboardWidgetConfigurationButton>
      </DashboardWidgetContent>
      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <TextWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default memo(TextWidget, isBaseWidgetConfigurationEqual);
