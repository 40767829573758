import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography, useTheme } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

interface InfoMessageProps extends PropsWithChildren {
  text: string;
}

const InfoMessage: FunctionComponent<InfoMessageProps> = ({ text }) => {
  const theme = useTheme();
  return (
    <div className="flex flex-row mt-2 w-full">
      <InfoOutlinedIcon fontSize="small" style={{ marginRight: '8px', color: theme.palette.info.main }} />
      <Typography variant="body2">{text}</Typography>
    </div>
  );
};

export default InfoMessage;
