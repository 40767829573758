import MetricsContainer from '@/components/metrics/MetricsContainer';
import { FunctionComponent } from 'react';
import { useOptimizerContext } from '../../contexts/OptimizerContext';
import { MetricModel } from '@/components/metrics/models/MetricModel';

interface OptimizerMetricsProps {
  metricValues: MetricModel[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
}

const OptimizerMetrics: FunctionComponent<OptimizerMetricsProps> = ({ metricValues, isLoading, isError, error }) => {
  const { visibleMetrics, setVisibleMetrics, gridToggles } = useOptimizerContext();

  return (
    <MetricsContainer
      metricValues={metricValues}
      isLoading={isLoading}
      isError={isError}
      error={error}
      visibleMetrics={visibleMetrics}
      setVisibleMetrics={setVisibleMetrics}
      showComparison={gridToggles.comparisonUnit != 'hidden'}
    />
  );
};

export default OptimizerMetrics;
