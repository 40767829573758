import { WithActiveProfileGridContext } from '@/components/grid/types';
import { getMetricColumns } from '@/components/metrics/MetricsConfig';
import { ProfileModel } from '@/modules/profiles/types/ProfileModel';
import { GridApi } from 'ag-grid-enterprise';
import { useEffect } from 'react';

interface UseProfileDataProps<T> {
  gridApiRef: React.MutableRefObject<GridApi<T> | undefined>;
  gridContextRef: React.MutableRefObject<WithActiveProfileGridContext | undefined>;
  profile: ProfileModel | undefined;
}

const useProfileData = <T>({ gridApiRef, gridContextRef, profile }: UseProfileDataProps<T>) => {
  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current || !profile?.countryCode) return;
    gridContextRef.current.countryCode = profile.countryCode;
    gridApiRef.current.refreshCells({ columns: getMetricColumns(), force: true });
  }, [profile]);

  function onGridReadyForProfileData() {
    if (!gridApiRef.current || !gridContextRef.current || !profile?.countryCode) return;
    Object.assign(gridContextRef.current, {
      countryCode: profile?.countryCode,
    });
  }
  return { onGridReadyForProfileData };
};

export default useProfileData;
