import { NegativeMatchType } from '@/modules/negative-targets/api/negative-targets-contracts';
import { CampaignAdType, TargetingType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';

export enum BiddingMethod {
  ADLABS = 'ADLABS',
  CPC_PLUS = 'CPC_PLUS', // 0.1 ($)
  CPC_MINUS = 'CPC_MINUS', // 0.1 ($)
  CPC_TIMES = 'CPC_TIMES', // 1.1
  CUSTOM = 'CUSTOM', // 0.1 ($)
}

export const biddingMethodsWith_currencyValues = [BiddingMethod.CPC_PLUS, BiddingMethod.CPC_MINUS, BiddingMethod.CUSTOM];
export const biddingMethodsWith_noUnit = [BiddingMethod.CPC_TIMES];

/**
 * Interface representing the mapping between source and destination campaigns and ad groups.
 */
export interface CampaignMappingDTO {
  /** Source campaign id */
  sci: string;

  /** Source campaign name */
  scn: string;

  /** Source campaign ad type */
  sct: CampaignAdType;

  /** Source ad group id */
  sai: string;

  /** Source ad group name */
  sagn: string;

  /** Source ad group entity type */
  set: TargetEntityType;

  /** Destination campaign id */
  dci: string;

  /** Destination campaign name */
  dcn: string;

  /** Destination campaign ad type */
  dct: CampaignAdType;

  /** Destination ad group id */
  dai: string;

  /** Destination ad group name */
  dagn: string;

  /** Destination ad group entity type */
  det: TargetEntityType;

  /** Campaign negative exact */
  cne: boolean;

  /** Campaign negative phrase */
  cnp: boolean;

  /** Campaign negative product target */
  cnpt: boolean;

  /** Ad group negative exact */
  agne: boolean;

  /** Ad group negative phrase */
  agnp: boolean;

  /** Ad group negative product target */
  agnpt: boolean;

  /** Created at */
  cr: string;

  /** Updated at */
  up: string;

  /** Mapping inner */
  mi: CampaignMappingItemDTO[];
}

export interface CampaignMappingResponseDTO {
  mappings: CampaignMappingDTO[];
}

export interface UpsertCampaignMappingDTO {
  mappings: UpsertCampaignMappingItemDTO[];
}

export interface UpsertCampaignMappingItemDTO {
  sci: string;
  dci: string;
  sai: string;
  dai: string;

  cne: boolean;
  cnp: boolean;
  cnpt: boolean;
  agne: boolean;
  agnp: boolean;
  agnpt: boolean;

  mi?: UpsertCampaignMappingInnerDTO[]; // send only when create or update, don't send on negatives update
}

export interface UpsertCampaignMappingInnerDTO {
  /** Match type */
  m: MatchType;

  /** Bidding method */
  bdm: BiddingMethod;

  /** Bidding method value */
  bdmv: number | null;

  /** Bid floor */
  bf: number | null;

  /** Bid ceiling */
  bc: number | null;
}

export interface DeleteCampaignMappingDTO {
  mappings?: DeleteCampaignMappingItemDTO[]; // send when deleting whole mapping or a (neg) match type
  delete_all?: boolean; // send only when deleting all
}

export interface DeleteCampaignMappingItemDTO {
  sai: string;
  dai: string;
  mt?: MatchType[] | NegativeMatchType[];
}

export interface CampaignDataWithCampaignMappingAdGroupDataDTO {
  id: string;
  name: string;
  targeting_type: TargetingType;
  campaign_ad_type: CampaignAdType;
  is_video?: boolean;
  ad_groups: CampaignMappingAdGroupDataDTO[] | null;
}

export interface CampaignMappingAdGroupDataDTO {
  id: string;
  name: string;
  map_count: number;
  entity_type: TargetEntityType;
  targeting: string;
}

export interface CampaignMappingItemDTO {
  /** Match type */
  m: MatchType;

  /** Bidding method */
  bdm: BiddingMethod;

  /** Bidding method value */
  bdmv: number | null;

  /** Bid floor */
  bf: number | null;

  /** Bid ceiling */
  bc: number | null;

  /** Created at */
  cr: string | null;

  /** Updated at */
  up: string | null;
}
