import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnState } from 'ag-grid-enterprise';

export function generateCampaignMappingTableColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.CHECKBOX, hide: false },
    { colId: ColumnId.CAMPAIGN_NAME, hide: false },
    { colId: ColumnId.CAMPAIGN_AD_TYPE, hide: false },
    { colId: ColumnId.CAMPAIGN_NAME_DESTINATION, hide: false },
    { colId: ColumnId.ENTITY_TYPE, hide: false },
    { colId: ColumnId.NEGATIVE_CAMPAIGN, hide: false },
    { colId: ColumnId.NEGATIVE_AD_GROUP, hide: false },
    { colId: ColumnId.MATCH, hide: false },
    { colId: ColumnId.CREATED_AT, hide: false },
    { colId: ColumnId.UPDATED_AT, hide: false },
    { colId: ColumnId.ACTIONS, width: 180, hide: false },
  ];
}
