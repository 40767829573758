import { Autocomplete, TextField, Typography, Checkbox, Chip } from '@mui/material';
import { FunctionComponent } from 'react';
import { METRICS } from '../metrics/MetricsConfig';
import { MetricField } from '../metrics/types/MetricField';
import { isNil } from 'lodash-es';

interface MultipleMetricSelectProps {
  availableMetrics: Map<string, MetricField[]>; // Map of group titles to metrics
  selectedMetrics: MetricField[];
  onChange: (selectedMetrics: MetricField[]) => void;
  maxSelectedMetrics?: number; // Optional prop to limit the number of selected metrics
}

const MultipleMetricSelect: FunctionComponent<MultipleMetricSelectProps> = ({
  availableMetrics,
  selectedMetrics,
  onChange,
  maxSelectedMetrics,
}) => {
  // Flatten the Map into an array of metrics, preserving group information
  const flattenedMetrics = Array.from(availableMetrics.entries()).flatMap(([group, metrics]) =>
    metrics.map((metric) => ({ key: metric, group })),
  );

  const getOptionLabel = (metricKey: MetricField) => {
    const metric = METRICS[metricKey];
    return metric?.title || metricKey;
  };

  // TODO: Fix Unexpected any. Specify a different type
  const handleChange = (_: any, newSelectedMetrics: { key: MetricField; group: string }[]) => {
    if (maxSelectedMetrics && newSelectedMetrics.length > maxSelectedMetrics) {
      return;
    }
    onChange(newSelectedMetrics.map((metric) => metric.key));
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disableClearable
      options={flattenedMetrics}
      value={flattenedMetrics.filter((metric) => selectedMetrics.includes(metric.key))}
      onChange={handleChange}
      groupBy={(option) => option.group}
      getOptionLabel={(option) => getOptionLabel(option.key)}
      renderInput={(params) => <TextField {...params} label="Select Metrics" variant="outlined" />}
      renderGroup={(params) => (
        <div key={params.key}>
          <Typography variant="subtitle2" className="pl-2 pt-1.5 text-gray-500">
            {params.group}
          </Typography>
          {params.children}
        </div>
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.key}>
          <Checkbox
            checked={selected}
            style={{ marginRight: 8 }}
            disabled={!isNil(maxSelectedMetrics) && selectedMetrics.length >= maxSelectedMetrics && !selected}
          />
          {getOptionLabel(option.key)}
        </li>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip {...getTagProps({ index })} key={option.key} label={getOptionLabel(option.key)} className="mr-1 mb-1" />
        ))
      }
    />
  );
};

export default MultipleMetricSelect;
