import { FunctionComponent } from 'react';

import { TeamMemberModel } from '@/modules/teams/types/TeamMemberModel';
import { Avatar, AvatarGroup, Tooltip } from '@mui/material';
import { blue, green, orange } from '@mui/material/colors';

export interface IAvatarCellRendererParams {
  members: TeamMemberModel[];
  avatarSize?: number;
}

// Define your custom component for the additional avatar

export const AvatarCellRenderer: FunctionComponent<IAvatarCellRendererParams> = ({ members, avatarSize = 24 }) => {
  const maxAvatars = 3; // colors must have at least maxAvatars elements
  const colors = [orange[500], blue[500], green[500]];

  return (
    <div className="flex flex-row mr-0.5 h-full items-center gap-2">
      <AvatarGroup
        max={maxAvatars}
        slotProps={{
          surplus: {
            sx: { width: avatarSize, height: avatarSize, fontSize: '14px' },
          },
        }}
      >
        {members.map((member, index) => (
          <Tooltip key={member.user.id} title={member.user.name}>
            <Avatar
              alt={member.user.name}
              src={member.user.name}
              sx={{ bgcolor: colors[index], width: avatarSize, height: avatarSize, fontSize: '14px' }}
            />
          </Tooltip>
        ))}
      </AvatarGroup>
    </div>
  );
};
