import { FunctionComponent, PropsWithChildren } from 'react';

interface PageLayoutBodyProps extends PropsWithChildren {
  suppressBottomPadding?: boolean;
  suppressHorizontalPadding?: boolean;
}

export const PageLayoutBody: FunctionComponent<PageLayoutBodyProps> = ({
  children,
  suppressBottomPadding = false,
  suppressHorizontalPadding = false,
}) => {
  return (
    <div
      className={`relative flex w-full flex-1  flex-grow flex-col items-stretch 
    ${suppressHorizontalPadding ? 'px-0' : 'px-2'}
    ${suppressBottomPadding ? 'pb-0' : 'pb-4'}`}
    >
      {children}
    </div>
  );
};
