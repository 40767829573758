import { RouteObject } from 'react-router';
import DataGroupsPage from '../pages/DataGroupsPage';

export const DATA_GROUPS_ROUTES: RouteObject[] = [
  {
    path: 'data-groups',
    children: [
      {
        index: true,
        element: <DataGroupsPage />,
      },
    ],
  },
];
