import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Typography, useTheme } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

interface ErrorMessageProps extends PropsWithChildren {
  text: string;
}

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({ text }) => {
  const theme = useTheme();
  return (
    <div className="flex flex-row mt-2 w-full">
      <ErrorOutlineIcon fontSize="small" style={{ marginRight: '8px', color: theme.palette.error.main }} />
      <Typography variant="body2">{text}</Typography>
    </div>
  );
};

export default ErrorMessage;
