import BetaLabel from '@/components/BetaLabel';
import { ButtonBase } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';

interface MainMenuButtonProps {
  isActive: boolean;
  label: string;
  icon: ReactNode;
  isExpanded: boolean;
  isBeta?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const MainMenuButton: FunctionComponent<MainMenuButtonProps> = ({ onClick, icon, label, isExpanded, isActive, isBeta }) => {
  return (
    <ButtonBase
      onClick={onClick}
      component="div"
      className={`flex h-8  w-full  justify-start overflow-hidden rounded-lg my-2    px-2 
          ${
            isActive
              ? ' text-primary-300  dark:text-primary-500'
              : ' focus:text-white dark:first-line:focus:text-primary-700 text-blue-50 hover:bg-primary-200 hover:bg-opacity-20 dark:text-white dark:hover:bg-gray-700'
          }`}
    >
      <div
        className={`pl-1 pt-1.5
      ${isActive ? ' text-primary-300  dark:text-primary-500' : 'text-blue-50  dark:text-white '}
      `}
      >
        {icon}
      </div>

      <span className={` truncate font-medium tracking-wide  transition-opacity ${isExpanded ? 'opacity-100 pl-2.5' : 'opacity-0 w-0'}`}>
        {label}
      </span>

      {isBeta && isExpanded && (
        <div className="ml-auto mr-2.5 ">
          <BetaLabel />
        </div>
      )}
    </ButtonBase>
  );
};
