import { v4 } from 'uuid';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';

export type HorizontalDividerWidgetFontSize = 'small' | 'medium' | 'large' | 'xlarge';

export class HorizontalDividerWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  id: string;
  title: string;
  isConfigured: boolean;
  fontSize: HorizontalDividerWidgetFontSize;
  fontIsBold: boolean;
  lineThickness: number;

  constructor() {
    this.id = v4();
    this.title = 'Divider Title';
    this.fontSize = 'medium';
    this.fontIsBold = false;
    this.lineThickness = 1;
    this.isConfigured = true;
  }
}
