import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel } from '../models/AlFilterModel';
import { AlMultiSelectOptionModel } from '../models/AlMultiSelectOptionModel';

interface FilterMultiSelectProps {
  filter: AlFilterModel;
  label: string;
}

export const FilterMultiSelect: FunctionComponent<FilterMultiSelectProps> = ({ filter, label }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const [selectedOptionIds, setSelectedOptionIds] = useState<string[]>(
    filter.conditions && filter.conditions[0].values ? (filter.conditions[0].values as string[]) : [],
  );

  useEffect(() => {
    filter.conditions = [
      {
        values: selectedOptionIds,
        operator: filter.defaultConditionOperators[0],
      },
    ];

    setFilterValue(filter);
  }, [selectedOptionIds]);

  const [options, setOptions] = useState<AlMultiSelectOptionModel[]>([]);

  useEffect(() => {
    filter.getOptions().then((options) => {
      setOptions(options);
    });
  }, []);

  return (
    <div className="flex flex-row gap-2 w-full">
      {selectedOptionIds && (
        <AlMultiSelect options={options} selectedOptionIds={selectedOptionIds} setSelectedOptionIds={setSelectedOptionIds} label={label} />
      )}
    </div>
  );
};
