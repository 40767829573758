import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { FunctionComponent } from 'react';
import { toastService } from '@/services/toast.service';
import { logsService } from '../api/logs-service';
import LogsTable from '../components/LogsTable';
import { LoadingButton } from '@mui/lab';

const LogsPage: FunctionComponent = () => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();

  const {
    data: rowData,
    isLoading: isLogsLoading,
    isFetching: isLogsFetching,
    isError: isLogsLoadingError,
    error: logsLoadingError,
    refetch: refetchLogs,
  } = useQuery({
    queryKey: logsService.createGetJobActionsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await logsService.getJobActions([]);

      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error(`Error loading jobs ${result.message}`);
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const isLoading = isLogsLoading || isLogsFetching;

  function onRefreshLogsClicked() {
    refetchLogs();
  }

  return (
    <PageLayout>
      <PageLayoutTopBar
        header={t('logs')}
        actions={
          <div className="flex flex-row items-center gap-2">
            {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
            <TeamSelect />
            <ProfileSyncSelectButton />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <div className="mt-4 flex flex-grow flex-1 flex-col">
          <div className="flex items-center mb-4">
            <div>
              <LoadingButton variant="outlined" loading={isLoading} onClick={onRefreshLogsClicked} disabled={isLoading}>
                Refresh Logs
              </LoadingButton>
            </div>
          </div>
          <ErrorBoundary>
            <div className="flex flex-grow ">
              <LogsTable
                rowData={rowData}
                isLoading={isLoading}
                isLoadingError={isLogsLoadingError}
                loadingErrorMessage={logsLoadingError instanceof Error ? logsLoadingError.message : ''}
              />
            </div>
          </ErrorBoundary>
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default LogsPage;
