import DateRangeButtonBase from '@/components/buttons/DateRangeButtonBase';
import { AlFilterModel, MIN_DATE_OFFSET_FROM_CREATION_DAYS } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { AlDate } from '@/lib/date/AlDate';

import { DateRangePickerPopover } from '@/modules/application/components/date-range-picker/DateRangePickerPopover';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import Tooltip from '@mui/material/Tooltip';
import { isNil } from 'lodash-es';
import { FunctionComponent, useRef, useState } from 'react';

interface DateRangeButtonProps {
  title: string;
  filters: AlFilterModel[];
  setFilterValues: (filters: AlFilterModel[]) => void;
  tooltip: string;
  isDisabled?: boolean;
  hideComparison?: boolean;
  comparisonRangeWarning?: string | null;
  minDate?: string;
}

const DateRangeButton: FunctionComponent<DateRangeButtonProps> = ({
  title,
  filters,
  setFilterValues,
  tooltip,
  isDisabled = false,
  hideComparison = false,
  comparisonRangeWarning,
  minDate,
}) => {
  const { activeProfile } = useActiveTeamContext();

  const dateRangeButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isDateRangePopoverOpen, setIsDateRangePopoverOpen] = useState(false); // state to control the popover

  const optimizationDateRange = filters.find((filterItem) => filterItem.key === FilterKey.DATE)?.conditions;
  const comparisonDateRange = filters.find((filterItem) => filterItem.key === FilterKey.COMPARE_DATE)?.conditions;

  const onDateRangeButtonClicked = () => {
    setIsDateRangePopoverOpen((previousValue) => !previousValue);
  };

  if (isNil(minDate)) {
    if (activeProfile?.createdAt) {
      const createdAt = AlDate.fromISO(activeProfile.createdAt) ?? AlDate.now();
      const adjustedDate = createdAt.subtractDays(MIN_DATE_OFFSET_FROM_CREATION_DAYS);
      minDate = adjustedDate.toDefaultFormat();
    } else {
      minDate = undefined;
    }
  }

  return (
    <div className="flex shrink-0">
      <Tooltip title={tooltip}>
        <span>
          <DateRangeButtonBase
            buttonRef={dateRangeButtonRef}
            isDisabled={isDisabled}
            onDateRangeButtonClicked={onDateRangeButtonClicked}
            dateRange={optimizationDateRange}
            comparisonDateRange={comparisonDateRange}
            hideComparison={hideComparison}
            comparisonRangeWarning={comparisonRangeWarning}
          />
        </span>
      </Tooltip>
      <DateRangePickerPopover
        title={title}
        buttonRef={dateRangeButtonRef}
        setIsOpen={setIsDateRangePopoverOpen}
        isOpen={isDateRangePopoverOpen}
        onSubmit={setFilterValues}
        defaultDateRange={optimizationDateRange}
        defaultComparisonDateRange={comparisonDateRange}
        minDateString={minDate}
        hideComparison={hideComparison}
      />
    </div>
  );
};

export default DateRangeButton;
