import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ChangePercentageCellRenderer from '@/components/grid/cells/ChangePercentageCellRenderer';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';
import TargetEstimateCellRenderer, { ITargetEstimateCellRendererParams } from '@/components/grid/cells/TargetEstimateCellRenderer';
import { ColumnId, isSellerColId } from '@/components/grid/columns/columns.enum';
import ChangePercentageHeaderRenderer from '@/components/grid/headers/ChangePercentageHeaderRenderer';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useDynamicHeight, { EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN } from '@/components/grid/hooks/useDynamicHeight';
import useToggles from '@/components/grid/hooks/useToggles';
import { ExpandedGridContext, GridToggles } from '@/components/grid/types';
import { UnitType } from '@/components/metrics/MetricsConfig';
import { MetricAggregates } from '@/components/metrics/types/MetricField';
import { useLayoutContext } from '@/contexts/LayoutContext';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { sleep } from '@/lib/api/api-utils';
import useCurrency from '@/modules/currency/hooks/useCurrency';
import { CurrencyRatesModel } from '@/modules/currency/models/CurrencyRatesModel';
import { PerformanceTargetEstimate } from '@/modules/profiles/api/profile.contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { Tooltip } from '@mui/material';
import {
  BodyScrollEvent,
  CellClassParams,
  CellValueChangedEvent,
  GridReadyEvent,
  IHeaderParams,
  ValueFormatterParams,
  ValueParserParams,
} from 'ag-grid-community';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-enterprise';
import { isArray } from 'lodash-es';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';
import useProfileSettingsUpdate from '../../hooks/useProfileSettingsUpdate';
import useProfileStatsMetricColumnAggregates from '../../hooks/useProfileStatsMetricColumnAggregates';
import useSelectedCurrency from '../../hooks/useSelectedCurrency';
import { PerformanceMetricsModel } from '../../models/PerformanceMetricsModel';
import { ProfileWithMetricsModel } from '../../models/ProfileWithMetricsModel';
import { profilesStatsTableDefaultColumnState } from './profiles-stats-table.default-column-state';

const PLACEHOLDER_VALUE = '–';

export interface ProfilesStatsGridContext extends ExpandedGridContext {
  metricColumnAggregates: MetricAggregates | undefined;
  conversionRatesModel: CurrencyRatesModel | undefined;
  selectedCurrency: CurrencyCode;
}

interface ProfilesStatsTableProps {
  onGridReadyCallback?: (params: GridReadyEvent) => void;
  rowData: ProfileWithMetricsModel[] | undefined;
  isLoading: boolean;
  gridToggles: GridToggles;
  selectedCurrency: CurrencyCode;
  refetch: () => void;
  setMetricColumnAggregates: React.Dispatch<React.SetStateAction<MetricAggregates | undefined>>;
  isExpanded: boolean;
}

const ProfilesStatsTable: FunctionComponent<ProfilesStatsTableProps> = ({
  onGridReadyCallback,
  rowData,
  isLoading,
  gridToggles,
  selectedCurrency,
  refetch,
  setMetricColumnAggregates,
  isExpanded,
}) => {
  const navigate = useNavigate();
  const { setActiveTeam } = useActiveTeamContext();
  const { hasSellerProfiles, isProfileSeller } = useUserContext();
  const { formatPercent, formatCurrencyWholeNumbers } = useFormatting();
  const { metricColDefsConditionallyAttachEmptyCellRendererSelectorByProfileField } = useColDefsFunctions();

  const { onSourceScroll } = useLayoutContext(); // TODO: move to AlGrid and make optionally turned on
  const { heightCssValue, onGridReadyCallback: onGridReadyForDynamicHeight } = useDynamicHeight({});

  const gridApiRef = useRef<GridApi<ProfileWithMetricsModel>>();
  const gridContextRef = useRef<ProfilesStatsGridContext>();

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.PROFILES_STATS_TABLE_COLUMN_STATE,
    profilesStatsTableDefaultColumnState(),
  );

  const { gridToggleParams } = useToggles<ProfileWithMetricsModel>({
    gridApiRef,
    gridContextRef,
    gridToggles,
  });

  const { conversionRatesModel } = useCurrency();

  const { teamNameFilteredGridAggFunc, profileNameFilteredGridAggFunc } = useAggregators();

  const { updateProfileSettings } = useProfileSettingsUpdate();

  const { getChangePercentageHeaderRendererParams, lastOptimizedType } = useColumnTypes();
  const { metricsDataComparator, getMetricFieldChangePercentageCellRendererParams } = useColDefsFunctions();

  const { metricColumnAggregates, onGridReadyForMetricColumnAggregates, profileStatsMetricAggFunc } = useProfileStatsMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    aggDataCurrencyCode: selectedCurrency,
    rowData,
    conversionRatesModel,
  });

  useEffect(() => {
    if (metricColumnAggregates) {
      setMetricColumnAggregates(metricColumnAggregates);
    }
  }, [metricColumnAggregates]);

  const { onGridReadyForSelectedCurrency } = useSelectedCurrency({
    gridApiRef,
    gridContextRef,
    selectedCurrency,
  });

  async function onProfileNameClicked(profileWithMetrics: ProfileWithMetricsModel) {
    try {
      if (!profileWithMetrics.id) {
        return;
      }
      setActiveTeam({ teamId: profileWithMetrics.teamId, profileId: profileWithMetrics.id });
      await sleep(50); // wait for 50 ms before navigate
      navigate(Routes.OPTIMIZER);
    } catch (error) {
      console.log(error);
      toastService.error('Unable to navigate to profile. Please try again later.');
    }
  }

  const columnDefs: ColDef<ProfileWithMetricsModel>[] = useMemo(() => {
    const colDefs: ColDef<ProfileWithMetricsModel>[] = [
      {
        colId: ColumnId.TEAM_NAME,
        headerName: 'Team',
        field: 'teamName',
        minWidth: 90,
        enableRowGroup: true,
        aggFunc: 'teamNameFilteredGridAggFunc',
      },
      {
        colId: ColumnId.PROFILE_NAME,
        headerName: 'Profile',
        minWidth: 90,
        field: 'nameWithMarket',
        cellRendererSelector(params: ICellRendererParams<ProfileWithMetricsModel>) {
          if (params.node.group) {
            return {
              component: () => <div className="h-full flex items-center">{params.value}</div>,
            };
          }
        },
        aggFunc: 'profileNameFilteredGridAggFunc',
        cellRenderer: LinkCallbackCellRenderer,
        cellRendererParams: (
          params: ICellRendererParams<ProfileWithMetricsModel>,
        ): ILinkCallbackCellRendererParams<ProfileWithMetricsModel> => {
          return {
            buttonText: params.data?.nameWithMarket || '',
            tooltip: 'Start optimizing this Profile',
            callback: onProfileNameClicked,
          };
        },
      },
      {
        colId: ColumnId.LAST_OPTIMIZED,
        field: 'lastOptimizedAt',
        type: 'lastOptimizedType',
      },
      // Performance
      {
        colId: ColumnId.PERFORMANCE_ACOS,
        headerName: '🎯 ACOS',
        field: 'performanceAcos',
        type: 'targetEstimateColType',
        comparator: getPerformanceMetricComparator('acos'),
        headerTooltip: 'Target ACOS compared to the current MTD performance.',
      },
      {
        colId: ColumnId.PERFORMANCE_AD_SPEND,
        headerName: '🎯 Ad Spend (Pacing)',
        field: 'performanceAdSpend',
        type: 'targetEstimateColType',
        comparator: getPerformanceMetricComparator('adSpend'),
        headerTooltip:
          'Ad Spend Monthly Target compared to the current month estimate. Estimate calc: (MTD Ad Spend * Days in Month / MTD Days) / Monthly Target',
        width: 104,
      },
      {
        colId: ColumnId.PERFORMANCE_AD_SALES,
        headerName: '🎯 Ad Sales (Pacing)',
        field: 'performanceAdSales',
        type: 'targetEstimateColType',
        comparator: getPerformanceMetricComparator('adSales'),
        headerTooltip:
          'Ad Sales Monthly Target compared to the current month estimate. Estimate calc: (MTD Ad Sales * Days in Month / MTD Days) / Monthly Target',
        width: 114,
      },
      // Metrics
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Ad Impressions',
        field: 'impressions',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Ad Clicks',
        field: 'clicks',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Ad Orders',
        field: 'orders',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CTR,
        headerName: 'Ad CTR',
        field: 'ctr',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CVR,
        headerName: 'Ad CVR',
        field: 'cvr',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CPC,
        headerName: 'Ad CPC',
        field: 'cpc',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Ad Spend',
        field: 'spend',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Ad Sales',
        field: 'sales',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'Ad ROAS',
        field: 'roas',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.RPC,
        headerName: 'Ad RPC',
        field: 'rpc',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CPA,
        headerName: 'Ad CPA',
        field: 'cpa',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.AOV,
        headerName: 'Ad AOV',
        field: 'aov',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.CPM,
        headerName: 'Ad CPM',
        field: 'cpm',
        type: 'profileStatsMetricColType',
      },
      {
        colId: ColumnId.UNITS,
        headerName: 'Units',
        field: 'units',
        type: 'profileStatsMetricColType',
      },
    ];

    if (hasSellerProfiles) {
      colDefs.push(
        {
          colId: ColumnId.ACOTS,
          headerName: 'TACOS', // Total Ad Cost of Sales was Ad Cost of Total Sales (ACOTS)
          field: 'acots',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.ASP,
          headerName: 'Total ASP',
          field: 'asp',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.ORGANIC_SALES,
          headerName: 'Organic Sales',
          field: 'organicSales',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.ORGANIC_TRAFFIC,
          headerName: 'Organic Traffic',
          field: 'organicTraffic',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_VIEWS,
          headerName: 'Total Page Views',
          field: 'totalViews',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_UNITS,
          headerName: 'Total Units',
          field: 'seller_units',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_SALES,
          headerName: 'Total Sales',
          field: 'totalSales',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.AD_SALES_OF_TOTAL,
          headerName: 'Ad Sales % of Total',
          field: 'adSalesOfTotal',
          type: 'profileStatsMetricColType',
          width: 108,
        },
        {
          colId: ColumnId.TOTAL_CPA,
          headerName: 'Total CPA',
          field: 'totalCpa',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_ROAS,
          headerName: 'Total ROAS',
          field: 'totalRoas',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.UNIT_VIEW,
          headerName: 'Unit Pageview %',
          field: 'unitView',
          type: 'profileStatsMetricColType',
          width: 130,
        },
        {
          colId: ColumnId.UNITS_REFUNDED,
          headerName: 'Units Refunded',
          field: 'unitRefunded',
          type: 'profileStatsMetricColType',
          width: 102,
        },
        {
          colId: ColumnId.UNITS_REFUND_RATE,
          headerName: 'Units Refund Rate',
          field: 'unitRefundRate',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.UPS,
          headerName: 'Units Per Session',
          field: 'ups',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_CVR,
          headerName: 'Total CVR',
          field: 'totalCvr',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_CLICKS,
          headerName: 'Total Sessions',
          field: 'totalClicks',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_ORDERS,
          headerName: 'Total Orders',
          field: 'totalOrders',
          type: 'profileStatsMetricColType',
        },
        {
          colId: ColumnId.TOTAL_AOV,
          headerName: 'Total AOV',
          field: 'totalAov',
          type: 'profileStatsMetricColType',
        },

        // Seller performance
        {
          colId: ColumnId.PERFORMANCE_ACOTS,
          headerName: '🎯 TACOS',
          field: 'performanceAcots',
          type: 'targetEstimateColType',
          comparator: getPerformanceMetricComparator('acots'),
          headerTooltip: 'Target TACOS compared to the current MTD performance.',
        },
        {
          colId: ColumnId.PERFORMANCE_TOTAL_SALES,
          headerName: '🎯 Total Sales (Pacing)',
          field: 'performanceTotalSales',
          type: 'targetEstimateColType',
          comparator: getPerformanceMetricComparator('totalSales'),
          headerTooltip:
            'Total Sales Monthly Target compared to the current month estimate. Estimate calc: (MTD Total Sales * Days in Month / MTD Days) / Monthly Target',
        },
      );
    }

    metricColDefsConditionallyAttachEmptyCellRendererSelectorByProfileField<ProfileWithMetricsModel>(colDefs, 'id');

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, []);

  const profileStatsMetricColType = {
    autoHeight: true,
    width: 125,
    headerComponent: ChangePercentageHeaderRenderer,
    headerComponentParams: (params: IHeaderParams<ProfileWithMetricsModel, ProfilesStatsGridContext>) =>
      getChangePercentageHeaderRendererParams(params, { customCurrencyCode: params.context?.selectedCurrency || CurrencyCode.USD }),
    comparator: metricsDataComparator,
    aggFunc: 'profileStatsMetricAggFunc',
    cellRenderer: ChangePercentageCellRenderer,
    cellRendererParams: (params: ICellRendererParams<ProfileWithMetricsModel, unknown, ProfilesStatsGridContext>) =>
      getMetricFieldChangePercentageCellRendererParams(params, {
        customCurrencyCode: params.data?.currencyCode || CurrencyCode.USD,
      }),
    valueFormatter: (params: ValueFormatterParams) => {
      if (Array.isArray(params.value) && params.value.length > 0) {
        // Convert to string to avoid 0 values to be discarded and whole array returned on export
        return params.value[0].toString();
      }
      return '';
    },
  };

  const targetEstimateColType = {
    width: 100,
    singleClickEdit: true,
    editable: true,
    headerClass: 'accented-header',
    cellRendererSelector: (params: ICellRendererParams<ProfileWithMetricsModel, PerformanceTargetEstimate>) => {
      // Don't use the custom cell renderer on special cases
      if (params.node.group) {
        return { component: () => <></> };
      }

      if (isSellerColId(params.colDef?.colId as ColumnId | undefined) && !isProfileSeller(params.data?.id ?? '')) {
        return {
          component: () => (
            <Tooltip title="Seller not connected">
              <div className="flex flex-1 h-full justify-end opacity-50">{PLACEHOLDER_VALUE}</div>
            </Tooltip>
          ),
        };
      }

      if (!isArray(params.value)) {
        console.error('Received invalid target estimate: ', params.value);
        return {
          component: () => (
            <Tooltip title="Something went wrong">
              <div className="flex flex-1"></div>
            </Tooltip>
          ),
        };
      }

      return undefined;
    },
    cellStyle: (params: CellClassParams<ProfileWithMetricsModel, PerformanceTargetEstimate>) => {
      // Don't style if not array (should not happen) or target isn't set
      if (!isArray(params.value) || !params.value[0]) return null;

      const colId = params.colDef?.colId as ColumnId | undefined;
      if (!colId) return null;

      const positionOnGradient = PerformanceMetricsModel.calculateEstimateToTarget(params.value);
      if (!positionOnGradient) return null;
      const color = PerformanceMetricsModel.getPerformanceMetricColor(colId, positionOnGradient);
      if (!color) return null;

      return { backgroundColor: color };
    },
    valueFormatter: (params: ValueFormatterParams<ProfileWithMetricsModel, PerformanceTargetEstimate>) => {
      // Using valueFormatter to set which value is shown when entering edit mode, doesn't affect rendering when not editing
      // Not using valueGetter because that is also being used by comparator which needs both values
      if (!isArray(params.value) || !params.value[0]) return '';

      const colId = params.colDef?.colId as ColumnId | undefined;
      let multiplier = 1;

      const unitType = PerformanceMetricsModel.getUnitTypeForColId(colId);
      if (unitType === UnitType.PERCENTAGE) {
        multiplier = 100;
      } // else just convert to string

      return (params.value[0] * multiplier).toString() ?? '';
    },
    cellEditorParams: {
      // Getting target value from formatter to edit
      useFormatter: true,
    },
    cellRenderer: TargetEstimateCellRenderer,
    cellRendererParams: (
      params: ICellRendererParams<ProfileWithMetricsModel, PerformanceTargetEstimate, ProfilesStatsGridContext>,
    ): ITargetEstimateCellRendererParams => {
      const defaultFormatter = (value: number | null) => value?.toString() ?? '';

      if (!isArray(params.value) || !params.value[0]) {
        // Target not set - offer to set target by setting target null
        // params.value == null handled by cellRendererSelector
        return {
          ...params,
          target: null,
          estimate: null,
          isEditable: true,
          formatter: defaultFormatter,
        };
      }

      let formatter = (value: number | null): string => value?.toString() ?? '';
      const colId = params.colDef?.colId as ColumnId | undefined;

      const unitType = PerformanceMetricsModel.getUnitTypeForColId(colId);
      if (unitType === UnitType.CURRENCY) {
        const profileCurrency = params.data?.currencyCode || CurrencyCode.USD;
        formatter = (value: number | null) => formatCurrencyWholeNumbers(value, { customCurrencyCode: profileCurrency });
      } else if (unitType === UnitType.PERCENTAGE) {
        formatter = (value: number | null) => formatPercent(value);
      } // else just convert to string

      const target = params.value[0];
      const estimate = params.value[1];

      const percent = formatPercent(PerformanceMetricsModel.calculateEstimateToTarget(params.value)) || '-%';
      const estimateText =
        estimate == null ? (
          'Not enough data for this month'
        ) : (
          <>
            {formatter(estimate)} ({percent} of Target)
          </>
        );
      const tooltip = (
        <>
          <strong>TARGET VS ESTIMATE (MTD)</strong>
          <p>
            <strong>Target:</strong> {formatValueWithFormatterOrPlaceholder(target, formatter)}
            <br />
            <strong>Estimate:</strong> {estimateText}
          </p>
          <i>Click to edit Target</i>
        </>
      );
      return {
        ...params,
        target,
        estimate,
        isEditable: true,
        tooltip,
        formatter: (value: number | null) => formatValueWithFormatterOrPlaceholder(value, formatter),
      };
    },
    valueParser: (params: ValueParserParams<ProfileWithMetricsModel, PerformanceTargetEstimate>) => {
      if (params.newValue == null || params.newValue === '') return [null, params.oldValue?.[1] ?? null];
      const colId = params.colDef?.colId as ColumnId | undefined;

      const parsedValue = Number(params.newValue);
      if (isNaN(parsedValue) || parsedValue < 0) {
        toastService.error('Invalid new target value');
        return params.oldValue;
      }

      const newValue = [isPerformanceMetricFieldPercent(colId) ? parsedValue / 100 : parsedValue, params.oldValue?.[1] ?? null];
      return newValue;
    },
  };

  function formatValueWithFormatterOrPlaceholder(value: number | null, formatter: (value: number | null) => string) {
    if (value === null || value === 0) {
      // Use the formatter to get the format structure, then replace the numeric part
      const formattedZeroValue = formatter(0);

      // Replace the entire numeric part, including decimals, with the placeholder
      const formattedWithPlaceholder = formattedZeroValue.replace(
        /[0-9.,]+/g, // Match the whole number block, including commas and periods
        PLACEHOLDER_VALUE,
      );

      return formattedWithPlaceholder.trim();
    }

    return formatter(value);
  }

  function isPerformanceMetricFieldPercent(colId: ColumnId | undefined) {
    const unitType = PerformanceMetricsModel.getUnitTypeForColId(colId);
    if (unitType === UnitType.PERCENTAGE) {
      return true;
    }

    return false;
  }

  function getPerformanceMetricComparator(field: keyof PerformanceMetricsModel) {
    const idealValue = PerformanceMetricsModel.getIdealEstimateToTargetForMetric(field);

    const comparator = (valueA: PerformanceTargetEstimate, valueB: PerformanceTargetEstimate) => {
      if (!valueA || !valueB) return 0;

      const estimateToTargetA = PerformanceMetricsModel.calculateEstimateToTarget(valueA);
      const estimateToTargetB = PerformanceMetricsModel.calculateEstimateToTarget(valueB);

      const distanceToIdealA = estimateToTargetA ? Math.abs(estimateToTargetA - idealValue) : Infinity;
      const distanceToIdealB = estimateToTargetB ? Math.abs(estimateToTargetB - idealValue) : Infinity;

      return distanceToIdealA - distanceToIdealB;
    };

    return comparator;
  }

  const onCellValueChanged = (event: CellValueChangedEvent<ProfileWithMetricsModel>) => {
    // Check if the edited cell is the one you're interested in
    if (PerformanceMetricsModel.isPerformanceMetricColId(event.colDef.colId as ColumnId | undefined)) {
      const updatedData = event.data;

      updatePerformanceMetrics(updatedData);
    }
  };

  function updatePerformanceMetrics(data: ProfileWithMetricsModel) {
    updateProfileSettings({
      teamId: data.teamId,
      profileId: data.id,
      performanceAcos: data.performanceAcos[0] ?? 0,
      performanceAcots: data.performanceAcots[0] ?? 0,
      performanceAdSpend: data.performanceAdSpend[0] ?? 0,
      performanceAdSales: data.performanceAdSales[0] ?? 0,
      performanceTotalSales: data.performanceTotalSales[0] ?? 0,
      createSTVReports: data.createSTVReports,
      refetch,
    });
  }

  const customGridOptions: GridOptions<ProfileWithMetricsModel> = {
    ...DEFAULT_GRID_OPTIONS,
    getRowId: (params) => params.data.teamId.toString() + '-' + params.data.id.toString(),
    // TODO: extract context and use context type
    context: {
      metricColumnAggregates,
      conversionRatesModel,
      ...gridToggleParams,
    },
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnRowGroupChanged: handleColumnStateChange,
    onSortChanged: handleColumnStateChange,
    onColumnPinned: handleColumnStateChange,
    onBodyScroll: (event: BodyScrollEvent) => onSourceScroll(event.top),
    onCellValueChanged,
    columnTypes: {
      profileStatsMetricColType,
      targetEstimateColType,
      lastOptimizedType,
    },
    aggFuncs: {
      profileStatsMetricAggFunc,
      teamNameFilteredGridAggFunc,
      profileNameFilteredGridAggFunc,
    },
    getRowStyle: (params) => {
      if (params.node.group) {
        return {
          background: 'linear-gradient(to bottom, rgba(96,165,250, 0.2), rgba(96,165,250, 0.05))',
        };
      }
    },
    rowGroupPanelShow: 'always', // The place you can drag headers for row grouping
    groupDefaultExpanded: 0,
    // What to show in the auto created Group column on non aggregated rows
    autoGroupColumnDef: {
      pinned: 'left',
      cellRendererParams: {
        suppressCount: true, // Suppress the default (count)
      },
    },
    groupSelectsChildren: true,
    suppressAggFuncInHeader: true, // without this aggregated Impressions header would be func(Impressions)
  };

  const onGridReady = (params: GridReadyEvent<ProfileWithMetricsModel>) => {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    gridContextRef.current = params.context;
    onGridReadyCallback?.(params);
    onGridReadyForMetricColumnAggregates();
    onGridReadyForSelectedCurrency();
    onGridReadyForDynamicHeight(params);
  };

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <div style={{ height: isExpanded ? `calc(100vh - ${EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` : heightCssValue }}>
      <AlGrid
        colDefs={columnDefs}
        rowData={rowData}
        gridOptions={customGridOptions}
        isLoading={isLoading}
        onGridReadyCallback={onGridReady}
        noTopBorderRadius={true}
        fitToResizeEnabled={false}
        addExtraBottomPadding={true}
      />
    </div>
  );
};

export default ProfilesStatsTable;
