import { createPlacementsFilters } from '@/components/filter-builder/models/AlFilterModel';
import { useDataGroups } from '@/hooks/useDataGroups';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { campaignService, createCampaignGroupsQueryKey } from '@/modules/optimizer/api/campaign/campaign-service';
import { emptyUnAssignedCampaignGroup } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';

const useAvailablePlacementFilters = () => {
  const { dataGroups } = useDataGroups([DataGroupType.CAMPAIGN]);
  const { activeTeam, activeProfile } = useActiveTeamContext();

  const {
    data: campaignGroups,
    isLoading: isLoadingCampaignGroups,
    error: errorCampaignGroups,
  } = useQuery({
    queryKey: createCampaignGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading groups');
      }
    },
    select: (campaignGroups) => {
      if (!campaignGroups.find((group) => group.id === 0)) {
        campaignGroups.unshift(emptyUnAssignedCampaignGroup);
      }
      return campaignGroups;
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const isLoading = isLoadingCampaignGroups;
  const error = errorCampaignGroups;

  const availablePlacementFilters = createPlacementsFilters(campaignGroups || [], dataGroups || []);

  const availablePlacementFilterBuilderFilters = availablePlacementFilters.filter((filter) => filter.isFilterBuilderFilter);

  return {
    availablePlacementFilters,
    availablePlacementFilterBuilderFilters,
    isLoading,
    error,
  };
};

export default useAvailablePlacementFilters;
