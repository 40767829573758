export enum BidLimitType {
  PERCENT = 'PERCENT',
  CURRENCY = 'CURRENCY',
  OFF = 'OFF',
}

export enum PlacementBidLimitType {
  PERCENT = 'PERCENT',
  OFF = 'OFF',
}
