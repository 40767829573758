import { DTO } from '@/types/dto-wrapper';
import { ActionEntityType, ActionType, JobActionDTO as JobActionDTO, FlowType } from '../api/logs-contracts';

export class JobActionModel extends DTO<JobActionDTO> {
  constructor(data: JobActionDTO) {
    super(data);
  }

  public get id(): string {
    return this.dto.id;
  }

  public get action(): ActionType {
    return this.dto.type;
  }

  public get entityType(): ActionEntityType {
    return this.dto.entity_type;
  }

  public get success(): number {
    return this.dto.success;
  }

  public get failed(): number {
    return this.dto.failed;
  }

  public get total(): number {
    return this.dto.success + this.dto.failed;
  }

  public get createdAt(): string {
    return this.dto.created_at;
  }

  public get flowType(): FlowType {
    return this.dto.flow_type;
  }

  public get jobId(): string {
    return this.dto.job_id;
  }

  public get createdBy(): string {
    return this.dto.created_by;
  }

  static fromResponseObject(payload: JobActionDTO[]): JobActionModel[] {
    return payload.map((dto) => new JobActionModel(dto));
  }
}
