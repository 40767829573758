import SetNoStatsFilterButton from '@/components/buttons/SetNoStatsFiltersButton';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import useFormatting from '@/hooks/useFormatting';
import { AlDate } from '@/lib/date/AlDate';
import { UserSettingKey, useUserSettingsContext } from '@/modules/users';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import { isNil } from 'lodash-es';
import { FunctionComponent, useState } from 'react';

interface TooManyNoStatsTargetsAlertProps {
  noStatsTargetCount: number | undefined;
  setFilters: (filters: AlFilterModel[]) => void;
}

const SHOW_ALERT_NO_STATS_TARGET_THRESHOLD = 50000;
const SHOW_ALERT_AGAIN_AFTER_HOURS = 24;

const TooManyNoStatsTargetsAlert: FunctionComponent<TooManyNoStatsTargetsAlertProps> = ({ noStatsTargetCount, setFilters }) => {
  const { formatNumbersAsAbbreviations } = useFormatting();
  const { getUserSettingValueByKey, upsertUserSetting } = useUserSettingsContext();

  const [isOpen, setIsOpen] = useState(true);

  const lastClosedSetting = getUserSettingValueByKey<string | null | undefined>(UserSettingKey.TARGETING_MANY_NO_STATS_MSG_CLOSED_AT);
  const lastClosedAt = lastClosedSetting ? AlDate.fromISO(lastClosedSetting) : null;

  function onClose() {
    setIsOpen(false);
    upsertUserSetting(UserSettingKey.TARGETING_MANY_NO_STATS_MSG_CLOSED_AT, AlDate.now().toISO() as string);
  }

  const isDffInHoursOverLimit =
    !lastClosedAt || !lastClosedAt.isValid() || AlDate.now().diff(lastClosedAt, 'hours') > SHOW_ALERT_AGAIN_AFTER_HOURS;
  const isShowingAlert =
    isOpen && !isNil(noStatsTargetCount) && noStatsTargetCount > SHOW_ALERT_NO_STATS_TARGET_THRESHOLD && isDffInHoursOverLimit;

  return isShowingAlert ? (
    <Alert
      className="py-4 mt-2"
      severity="info"
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      sx={{ mb: 2 }}
    >
      <AlertTitle>A lot of targets with no stats found</AlertTitle>
      We have identified <strong>{formatNumbersAsAbbreviations(noStatsTargetCount)}</strong> targets with no stats during last sync. Archiving
      these targets can significantly speed up your loading times in AdLabs.
      <SetNoStatsFilterButton setFilters={setFilters} />
    </Alert>
  ) : null;
};

export default TooManyNoStatsTargetsAlert;
