import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import { SelectedSearchTerm } from '@/modules/search-terms/models/SearchTermModel';
import { HarvestingParams, KeywordHarvestingModel } from '../models/KeywordHarvestingModel';
import { KeywordHarvestingPreviewDataRow } from '../models/KeywordHarvestingPreviewDataRow';
import {
  ApplyHarvestingRequestDTO,
  KeywordHarvestingCreateResponseDTO,
  KeywordHarvestingPreviewRequest,
  KeywordHarvestingResponse,
} from './keyword-harvesting-contracts';

// Don't expose this and only use creators
const _KEYWORD_HARVESTING_QUERY_KEY = 'keyword-harvesting';

// Main key to use

class KeywordHarvestingService {
  public basePath = 'harvesting';

  async getKeywordHarvestingPreview(
    selectedSearchTerms: SelectedSearchTerm[],
    harvestingParams: HarvestingParams,
    filters: AlFilterModel[],
  ): Promise<ApiResponse<KeywordHarvestingModel>> {
    const startEndDateConditions = filters.find((f) => f.key == FilterKey.DATE)?.conditions;

    if (!startEndDateConditions) {
      console.error('Could not get start and end date from conditions', { filters });
      return ApiResponse.UnknownErrorResponse();
    }

    try {
      const requestData: KeywordHarvestingPreviewRequest = {
        search_term_ids: selectedSearchTerms.map((s) => s.id),
        start_date: startEndDateConditions[0].values[0].toString(), // YYYY-MM-DD
        end_date: startEndDateConditions[1].values[0].toString(),
        tacos: harvestingParams.tacos / 100,
        override_group_settings: !harvestingParams.useGroupSettings,
      };

      const applicationResponse = await apiProfileClient.post<KeywordHarvestingResponse>(`${this.basePath}/preview`, requestData);

      return applicationResponse.processPayload(KeywordHarvestingModel.fromResponse);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async applyHarvestingData(
    selectedHarvestingKeywords: KeywordHarvestingPreviewDataRow[],
  ): Promise<ApiResponse<KeywordHarvestingCreateResponseDTO>> {
    try {
      const items = selectedHarvestingKeywords.map((selectedHarvestingKeyword) => {
        const { stats: _stats, ...applyDto } = selectedHarvestingKeyword.dto; // Destructure and omit 'stats'
        return applyDto;
      });

      const requestData: ApplyHarvestingRequestDTO = { items };

      return apiProfileClient.post<KeywordHarvestingCreateResponseDTO>(`${this.basePath}/create`, requestData);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const keywordHarvestingService = new KeywordHarvestingService();
