import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { UserSettingUpdateDTO, UserSettingReadDTO, UserSettingKey, UserSettingValue } from './user-setting.contracts';
import { UserSettingModel } from '../../types/UserSettingModel';

class UserSettingService {
  public basePath = 'users';

  async getAll(userId: number): Promise<ApiResponse<UserSettingModel[]>> {
    const applicationResponse = await apiClient.get<UserSettingReadDTO[]>(`${this.basePath}/${userId}/settings`);
    return applicationResponse.processPayload((payload) => {
      return payload.map(UserSettingModel.fromDTO);
    });
  }

  async updateValue(userId: number, key: UserSettingKey, value: UserSettingValue): Promise<ApiResponse<UserSettingModel>> {
    const updatedSetting: Partial<UserSettingUpdateDTO> = { value: value };
    const applicationResponse = await apiClient.post<UserSettingReadDTO>(`${this.basePath}/${userId}/settings/${key}`, updatedSetting);
    return applicationResponse.processPayload(UserSettingModel.fromDTO);
  }
}

export const userSettingService = new UserSettingService(); // Updated the constant name
