import { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

interface ToastParams {
  autoCloseDelay?: number;
}

class ToastService {
  private defaultOptions: ToastOptions = {
    autoClose: 3000, // default auto close delay
    position: 'top-center',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  };

  private getOptions(params?: ToastParams): ToastOptions {
    return {
      ...this.defaultOptions,
      autoClose: params?.autoCloseDelay ?? this.defaultOptions.autoClose,
    };
  }

  success(message: ReactNode, params?: ToastParams) {
    toast.success(message, this.getOptions(params));
  }

  warn(message: ReactNode, params?: ToastParams) {
    toast.warn(message, this.getOptions(params));
  }

  info(message: ReactNode, params?: ToastParams) {
    toast.info(message, this.getOptions(params));
  }

  error(message: ReactNode, params?: ToastParams) {
    toast.error(message, this.getOptions(params));
  }
}

export const toastService = new ToastService();
