import { Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { NewSingleMappingModal } from './NewSingleMappingModal';

const OpenNewSingleMappingModalButton: FunctionComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onNewMappingClicked = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Button variant="contained" onClick={onNewMappingClicked}>
        Add Single Line
      </Button>

      {isModalOpen && <NewSingleMappingModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
    </>
  );
};

export default OpenNewSingleMappingModalButton;
