import { DARK_THEME } from '@/config/theme/dark-theme';
import { BASE_THEME } from '@/config/theme/light-theme';
import { useTheming } from '@/config/theme/useTheming';
import { ColorModeContext } from '@/modules/application';
import { DataGroupsPopover } from '@/modules/data-groups/components/DataGroupsPopover';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Routes } from '@/router/router-paths';
import { ThemeProvider } from '@emotion/react';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { isNil } from 'lodash-es';
import { FunctionComponent, useContext, useRef, useState } from 'react';
import { toastService } from '@/services/toast.service';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import { Button } from '@mui/material';

interface TagsButtonProps {
  selectedCampaigns: SelectedCampaignDTO[];
}

const TagsButton: FunctionComponent<TagsButtonProps> = ({ selectedCampaigns }) => {
  const { activeTeam } = useActiveTeamContext();

  // Paywall
  const [paywallMessage, setPaywallMessage] = useState('');
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
    setPaywallMessage('');
  };

  // Data groups
  const dropdownButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isDataGroupsPopoverOpen, setIsDataGroupsPopoverOpen] = useState(false);
  const onDataGroupsClicked = () => {
    if (isNil(activeTeam)) {
      toastService.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canAssignDataGroups) {
      setPaywallMessage(`Upgrade to Pro to assign tags`);
      setIsPaywallModalOpen(true);
      return;
    }

    setIsDataGroupsPopoverOpen(true);
  };

  // Theming, because it's inside OppositeModeOverlayBar, the theme needs to be opposite again
  const { mode } = useContext(ColorModeContext);
  const { colorMode } = useTheming();
  return (
    <>
      <Button ref={dropdownButtonRef} size="small" startIcon={<AccountTreeIcon />} variant={'outlined'} onClick={onDataGroupsClicked}>
        Tags
      </Button>
      <ColorModeContext.Provider
        value={{ toggleColorMode: colorMode.toggleColorMode, mode: mode === 'light' ? 'dark' : 'light', isDarkMode: mode === 'light' }}
      >
        <ThemeProvider theme={mode === 'light' ? DARK_THEME : BASE_THEME}>
          <DataGroupsPopover
            buttonRef={dropdownButtonRef}
            isOpen={isDataGroupsPopoverOpen}
            setIsOpen={setIsDataGroupsPopoverOpen}
            selectedItems={selectedCampaigns}
            dataGroupType={DataGroupType.CAMPAIGN}
          />

          <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal} returnURLPath={Routes.OPTIMIZER}>
            {paywallMessage}
          </PaywallModal>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
};

export default TagsButton;
