import MetricTimelineChart from '@/components/chart/MetricTimelineChart';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { FunctionComponent } from 'react';
import { createSearchTermsWithTimelineQueryKey, searchTermsService } from '../api/search-terms-service';
import { useSearchTermsContext } from '../contexts/SearchTermsContext';

const SearchTermsTimelineChart: FunctionComponent = () => {
  const { filters, visibleMetrics } = useSearchTermsContext();
  const { activeProfile } = useActiveTeamContext();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: createSearchTermsWithTimelineQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await searchTermsService.getSearchTermsWithTimeline(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading timeline data ' + JSON.stringify(result));
      }
    },

    enabled: !isEmpty(visibleMetrics.filter((metric) => metric.isSelected)) && !isEmpty(filters),
    retry: false,
  });

  return (
    <MetricTimelineChart
      visibleMetrics={visibleMetrics}
      timelineData={data?.timeline}
      isLoading={isLoading}
      error={error}
      isError={isError}
    />
  );
};

export default SearchTermsTimelineChart;
