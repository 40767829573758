import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { ImageRounded, ListAltRounded } from '@mui/icons-material';
import { TimeUnit } from '@/components/chart/types';
import { FunctionComponent } from 'react';

interface ChartToolboxProps {
  view: TimeUnit;
  setView: (view: TimeUnit) => void;
  onViewChartDataClick: () => void;
  onDownloadPngClick: () => void;
  onDownloadDataAsCsv: () => void;
}

const ChartToolbox: FunctionComponent<ChartToolboxProps> = ({
  view,
  setView,
  onViewChartDataClick,
  onDownloadPngClick,
  onDownloadDataAsCsv,
}) => {
  return (
    <div className="flex flex-col items-center  gap-y-2">
      <ToggleButtonGroup
        className="w-full"
        size="small"
        value={view}
        orientation="vertical"
        exclusive
        onChange={(event, newValue) => {
          if (newValue) {
            setView(newValue as TimeUnit);
          }
        }}
        aria-label="comparison value unit"
      >
        <Tooltip title={'View chart by day'} placement="left">
          <ToggleButton value={TimeUnit.DAY} aria-label="comparison unit percent">
            D
          </ToggleButton>
        </Tooltip>
        <Tooltip title={'View chart by week'} placement="left">
          <ToggleButton value={TimeUnit.WEEK} aria-label="comparison unit nominal">
            W
          </ToggleButton>
        </Tooltip>
        <Tooltip title={'View chart by month'} placement="left">
          <ToggleButton value={TimeUnit.MONTH} aria-label="comparison hidden">
            M
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>

      <Tooltip title={'View chart data'} placement="left">
        <ToggleButton value={''} onClick={onViewChartDataClick} className="w-full ">
          <div className="w-full flex justify-center">
            <ListAltRounded fontSize={'small'} />
          </div>
        </ToggleButton>
      </Tooltip>

      <Tooltip title={'Download the chart as a PNG image'} placement="left">
        <ToggleButton value={''} className="w-full " onClick={onDownloadPngClick}>
          <div className="w-full flex justify-center">
            <ImageRounded fontSize={'small'} />
          </div>
        </ToggleButton>
      </Tooltip>

      <Tooltip title={'Download chart data as CSV'} placement="left">
        <ToggleButton value={''} onClick={onDownloadDataAsCsv}>
          <div className="w-full flex">
            <span className={`tracking-tighter leading-normal`}>.csv</span>
          </div>
        </ToggleButton>
      </Tooltip>
    </div>
  );
};

export default ChartToolbox;
