import { TeamDTO } from '@/modules/teams/api/teams.contracts';

export const SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY = 'selling_partners_country_code';

export interface UserDTO {
  id: number;
  firebase_id: string;
  email: string;
  name: string;
  surname: string;
  phone: string;
  teams: TeamDTO[];
  is_admin: boolean;
  gleap_user_hash?: string;
}
export interface UserCreateDTO {
  name: string;
  surname: string;
  email: string;
  password: string;
}

export interface RegisterUserDTO extends UserCreateDTO {
  team_name: string;
  phone: string;
}

export interface UserMetadataDTO {
  category: string;
  average_ad_spend: string;
  heard_about_adlabs: string;
}

export interface UserAndTeamCreateResponse {
  team_id: number;
  user_id: number;
  firebase_id: string;
}

export interface UpdateUserNameDTO {
  name: string;
  surname: string;
}

export interface UpdateUserPhoneDTO {
  phone: string;
}

export interface UpdateUserPasswordDTO {
  password: string;
  new_password: string;
}

export interface AuthorizeUserDTO {
  code: string;
  state: string;
}

export interface AdsAuthorizeUserDTO extends AuthorizeUserDTO {
  is_registration: boolean;
}

export interface VendorAuthorizationDTO extends AuthorizeUserDTO {
  selling_partner_id: string;
  profile_ids: string[];
}

export interface AmazonAccountDTO {
  id: number;
  is_authorized: boolean;
  auth_email: string;
  auth_name: string;
  owner: OwnerDTO;
}

export interface OwnerDTO {
  id: number;
  name: string;
  email: string;
}

export interface UserGleapDataDTO {
  [key: string]: string;
}

export interface CheckEmailDTO {
  exists: boolean;
}
