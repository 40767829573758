import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import useFormatting from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { GleapWorkflowType } from '@/lib/gleap';
import { ReportingStatusType } from '@/modules/profiles/api/profile.contracts';
import { ProfileModel } from '@/modules/profiles/types/ProfileModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useReportsContext } from '@/modules/teams/contexts/ReportsContext';
import { Routes } from '@/router/router-paths';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { ButtonBase, Link, ListItemIcon, ListItemText, Paper } from '@mui/material';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Gleap from 'gleap';
import { debounce } from 'lodash-es';
import { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { toastService } from '@/services/toast.service';
import ProfileSearchBox from './ProfileSearchBox';

interface ProfileSelectProps {
  squareLeftSide?: boolean;
  setFilters?: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
}

export const ProfileSelect: FunctionComponent<ProfileSelectProps> = ({ squareLeftSide = false, setFilters }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { formatDateStringTimeNoSeconds } = useFormatting();
  const { activeTeam, activeProfile, setActiveProfile } = useActiveTeamContext();
  const { activeProfileReportsStatusInfo } = useReportsContext();
  const buttonRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const doesStatusNeedAttention = activeProfileReportsStatusInfo.status != ReportingStatusType.COMPLETED;
  const activeProfileDataStatusInfoStatusDescription = t(`enums.profile_status_descriptions.${activeProfileReportsStatusInfo.status}`);
  const hasMoreThanOneProfile = activeTeam ? activeTeam?.profiles.filter((profile) => profile.isActive).length > 1 : false;

  const handleClick = () => {
    setIsDropdownOpen(true);
  };
  const handleClose = () => {
    setIsDropdownOpen(false);
  };

  const onAddAnotherProfileClicked = () => {
    navigate(Routes.PROFILES);
    setIsDropdownOpen(false);
  };

  const onProfileClicked = (profile: ProfileModel) => {
    if (profile) {
      // To avoid fetching data with previous profile filters during profile change
      if (setFilters) {
        setFilters([]);
      }

      setActiveProfile(profile.id);
    }

    setIsDropdownOpen(false);
  };

  const debouncedToast = debounce(() => {
    toastService.warn('You have no profiles. Please add a profile first.');
  }, 250);

  function onContactSupportClicked() {
    Gleap.startFeedbackFlow(GleapWorkflowType.BugReport, true);
  }

  useEffect(() => {
    if (activeTeam && activeTeam.profiles.length == 0) {
      debouncedToast();
      navigate(Routes.PROFILES);
    }
  }, [activeTeam?.profiles.length]);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProfiles, setFilteredProfiles] = useState<ProfileModel[]>([]);
  useEffect(() => {
    if (searchTerm && searchTerm != '') {
      return setFilteredProfiles(activeTeam?.profiles.filter((p) => p.name.toLowerCase().includes(searchTerm.toLowerCase())) ?? []);
    } else {
      return setFilteredProfiles(activeTeam?.profiles ?? []);
    }
  }, [activeTeam?.profiles, searchTerm]);

  return (
    <Fragment>
      <>
        <Tooltip title="Switch Profiles">
          <ButtonBase
            ref={buttonRef}
            className={`min-w-32 h-9 flex cursor-pointer justify-center ${
              squareLeftSide ? 'rounded-r-md' : 'rounded-md'
            } p-2 font-semibold text-gray-850 bg-white hover:bg-primary-50 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700`}
            onClick={handleClick}
            aria-roledescription="button"
            role="button"
            tabIndex={0}
            style={{ minWidth: '200px' }}
          >
            <div className="flex items-center gap-1 px-1 justify-between w-full ">
              <div className="flex items-center gap-1">
                <span className="relative flex h-3 w-3 mr-1">
                  <span
                    className={` absolute inline-flex h-full w-full rounded-full ${
                      doesStatusNeedAttention ? 'bg-orange-400 animate-ping' : 'bg-green-400'
                    } opacity-75`}
                  ></span>
                  <span
                    className={`relative inline-flex rounded-full h-3 w-3 ${doesStatusNeedAttention ? 'bg-orange-500' : 'bg-green-500'}`}
                  ></span>
                </span>

                <div className="flex flex-col">
                  <div className="flex text-xs truncate">{activeProfile?.nameWithMarket}</div>
                  <div className="flex text-xs font-normal">{formatDateStringTimeNoSeconds(activeProfileReportsStatusInfo.updatedAt)}</div>
                </div>
              </div>

              <ArrowDropDownRoundedIcon fontSize="small" />
            </div>
          </ButtonBase>
        </Tooltip>
      </>

      <Menu
        style={{ width: '280px' }}
        anchorEl={buttonRef.current}
        id="account-menu"
        open={isDropdownOpen}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          disablePadding: true,
        }}
        slotProps={{
          paper: {
            sx: { minWidth: '275px' },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Paper square elevation={0} className="flex flex-col w-full text-xs px-4 bg-gray-100 pt-2 ">
          <div className="flex flex-col mb-3">
            <div className="font-bold text-sm">{activeProfile?.nameWithMarket}</div>

            <div className="break-normal">
              {' '}
              <span className={` ${doesStatusNeedAttention ? 'text-orange-600' : 'text-green-600'}`}>
                {activeProfileDataStatusInfoStatusDescription}
              </span>
              {activeProfileReportsStatusInfo.status == ReportingStatusType.ERROR && (
                <Link href="#" className="text-xs px-1 rounded" onClick={onContactSupportClicked}>
                  (Contact Support)
                </Link>
              )}
            </div>
          </div>
        </Paper>
        <Divider />
        {hasMoreThanOneProfile && <div className="mb-1 mt-3 text-xs font-bold px-4">Switch to other Profile</div>}

        {hasMoreThanOneProfile && activeTeam && activeTeam.profiles?.length > 5 && (
          <div className="ml-2 mr-2" onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
            <ProfileSearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </div>
        )}

        {filteredProfiles
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((profile) => {
            if (activeProfile?.id == profile.id || profile.isNotAuthorized) {
              return null;
            }
            return (
              <MenuItem key={profile.id} onClick={() => onProfileClicked(profile)}>
                <div className="flex w-full items-center justify-between">
                  <div>{profile.getTruncatedNameWithMarket(25)}</div>
                  {/* The board does not want these here, they get confused of a dropdown happening <ChevronRightRounded className="pb-px" /> */}
                </div>
              </MenuItem>
            );
          })}
        <Divider />
        <MenuItem onClick={onAddAnotherProfileClicked}>
          <ListItemIcon>
            <BusinessRoundedIcon />
          </ListItemIcon>
          <ListItemText>Manage Profiles</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
