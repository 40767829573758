import { FunctionComponent, ChangeEvent } from 'react';
import { Button, FormControl, IconButton, TextField, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { DataItemModel, NewDataItem, countNameOccurancesCaseInsensitive, getOriginalName } from '../models/DataItem';
import UndoIcon from '@mui/icons-material/Undo';
interface EditableDataItemsListProps {
  items: (DataItemModel | NewDataItem)[];
  setItems: (items: (DataItemModel | NewDataItem)[]) => void;
  deleteItemIds: number[];
  setDeleteItemIds: (ids: number[]) => void;
  originalItems: (DataItemModel | NewDataItem)[];
}

export const EditableDataItemsList: FunctionComponent<EditableDataItemsListProps> = ({
  items,
  setItems,
  deleteItemIds,
  setDeleteItemIds,
  originalItems,
}) => {
  const handleItemChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newItems = [...items];
    newItems[index] = { ...newItems[index], name: event.target.value };
    setItems(newItems);
  };

  const handleAddItem = () => {
    setItems([...items, { name: '' }]);
  };

  const handleDeleteItem = (index: number) => {
    const item = items[index];
    if (item.id && !deleteItemIds.includes(item.id)) {
      setDeleteItemIds([...deleteItemIds, item.id]);
    } else {
      const newItems = items.filter((_, idx) => idx !== index);
      setItems(newItems);
    }
  };

  const handleRestoreItem = (id: number) => {
    const newDeleteItemIds = deleteItemIds.filter((itemId) => itemId !== id);
    setDeleteItemIds(newDeleteItemIds);
  };

  return (
    <div>
      {items.map((item, index) => {
        const isDeleted = item.id ? deleteItemIds.includes(item.id) : false;
        const originalName = getOriginalName(item, originalItems);
        const isNameChanged = originalName && originalName !== item.name; // original name exists only for existing items
        const isDuplicate = item.name !== '' && countNameOccurancesCaseInsensitive(item, items) > 1;
        const helperText = (isDuplicate ? 'Duplicate name ' : '') + (isNameChanged && !isDeleted ? `(changed from: ${originalName})` : '');
        return (
          <div key={index} style={{ display: 'flex', textDecoration: isDeleted ? 'line-through' : 'none' }}>
            <FormControl fullWidth>
              <TextField
                autoFocus
                label={`Value ${index + 1}`}
                value={isDeleted ? originalName : item.name}
                onChange={(event) => handleItemChange(index, event)}
                helperText={helperText}
                disabled={isDeleted}
                error={isDuplicate}
              />
            </FormControl>
            <Tooltip title={isDeleted ? 'Restore' : 'Delete'}>
              {/* This div keeps icon buttons from expanding vertically, using mt instead of center because of helperText changing the parent height */}
              <div className="flex-shrink-0 flex-grow-0 mt-4">
                {isDeleted ? (
                  <IconButton onClick={() => handleRestoreItem(item.id!)}>
                    <UndoIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleDeleteItem(index)}>
                    <HighlightOffRoundedIcon />
                  </IconButton>
                )}
              </div>
            </Tooltip>
          </div>
        );
      })}
      <Button onClick={handleAddItem} autoFocus variant="text" startIcon={<AddIcon />}>
        Add New Value
      </Button>
    </div>
  );
};
