import { Link, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

export const CopyrightText: FunctionComponent = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
      {'Copyright © '}
      <Link color="inherit" href="https://adlabs.app/">
        AdLabs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};
