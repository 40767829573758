import { useState, useEffect } from 'react';
import { AccountType, SpendType } from '../../types/wizard_step_state.enum';

// Interfaces
interface WizardUser {
  email: string;
  userId: number;
  firebaseId: string;
  phone: string;
}

interface WizardProfile {
  profileId: string;
}

interface RegistrationState {
  user: WizardUser | null;
  teamName: string;
  teamId: number;
  profiles: WizardProfile[];
  accountType: AccountType;
  averageAdSpend: SpendType;
  hearAboutAdlabs: string;
}

const LOCAL_STORAGE_KEY = 'registrationState';

const DEFAULT_STATE: RegistrationState = {
  user: null,
  teamName: '',
  teamId: 0,
  profiles: [],
  accountType: '' as AccountType,
  averageAdSpend: '' as SpendType,
  hearAboutAdlabs: '',
};

// Hook
export const useRegistration = () => {
  const [state, setState] = useState<RegistrationState>(DEFAULT_STATE);

  // Load state from local storage
  useEffect(() => {
    const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedState) {
      setState(JSON.parse(storedState));
    }
  }, []);

  // Save state to local storage whenever it changes
  useEffect(() => {
    // Save only if state is different from default
    if (JSON.stringify(state) != JSON.stringify(DEFAULT_STATE)) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    }
  }, [JSON.stringify(state)]);

  const setUser = (user: WizardUser) => {
    setState((prevState) => ({ ...prevState, user }));
  };

  const setTeamName = (teamName: string) => {
    setState((prevState) => ({ ...prevState, teamName }));
  };

  const setTeamId = (teamId: number) => {
    setState((prevState) => ({ ...prevState, teamId }));
  };

  const addProfile = (profile: WizardProfile) => {
    setState((prevState) => ({ ...prevState, profiles: [...prevState.profiles, profile] }));
  };

  const setAccountType = (accountType: AccountType) => {
    setState((prevState) => ({ ...prevState, accountType }));
  };

  const setAverageAdSpend = (averageAdSpend: SpendType) => {
    setState((prevState) => ({ ...prevState, averageAdSpend }));
  };

  const setHearAboutAdlabs = (hearAboutAdlabs: string) => {
    setState((prevState) => ({ ...prevState, hearAboutAdlabs }));
  };

  const loadStateFromStorage = () => {
    const storedState = localStorage.getItem('registrationState');
    if (storedState) {
      setState(JSON.parse(storedState));
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  return {
    state,
    setUser,
    firebaseId: state.user?.firebaseId,
    userId: state.user?.userId,
    phone: state.user?.phone,

    teamName: state.teamName,
    setTeamName,

    teamId: state.teamId,
    setTeamId,

    accountType: state.accountType,
    setAccountType,

    averageAdSpend: state.averageAdSpend,
    setAverageAdSpend,

    hearAboutAdlabs: state.hearAboutAdlabs,
    setHearAboutAdlabs,

    addProfile,
    loadStateFromStorage,
    clearLocalStorage,
  };
};
