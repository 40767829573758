import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnState } from 'ag-grid-enterprise';

export function generateNegativeTargetingTableColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.CHECKBOX, hide: false, pinned: 'left' },
    { colId: ColumnId.ID, hide: true, pinned: 'left' },
    { colId: ColumnId.TARGETING, hide: false, pinned: 'left' },
    { colId: ColumnId.TARGETING_TYPE, hide: false, pinned: 'left' },
    { colId: ColumnId.ENTITY_TYPE, hide: false, pinned: 'left' },
    { colId: ColumnId.NEGATIVE_MATCH_TYPE, hide: false, pinned: 'left' },
    { colId: ColumnId.STATE, hide: false, pinned: 'left' },
    { colId: ColumnId.CAMPAIGN_NAME, hide: false },
    { colId: ColumnId.CAMPAIGN_AD_TYPE, hide: false },
    { colId: ColumnId.CAMPAIGN_STATE, hide: false },
    { colId: ColumnId.AD_GROUP, hide: false },
    { colId: ColumnId.CREATED_AT, hide: false },
  ];
}
