import { useState, useRef, FunctionComponent } from 'react';
import { ButtonBase, ButtonGroup, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown, Addchart, Info } from '@mui/icons-material';
import PopoverLikePopper from '@/components/PopoverLikePopper';
import DashboardWidgetCatalogItem from '../widget-catalog/DashboardWidgetCatalogItem';
import { COMBO_GRAPH_WIDGET_CATALOG_ITEM } from '../widgets/combo-graph-widget/ComboGraphWidgetCatalogItem';
import { METRIC_WIDGET_CATALOG_ITEM } from '../widgets/metric-widget/MetricWidgetCatalogItem';
import { BAR_GRAPH_WIDGET_CATALOG_ITEM } from '../widgets/bar-graph-widget/BarGraphWidgetCatalogItem';
import { HORIZONTAL_DIVIDER_WIDGET_CATALOG_ITEM } from '../widgets/horizontal-divider-widget/HorizontalDividerWidgetCatalogItem';
import { LINE_GRAPH_WIDGET_CATALOG_ITEM } from '../widgets/line-graph-widget/LineGraphWidgetCatalogItem';
import { PIE_GRAPH_WIDGET_CATALOG_ITEM } from '../widgets/pie-graph-widget/PieGraphWidgetCatalogItem';
import { TABLE_WIDGET_CATALOG_ITEM } from '../widgets/table-widget/TableWidgetCatalogItem';
import { TEXT_WIDGET_CATALOG_ITEM } from '../widgets/text-widget/TextWidgetCatalogItem';

interface DashboardActionsButtonProps {
  onRenameDashboard: () => void;
  onDeleteDashboard: () => void;
  onWidgetAdded: () => void;
}

const DashboardActionsButton: FunctionComponent<DashboardActionsButtonProps> = ({ onRenameDashboard, onDeleteDashboard, onWidgetAdded }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isAddWidgetPopoverOpen, setIsAddWidgetPopoverOpen] = useState(false);
  const addWidgetAnchorEl = useRef<HTMLButtonElement | null>(null);

  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onAddWidgetPopoverClose = () => setIsAddWidgetPopoverOpen(false);

  const onAddWidgetClicked = () => {
    setIsAddWidgetPopoverOpen(true);
  };

  return (
    <>
      <ButtonGroup variant="contained" disableElevation color="secondary">
        <ButtonBase
          className="min-w-32 h-10 flex cursor-pointer justify-center rounded-l-md p-2 text-sm font-medium text-gray-850 bg-white hover:bg-primary-50 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700"
          onClick={onAddWidgetClicked}
          ref={addWidgetAnchorEl}
        >
          <Addchart fontSize="small" className="pr-1" />
          Add Widget
        </ButtonBase>
        <div className="flex h-full w-px bg-slate-400"></div>
        <ButtonBase
          className="min-w-8 h-10 flex cursor-pointer justify-center rounded-r-md p-2 font-semibold text-gray-850 bg-white hover:bg-primary-50 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700"
          onClick={handleMenuClick}
        >
          <ArrowDropDown />
        </ButtonBase>
      </ButtonGroup>
      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            onRenameDashboard();
            handleMenuClose();
          }}
        >
          Rename Dashboard
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteDashboard();
            handleMenuClose();
          }}
        >
          Delete Dashboard
        </MenuItem>
      </Menu>
      <PopoverLikePopper
        anchorEl={addWidgetAnchorEl.current}
        open={isAddWidgetPopoverOpen}
        onClose={onAddWidgetPopoverClose}
        transformOrigin="right top"
        placement="bottom-end"
      >
        <div className="flex flex-col pb-4 ">
          <div className="text-xs  w-full px-4 pt-4 pb-1 flex items-center  border-gray-200 ">
            <Info className="text-blue-400 pr-1" fontSize="small" />
            Added widgets will appear at the bottom of the Dashboard.
          </div>

          <div className="px-4 font-medium mt-4 text-gray-500 uppercase text-xs ">KPI&apos;s</div>
          <div className="flex flex-col px-4 gap-y-3 mt-2">
            <div key={METRIC_WIDGET_CATALOG_ITEM.id} className=" ">
              <DashboardWidgetCatalogItem widget={METRIC_WIDGET_CATALOG_ITEM} onWidgetAdded={onWidgetAdded} />
            </div>
          </div>
          <div className="px-4 font-medium mt-4 text-gray-500 uppercase text-xs ">Tables</div>
          <div className="flex flex-col px-4 gap-y-3 mt-2">
            <div key={TABLE_WIDGET_CATALOG_ITEM.id} className=" ">
              <DashboardWidgetCatalogItem widget={TABLE_WIDGET_CATALOG_ITEM} onWidgetAdded={onWidgetAdded} />
            </div>
          </div>

          <div className="px-4 font-medium mt-4 text-gray-500 uppercase text-xs ">Graphs</div>
          <div className="flex flex-col px-4 gap-y-3 mt-2">
            <div key={COMBO_GRAPH_WIDGET_CATALOG_ITEM.id} className=" ">
              <DashboardWidgetCatalogItem widget={COMBO_GRAPH_WIDGET_CATALOG_ITEM} onWidgetAdded={onWidgetAdded} />
            </div>
            <div key={BAR_GRAPH_WIDGET_CATALOG_ITEM.id} className=" ">
              <DashboardWidgetCatalogItem widget={BAR_GRAPH_WIDGET_CATALOG_ITEM} onWidgetAdded={onWidgetAdded} />
            </div>
            <div key={PIE_GRAPH_WIDGET_CATALOG_ITEM.id} className=" ">
              <DashboardWidgetCatalogItem widget={PIE_GRAPH_WIDGET_CATALOG_ITEM} onWidgetAdded={onWidgetAdded} />
            </div>
            <div key={LINE_GRAPH_WIDGET_CATALOG_ITEM.id} className=" ">
              <DashboardWidgetCatalogItem widget={LINE_GRAPH_WIDGET_CATALOG_ITEM} onWidgetAdded={onWidgetAdded} />
            </div>
          </div>

          <div className="px-4 font-medium mt-4 text-gray-500 uppercase text-xs ">Miscellaneous</div>
          <div className="flex flex-col px-4 gap-y-3 mt-2">
            <div key={HORIZONTAL_DIVIDER_WIDGET_CATALOG_ITEM.id} className=" ">
              <DashboardWidgetCatalogItem widget={HORIZONTAL_DIVIDER_WIDGET_CATALOG_ITEM} onWidgetAdded={onWidgetAdded} />
            </div>
            <div key={TEXT_WIDGET_CATALOG_ITEM.id} className=" ">
              <DashboardWidgetCatalogItem widget={TEXT_WIDGET_CATALOG_ITEM} onWidgetAdded={onWidgetAdded} />
            </div>
          </div>
        </div>
      </PopoverLikePopper>
    </>
  );
};

export default DashboardActionsButton;
