import FilterControls from '@/components/filter-builder/FilterControls';
import { SearchBox } from '@/components/filter-builder/SearchBox';
import { ProductTitleFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import GridTools from '@/components/grid/components/GridTools';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import ExpandToggleButton from '@/modules/application/components/ExpandToggleButton';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';
import { ProductsWithTimeline } from '../api/products-contracts';
import { useProductsContext } from '../contexts/ProductsContext';
import useAvailableProductFilters from '../hooks/useAvailableProductFilters';
import FilterBarCard from '@/components/cards/FilterBarCard';
interface ProductsFilterBarProps {
  withTimeline: ProductsWithTimeline | undefined;
  gridApiRef: React.MutableRefObject<GridApi<unknown> | null>;
  onExpandTable: () => void;
  isExpanded: boolean;
}

const ProductsFilterBar: FunctionComponent<ProductsFilterBarProps> = ({ withTimeline, gridApiRef, onExpandTable, isExpanded }) => {
  const { filters, setFilters, setFilterValue, setFilterValues, gridToggles, setGridToggles } = useProductsContext();
  const { getComparisonDataMissingMessage } = useComparisonMissing({});

  const { availableProductFilters: availableFilters } = useAvailableProductFilters();
  const defaultFilterKey = FilterKey.PRODUCT_TITLE;

  return (
    <FilterBarCard>
      <SearchBox
        filterKey={FilterKey.PRODUCT_TITLE}
        newFilterModel={() => new ProductTitleFilterModel()}
        placeholder="Product Title"
        filters={filters}
        setFilterValue={setFilterValue}
        setFilters={setFilters}
      />

      <FilterControls filters={filters} setFilters={setFilters} availableFilters={availableFilters} defaultFilterKey={defaultFilterKey} />

      <div className="flex flex-grow"></div>
      <div className="flex h-full justify-center items-center gap-x-3">
        <ExpandToggleButton isExpanded={isExpanded} onExpandToggled={onExpandTable} />

        <DateRangeButton
          title="Range"
          filters={filters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to stats"
          comparisonRangeWarning={getComparisonDataMissingMessage(withTimeline?.isComparisonDataMissing ?? false)}
        />
      </div>
      <GridTools gridToggles={gridToggles} setGridToggles={setGridToggles} gridApiRef={gridApiRef} />
    </FilterBarCard>
  );
};

export default ProductsFilterBar;
