import { FunctionComponent } from 'react';
import TextLink from '../buttons/TextLink';

interface FiltersMoreButtonProps {
  filterChipsCount: number;
  maxFilterChips: number;
  onFilterChipClicked: () => void;
}

export const FiltersMoreButton: FunctionComponent<FiltersMoreButtonProps> = ({
  filterChipsCount: filtersCount,
  maxFilterChips,
  onFilterChipClicked,
}) => {
  if (filtersCount <= maxFilterChips) {
    return <></>;
  }

  return (
    <TextLink onClick={onFilterChipClicked}>
      <span className="text-gray-400">+{filtersCount - maxFilterChips}</span>
    </TextLink>
  );
};
