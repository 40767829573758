import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { DashboardCalculatedDataDTO } from './dashboard-data.contracts';
import { DashboardCalculatedDataModel } from '@/modules/dashboards/types/DashboardCalculatedDataModel';

class DashboardDataService {
  public basePath = 'dashboards';

  async getById(id: number): Promise<ApiResponse<DashboardCalculatedDataModel>> {
    try {
      const applicationResponse = await apiClient.get<DashboardCalculatedDataDTO>(`${this.basePath}/${id}/data`);
      return applicationResponse.processPayload((payload) => {
        return new DashboardCalculatedDataModel(payload);
      });
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const dashboardDataService = new DashboardDataService();
