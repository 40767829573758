import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FunctionComponent, useState } from 'react';

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  url: string;
  title: string;
}

const VideoModal: FunctionComponent<VideoModalProps> = ({ isOpen, onClose, url, title }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <Dialog open={isOpen} aria-labelledby="video-modal" maxWidth="lg" fullWidth>
      <DialogTitle>
        <div className="flex flex-row">
          {title}
          <div className="flex flex-grow"></div>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ padding: 16, paddingTop: 0 }}>
        <div style={{ position: 'relative', aspectRatio: '16/9' }}>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </div>
          )}
          <iframe
            src={url}
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 'none',
            }}
            title={title}
            onLoad={handleIframeLoad}
          ></iframe>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VideoModal;
