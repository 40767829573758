import { RouteObject } from 'react-router';
import LogsPage from '../pages/LogsPage';

export const LOGS_ROUTES: RouteObject[] = [
  {
    path: 'logs',
    children: [
      {
        index: true,
        element: <LogsPage />,
      },
    ],
  },
];
