import { DebugDataRowWithPlacement } from './DebugDataRow';
import { ResponseCampaignDebugRow } from '../api/optimization-contracts';

export class CampaignDebugRow {
  public campaignName: string;
  public adType: string;
  public placementMod: number;

  public stats: DebugDataRowWithPlacement[] | null;

  constructor(args: CampaignDebugRowCreateArguments) {
    this.campaignName = args.campaignName;
    this.adType = args.adType;
    this.placementMod = args.placementMod;

    this.stats = args.stats;
  }

  public static fromResponse(response: ResponseCampaignDebugRow): CampaignDebugRow {
    return new CampaignDebugRow({
      campaignName: response.campaign_name,
      adType: response.ad_type,
      placementMod: response.placement_mod,

      stats: response.stats ? DebugDataRowWithPlacement.fromResponseArray(response.stats) : null,
    });
  }

  public static fromResponseArray(responseArray: ResponseCampaignDebugRow[]): CampaignDebugRow[] {
    return responseArray.map((response) => CampaignDebugRow.fromResponse(response));
  }
}

export interface CampaignDebugRowCreateArguments {
  campaignName: string;
  placementMod: number;
  adType: string;

  stats: DebugDataRowWithPlacement[] | null;
}
