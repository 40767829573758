import { FunctionComponent } from 'react';
import { AlFilterModel } from './models/AlFilterModel';
import { FiltersMoreButton } from './FiltersMoreButton';
import { FilterChip } from './FilterChip';
import { Button } from '@mui/material';

const DEFAULT_MAX_FILTER_CHIPS = 3;

interface FilterChipRowProps {
  filters: AlFilterModel[];
  onFilterDeleteButtonClicked: (filter: AlFilterModel) => void;
  onFilterChipClicked: () => void;
  onRemoveAllFiltersClicked: () => void;
  maxFilterChips?: number;
}

export const FilterChipRow: FunctionComponent<FilterChipRowProps> = ({
  filters,
  onFilterDeleteButtonClicked,
  onFilterChipClicked,
  onRemoveAllFiltersClicked,
  maxFilterChips = DEFAULT_MAX_FILTER_CHIPS,
}) => {
  const filterChipsCount = filters.filter((f) => f.isFilterBuilderFilter).length;

  return (
    <div className="flex flex-row gap-2 items-center">
      {filters
        .filter((f) => f.isFilterBuilderFilter)
        .map((filter, index) => {
          return (
            <FilterChip
              key={filter.key + index}
              filter={filter}
              handleClick={onFilterChipClicked}
              handleDelete={onFilterDeleteButtonClicked}
            />
          );
        })
        .slice(0, maxFilterChips)}

      <FiltersMoreButton filterChipsCount={filterChipsCount} maxFilterChips={maxFilterChips} onFilterChipClicked={onFilterChipClicked} />

      {filters && filterChipsCount > 1 && (
        <Button variant="text" onClick={onRemoveAllFiltersClicked} className="min-w-20">
          Remove all
        </Button>
      )}
    </div>
  );
};
