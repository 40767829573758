import { GleapWorkflowType } from '@/lib/gleap';
import { BugReport } from '@mui/icons-material';
import Gleap from 'gleap';
import { FunctionComponent } from 'react';
import { MainMenuTextButton } from './MainMenuTextButton';

interface MainMenuBugButtonProps {
  isExpanded: boolean;
}

export const MainMenuBugButton: FunctionComponent<MainMenuBugButtonProps> = ({ isExpanded }) => {
  const startGleapFeedbackFlow = () => {
    Gleap.startFeedbackFlow(GleapWorkflowType.BugReport, true);
  };
  return (
    <MainMenuTextButton
      icon={<BugReport />}
      isActive={false}
      isExpanded={isExpanded}
      label={'Report a Bug'}
      onClick={startGleapFeedbackFlow}
    />
  );
};
