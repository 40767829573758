export enum WizardStepState {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export enum AccountType {
  Agency = 'Agency',
  Brand = 'Brand',
  Freelancer = 'Freelancer',
}

export enum SpendType {
  LessThanTenThousand = '<$10,000',
  TenToTwentyThousand = '$10,000 - $20,000',
  TwentyToFiftyThousand = '$20,000 - $50,000',
  FiftyToOneHundredFiftyThousand = '$50,000 - $150,000',
  MoreThanOneHundredFiftyThousand = '$150,000+',
}
