import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { Routes } from '@/router/router-paths';
import { Button } from '@mui/material';
import { useState } from 'react';

const UpgradeSubscriptionButton = () => {
  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  function onUpgradeButtonClicked() {
    setIsPaywallModalOpen(true);
  }

  return (
    <>
      <Button
        size="small"
        className="mx-2 px-8 rounded-md font-bold"
        style={{
          background: 'linear-gradient(to left, #EB548F, #F57335)',
        }}
        onClick={onUpgradeButtonClicked}
      >
        Upgrade
      </Button>

      <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal} returnURLPath={Routes.BILLING}></PaywallModal>
    </>
  );
};

export default UpgradeSubscriptionButton;
