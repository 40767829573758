import { MetricSelectionColorIndex } from '@/components/metrics/MetricsConfig';
import MetricsContainer from '@/components/metrics/MetricsContainer';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import { MetricAggregates, MetricField } from '@/components/metrics/types/MetricField';
import { FormattingParams } from '@/hooks/useFormatting';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { isNil } from 'lodash-es';
import { FunctionComponent } from 'react';

interface ProfilesStatsMetricsProps {
  metricColumnAggregates: MetricAggregates | undefined;
  visibleMetrics: MetricSelectionColorIndex[];
  setVisibleMetrics: React.Dispatch<React.SetStateAction<MetricSelectionColorIndex[]>>;
  availableMetrics: MetricField[];
  selectedCurrency: CurrencyCode;
  showComparison?: boolean;
}

const ProfilesStatsMetrics: FunctionComponent<ProfilesStatsMetricsProps> = ({
  metricColumnAggregates,
  visibleMetrics,
  setVisibleMetrics,
  availableMetrics,
  selectedCurrency,
  showComparison,
}) => {
  const metricValues = metricColumnAggregates ? MetricModel.fromMetricAggregates(metricColumnAggregates) : undefined;

  const formattingParams: FormattingParams = {
    customCurrencyCode: selectedCurrency,
  };

  return (
    <MetricsContainer
      metricValues={metricValues}
      isLoading={isNil(metricColumnAggregates)}
      isError={false}
      error={null}
      visibleMetrics={visibleMetrics}
      setVisibleMetrics={setVisibleMetrics}
      availableMetrics={availableMetrics}
      formattingParams={formattingParams}
      showComparison={showComparison}
    />
  );
};

export default ProfilesStatsMetrics;
