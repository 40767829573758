import { FunctionComponent, ReactNode } from 'react';
import DashboardWidgetDragHandle from './DashboardWidgetDragHandle';
import { useDynamicHandleSize } from '../../hooks/useDynamicHandleSize';

interface DashboardWidgetTopBarProps {
  children: ReactNode;
  isMouseOnWidget?: boolean;
}

const DashboardWidgetTopBar: FunctionComponent<DashboardWidgetTopBarProps> = ({ children, isMouseOnWidget }) => {
  const { containerRef, handleSize } = useDynamicHandleSize();

  return (
    <div ref={containerRef} className="flex justify-between items-center relative">
      {isMouseOnWidget && <DashboardWidgetDragHandle size={handleSize} />}
      {children}
    </div>
  );
};

export default DashboardWidgetTopBar;
