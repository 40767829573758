import { CampaignAdType } from '../../api/campaign/campaign-contracts';
import { BidCeilingType } from '../../types/BidCeilingType';
import { BidLimitType, PlacementBidLimitType } from '../../types/BidLimitType';
import { SmartBidCeilingType } from '../../types/SmartBidCeilingType';
import { MatchType, OptimizationReason } from './api/optimization-contracts';
import { BiddingEntity } from './models/OptimizationModel';

export enum OptimizationPreset {
  NOT_SET = 'NOT_SET',
  BALANCED = 'BALANCED',
  REDUCE_ACOS = 'REDUCE_ACOS',
  INCREASE_SALES = 'INCREASE_SALES',
}

export const availableOptimizationPresets = [
  OptimizationPreset.NOT_SET,
  OptimizationPreset.BALANCED,
  OptimizationPreset.REDUCE_ACOS,
  OptimizationPreset.INCREASE_SALES,
];

export interface OptimizationApplyData {
  id: string;
  ad_type: CampaignAdType;
  bidding_entity: BiddingEntity;
  match_type: MatchType;
  new_value: number;
  algo_value: number;
  old_value: number;
  reasons: OptimizationReason[];
}

export interface OptimizationParams {
  tacos: number;
  selectedPreset: OptimizationPreset;
  highAcos: boolean;
  lowAcos: boolean;
  highSpend: boolean;
  lowVisibility: boolean;
  showZeroImpressions: boolean;
  bidCeilingType: BidCeilingType;
  smartBidCeilingType: SmartBidCeilingType;
  bidCeiling?: number;
  bidFloor?: number;
  useGroupSettings: boolean;
  usePlacementOptimization: boolean;

  bidMaxIncreaseType: BidLimitType;
  bidMaxIncrease?: number;
  bidMaxDecreaseType: BidLimitType;
  bidMaxDecrease?: number;
  placementMaxIncreaseType: PlacementBidLimitType;
  placementMaxIncrease?: number;
  placementMaxDecreaseType: PlacementBidLimitType;
  placementMaxDecrease?: number;
}
