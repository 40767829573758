import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { AlDate, DAY_WITH_MONTH_FORMAT, DISPLAY_DATE_FORMAT, YEAR_FORMAT } from '@/lib/date/AlDate';

import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';

interface DateRangeDisplayProps {
  filtersUsed: AlFilterModel[];
  hideComparison?: boolean;
  comparisonRangeWarning?: string | null;
}

const DateRangeDisplay: FunctionComponent<DateRangeDisplayProps> = ({
  filtersUsed: filters,
  hideComparison = false,
  comparisonRangeWarning,
}) => {
  const dateRange = filters.find((filterItem) => filterItem.key === FilterKey.DATE)?.conditions;
  const comparisonDateRange = filters.find((filterItem) => filterItem.key === FilterKey.COMPARE_DATE)?.conditions;

  return (
    <div className="flex">
      <div className="flex flex-col">
        {dateRange && dateRange.length > 0 ? (
          <div className="font-bold whitespace-nowrap">
            {AlDate.parse(dateRange[0].values[0].toString()).toFormat(DAY_WITH_MONTH_FORMAT)}
            {AlDate.parse(dateRange[0].values[0].toString()).isSameYear(AlDate.parse(dateRange[1].values[0].toString()))
              ? ''
              : ', ' + AlDate.parse(dateRange[0].values[0].toString()).toFormat(YEAR_FORMAT)}
            <span className="px-1">-</span>
            {AlDate.parse(dateRange[1].values[0].toString()).toFormat(DISPLAY_DATE_FORMAT)}
          </div>
        ) : (
          <div className="flex">
            <div className="text-sm">-</div>
          </div>
        )}
        {!hideComparison && comparisonDateRange && comparisonDateRange.length > 0 && (
          <div className="flex items-center text-xs opacity-70">
            {comparisonRangeWarning && (
              <Tooltip title={comparisonRangeWarning} placement="top">
                <WarningIcon
                  sx={{
                    marginRight: '4px',
                    width: 12,
                    height: 12,
                    color: 'darkorange',
                  }}
                />
              </Tooltip>
            )}
            <div className="">{AlDate.parse(comparisonDateRange[0].values[0].toString()).toFormat(DAY_WITH_MONTH_FORMAT)}</div>
            <div>
              {AlDate.parse(comparisonDateRange[0].values[0].toString()).isSameYear(AlDate.parse(comparisonDateRange[1].values[0].toString()))
                ? ''
                : ', ' + AlDate.parse(comparisonDateRange[0].values[0].toString()).toFormat(YEAR_FORMAT)}
            </div>
            <div className=" px-1">-</div>
            <div className="">{AlDate.parse(comparisonDateRange[1].values[0].toString()).toFormat(DISPLAY_DATE_FORMAT)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangeDisplay;
