import { RouteObject } from 'react-router';
import TeamProfilesPage from '../pages/TeamProfilesPage';

export const TEAMS_ROUTES: RouteObject[] = [
  {
    path: 'profiles',
    children: [
      {
        index: true,
        element: <TeamProfilesPage />,
      },
    ],
  },
];
