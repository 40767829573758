import { ImportType } from '@/modules/data-management/api/importer.contracts';
import { CampaignMappingImportModal } from '@/modules/data-management/importers/campaign-mapping/CampaignMappingImportModal';
import { ImportDefinitionFactory } from '@/modules/data-management/types/import-factory';
import { ArrowDropDown } from '@mui/icons-material';
import { Button, ButtonGroup, Menu, MenuItem } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';

const ImportCampaignMappingsButton: FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onImportClicked = () => {
    setIsModalOpen(true);
  };

  const onDownloadTemplateClicked = () => {
    handleClose();
    const importer = ImportDefinitionFactory.create(ImportType.CAMPAIGN_MAPPING);
    window.open(importer.templateFileUrl, '_self');
  };

  return (
    <>
      <ButtonGroup>
        <Button variant="contained" onClick={onImportClicked}>
          Bulk Upload Campaign Map
        </Button>

        <Button variant="contained" onClick={handleClick}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={onDownloadTemplateClicked}>Download Import Template</MenuItem>
      </Menu>

      {isModalOpen && <CampaignMappingImportModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
    </>
  );
};

export default ImportCampaignMappingsButton;
