import { PayloadWithFiltersDTO } from '@/components/filter-builder/api/filters-contracts';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient, apiTeamClient } from '@/lib/api/base-client';
import { AdvertisedProductModel } from '@/modules/advertised-products/models/AdvertisedProductModel';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { QueryClient } from '@tanstack/react-query';
import { ProductModel } from '../models/ProductModel';
import {
  AdvertisedProductsWithTimeline,
  AdvertisedProductsWithTimelineDTO,
  ProductsWithTimeline,
  ProductsWithTimelineDTO,
} from './products-contracts';

// Don't expose this and only use creators
const _PRODUCTS_WITH_TIMELINE_QUERY_KEY = 'products-with-timeline';

// Main key to use
export function createProductsWithTimelineQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  const withTimelineQueryKey_withoutFilters = createProductsWithTimelineQueryKey_withoutFilters(activeProfileId);
  return [...withTimelineQueryKey_withoutFilters, filters.map((filter) => filter.toQueryKey())];
}

export function invalidateAll_productsWithTimelineQueryKeys(queryClient: QueryClient) {
  const key = [_PRODUCTS_WITH_TIMELINE_QUERY_KEY];
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

function createProductsWithTimelineQueryKey_withoutFilters(activeProfileId: string | undefined) {
  return [_PRODUCTS_WITH_TIMELINE_QUERY_KEY, activeProfileId];
}

// Don't expose this and only use creators
const _ADVERTISED_PRODUCT_WITH_TIMELINE_QUERY_KEY = 'advertisedProducts-with-timeline';

// Main key to use
export function createAdvertisedProductsWithTimelineQueryKey(activeProfileId: string | undefined, filters: AlFilterModel[]) {
  const withTimelineQueryKey_withoutFilters = createAdvertisedProductsWithTimelineQueryKey_withoutFilters(activeProfileId);
  return [...withTimelineQueryKey_withoutFilters, filters.map((filter) => filter.toQueryKey())];
}

function createAdvertisedProductsWithTimelineQueryKey_withoutFilters(activeProfileId: string | undefined) {
  return [_ADVERTISED_PRODUCT_WITH_TIMELINE_QUERY_KEY, activeProfileId];
}

class ProductsService {
  public basePath = 'products';

  async getProductsWithTimeline(filters: AlFilterModel[]): Promise<ApiResponse<ProductsWithTimeline>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }

      const applicationResponse = await apiProfileClient.post<ProductsWithTimelineDTO>(`${this.basePath}/with-timeline`, requestData);

      return applicationResponse.processPayload((payload) => ({
        products: ProductModel.fromResponseArray(payload.products),
        timeline: TimelineModel.fromProductsResponse(payload.timeline),
        isComparisonDataMissing: payload.comparison_missing,
      }));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAdvertisedProductsWithTimeline(filters: AlFilterModel[]): Promise<ApiResponse<AdvertisedProductsWithTimeline>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: PayloadWithFiltersDTO = {};
      if (filterData) {
        requestData.filters = filterData;
      }

      const applicationResponse = await apiProfileClient.post<AdvertisedProductsWithTimelineDTO>(
        `${this.basePath}/advertised/with-timeline`,
        requestData,
      );

      return applicationResponse.processPayload((payload) => ({
        products: AdvertisedProductModel.fromResponseArray(payload.products),
        timeline: TimelineModel.fromResponse(payload.timeline),
        isComparisonDataMissing: payload.comparison_missing,
      }));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getActiveProfileBrands(): Promise<ApiResponse<string[]>> {
    return await apiProfileClient.get<string[]>(`${this.basePath}/brands`);
  }

  async getBrandsByProfileId(profileId: string): Promise<ApiResponse<string[]>> {
    return await apiTeamClient.get<string[]>(`/profiles/${profileId}/${this.basePath}/brands`);
  }

  async getActiveProfileCategories(): Promise<ApiResponse<string[]>> {
    return await apiProfileClient.get<string[]>(`${this.basePath}/categories`);
  }

  async getCategoriesByProfileId(profileId: string): Promise<ApiResponse<string[]>> {
    return await apiTeamClient.get<string[]>(`/profiles/${profileId}/${this.basePath}/categories`);
  }

  async getActiveProfileASINs(): Promise<ApiResponse<string[]>> {
    return await apiProfileClient.get<string[]>(`${this.basePath}/asins`);
  }

  async getASINsByProfileId(profileId: string): Promise<ApiResponse<string[]>> {
    return await apiTeamClient.get<string[]>(`/profiles/${profileId}/${this.basePath}/asins`);
  }

  async getActiveProfileSKUs(): Promise<ApiResponse<string[]>> {
    return await apiProfileClient.get<string[]>(`${this.basePath}/skus`);
  }

  async getSKUsByProfileId(profileId: string): Promise<ApiResponse<string[]>> {
    return await apiTeamClient.get<string[]>(`/profiles/${profileId}/${this.basePath}/skus`);
  }

  async getActiveProfileParentASINs(): Promise<ApiResponse<string[]>> {
    return await apiProfileClient.get<string[]>(`${this.basePath}/parent-asins`);
  }

  async getParentASINsByProfileId(profileId: string): Promise<ApiResponse<string[]>> {
    return await apiTeamClient.get<string[]>(`/profiles/${profileId}/${this.basePath}/parent-asins`);
  }
}

export const productsService = new ProductsService();
