import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { IconButton, Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';
import useFilterFormatting from './hooks/useFilterFormatting';
import { AlFilterModel } from './models/AlFilterModel';

interface FilterListProps {
  filters: AlFilterModel[];
  handleClick: () => void;
  handleDelete: (filter: AlFilterModel) => void;
}

export const FilterList: FunctionComponent<FilterListProps> = ({ filters, handleClick, handleDelete }) => {
  const { getLabelForFilter } = useFilterFormatting();

  if (filters.length === 0) {
    return (
      <div className="w-full h-20 rounded border border-dashed border-gray-200 bg-gray-50 flex items-center justify-center">
        <div className=" text-gray-500 text-xs">No filters applied</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-2">
      {filters.map((filter, index) => (
        <div className="rounded-lg bg-gray-100 min-h-9 px-2 flex  justify-between " key={filter.key + index}>
          <div className="self-start my-1.5 cursor-pointer" onClick={handleClick}>
            {getLabelForFilter(filter)}
          </div>
          <div className="self-center">
            <IconButton
              onClick={() => {
                handleDelete(filter);
              }}
            >
              <Tooltip title="Remove this filter">
                <HighlightOffRoundedIcon />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
};
