import { FunctionComponent } from 'react';
import { RegistrationWizardStep } from './RegistrationWizardStep';
import { WizardStepState } from '../types/wizard_step_state.enum';
import { useLocation } from 'react-router';
import { Routes } from '@/router/router-paths';

const RegistrationWizardSteps: FunctionComponent = () => {
  const currentPath = useLocation().pathname;

  const steps = [
    {
      state: WizardStepState.NotStarted,
      title: 'Create your account',
      description: 'Supply your credentials',
      path: Routes.REGISTER_CREATE_YOUR_ACCOUNT,
    },
    {
      state: WizardStepState.NotStarted,
      title: 'About You',
      description: 'Tell us about yourself',
      path: Routes.REGISTER_ABOUT_YOU,
      isLastStep: currentPath != Routes.REGISTER_BOOK_CALL && currentPath != Routes.REGISTER_CONNECT_AMAZON,
    },
  ];

  if (currentPath === Routes.REGISTER_BOOK_CALL || currentPath === Routes.REGISTER_CONNECT_AMAZON) {
    steps.push(
      ...[
        {
          state: WizardStepState.NotStarted,
          title: 'Book a call',
          description: 'Book a call',
          path: Routes.REGISTER_BOOK_CALL,
          isLastStep: false,
        },
        {
          state: WizardStepState.NotStarted,
          title: 'Connect Amazon',
          description: 'Connect Amazon',
          path: Routes.REGISTER_CONNECT_AMAZON,
          isLastStep: true,
        },
      ],
    );
  }

  steps.forEach((step, i) => {
    if (step.path === currentPath) {
      step.state = WizardStepState.InProgress;
      // Set all previous steps to completed
      for (let j = 0; j < i; j++) {
        steps[j].state = WizardStepState.Completed;
      }
      // Set all next steps to not started
      for (let j = i + 1; j < steps.length; j++) {
        steps[j].state = WizardStepState.NotStarted;
      }
    }
  });

  return (
    <div className="flex flex-col items-start">
      <div className="grid w-full grid-cols-[min-content_1fr]">
        {steps.map((step, index) => (
          <RegistrationWizardStep
            key={index}
            state={step.state}
            title={step.title}
            description={step.description}
            isLastStep={step.isLastStep}
          />
        ))}
        {/* <RegistrationWizardStep state={WizardStepState.InProgress} title="Create your account" description="Supply your credentials" />
        <RegistrationWizardStep
          state={WizardStepState.NotStarted}
          title="Authorize with Amazon Ads"
          description="For AdLabs to access your Profiles, we require you to authenticate with Amazon Ads."
        />
        <RegistrationWizardStep
          state={WizardStepState.NotStarted}
          title="Select your Profiles"
          description="Select the Profiles you want to manage with AdLabs."
        />
        <RegistrationWizardStep
          state={WizardStepState.NotStarted}
          isLastStep
          title="Start Optimizing"
          description="Finetune your Bids like a Pro!"
        /> */}
      </div>
    </div>
  );
};

export default RegistrationWizardSteps;
