import { getMetricColumns } from '@/components/metrics/MetricsConfig';
import { GridApi } from 'ag-grid-enterprise';
import { useEffect } from 'react';
import { ComparisonUnit, ExpandedGridContext, FormatterType, GridToggles } from '../types';
import useColDefsFunctions from './useColDefsFunctions';
import { useComparator } from './useComparators';

interface UseTogglesProps<T> {
  gridApiRef: React.MutableRefObject<GridApi<T> | undefined>;
  gridContextRef: React.MutableRefObject<ExpandedGridContext | undefined>;
  gridToggles: GridToggles;
}

interface GridToggleParams {
  comparisonUnit?: ComparisonUnit;
  formatterType?: FormatterType;
}

const useToggles = <T>({ gridApiRef, gridContextRef, gridToggles }: UseTogglesProps<T>) => {
  // COMPARISON UNIT
  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current || !gridToggles.comparisonUnit) return;
    gridContextRef.current.comparisonUnit = gridToggles.comparisonUnit;
    gridApiRef.current.refreshCells({ columns: getMetricColumns(), force: true });
  }, [gridToggles.comparisonUnit]);

  // FORMATTER TYPE
  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current || !gridToggles.formatterType) return;
    gridContextRef.current.formatterType = gridToggles.formatterType;
    gridApiRef.current.refreshCells({ columns: getMetricColumns(), force: true });

    gridApiRef.current.refreshHeader();
  }, [gridToggles.formatterType]);

  const gridToggleParams: GridToggleParams = {
    comparisonUnit: gridToggles.comparisonUnit,
    formatterType: gridToggles.formatterType,
  };

  // COMPARATOR
  const { metricsDataComparator, metricsDataDeltaComparator, metricsDataDeltaPercentageComparator } = useColDefsFunctions();

  // Use the custom hook to get the appropriate comparator based on units
  const activeMetricComperator = useComparator(gridApiRef, gridToggles.sortByUnit, gridToggles.comparisonUnit, {
    metricsDataComparator,
    metricsDataDeltaComparator,
    metricsDataDeltaPercentageComparator,
  });

  return { gridToggleParams, activeMetricComperator };
};

export default useToggles;
