import { OptimizationResponse } from '../api/optimization-contracts';
import { AdGroupDebugRow } from './AdGroupDebugRow';
import { CampaignDebugRow } from './CampaignDebugRow';
import { PreviewDataRow } from './PreviewDataRow';

export enum BiddingEntity {
  PLACEMENT_TOP = 'PLACEMENT_TOP',
  PLACEMENT_PRODUCT_PAGE = 'PLACEMENT_PRODUCT_PAGE',
  PLACEMENT_REST_OF_SEARCH = 'PLACEMENT_REST_OF_SEARCH',

  KEYWORD = 'KEYWORD',
  PRODUCT_TARGET = 'PRODUCT_TARGET',
  // AUDIENCE = 'AUDIENCE', Do not want to add AUDIENCE to this enum as it is pseudo-type. It does not exist

  HOME = 'HOME',
  DETAIL_PAGE = 'DETAIL_PAGE',
  OTHER = 'OTHER',
  BRANDS_PLACEMENT_TOP = 'BRANDS_PLACEMENT_TOP', // only available under preview data
}

const percentEntities = [
  BiddingEntity.PLACEMENT_PRODUCT_PAGE,
  BiddingEntity.PLACEMENT_REST_OF_SEARCH,
  BiddingEntity.PLACEMENT_TOP,
  BiddingEntity.HOME,
  BiddingEntity.DETAIL_PAGE,
  BiddingEntity.OTHER,
  BiddingEntity.BRANDS_PLACEMENT_TOP,
];
export const isPercentEntity = (entity: BiddingEntity): boolean => percentEntities.includes(entity);

const currencyEntities = [BiddingEntity.KEYWORD, BiddingEntity.PRODUCT_TARGET];
export const isCurrencyEntity = (entity: BiddingEntity): boolean => currencyEntities.includes(entity);

enum OptimizationModelFields {
  PREVIEW_DATA = 'previewData',
  AD_GROUP_DEBUG_DATA = 'adGroupDebugData',
  CAMPAIGN_DEBUG_DATA = 'campaignDebugData',
  CAMPAIGN_GROUP_DEBUG_DATA = 'campaignGroupDebugData',
  PROFILE_DEBUG_DATA = 'profileDebugData',
}

type OptimizationModelType = number | PreviewDataRow[] | AdGroupDebugRow[] | CampaignDebugRow[] | CampaignDebugRow;

// TODO: refactor to DTO wrapper model instead
export class OptimizationModel implements Record<OptimizationModelFields, OptimizationModelType> {
  public jobId: number;
  public previewData: PreviewDataRow[];
  public adGroupDebugData: AdGroupDebugRow[];
  public campaignDebugData: CampaignDebugRow[];
  public campaignGroupDebugData: CampaignDebugRow[];
  public profileDebugData: CampaignDebugRow;
  public adTypeTotalsDebugData: CampaignDebugRow[];
  public isComparisonDataMissing: boolean;

  constructor(args: OptimizationCreateArguments) {
    this.jobId = args.jobId;
    this.previewData = args.previewData;
    this.adGroupDebugData = args.adGroupDebugData;
    this.campaignDebugData = args.campaignDebugData;
    this.campaignGroupDebugData = args.campaignGroupDebugData;
    this.profileDebugData = args.profileDebugData;
    this.adTypeTotalsDebugData = args.adTypeTotalsDebugData;
    this.isComparisonDataMissing = args.isComparisonDataMissing;
  }

  public static fromResponse(response: OptimizationResponse): OptimizationModel {
    const jobId = response.job_id;
    const previewData = PreviewDataRow.fromResponseArray(response.preview_data);
    const adGroupDebugData = AdGroupDebugRow.fromResponseArray(response.debug_data);
    const campaignDebugData = CampaignDebugRow.fromResponseArray(response.campaign_debug_data);
    const campaignGroupDebugData = CampaignDebugRow.fromResponseArray(response.campaign_group_debug_data);
    const profileDebugData = CampaignDebugRow.fromResponse(response.profile_debug_data);
    const adTypeTotalsDebugData = CampaignDebugRow.fromResponseArray(response.ad_type_totals_debug_data);

    return new OptimizationModel({
      jobId,
      previewData,
      adGroupDebugData,
      campaignDebugData,
      campaignGroupDebugData,
      profileDebugData,
      adTypeTotalsDebugData,
      isComparisonDataMissing: response.comparison_missing,
    });
  }
}

interface OptimizationCreateArguments {
  jobId: number;
  previewData: PreviewDataRow[];
  adGroupDebugData: AdGroupDebugRow[];
  campaignDebugData: CampaignDebugRow[];
  campaignGroupDebugData: CampaignDebugRow[];
  profileDebugData: CampaignDebugRow;
  adTypeTotalsDebugData: CampaignDebugRow[];
  isComparisonDataMissing: boolean;
}
