import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnState } from 'ag-grid-enterprise';

export function generateLogsTableColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.ENTITY_TYPE, hide: false, pinned: 'left' },
    { colId: ColumnId.ACTION, hide: false, pinned: 'left' },
    { colId: ColumnId.APPLIED_ENTITY_COUNT, hide: false },
    { colId: ColumnId.SUCCESS_ENTITY_COUNT, hide: false },
    { colId: ColumnId.FAILED_ENTITY_COUNT, hide: false },
    { colId: ColumnId.CREATED_AT, hide: false },
    { colId: ColumnId.USERNAME, hide: false },
  ];
}
