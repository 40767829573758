import { Card } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

const FilterBarCard: FunctionComponent<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    // Min height set to specific height to match the height of the filter bar with a search box in it, which is the most common use case.
    // This way, most of the time the filter bar will have the same height
    // Specific padding values are used to match bottom and top spacing with the search box, and left and right spacing between filterbar children
    <Card className="flex flex-row items-center gap-x-[7px] pt-[7px] px-[7px] pb-2.5 rounded-none rounded-tr-xl rounded-tl-xl -mb-1 relative overflow-visible h-[56px]">
      {children}
    </Card>
  );
};

export default FilterBarCard;
