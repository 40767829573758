import { createContext, useContext } from 'react';
import { User } from 'firebase/auth';

interface AuthUserContextType {
  authUser: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<User>;
  signUp: (email: string, password: string) => void;
  logOut: () => Promise<void>;
  signInWithGoogle: () => void;
  autoLogin: () => Promise<User | undefined>;
  authError: string | null;
  signInWithToken(token: string): Promise<User>;
  sendPasswordResetEmailViaFirebase(email: string): Promise<void>;
}

export const AuthUserContext = createContext<AuthUserContextType>({
  authUser: null,
  loading: true,
  signIn: () => new Promise(() => null),
  signUp: () => null,
  logOut: async () => new Promise(() => null),
  signInWithGoogle: () => null,
  autoLogin: () => new Promise(() => null),
  authError: null,
  signInWithToken: () => new Promise(() => null),
  sendPasswordResetEmailViaFirebase: () => new Promise(() => null),
});

export const useAuthUserContext = () => useContext(AuthUserContext);
