import { FunctionComponent } from 'react';

export const InfinitySpinner: FunctionComponent = () => {
  return (
    <div role="status">
      <svg width="300" aria-hidden="true" viewBox="0 0 187.3 93.7">
        <path
          className={`stroke-primary-600`}
          id="infinity-spinner"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="4"
          d="M93.9 46.4c9.3 9.5 13.8 17.9 23.5 17.9s17.5-7.8 17.5-17.5-7.8-17.6-17.5-17.5c-9.7.1-13.3 7.2-22.1 17.1-8.9 8.8-15.7 17.9-25.4 17.9s-17.5-7.8-17.5-17.5 7.8-17.5 17.5-17.5 16.3 9.3 24 17.1z"
        ></path>
        <path
          className="stroke-gray-400 "
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="4"
          d="M93.9 46.4c9.3 9.5 13.8 17.9 23.5 17.9s17.5-7.8 17.5-17.5-7.8-17.6-17.5-17.5c-9.7.1-13.3 7.2-22.1 17.1-8.9 8.8-15.7 17.9-25.4 17.9s-17.5-7.8-17.5-17.5 7.8-17.5 17.5-17.5 16.3 9.3 24 17.1z"
          opacity="0.05"
        ></path>
      </svg>

      <span className="sr-only">Loading...</span>
    </div>
  );
};
