import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { FormatterType } from '../grid/types';
import { ChevronRightOutlined } from '@mui/icons-material';

interface FormatterDropdownProps {
  handleFormatterChange: (newType: FormatterType) => void;
  formatterType: FormatterType;
}

const FormatterDropdown: FunctionComponent<FormatterDropdownProps> = ({ handleFormatterChange, formatterType = FormatterType.LONG }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent<FormatterType>) => {
    handleFormatterChange(event.target.value as FormatterType);
  };

  return (
    <div className="flex items-center">
      <div className="text-xs pr-1">Format</div>
      <Select
        className="cursor-pointer"
        onClick={() => setIsSelectOpen(!isSelectOpen)}
        open={isSelectOpen}
        value={formatterType}
        onChange={handleChange}
        size="small"
        displayEmpty
        inputProps={{ className: 'pl-2 pr-1' }}
        IconComponent={(props) => (
          <ChevronRightOutlined
            {...props}
            style={{ marginRight: 3, paddingRight: 0, display: 'flex' }}
            className="rotate-90 text-sm pr-1 text-slate-400"
          />
        )}
        renderValue={(selected) => (
          <div className="text-xs leading-none flex items-center h-full pt-[5px]  ">
            {selected === FormatterType.LONG ? (
              <span className="uppercase text-xxs font-semibold">1,234</span>
            ) : (
              <span className="uppercase text-xxs font-semibold">1.2k</span>
            )}
          </div>
        )}
        aria-label="display format"
      >
        <MenuItem value={FormatterType.LONG} className="text-xs" style={{ height: '15px' }}>
          <span className="">Long</span>
          <span className="ml-2 uppercase text-xxs font-semibold">1,234</span>
        </MenuItem>
        <MenuItem value={FormatterType.SHORT} className="text-xs" style={{ height: '15px' }}>
          <span className="">Short</span>
          <span className="ml-2 uppercase text-xxs font-semibold">1.2k</span>
        </MenuItem>
      </Select>
    </div>
  );
};

export default FormatterDropdown;
