export enum BetweenOperatorType {
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  BETWEEN = 'BETWEEN',
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
}
export enum DateOperatorType {
  BETWEEN = 'BETWEEN',
  NOT_BETWEEN = 'NOT_BETWEEN',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  NEVER = 'NEVER',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
}
