import { Environment } from '@/config/Environment';
import { useLayoutContext } from '@/contexts/LayoutContext';
import { ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { useLocation } from 'react-router';
import { MainMenuItemGroup } from '../types/MenuItem';
import { AdLabsLogo } from './AdLabsLogo';
import { MainMenuBottom } from './MainMenuBottom';
import { MainMenuItem } from './MainMenuItem';

const MOUSE_ENTER_HOVER_DELAY_MS = 220;
interface MainMenuProps {
  mainMenuItemGroups: MainMenuItemGroup[];
}

export const MainMenu: FunctionComponent<MainMenuProps> = ({ mainMenuItemGroups }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isDisplayingBanner } = useLayoutContext();

  // a map containing which menu groups are expanded
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});

  const currentLocation = useLocation();

  let hoverTimeout: string | number | NodeJS.Timeout | undefined;

  function onMouseEnter() {
    hoverTimeout = setTimeout(() => {
      setIsExpanded(true);
    }, MOUSE_ENTER_HOVER_DELAY_MS);
  }

  function onMouseLeave() {
    clearTimeout(hoverTimeout);
    setIsExpanded(false);
  }

  function isAnyPathActive(activePathnames: string[] | undefined) {
    if (!activePathnames) {
      return false;
    }

    return activePathnames.some((r) => {
      const regex = new RegExp(`^${r.replace('*', '.*')}$`);
      return regex.test(currentLocation.pathname);
    });
  }

  function onGroupClick(groupLabel: string) {
    setExpandedGroups((prevState) => {
      return {
        ...prevState,
        [groupLabel]: !prevState[groupLabel],
      };
    });
  }

  return (
    <div>
      <div className="w-16"></div>
      <aside
        className={` fixed top-0 z-20 flex h-full shrink-0 flex-col items-start justify-between overflow-y-auto overflow-x-hidden transition-all bg-slate-800 text-white  dark:border-r dark:border-r-gray-800 dark:bg-gray-800 ${
          isExpanded ? 'w-72 shadow-lg' : 'w-16'
        }
          ${isDisplayingBanner ? 'pt-8' : ''}
        `}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {/* <!-- Side Nav Bar--> */}
        <div className="w-full">
          <div className="ml-0.5 flex w-full items-center pt-3  pl-5">
            <AdLabsLogo isExpanded={isExpanded} />
          </div>
          <div className=" mx-2 mt-3  flex  flex-col gap-0.5">
            {mainMenuItemGroups.map((mmg) => (
              <div key={mmg.label} className="pt-0.5 pb-0.5">
                {mmg.hideHeader && mmg.children && mmg.children[0] ? (
                  <MainMenuItem
                    isExpanded={isExpanded}
                    label={mmg.children[0].label}
                    pathname={mmg.children[0].url}
                    activePathnames={mmg.children[0].activeRouteIds}
                    icon={mmg.children[0].icon}
                    key={mmg.children[0].url}
                    isBeta={mmg.children[0].isBeta}
                    paywallMessage={mmg.children[0].paywallMessage}
                    shouldDisplayPaywall={mmg.children[0].shouldDisplayPaywall}
                  />
                ) : (
                  <>
                    <div
                      onClick={() => onGroupClick(mmg.label)}
                      className={`flex items-center px-2 rounded-lg cursor-pointer  ${
                        isAnyPathActive(mmg.children?.flatMap((c) => c.activeRouteIds))
                          ? ' text-primary-50  dark:text-primary-500'
                          : 'text-blue-50 hover:bg-primary-200 hover:bg-opacity-20 dark:text-white dark:hover:bg-gray-700'
                      }`}
                    >
                      {mmg.icon && (
                        <div
                          className={`pl-1 pt-1.5 
                      ${
                        isAnyPathActive(mmg.children?.flatMap((c) => c.activeRouteIds))
                          ? ' text-primary-300  dark:text-primary-500'
                          : 'text-blue-50  dark:text-white '
                      }
                      `}
                        >
                          {mmg.icon}
                        </div>
                      )}
                      <div
                        className={`   font-semibold  transition-opacity  pt-px   flex justify-between items-center ${isExpanded ? 'w-60 shadow-lg  h-3 px-2 ' : 'w-0 h-0 p-0 opacity-0 overflow-hidden truncate '}
                    
                      `}
                      >
                        <span
                          className={`truncate  ${
                            isAnyPathActive(mmg.children?.flatMap((c) => c.activeRouteIds))
                              ? ' text-primary-300  dark:text-primary-500'
                              : 'text-blue-50  dark:text-white '
                          }`}
                        >
                          {' '}
                          {mmg.label}
                        </span>
                        <div
                          className={`transition-transform origin-center  transform  h-6
                          ${expandedGroups[mmg.label] ? 'rotate-180 ' : 'rotate-0'}
                          ${
                            isAnyPathActive(mmg.children?.flatMap((c) => c.activeRouteIds))
                              ? ' text-primary-300  dark:text-primary-500'
                              : 'text-blue-50  dark:text-white '
                          }
                          `}
                        >
                          <ExpandMore className="p-0" />
                        </div>
                      </div>

                      {/* <div className={` ${isExpanded ? 'opacity-0' : i > 0 ? 'opacity-100  w-10  h-3' : ' opacity-0 w-10  h-3'}  mx-auto`}>
                      <div className="h-px w-full bg-gray-500"></div>
                    </div> */}
                    </div>
                    <Collapse in={expandedGroups[mmg.label] || mmg.hideHeader}>
                      <div className="flex flex-col w-full  pl-6 mt-1">
                        {mmg.children
                          ?.filter((i) => {
                            if (i.isDevOnly) {
                              return Environment.isAdlabsDev();
                            }
                            return true;
                          })
                          .map((mmi) => (
                            <div className="flex w-full" key={mmi.url}>
                              <div className="border-l border-gray-500 h-8 pr-2"></div>
                              {isExpanded && (
                                <MainMenuItem
                                  isExpanded={isExpanded}
                                  label={mmi.label}
                                  pathname={mmi.url}
                                  activePathnames={mmi.activeRouteIds}
                                  isBeta={mmi.isBeta}
                                  paywallMessage={mmi.paywallMessage}
                                  shouldDisplayPaywall={mmi.shouldDisplayPaywall}
                                />
                              )}
                            </div>
                          ))}
                      </div>
                    </Collapse>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="mb-4 flex w-full px-3">
          <MainMenuBottom isExpanded={isExpanded} />
        </div>
      </aside>
    </div>
  );
};
