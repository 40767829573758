import { RouteObject } from 'react-router';
import CampaignMappingPage from '../pages/CampaignMappingPage';

export const CAMPAIGN_MAPPING_ROUTES: RouteObject[] = [
  {
    path: 'campaign-mapping',
    children: [
      {
        index: true,
        element: <CampaignMappingPage />,
      },
    ],
  },
];
