import { isEmpty } from 'lodash-es';
import * as Sentry from '@sentry/react';

export enum Timezone {
  AfricaCairo = 'Africa/Cairo',
  AmericaSaoPaulo = 'America/Sao_Paulo',
  AmericaLosAngeles = 'America/Los_Angeles',
  AsiaDubai = 'Asia/Dubai',
  AsiaKolkata = 'Asia/Kolkata',
  AsiaRiyadh = 'Asia/Riyadh',
  AsiaSingapore = 'Asia/Singapore',
  AsiaTokyo = 'Asia/Tokyo',
  AustraliaSydney = 'Australia/Sydney',
  EuropeAmsterdam = 'Europe/Amsterdam',
  EuropeIstanbul = 'Europe/Istanbul',
  EuropeLondon = 'Europe/London',
  EuropeParis = 'Europe/Paris',
  EuropeStockholm = 'Europe/Stockholm',
  EuropeWarsaw = 'Europe/Warsaw',
  EuropeBrussels = 'Europe/Brussels',
}

export function getTimezone(timezone: string): Timezone {
  switch (timezone) {
    case 'Africa/Cairo':
      return Timezone.AfricaCairo;
    case 'America/Sao_Paulo':
      return Timezone.AmericaSaoPaulo;
    case 'America/Los_Angeles':
      return Timezone.AmericaLosAngeles;
    case 'Asia/Dubai':
      return Timezone.AsiaDubai;
    case 'Asia/Kolkata':
      return Timezone.AsiaKolkata;
    case 'Asia/Riyadh':
      return Timezone.AsiaRiyadh;
    case 'Asia/Singapore':
      return Timezone.AsiaSingapore;
    case 'Asia/Tokyo':
      return Timezone.AsiaTokyo;
    case 'Australia/Sydney':
      return Timezone.AustraliaSydney;
    case 'Europe/Amsterdam':
      return Timezone.EuropeAmsterdam;
    case 'Europe/Istanbul':
      return Timezone.EuropeIstanbul;
    case 'Europe/London':
      return Timezone.EuropeLondon;
    case 'Europe/Paris':
      return Timezone.EuropeParis;
    case 'Europe/Stockholm':
      return Timezone.EuropeStockholm;
    case 'Europe/Warsaw':
      return Timezone.EuropeWarsaw;
    case 'Europe/Brussels':
      return Timezone.EuropeBrussels;
    // Not all endpoints return a timezone (e.g. getUserProfilesWithMetrics)
    default:
      if (!isEmpty(timezone)) {
        console.log(`Invalid timezone : ${timezone}`);
        Sentry.captureMessage(`Invalid timezone : ${timezone}`);
      }
      return Timezone.AmericaLosAngeles;
  }
}
