import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import NumbersIcon from '@mui/icons-material/Numbers';
import PercentIcon from '@mui/icons-material/Percent';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FunctionComponent, useState } from 'react';
import { ComparisonUnit } from '../grid/types';
import { ChevronRightOutlined } from '@mui/icons-material';

interface UnitDropdownProps {
  handleComparisonUnitChange: (newUnit: ComparisonUnit) => void;
  comparisonUnit: ComparisonUnit;
}

const UnitDropdown: FunctionComponent<UnitDropdownProps> = ({ handleComparisonUnitChange, comparisonUnit = 'percent' }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent<ComparisonUnit>) => {
    handleComparisonUnitChange(event.target.value as ComparisonUnit);
  };

  return (
    <div className="flex items-center">
      <div className="text-xs pr-1">Comparison</div>
      <Select
        className="cursor-pointer"
        onClick={() => setIsSelectOpen(!isSelectOpen)}
        open={isSelectOpen}
        value={comparisonUnit}
        onChange={handleChange}
        size="small"
        displayEmpty
        inputProps={{ className: 'pl-2 pr-1' }}
        IconComponent={(props) => (
          <ChevronRightOutlined
            {...props}
            style={{ marginRight: 3, paddingRight: 0, display: 'flex' }}
            className="rotate-90 text-sm pr-1 text-slate-400"
          />
        )}
        renderValue={(selected) => (
          <div className="text-xs leading-none flex items-center h-full pt-1  ">
            {selected === 'hidden' ? (
              <VisibilityOffIcon sx={{ fontSize: 'inherit' }} />
            ) : selected === 'nominal' ? (
              <NumbersIcon className="leading-none" sx={{ fontSize: 'inherit' }} />
            ) : (
              <PercentIcon sx={{ fontSize: 'inherit' }} />
            )}
          </div>
        )}
        aria-label="comparison value unit"
      >
        <MenuItem value="hidden" className="text-xs" style={{ height: '15px' }}>
          <span>Hide comparison</span>
          <VisibilityOffIcon className="ml-1" sx={{ fontSize: 'inherit' }} />
        </MenuItem>
        <MenuItem value="nominal" className="text-xs" style={{ height: '15px' }}>
          <span>Nominal</span>
          <NumbersIcon className="ml-1" sx={{ fontSize: 'inherit' }} />
        </MenuItem>
        <MenuItem value="percent" className="text-xs" style={{ height: '15px' }}>
          <span>Percentage</span>
          <PercentIcon className="ml-1" sx={{ fontSize: 'inherit' }} />
        </MenuItem>
      </Select>
    </div>
  );
};

export default UnitDropdown;
